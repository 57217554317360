import React from "react";
import './nav-bar-option.styles.scss';

/**
 * @param { name} string keeps the text for the menu option
 * @param { imageUrl} string keeps the url for the iamge to be displayed as part of the menu option
 * @param { onClick} function is a callback function where the click event is captured 
 * @param { isActive } boolean detects whether the current option has been clicked - updated after callback function being called
 * @returns Single clickable menu option with an image and text
 */
const NavBarOption = ({name, Image, onClick, isActive }) => {
    return (
        <div className='nav-bar-option' onClick={onClick}>
            <div className='nav-bar-option__icon'>
                <img src={Image} alt='option generic' />
            </div>
            <div className='nav-bar-option__name'>
                <span>{name}</span>
            </div>
            <div className={`nav-bar-option__triangle ${!isActive ? 'd-none' : '' }` }></div>
        </div>
    )
}
export default NavBarOption;