import StepComponent from "../components/step.component";

export default {
  title: "Phases",
  description: "Select the system's phase setup.",
  component: StepComponent,
  schema: {
    properties: {
      numberOfPhases: {
        type: "string",
        title: "Meter Voltage and Phases",
        enum: ["1x 230V", "3x 230V", "3x 400V"],
      },
    },
  },
  uiSchema: {
    numberOfPhases: {
      "ui:widget": "radio",
      "ui:options": {
        inline: false,
      },
    },
  },
};
