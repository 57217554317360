const otpInputStyles = {
    container: {
        display: 'flex',
        width: '80%',
        height: '80%',
    },
    inputStyle: {
        width: '35px',
        height: '50px',
        margin: '2px',
        fontSize: '2rem',
        borderRadius: '4px',
        border: '1px solid rgba(0, 0, 0, 0.3)',
        padding: '0',
    },
};

export default otpInputStyles;
