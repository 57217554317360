import {
    BatteryAlert,
    BatterySaver,
    BatteryStd, BatteryUnknown,
    Check,
    Handyman, HealthAndSafety,
    NotInterested,
    QuestionMark
} from "@mui/icons-material";
import React from "react";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import {Box} from "@mui/material";

const emsModes = {
    '0': {
        label: 'Inactive',
        icon: BatteryAlert,
        description: 'Disable the battery by setting the power setpoint to zero',
        color: 'error.main',
        settable: true,
        settablePartner: true,
    },
    '1': {
        label: 'Normal',
        icon: Check,
        description: 'This is the mode FlexiO should be in when the installation is done',
        color: 'success.main',
        settable: true,
        settablePartner: true,
    },
    '3': {
        label: 'Cycle',
        icon: Handyman,
        description: 'Battery will be fully charged (Max State of Charge) and discharged (Min State of charge) to test if battery is charged/discharged correctly',
        color: 'secondary.main',
        settable: false,
        settablePartner: false,
    },
    '4': {
        label: 'Explore',
        icon: BatteryUnknown,
        description: 'Test whether the battery is reacting correctly to charge/discharge commands',
        color: 'secondary.main',
        settable: false,
        settablePartner: false,
    },
    '5': {
        label: 'Balance',
        icon: BatterySaver,
        description: 'Keep charging the battery, to invoke cell balancing',
        color: 'secondary.main',
        settable: true,
        settablePartner: true,
    },
    '7': {
        label: 'No EMS',
        icon: NotInterested,
        description: 'Disable the EMS, let the external systems work on their own',
        color: 'warning.main',
        settable: true,
        settablePartner: true,
    },
    '8': {
        label: 'Self-Consumption',
        icon: BatteryStd,
        description: 'In this mode the battery will work in full self-consumption',
        color: 'secondary.main',
        settable: true,
        settablePartner: true,
    },
    '9': {
        label: 'Uninitialized',
        icon: QuestionMark,
        description: 'The device has just started up, and another mode needs to be chosen',
        color: 'error.main',
        settable: false,
        settablePartner:false,
    },
}

function EmsMode(props){
    const { mode, wrapper, disabled } = props;
    let label = 'Unknown';
    let description = 'Operational mode could not be found'
    let Icon = QuestionMark
    let color = 'secondary.main';
    

    if (mode in emsModes) {
        const inner = emsModes[mode];
        ( {label, description, icon: Icon, color} = inner )
    }

    return (
       <Tooltip title={description} placement="right-start">
            <Box width="100%" color={color}>
                <Icon />
                {label}
            </Box>
       </Tooltip> 
    )
}

export { emsModes, EmsMode };
