import React from 'react';
import './status-yellow.styles.scss';
import RoundGraph from '@lifepowr/components/src/components-shared/round-graph-component/round-graph.component';

/**
 * 
 * @param { title } string  top text of component 
 * @param { text } string description for component info
 * @param { values }  object 
 *      current - Current value read from socket for this type of unit 
 * 		max - Max value to display in graph
 * 		unit - Unit to display in label	
 * @param { type } string Type of units read to be displayed in this component passed to round graph to display in graph tooltip
 * @returns renderization of a yellow component with information and a doughnut graph 
 */
const StatusYellow = ( {title, text, values, type, ...otherProps} ) => {
    const {current, unit, max} = values;
    const prefixes = [["G", 1e9], ["M", 1e6], ["k", 1e3], ["", 1]];
    const display = {};

    for (var i = 0; i < prefixes.length; i++) {
        let [prefix, power] = prefixes[i];
        display.current = current / power;
        display.max = max / power;
        display.unit = `${prefix}${unit}`;

        if(Math.abs(display.current) > 1 || display.max > 1) break;
    }

    return (
        <div className='yellow-status mb-3 mb-lg-0'>
            <div className='yellow-status__info'>
                <h2>{title}</h2>
                <p>{text}</p>
            </div>
            <div className='yellow-status__graph-wrapper'>
                <div className='yellow-status__graph'>
                    <RoundGraph current={ (values.current) } remaining={(values.max - values.current)} unit={values.unit} type={type} backgroundColorI={'#4c9292'} backgroundColorII={'#ffce86'} cutout={55}/>
                </div>
                <div className='yellow-status__labels'>
                    <span className='yellow-status__labels__value'>{display.current.toFixed(2)}</span><br/>
                    <span className='yellow-status__labels__unit'>{display.unit}</span>
                </div>
                <span className='yellow-status__percentage'>{ Math.abs(((current/max) * 100)).toFixed(0) }%</span>
                <span className='yellow-status__max'>{display.max.toFixed(2)}</span>
            </div>
        </div>
    )
}
export default StatusYellow;
;