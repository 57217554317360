import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import './single-device.styles.scss';
import Icon from '@lifepowr/components/src/assets/icons/filter-green.png'
/**
 * This component is responsible for the individual renderization of each device on the devices page.
 * @param { name} string with the name for the device 
 * @param { id } string with the device ID to be shown in gray
 * @param { reported } object to search inside reported?.diagnostics for a device that requires attention and thus display with the red dot
 * @param { connected } boolean - with the status of the device to display the yellow color 
 * @returns individual device renderization of each device and a link to the device's individual page.
 */
const SingleDevice = ({ name, id, reported = {}, connected }) => {
    const { operational = false, diagnostics = {} } = reported;
    const { search } = useLocation();

    const needsAttention = false /*!Object.entries(diagnostics).reduce( (acc, [diagnsotic, value]) => {
        acc = ((typeof value) === 'object') ? acc && value.status : acc;

        return acc;
    }, true);*/

    return (
        <Link className='single-device-link' to={`devices/${id}${search}`} >
            <div className='single-device p-2 d-flex flex-row'>
                <div className='single-device__icon p-2'>
                    <span className='dot green'>
                        <span className='single-device__icon__img'><img src={Icon} alt='house representing green filter' /></span>
                    </span>
                </div>
                <div className='single-device__info d-flex flex-row p-2'>
                    <div className='single-device__info'>
                        <h3 className='single-device__title'>{name.split("_").join(" ")}</h3>
                        <span className='single-device__id'>{id}</span>
                    </div>
                    <div className='single-device__filters d-flex flex-column ml-2'>

                        {!connected && <span className="dot yellow"></span>}
                        {needsAttention && <span className="dot red"></span>}
                    </div>

                </div>
            </div>
        </Link>
    )
}

export default SingleDevice
