import './sunergy_logo.styles.scss';

function SunergyLogo(props) {
  return (
    <svg
      width="150"
      height="41.625"
      viewBox="0 0 400 111"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className="sunergy-logo"
    >
    <path d="M0 55.507C0 25.038 24.7 0.338013 55.169 0.338013H344.831C375.3 0.338013 400 25.038 400 55.507C400 85.976 375.3 110.676 344.831 110.676H55.169C24.7 110.676 0 85.976 0 55.507Z" fill="#FFD300"/>
    <path d="M68.3784 62.083L91.7232 85.4278L91.7264 85.4246C92.5782 86.2762 93.7549 86.8029 95.0546 86.8029C97.6545 86.8029 99.7621 84.6952 99.7621 82.0953C99.7621 80.7206 99.1729 79.4836 98.2333 78.6229L71.8383 52.2279C71.7953 52.1814 71.7513 52.1355 71.7061 52.0904C69.8677 50.2519 66.887 50.2519 65.0486 52.0904C64.9953 52.1437 64.9435 52.1979 64.8933 52.2531L38.5234 78.623C37.5838 79.4836 36.9946 80.7207 36.9946 82.0953C36.9946 84.6953 39.1022 86.8029 41.7021 86.8029C43.0018 86.8029 44.1785 86.2762 45.0303 85.4247L45.0335 85.4279L68.3784 62.083Z" fill="#2A1243"/>
    <path d="M63.6708 79.48C63.6708 78.3245 64.6075 77.3878 65.763 77.3878H70.9937C72.1492 77.3878 73.0859 78.3245 73.0859 79.48V84.7107C73.0859 85.8662 72.1492 86.8029 70.9937 86.8029H65.763C64.6075 86.8029 63.6708 85.8662 63.6708 84.7107V79.48Z" fill="#2A1243"/>
    <path d="M66.2861 26.1276C66.2861 24.9721 67.2228 24.0353 68.3784 24.0353C69.5339 24.0353 70.4706 24.9721 70.4706 26.1276V31.3582C70.4706 32.5137 69.5339 33.4505 68.3784 33.4505C67.2228 33.4505 66.2861 32.5137 66.2861 31.3582V26.1276Z" fill="#FF486A"/>
    <path d="M97.6699 53.3269C98.8254 53.3269 99.7622 54.2636 99.7622 55.4191C99.7622 56.5747 98.8254 57.5114 97.6699 57.5114H92.4393C91.2837 57.5114 90.347 56.5747 90.347 55.4191C90.347 54.2636 91.2837 53.3269 92.4393 53.3269H97.6699Z" fill="#FF486A"/>
    <path d="M44.3174 53.3269C45.473 53.3269 46.4097 54.2636 46.4097 55.4191C46.4097 56.5747 45.473 57.5114 44.3174 57.5114H39.0868C37.9313 57.5114 36.9946 56.5747 36.9946 55.4191C36.9946 54.2636 37.9313 53.3269 39.0868 53.3269H44.3174Z" fill="#FF486A"/>
    <path d="M87.611 33.2274C88.4281 32.4103 89.7528 32.4103 90.5699 33.2274C91.387 34.0445 91.387 35.3692 90.5699 36.1863L86.8713 39.8849C86.0542 40.702 84.7294 40.702 83.9124 39.8849C83.0953 39.0679 83.0953 37.7431 83.9124 36.926L87.611 33.2274Z" fill="#FF486A"/>
    <path d="M52.8439 36.926C53.661 37.7431 53.661 39.0678 52.8439 39.8849C52.0269 40.702 50.7021 40.702 49.885 39.8849L46.1864 36.1863C45.3694 35.3692 45.3694 34.0445 46.1864 33.2274C47.0035 32.4103 48.3282 32.4103 49.1453 33.2274L52.8439 36.926Z" fill="#FF486A"/>
    <path d="M40.5656 73.2349C39.7485 74.0514 38.4242 74.0512 37.6074 73.2343C36.7903 72.4172 36.7903 71.0925 37.6074 70.2754C37.643 70.2398 37.6796 70.2057 37.717 70.1732L52.8652 55.025L55.8241 57.9839L40.5694 73.2387L40.5656 73.2349Z" fill="#FF486A"/>
    <path d="M57.5229 56.2774C57.4857 55.9092 57.4666 55.5356 57.4666 55.1576C57.4666 49.0911 62.3844 44.1733 68.4509 44.1733C74.5174 44.1733 79.4352 49.0911 79.4352 55.1576C79.4352 55.5809 79.4113 55.9986 79.3647 56.4094L86.0893 63.134C87.1909 60.7018 87.8042 58.0013 87.8042 55.1576C87.8042 44.4691 79.1394 35.8043 68.4509 35.8043C57.7623 35.8043 49.0975 44.4691 49.0975 55.1576C49.0975 57.9621 49.6941 60.6273 50.7672 63.0331L57.5229 56.2774Z" fill="#FF486A"/>
    <path d="M129.862 47.9388C129.862 48.873 130.469 49.6438 131.684 50.251C132.945 50.8115 134.463 51.3253 136.238 51.7924C138.013 52.2128 139.788 52.7734 141.563 53.474C143.338 54.1279 144.833 55.249 146.047 56.8371C147.308 58.4253 147.939 60.4105 147.939 62.7927C147.939 66.3894 146.584 69.1687 143.875 71.1305C141.213 73.0456 137.873 74.0032 133.856 74.0032C127.031 74.0032 122.266 71.5012 119.562 66.4972C119.312 66.0356 119.496 65.4662 119.953 65.209L125.937 61.843C126.484 61.5356 127.17 61.8057 127.457 62.3633C128.636 64.6581 130.769 65.8055 133.856 65.8055C137.079 65.8055 138.69 64.8012 138.69 62.7927C138.69 61.8585 138.06 61.1111 136.798 60.5506C135.584 59.9434 134.089 59.4062 132.314 58.9391C130.539 58.472 128.764 57.8881 126.989 57.1875C125.214 56.4868 123.696 55.3891 122.435 53.8944C121.221 52.353 120.613 50.4378 120.613 48.149C120.613 44.6925 121.875 41.9833 124.397 40.0214C126.966 38.0129 130.142 37.0086 133.926 37.0086C136.775 37.0086 139.368 37.6626 141.703 38.9705C143.746 40.0737 145.414 41.6058 146.706 43.5669C147.014 44.0343 146.835 44.6554 146.344 44.9234L140.464 48.1308C139.958 48.4067 139.331 48.1935 139.017 47.7102C137.873 45.9475 136.176 45.0662 133.926 45.0662C132.758 45.0662 131.777 45.3231 130.983 45.8369C130.236 46.3507 129.862 47.0513 129.862 47.9388Z" fill="#2A1243"/>
    <path d="M174.871 38.9905C174.871 38.4377 175.319 37.9896 175.872 37.9896H182.909C183.462 37.9896 183.91 38.4377 183.91 38.9905V72.0213C183.91 72.5741 183.462 73.0223 182.909 73.0223H175.787C175.281 73.0223 174.871 72.6121 174.871 72.1062C174.871 71.2014 173.569 70.7542 172.876 71.3362C170.76 73.1142 167.945 74.0032 164.432 74.0032C160.601 74.0032 157.425 72.7186 154.903 70.1496C152.427 67.5805 151.189 64.0305 151.189 59.4996V38.9905C151.189 38.4377 151.637 37.9896 152.19 37.9896H159.227C159.779 37.9896 160.228 38.4377 160.228 38.9905V58.3786C160.228 60.7141 160.858 62.5124 162.119 63.7736C163.381 64.9881 165.062 65.5953 167.164 65.5953C169.5 65.5953 171.368 64.8713 172.769 63.4233C174.171 61.9753 174.871 59.8032 174.871 56.9072V38.9905Z" fill="#2A1243"/>
    <path d="M209.664 37.0086C213.494 37.0086 216.647 38.2932 219.123 40.8622C221.645 43.4313 222.907 46.9813 222.907 51.5122V72.0213C222.907 72.5741 222.458 73.0223 221.906 73.0223H214.869C214.316 73.0223 213.868 72.5741 213.868 72.0213V52.6332C213.868 50.2977 213.237 48.5227 211.976 47.3083C210.715 46.0471 209.034 45.4165 206.932 45.4165C204.596 45.4165 202.728 46.1405 201.326 47.5885C199.925 49.0365 199.224 51.2086 199.224 54.1046V72.0213C199.224 72.5741 198.776 73.0223 198.223 73.0223H191.187C190.634 73.0223 190.186 72.5741 190.186 72.0213V38.9905C190.186 38.4377 190.634 37.9896 191.187 37.9896H198.308C198.814 37.9896 199.224 38.3997 199.224 38.9056C199.224 39.8104 200.527 40.2577 201.22 39.6757C203.336 37.8976 206.151 37.0086 209.664 37.0086Z" fill="#2A1243"/>
    <path d="M237.575 59.2194C236.913 59.2194 236.432 59.8526 236.683 60.4643C238.149 64.0251 241.301 65.8055 246.139 65.8055C249.244 65.8055 251.671 64.8628 253.419 62.9775C253.762 62.6079 254.313 62.491 254.75 62.7428L260.332 65.9633C260.839 66.2557 260.989 66.9205 260.63 67.3822C257.197 71.7962 252.32 74.0032 245.998 74.0032C240.206 74.0032 235.559 72.2515 232.055 68.7483C228.552 65.245 226.801 60.8309 226.801 55.5059C226.801 50.2276 228.529 45.8369 231.985 42.3336C235.442 38.7836 239.879 37.0086 245.298 37.0086C250.436 37.0086 254.663 38.7836 257.98 42.3336C261.343 45.8836 263.024 50.2744 263.024 55.5059C263.024 56.4246 262.952 57.3866 262.808 58.392C262.739 58.872 262.321 59.2194 261.836 59.2194H237.575ZM236.465 51.0057C236.253 51.6129 236.732 52.2128 237.376 52.2128H252.746C253.384 52.2128 253.862 51.6219 253.664 51.0158C253.078 49.2268 252.111 47.8509 250.763 46.8879C249.175 45.7201 247.353 45.1362 245.298 45.1362C242.869 45.1362 240.86 45.7668 239.272 47.028C237.982 48.0147 237.046 49.3406 236.465 51.0057Z" fill="#2A1243"/>
    <path d="M276.371 39.9149C276.371 40.9629 277.877 41.3525 278.571 40.5677C279.12 39.9473 279.744 39.4155 280.505 38.9705C282.188 38.0083 284.027 37.4591 286.02 37.3227C286.572 37.2849 287.021 37.737 287.021 38.2898V46.2516C287.021 46.8511 286.496 47.3121 285.897 47.2937C283.588 47.2227 281.486 47.8114 279.594 49.0599C277.445 50.5079 276.371 52.9135 276.371 56.2766V72.0213C276.371 72.5741 275.923 73.0223 275.37 73.0223H268.333C267.78 73.0223 267.332 72.5741 267.332 72.0213V38.9905C267.332 38.4377 267.78 37.9896 268.333 37.9896H275.37C275.923 37.9896 276.371 38.4377 276.371 38.9905V39.9149Z" fill="#2A1243"/>
    <path d="M315.84 38.9905C315.84 38.4377 316.288 37.9896 316.841 37.9896H323.667C324.22 37.9896 324.668 38.4377 324.668 38.9905V71.3407C324.668 76.7591 322.87 80.8929 319.273 83.7423C315.723 86.5916 311.426 88.0163 306.381 88.0163C302.597 88.0163 299.258 87.2922 296.362 85.8442C293.807 84.5459 291.778 82.7032 290.275 80.316C289.984 79.8539 290.155 79.2507 290.629 78.9793L296.594 75.5631C297.082 75.2839 297.699 75.4643 298.015 75.9288C299.841 78.6154 302.724 79.9587 306.661 79.9587C309.511 79.9587 311.753 79.2114 313.388 77.7166C315.022 76.2219 315.84 74.0966 315.84 71.3407V70.56C315.84 69.598 314.529 69.1257 313.8 69.753C311.374 71.8391 308.317 72.8821 304.629 72.8821C299.678 72.8821 295.521 71.1538 292.158 67.6973C288.841 64.194 287.183 59.9434 287.183 54.9454C287.183 49.9941 288.865 45.7668 292.228 42.2635C295.591 38.7603 299.725 37.0086 304.629 37.0086C308.317 37.0086 311.374 38.0517 313.8 40.1378C314.529 40.765 315.84 40.2927 315.84 39.3308V38.9905ZM298.954 61.8819C300.822 63.6568 303.181 64.5443 306.031 64.5443C308.88 64.5443 311.216 63.6568 313.037 61.8819C314.906 60.0602 315.84 57.748 315.84 54.9454C315.84 52.1895 314.906 49.924 313.037 48.149C311.216 46.3273 308.88 45.4165 306.031 45.4165C303.181 45.4165 300.822 46.3273 298.954 48.149C297.132 49.924 296.222 52.1895 296.222 54.9454C296.222 57.748 297.132 60.0602 298.954 61.8819Z" fill="#2A1243"/>
    <path d="M353.477 38.6765C353.612 38.2665 353.995 37.9896 354.427 37.9896H361.943C362.638 37.9896 363.121 38.68 362.884 39.3329L350.621 73.0223C348.799 78.067 346.37 81.7104 343.334 83.9525C340.558 86.0777 337.138 87.1155 333.073 87.0661C332.541 87.0596 332.123 86.6177 332.123 86.0852V79.6479C332.123 79.0875 332.584 78.6409 333.144 78.6126C335.095 78.5141 336.67 78.0052 337.869 77.086C339.242 76.0674 340.342 74.4608 341.169 72.2662C341.257 72.0325 341.248 71.7737 341.151 71.5438L327.528 39.3809C327.248 38.7209 327.733 37.9896 328.45 37.9896H336.132C336.545 37.9896 336.916 38.2437 337.065 38.6293L344.918 58.9267C345.257 59.8029 346.508 59.7715 346.802 58.8795L353.477 38.6765Z" fill="#2A1243"/>
    </svg>


  );
}

export default SunergyLogo;
