import React from "react";
import './circular-progress-loader.styles.scss'
import { CircularProgress } from "@mui/material";
/**
 * @returns 
 */
const CircularProgressLoader = ({ key, size }) => {
  return <div key={key} className='circular-progress-loader'>
    <CircularProgress size={size ? size : '5em'} />
  </div>
}

export default CircularProgressLoader;
