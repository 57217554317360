
import React, { useState } from "react";
import './header.styles.scss';

// import LifepowrLogo from "../lifepowr-logo/lifepowr-logo";
import { Link } from 'react-router-dom';
import { ReactComponent as LifepowrLogo } from '@lifepowr/components/src/assets/logos/Lifepowr-Full-Logo-Black.svg';
import DateView from "@lifepowr/components/src/components-shared/date-component/date-view.component";
import UserDisplay from "@lifepowr/components/src/components-shared/user-display-component/user-display.component";

import getClientInfo from "../../clients";

/**
 * @returns The application's header for the application. Includes the Date and the User displays
 */
const Header = ({ userMode, }) => {
    let clientInfo = getClientInfo();
    const Logo = clientInfo ? clientInfo.logo : LifepowrLogo;

    return (
        <div className='header-component'>
            <div className='container'>
                <div className='row'>
                    <div className='header-component__logo col-5 col-md-6'>
                        { Logo ?
                          <Link to="/">
                            <Logo className="logo"/>
                          </Link>

                           : null}
                    </div>
                    <div className='header-component__dateNuser col-7 col-md-6'>
                            <div className='header-component__date-view col-12 col-lg-6'> <DateView  showWeekday={true} /> </div>
                            <div className='header-component__user-display col-12 col-lg-6'> <UserDisplay userMode={userMode}/> </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;
