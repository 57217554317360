import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import useSocket from "@lifepowr/components/src/components/socket/useSocket";
import validator from "@rjsf/validator-ajv8";

import { API } from "Amplify";

import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";

import { get, isEmpty, isUndefined, omit, omitBy } from "lodash";
import {
  generateConverterData,
  generateNewFormConfig,
  updateConvertersData,
  isInvertorHybrid,
  generateBatteryDataFromBatteryType,
  getBatteryDetailsByBrandModel,
  getConverterDetailsByBrandModel,
} from "../../device-details-component/device-details-utils";
import { LinearProgress, StepLabel } from "@mui/material";
import { nanoid } from "nanoid";
import { steps, STEP_IDS } from "../config-wizard-steps";
import { generateStepSchema, isReadyToSave, validateStep } from "../shared";
import SaveDialog from "./save-dialog.component";

const stepperStyles = (theme) => ({
  overflowX: "auto",
  [theme.breakpoints.down("md")]: {
    minHeight: "120px",
  },
  ".MuiStep-horizontal": {
    cursor: "pointer",
  },
});

const nameUtils = {
  get: (data) => get(data, "attributes.name", ""),
  decode: (name) => name?.split("_").join(" "),
  encode: (name) => name?.split(" ").join("_"),
  name: function (data) {
    return this.decode(this.get(data));
  },
};

const generateDeviceData = (data, schemas) => {
  const settings = get(data, "shadow.name.settings.reported");
  const configuration = get(data, "shadow.reported.configuration");
  return {
    BASIC: {
      name: nameUtils.name(data),
      email: get(data, "attributes.email"),
    },
    ADDRESS: get(data, "shadow.reported.configuration.addressObj", {}),
    CONVERTER: generateConverterData(configuration),
    BATTERY: {
      batteryBrandModel: generateBatteryDataFromBatteryType(
        get(
          data,
          "shadow.name.settings.reported.converters[0].battery.batteryType",
          ""
        ),
        schemas
      ),
      battery: get(data, "shadow.name.settings.reported.converters[0].battery"),
    },
    PV: {
      pvArrays: get(
        data,
        "shadow.name.settings.reported.converters[0].pvArrays",
        []
      ),
      pvArraysRetrofit: get(
        data,
        "shadow.name.settings.reported.pvArraysRetrofit",
        []
      ),
    },
    ENERGY_TARIFF: {
      tariffStructure: get(
        data,
        "shadow.name.settings.reported.tariffStructure",
        {}
      ),
    },
    METER: {
      meterCurrentLimitation: get(
        data,
        "shadow.name.settings.reported.meterCurrentLimitation",
        25
      ),
    },
    PHASES: {
      numberOfPhases: get(
        data,
        "shadow.name.settings.reported.numberOfPhases",
        "1x 230V"
      ),
    },
    settings,
    configuration,
  };
};

const generateSubmissionData = (data, changes) => {
  // Extract any changes OR fallback to original values
  const { name, email } = changes.get(STEP_IDS.BASIC) || {};
  const address = changes.get(STEP_IDS.ADDRESS) || {};
  const { battery, batteryBrandModel } =
    changes.get(STEP_IDS.BATTERY) || data.BATTERY || {};
  const converterBrandModel =
    changes.get(STEP_IDS.CONVERTER) || data.CONVERTER || {};
  const {
    pvArrays = data.PV?.pvArrays,
    pvArraysRetrofit = data.PV?.pvArraysRetrofit,
  } = changes.get(STEP_IDS.PV) || {};
  const { tariffStructure } =
    changes.get(STEP_IDS.ENERGY_TARIFF) || data.ENERGY_TARIFF || {};
  const { meterCurrentLimitation } = changes.get(STEP_IDS.METER) || {};
  const { numberOfPhases } = changes.get(STEP_IDS.PHASES) || {};

  // Construct converter's info
  const converterName = converterBrandModel
    ? `${converterBrandModel.brand} ${converterBrandModel.model}`
    : undefined;
  const originalConverter = get(data.settings, "converters[0]", {});
  const converterDetails =
    getConverterDetailsByBrandModel(converterBrandModel) || originalConverter;
  const converter = omitBy(converterDetails, isUndefined);

  const batteryData = {
    ...originalConverter.battery,
    ...getBatteryDetailsByBrandModel(data.schemas, batteryBrandModel),
    ...battery,
  };

  const converters = [
    {
      ...converter,
      // Battery
      battery: !isEmpty(batteryData) ? batteryData : undefined,
      // PV
      pvArrays: isInvertorHybrid(converterBrandModel) ? pvArrays : null,
    },
  ];

  // Generate cleared settings & configuration
  const settings = omitBy(
    {
      EVChargers: get(data.settings, "EVChargers"),
      customInjectionTariffStructure: get(
        data.settings,
        "customInjectionTariffStructure"
      ),
      converters,
      pvArraysRetrofit,
      tariffStructure,
      meterCurrentLimitation,
      numberOfPhases,
    },
    isUndefined
  );
  const configuration = omitBy(
    {
      address,
      converter: converterName,
    },
    isUndefined
  );

  return { name, email, settings, configuration };
};

export default function DeviceConfigWizardComponent({ isOpen, onClose }) {
  const { deviceName: thingName } = useParams();
  const [clientToken] = useState(nanoid());
  const { publish = () => undefined } = useSocket();
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [formData, setFormData] = useState({});
  const [newFormConfig, setNewFormConfig] = useState(formData);
  const [changes, setChanges] = useState(new Map());

  // Navigation methods
  const totalSteps = () => steps.length;
  const isLastStep = () => activeStep === totalSteps() - 1;
  const handleNext = () => !isLastStep() && setActiveStep(activeStep + 1);
  const handleBack = () => setActiveStep((current) => current - 1);
  const handleStep = (step) => () => setActiveStep(step);

  const readyToSave = isReadyToSave(data, changes);

  const onSubmit = async () => {
    if (!readyToSave) {
      throw Error("Not ready to save, please check validation");
    }
    setLoading(true);
    try {
      const { name, email, settings, configuration } = generateSubmissionData(
        data,
        changes
      );

      // Updated `/config`
      const shouldSaveConfig = !isEmpty(settings) || !isEmpty(configuration);
      if (shouldSaveConfig) {
        const body = {
          formData: {
            settings,
            configuration,
          },
          clientToken,
        };
        await API.post("IO_API", `/devices/${thingName}/config`, { body });
      }

      // Update `name`
      const shouldSaveName = !!name;
      if (shouldSaveName) {
        await API.post("IO_API", `/devices/${thingName}/name`, {
          body: { name: nameUtils.encode(name) },
        });
      }

      // Update `email`
      const shouldSaveEmail = !!email;
      if (shouldSaveEmail) {
        await API.post("IO_API", `/devices/${thingName}/link`, {
          body: { email: email.toLowerCase() },
        });
      }
      setLoading(false);
      onClose();
    } catch (e) {
      const errorMessage = e?.response?.data || `${e}`;
      console.error("[config wizard: submit]", errorMessage);
      setError(errorMessage);
      setSaveDialogOpen(false);
      handleStep(steps.length - 1);
      setLoading(false);
    }
  };

  const onChange = (stepId, formRef) => (key) => {
    formRef.current?.validateForm();
    let changes = get(formRef, "current.state.formData", null);
    if (!changes) return;
    setChanges((collection) => {
      if (key && typeof key === "string") {
        const content = collection.get(stepId);
        changes = { ...content, [key]: changes[key] };
      }
      collection.set(
        stepId,
        omitBy(changes, (change) => change === undefined)
      );
      return collection;
    });
  };

  const onClear = (stepId) => (propertyToClear) => {
    setChanges((collection) => {
      const content = collection.get(stepId);
      collection.set(stepId, omit(content, [propertyToClear]));
      return collection;
    });
  };

  const close = () => {
    setSaveDialogOpen(true);
  };

  const fetchDeviceData = async () => {
    setLoading(true);
    try {
      // Perp
      const prefix = `fleet/devices/${thingName}`;
      const schemaConfig = { task: "schemas", args: { type: "settings" } };

      // Get schemas
      const schemas = await publish(
        `${prefix}/state/interact`,
        schemaConfig,
        true
      );

      // Get device information
      const deviceInfo = await API.get("IO_API", `/devices/${thingName}`);

      // Generate device data and set the state
      setData({ schemas, ...generateDeviceData(deviceInfo, schemas) });
    } catch (e) {
      console.error(e);
      setError(e?.response?.data || `${e}`);
    }
    setLoading(false);
  };

  const ActiveStepComponent = useCallback(() => {
    if (!data) return;
    const { id: stepId, ...stepProps } = steps[activeStep];
    const schema = generateStepSchema(stepProps, data);
    const props = {
      ...data,
      data,
      stepId,
      onChange,
      onClear,
      onSubmit,
      loading,
      state: changes,
      error,
      steps,
      stepProps: {
        ...stepProps,
        validator,
        schema,
        liveValidate: !!changes.get(stepId),
        showErrorList: false,
        liveOmit: true,
        omitExtraData: true,
        formData: changes.get(stepId) || data[stepId],
      },
    };
    return steps[activeStep].component(props);
  }, [data, changes, activeStep, loading, error]);

  const initialize = () => {
    setError(false);
    setData(null);
    fetchDeviceData();
  };

  useEffect(() => {
    isOpen && initialize();
  }, [isOpen]);

  useEffect(() => {
    const newFormData = updateConvertersData(newFormConfig, formData);
    setFormData(newFormData);
  }, [newFormConfig]);

  useEffect(() => {
    if (!data) return;
    const { configuration, settings } = data;
    const config = generateNewFormConfig(configuration, settings);
    setNewFormConfig(config);
  }, [data]);

  if (!data) return <LinearProgress />;

  return (
    <Box sx={{ width: "100%" }}>
      <SaveDialog
        isOpen={saveDialogOpen}
        readyToSave={readyToSave}
        onSave={onSubmit}
        onClose={onClose}
        onCancel={() => setSaveDialogOpen(false)}
        loading={loading}
      />
      {/* The stepper containing the titles */}
      <Stepper
        sx={stepperStyles}
        nonLinear
        activeStep={activeStep}
        alternativeLabel
      >
        {steps.map(({ id, title }, index) => (
          <Step key={title}>
            <StepLabel
              onClick={handleStep(index)}
              error={!validateStep(id, data, changes)}
            >
              {title}
            </StepLabel>
          </Step>
        ))}
      </Stepper>

      <Box>
        {/* Active step component */}
        <ActiveStepComponent />

        {/* Actions */}
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Button
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            Back
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />
          {activeStep !== steps.length - 1 ? (
            <Button onClick={handleNext} sx={{ mr: 1 }}>
              Next
            </Button>
          ) : (
            ""
          )}
          <Button onClick={close}>Close</Button>
        </Box>
      </Box>
    </Box>
  );
}
