const SET_GLOBAL = 'SET_GLOBAL';

const { merge } = require("lodash");

const initialState = {
	advanced: false
};

function globalReducer(state = initialState, action = {}) {
	const { type, data } = action;


	switch (type) {
		case SET_GLOBAL:
			return merge({}, state, data);
		default:
			return state;
	}
}

// Action creators
const setGlobal = (data) => ({ type: SET_GLOBAL, data });

export { setGlobal };
export default globalReducer;