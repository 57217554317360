import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import StepContainer from "../components/step-container.component";

const style = {
  my: 2,
  py: 0,
  width: "100%",
  borderRadius: 2,
  border: "1px solid",
  borderColor: "divider",
  backgroundColor: "background.paper",
};

function StepsSummaryList({ steps }) {
  return (
    <List dense={true} sx={style}>
      {steps
        .filter((s) => !s.nonFunctional)
        .map(({ title, description }) => (
          <>
            <ListItem>
              <ListItemText
                sx={{ m: 0 }}
                primary={title}
                primaryTypographyProps={{
                  variant: "subtitle1",
                  fontWeight: 700,
                }}
                secondary={description}
                secondaryTypographyProps={{ variant: "caption" }}
              />
            </ListItem>
            <Divider component="li" />
          </>
        ))}
    </List>
  );
}
export default {
  title: "Start",
  nonFunctional: true,
  description: "Step-by-step configuration of FlexiO",
  component: ({ steps }) => (
    <StepContainer>
      <Typography variant="h6">Step-by-step configuration of FlexiO</Typography>
      <Typography variant="body" fontWeight={500}>
        This wizard will guide you through each step. Make sure you have the
        right data at hand:
      </Typography>
      <StepsSummaryList steps={steps} />
    </StepContainer>
  ),
};
