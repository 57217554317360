/**
 * This module declares the main Amplify object
 */

import { Auth, API, PubSub, Amplify } from 'aws-amplify';
import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers';

import getClientInfo from '@lifepowr/components/src/clients';

// This is kinda ugly but probably will have no other option here - we'll see
//const {REACT_APP_API_GATEWAY_URL_DEV,REACT_APP_API_GATEWAY_URL, NODE_ENV} = process.env;
const { REACT_APP_API_GATEWAY_URL } = process.env;
const { REACT_APP_COGNITO_APP_DOMAIN } = process.env;
//const development = NODE_ENV === "development";
const gw_url = REACT_APP_API_GATEWAY_URL;

let clientInfo = getClientInfo();

console.log(PubSub)

const { host, protocol } = window.location;
const uri = `${protocol}//${host}/`;
const redirectSignOut = clientInfo ? clientInfo.signOut : uri;

const authOptions = {
	Auth: {
		region: 'us-east-1',
		userPoolId: 'us-east-1_zReHCug8l',
		userPoolWebClientId: '752stvh0pfmt4cpc56sm9k6t9b',
		identityPoolId: "us-east-1:d1c6961b-d1dd-48e4-9400-9ffa24e7d381",
		oauth: {
			domain: REACT_APP_COGNITO_APP_DOMAIN,
			scope: ["email", "openid", "aws.cognito.signin.user.admin", "profile"],
			redirectSignIn: uri,
			redirectSignOut: redirectSignOut,
			responseType: "code"
		},

	},
	API: {
		endpoints: [
			{
				name: "IO_API",
				endpoint: gw_url,
				custom_header: async () => {
					return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`};
					//return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
				}
			}
		]
	}
};

Amplify.configure(authOptions);
Auth.configure();
Amplify.Logger.LOG_LEVEL = process.env.NODE_ENV === "development" ? "DEBUG" : "";

const provider = new AWSIoTProvider({
	aws_pubsub_region: "eu-west-1",
	aws_pubsub_endpoint: "wss://asfjfwm1u8ru-ats.iot.eu-west-1.amazonaws.com/mqtt"
})
Amplify.addPluggable(provider);

export {API, PubSub, Auth, provider};
