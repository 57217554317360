import COLOR from '../colors-variables';

export default {
	"Household": {
		measures: {
			inverter: ['ACPower', 'ACFrequency'],
			batteries: ['stateOfCharge', 'stateOfHealth']
		},
		calc: (data, composite) => {
			return Object.entries(data).reduce((acc, [timestamp, dataObj]) => {
				const { totalPVPowerFiltered = 0, LoadPowerFiltered = 0, SoC } = dataObj;
				const PV = totalPVPowerFiltered;
				const Load = LoadPowerFiltered;
				const ts = timestamp.split(" ").length > 1 ? new Date(`${timestamp.split(" ").join("T").slice(0, -6)}Z`) : new Date(timestamp);
				if (!isNaN(PV)) {
					acc.pv[0].push(ts);
					acc.pv[1].push(PV);
				}
				if (!isNaN(Load)) {
					acc.load[0].push(ts);
					acc.load[1].push(Load);
				}
				if (!isNaN(SoC)) {
					acc.soc[0].push(ts);
					acc.soc[1].push(SoC * 1000);
				}
				return acc;
			}, { pv: [[], []], load: [[], []], soc: [[], []] });
		},
		layouts: {
			pv: {
				name: "Solar (W)",
				type: 'scatter',
				mode: 'lines',
				marker: { color: COLOR.PV },
				title: 'Power (W)'
			},
			load: {
				name: "Home (W)",
				type: 'scatter',
				mode: 'lines',
				marker: { color: COLOR.LOAD },
				title: 'Power (W)'
			},
			soc: {
				name: "Stored Energy (W)",
				type: 'scatter',
				mode: 'lines',
				yaxis: 'y2',
				marker: { color: COLOR.SOC },
				title: 'Power (W)'
			}
		},
		globalLayout: {
			yaxis: { title: "Power (W)" },
			yaxis2: { title: "Energy (kWH)" },
		}
	},
	"Grid": {
		measures: { frequency: ['frequency'] },
		calc: (data, composite) => {
			return Object.entries(data).reduce((acc, [timestamp, dataObj]) => {
				const { MeterPowerFiltered, frequency } = dataObj;
				const ts = timestamp.split(" ").length > 1 ? new Date(`${timestamp.split(" ").join("T").slice(0, -6)}Z`) : new Date(timestamp);
				if (!isNaN(frequency)) {
					acc.frequency[0].push(ts);
					acc.frequency[1].push(frequency / 10000);
				}
				if (!isNaN(MeterPowerFiltered)) {
					acc.grid[0].push(ts);
					acc.grid[1].push(MeterPowerFiltered);
				}
				return acc;
			}, { grid: [[], []], frequency: [[], []] });
		},
		layouts: {
			grid: {
				name: "Grid (W)",
				type: 'scatter',
				mode: 'lines',
				marker: { color: COLOR.GRID },
				title: 'Power (W)'
			},
			frequency: {
				name: "Frequency (Hz)",
				type: 'scatter',
				mode: 'lines',
				yaxis: 'y2',
				marker: { color: COLOR.FREQUENCY },
				title: 'Frequency (Hz)'
			}
		},
		globalLayout: {
			yaxis: { title: "Power (W)" },
			yaxis2: { title: "Frequency (Hz)" },
		}
	}
};