import React, { useState, useEffect } from 'react';

import Badge from '@mui/material/Badge';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Slider from '@mui/material/Slider';
import Alert from '@mui/material/Alert';
import Tooltip from '@mui/material/Tooltip';

import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import DangerousIcon from '@mui/icons-material/Dangerous';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsInputComponentIcon from '@mui/icons-material/SettingsInputComponent';
import ScheduleIcon from '@mui/icons-material/Schedule';
import AddIcon from '@mui/icons-material/Add';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EvStationIcon from '@mui/icons-material/EvStation';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import PowerOffIcon from '@mui/icons-material/PowerOff';
import PowerIcon from '@mui/icons-material/Power';
import HelpIcon from '@mui/icons-material/Help';

import ReactSelect from 'react-select';

import { merge, isEqual } from 'lodash';

import { customAlphabet } from 'nanoid';

import useSocket from '@lifepowr/components/src/components/socket/useSocket';

import brands from "./brands";

import { modes } from "./Charger";

const nanoid = customAlphabet('0123456789abcdefghijklmnopqrstuvwxyz', 5);

function OCPPComponent(props){
	const { setDialog, ev, setEv, setError, error } = props;
	const { thingName } = props?.system || {};
	const [id, setId] = useState(false); 
	const [waiting, setWaiting] = useState(false);
	const [charger, setCharger] = useState(false);
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState('');
	//const [error, setError] = useState('');
	const { subscribe, publish, unsubscribe } = useSocket();
	const label = 'New Charger';
	const { ocpp = {} } = ev;
	const chargers = Object.entries(ocpp).map( ([chgId, chg]) => {
		const { token, ...rest } = chg;
		const { chargerProperties = {}, chargerStates = {} } = rest || {};
		const { connected = 'disconnected', available = 'unavailable', charging = 'not_available' } = chargerStates;
		const { vendor, model } = chargerProperties;
		let ChargerCheckIcon = DangerousIcon;
		let ChargerStatusIcon = PowerOffIcon
		let chargerCheckIconClass = 'error';
		let chargerStatusIconClass = 'error';
		let Icon;
		let vendorStr = 'Unknown';

		Object.entries(brands).forEach( ([brand, brandComps]) => {
			const { vendorNames = [] } = brandComps;

			if(vendorNames.includes(vendor)){
				vendorStr = brand;
				Icon = brandComps.Icon;
			}
		});
		let IconComponent = Icon ? 
			<CardMedia component="img" image={Icon} className="ocpp-charger-card-icon"/> :
			<EvStationIcon className="ocpp-charger-card-icon"/>;
		let card = null;
		const label = `${vendorStr} - ${model} (${chgId})`;

		if (connected === 'connected') {
			if (available === 'available') {
				ChargerCheckIcon = CheckIcon;
				chargerCheckIconClass = 'good';
			}
			else{
				ChargerCheckIcon = WarningAmberIcon;
				chargerCheckIconClass = 'warn';
			}
			if(charging === 'available'){
				ChargerStatusIcon = PowerIcon;
				chargerStatusIconClass = 'good';
			}
			else if(charging === 'charging'){
				ChargerStatusIcon = BatteryChargingFullIcon;
				chargerStatusIconClass = 'good';
			}
		}

		if(Object.keys(rest).length > 0 && token){
			card = (
				<Card key={chgId}>
					<div className='ocpp-conn-icon'>
						<ChargerCheckIcon className={chargerCheckIconClass}/>
						<ChargerStatusIcon className={chargerStatusIconClass}/>
					</div>
					<CardActionArea onClick={ () => {setCharger(chg); setId(chgId);} }>
						<CardContent className="ocpp-charger-card">
							{IconComponent}
							<Typography >{label}</Typography>
						</CardContent>
					</CardActionArea>
				</Card>
			);
		}
		return card;
	});
	let render = (
		<div className='occp-charger-list'>
			{chargers}
			<Card >
				<CardActionArea onClick={ () => {setCharger({}); setId(nanoid());} }>
					<CardContent className="ocpp-charger-card">
						<AddIcon className="ocpp-charger-card-icon"/>
						<Typography >{label}</Typography>
					</CardContent>
				</CardActionArea>
			</Card>
		</div>
	);

	const submit = async (brand, obj, expire = 0) => {
		const prefix = `fleet/devices/${thingName}`;
		const topic = `${prefix}/state/interact`;
		const pubObj = {task:'ev', args: { type: 'set', expire }};
		let repEv;

		setEv( old => {
			const {[brand]: brandObj} = old;
			const newObj = merge(old, { [brand] : obj});

			if(!obj) delete newObj[brand];
			return newObj;
		});
		pubObj.args.data = ev;

		setLoading(true);
		setError('');
		try{
			if(!brand) throw new Error('Undefined data provided');

			repEv = await publish(topic, pubObj, true, 10000);

			setEv(repEv);
			setSuccess("Your device's EV configuration has been successfully updated!");
		}
		catch(e){
			setError(`An error ocurred: ${e}`);
		}
		setLoading(false);
	}

	const submitCharger = async (id, obj, expire) => {
		const prefix = `fleet/devices/${thingName}`;
		const topic = `${prefix}/state/interact`;
		const pubObj = {task:'ev', args: { type: 'setSingle', expire }};
		
		if(obj){
			pubObj.args.data = { ocpp: { [id]: obj} };
		}
		else{
			pubObj.args.data = id;
			pubObj.args.type = 'deleteSingle';
		}

		setEv(old => {
			const newObj = merge(old, { ocpp: { [id]: obj } });

			if(!obj){
				delete newObj.ocpp[id];
			} 
			return newObj;
		});
		await submit('ocpp', ev.ocpp, expire);

	}

	const chargerProps = { charger, setCharger, thingName, ev, setEv, loading, setLoading, submitCharger, id, setId, setError, error };

	useEffect( () => {
		if(!error && !waiting){
			setTimeout( async () => {
				try{
					const prefix = `fleet/devices/${thingName}`;
					const topic = `${prefix}/state/interact`;
					const pubObj = {task:'ev', args: {type: 'get'}};
					const evData = await publish(topic, pubObj, true, 10000);
					if(!isEqual(ev, evData)) setEv(evData);
				}
				catch(e){
					setError(`An error ocurred: ${e}`);
				}
				setWaiting(false);
			}, 10000);
			setWaiting(true);
		}
	}, [ev, error, waiting]);

	useEffect( () => {
		setDialog('EV Charger - Local pairing');
	}, []);

	if(charger) render = (
		<OCPPCharger {...chargerProps}/>
	);
	// Must search existing 
	return render;
}

function CopyInputLabel(props){
	const { value, label, className, multiline, fullWidth } = props;
	const copy = (ev) => {
		navigator.clipboard.writeText(value);
	};
	return (
		<FormControl className={className} variant="standard">
			<InputLabel htmlFor="standard-adornment-password">{ label }</InputLabel>
			<Input
				fullWidth={fullWidth}
				multiline={multiline}
				value={value}
				endAdornment={
					<InputAdornment position="end">
						<IconButton
							aria-label="toggle password visibility"
							onClick={copy}
						>
							<ContentCopyIcon />
						</IconButton>
					</InputAdornment>
				}
			/>
		</FormControl>
	);
}

function Strategy(props){
	const { valid, setValid, charger, setCharger, submitCharger, id, loading } = props;
	const { strategy, chargerSettings = {} } = charger || {};
	const { maxChargingCurrent: maxSettings } = chargerSettings
	const { mode = 'slow', maxChargingCurrent, maxAvailableCurrent = 32 } = strategy || {};
	const defaultValue = Number.isNaN(maxChargingCurrent) ? (maxSettings || 6) : maxChargingCurrent;
	const [localCurrent, setLocalCurrent] = useState(defaultValue);
	const options = modes.map( ({value, label, Icon}, idx) => (
		<MenuItem value={value} key={idx}>
			<ListItemIcon>
				<Icon />
			</ListItemIcon>
			<Typography variant="inherit">
				{label}
			</Typography>
		</MenuItem>
	));
	const modeObj = modes.filter( m => m.value === mode ).pop() || {};
	const { explanation = '' } = modeObj;
	const showSlider = ['smart', 'urgent'].includes(mode);

	useEffect( () => {
		async function send(){
			await submitCharger(id, charger);
		}
		send();
		console.log("SEMNT", charger);
	}, [maxChargingCurrent, mode]);

	const setMode = (ev) => {
		setCharger( old => {
			return merge({}, old, { strategy: { mode: ev.target.value } });
		})
	};
	const settingCurrent = (ev) => {
		setLocalCurrent( Number(ev.target.value))
	}
	const setCurrent = () => {
		setCharger( old => {
			return merge({}, old, { strategy: { maxChargingCurrent: localCurrent } });
		});
	}

	return (
		<div className="ocpp-cp-charging-strategy">			
			<FormControl>
				<InputLabel>
					Charging mode
				</InputLabel>
				<div style={{display: 'flex'}}>
					<Select disabled={loading} value={mode} onChange={setMode} style={{'flex': 5}}>
						{options}
					</Select>
					{ 
						explanation ? 
							<Tooltip title={explanation} placement="left">
								<IconButton>
									<HelpIcon />
								</IconButton>
							</Tooltip>
							: null 
					}
				</div>
			</FormControl>
			{ showSlider ? <FormControl>
				<InputLabel>
					Max. Charging Current
				</InputLabel>
				<Slider 
					disabled={loading}
					valueLabelDisplay="on"
					value={localCurrent}
					step={1}
					max={maxAvailableCurrent}
					onChange={settingCurrent}
					onChangeCommitted={setCurrent}
				/>
			</FormControl> : null }
		</div>
	);
}

function Info(props){
	const { valid, setValid, charger, setCharger, submitCharger, id } = props;
	const { chargerProperties = {}, chargerStates = {} } = charger;
	const { vendor = 'N/A', model = 'N/A' } = chargerProperties;
	const { connected = 'N/A', available = 'N/A', charging = 'N/A' } = chargerStates;

	const disabled = async () => {
		const r = window.confirm(`Forget ${vendor} ${model}?`);

		try{
			if(r){
				delete charger.token;
				await submitCharger(id, null);
				setCharger(null);
			}
			else console.log("NOT DOING IT");
		}
		catch(e){
			console.log("ERROR", e);
		}
	}

	return (
		<div>
			<div>
				Vendor: {vendor}
			</div>
			<div>
				Model: {model}
			</div>
			<div>
				Connected: {connected}
			</div>
			<div>
				Available: {available}
			</div>
			<div>
				Charging State: {charging}
			</div>
			<div>
				ID: {id}
			</div>
			<Button onClick={disabled} startIcon={<DeleteIcon />}>
				Forget
			</Button>
		</div>
	);
}

function BrandSteps(props){
	const [brand, setBrand] = useState(null);
	// const choiceNull = {value: null, label: 'Choose a brand'};
	const choices = Object.entries(brands).map( ([brandName, {ocpp}]) => {
		if(ocpp) return { value: ocpp, label: brandName };
	}).filter( v => v)
	const { value: BrandComponent } = brand || {};

	return (
		<div className='ocpp-brand-steps'>
			<ReactSelect 
				value={brand}
				options={choices}
				onChange={ (selected) => setBrand(selected) }
				placeholder="Choose a charger brand"
			/>
			{ BrandComponent ? <BrandComponent {...props} /> : <div></div> }
		</div>
	);
}

function OCPPCharger(props){
	const { charger, thingName, setCharger, ev, setEv, loading, setLoading, submitCharger, id, setId, error, setError } = props;
	const [step, setStep] = useState(0);
	const [valid, setValid] = useState(false);
	const [waitPairing, setWaitPairing] = useState(null);
	const [pairTimeout, setPairTimeout] = useState(null);
	const [component, setComponent] = useState('Info');
	const url = `ws://${thingName}.locals.io-comms.com:13000`;
	const { subscribe, publish, unsubscribe } = useSocket();

	const components = { Info, Strategy };
	const tabsProps = {
		orientation: "vertical",
		className: "ev-charger-tabs",
		value: component,
		onChange: (e, value) => setComponent(value)
	};
	const tabs = Object.entries(components).map(([name, Component]) => {
		const { strategy } = charger || {};
		const IconComponent = name === "Info" ? SettingsInputComponentIcon : ScheduleIcon;
		const tabInvisible = Boolean(charger);
		const Icon = (<Badge color="secondary" variant="dot" invisible={tabInvisible}><IconComponent /></Badge>);

		return <Tab icon={Icon} key={name} value={name}/>
	});
	const Component = components[component];

	useEffect(
		() => {
			const pairingTimeout = 300000;
			if(waitPairing !== null) setPairTimeout(
				old => {
					if(old){
						 clearTimeout(old);
						 return null;
					}
					return setTimeout(
						async () => {
							try{
								await submitCharger(id, null);
								setWaitPairing(false);
							}
							catch(e){
								setError(`${e}`);
							}
						},
						pairingTimeout,
					);
				}
			);
		},
		[ waitPairing ],
	);

	useEffect( () => {
		async function test(){
			const { ocpp: {
				[id]: chargerObj = {},
			} = {}, } = ev;
			const { token } = chargerObj;
			switch(step){
				case 0:
					setLoading(true);
					if(!token){
						// Periodic check in case user takes too much time
						if(!error) setTimeout( async () => {
							//console.log("SENDING", { ...restOcpp, [id]: { token: nanoid() } });
							await submitCharger(id, { token: nanoid() }, 3600);
						}, 5000);
					}
					else{
						setStep(1);
					}
					setLoading(false);
					break;
				case 1:
					if(Object.keys(chargerObj).length > 1){
						setStep(2);
						setCharger(chargerObj);
						await submitCharger(id, chargerObj);
					}
					break;
				case 2:
					// Do something state-related when device is properly detected
					setCharger(chargerObj);
					setWaitPairing(false);
					break;
				case -1:
					setId(nanoid());
					setWaitPairing(null);
					setStep(0); // Delete here?!
					break;
				default:
					console.log("BAD STEP", step);
					break;
			}
		}
		if(id) test();
	}, [ev, step, id, error]);
	const IdComp = ( <CopyInputLabel fullWidth value={id} label={'ID'} /> );
	const UrlComp = ( <CopyInputLabel fullWidth multiline value={url} label={'URL'} /> ); 
	const brandProps = { id, url, UrlComp, IdComp, CopyInputLabel, setWaitPairing };
	let render = (
		<>
			<BrandSteps {...brandProps}/>
			<div className='ocpp-cp-update'>
			</div>
		</>
	);
	let waitRender = null;
	const chargerProps = { valid, setValid, charger, setCharger, submitCharger, id, loading };
	const className = step===2 ? 'ocpp-cp-charging-details' : 'ocpp-cp';

	if(step===2){
		render = (
			<>
				<Tabs {...tabsProps}>
					{tabs}
				</Tabs>
				<Component {...chargerProps} />
			</>
		);
	}
	else if(waitPairing){
		render = (
			<>
				<CircularProgress />
				<Alert severity="info">Searching for Charger</Alert>
				<CopyInputLabel fullWidth value={id} label={'ID'} />
				<CopyInputLabel fullWidth multiline value={url} label={'URL'} />
				<Button onClick={() => setStep(-1)}>Restart</Button>
			</>
		);
	}
	else if(Object.keys(charger).length < 2 && waitPairing === false){
		render = (
			<>
				<Alert severity="error">Charger not found</Alert>
				<Button onClick={() => setStep(-1)}>Restart</Button>
			</>
		);
	}

	return (
		<div className={className}>
			{
				error ? null : render
			}
		</div>
	);
}

export default OCPPComponent;
