import ChartComponent from './chart-component/chart.component';
import CircularProgressLoader from './circular-progress-loader-component/circular-progress-loader.component';
import DateView from './date-component/date-view.component';
import DotAnimation from './dot-loading-animation/dot-loading-animation.component';
import FieldDropDown from './field-drop-down-component/field-drop-down.component';
import OneLineStatistics from './one-line-statistics/one-line-statistics.component';
import RoundGraph from './round-graph-component/round-graph.component';
import UnitDisplay from './unit-display/unit-display.component';
import UserDisplay from './user-display-component/user-display.component';

export default {ChartComponent, CircularProgressLoader, DateView, DotAnimation, FieldDropDown, OneLineStatistics, RoundGraph, UnitDisplay, UserDisplay};