import React, { useState, useEffect } from 'react'
import OneLineStatistics from '@lifepowr/components/src/components-shared/one-line-statistics/one-line-statistics.component';
import DeviceChartComponent from '../device-chart-component/device-chart/device-chart.component';
import './device-general-status.styles.scss';
import customCharts from '@lifepowr/components/src/utils/graphDescs/system';
import { styled } from '@mui/material/styles';

import SunDark from '@lifepowr/components/src/assets/icons/statistics-sun-dark.png'
import EcoHouse from '@lifepowr/components/src/assets/icons/eco-house.png'
import Gridbalancing from '@lifepowr/components/src/assets/icons/gridbalancing.png'
import Savings from '@lifepowr/components/src/assets/icons/savings.png'
import Earnings from '@lifepowr/components/src/assets/icons/earnings.png'
import  { ReactComponent as BatteryIcon }from '@lifepowr/components/src/assets/icons/battery.svg';
import  { ReactComponent as EvIcon }from '@lifepowr/components/src/assets/icons/ev.svg';
import  { ReactComponent as SolarIcon }from '@lifepowr/components/src/assets/icons/solar.svg';
import  { ReactComponent as TariffIcon }from '@lifepowr/components/src/assets/icons/tariff.svg';

import { connect } from 'react-redux';

import { API } from 'Amplify';
import getClientInfo from '@lifepowr/components/src/clients';
import { mapValues } from 'lodash';

const SAVINGS_FOOTNOTE = `More info on savings can be found on our FAQ page.`;
const EARNINGS_FOOTNOTE = `More info on earnings can be found on our FAQ page.`;
const formatPrice = (value) => Number(value) ? `€ ${Number(value).toFixed(2)}` : '-';
const savingIconsStyles = { width: "20", height: "20" };
const SavingsComponent = ({ savings }) => {
    const { battery, tariff, ev, solar } = mapValues(savings, formatPrice)  ;

    return <SavingContainer>
                
        {/* battery */}
        <SavingItemContainer>
            <BatteryIcon {...savingIconsStyles}/> 
            <SavingItemLabel>Battery:</SavingItemLabel>
            <SavingItemValue>{battery}</SavingItemValue>
        </SavingItemContainer>

        {/* tariff */}
        <SavingItemContainer>
            <TariffIcon {...savingIconsStyles}/> 
            <SavingItemLabel>Tariff:</SavingItemLabel>
            <SavingItemValue>{tariff}</SavingItemValue>
        </SavingItemContainer>
        
        {/* EV */}
        <SavingItemContainer>
            <EvIcon {...savingIconsStyles}/>
            <SavingItemLabel>EV:</SavingItemLabel>
            <SavingItemValue>{ev}</SavingItemValue>
        </SavingItemContainer>

        
        {/* solar */}
        <SavingItemContainer>
            <SolarIcon {...savingIconsStyles}/> 
            <SavingItemLabel>Solar:</SavingItemLabel>
            <SavingItemValue>{solar}</SavingItemValue>
        </SavingItemContainer>


        {/* footnote */}
        <SavingFootnoteContainer>{SAVINGS_FOOTNOTE}</SavingFootnoteContainer>

    </SavingContainer>
}

const SavingContainer = styled('div')(({theme}) => ({
    // For debugging purposes
    // position: 'absolute',
    // zIndex: 100,
    // top: '130px',
    // right: '120px',
        
    minWidth: 240,
    color: 'black',
    fontSize: 15,
    fontWeight: 600,
    borderRadius: 5,
    padding: 10,
    backgroundColor: '#f8f8f8',
    boxShadow: '#21252952 3px 6px 13px -5px',
    border: 'solid 1px #0000002b',
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
}));

const SavingItemValue = styled('div')(({theme}) => ({
    fontWeight: 600,
}));

const SavingItemLabel = styled('div')(({theme}) => ({
    fontWeight: 400,
    color: 'darkslategray',
    textAlign: 'end'
}));

const SavingItemContainer = styled('div')(({theme}) => ({
    display: 'grid',
    gridTemplateColumns: '30px 60px 110px',
    alignItems: 'center',
    gap: 5,
}));

const SavingFootnoteContainer = styled('div')(({theme}) => ({
    paddingTop: 10,
    fontSize: 10,
    opacity: 0.5,
}));

const DeviceGeneralStatus = ({ shadow, deviceId, userMode, admin }) => {
    const [loading, setLoading] = useState(false);
    const [savings, setSavings] = useState(null);

    const clientInfo = getClientInfo();
    const date = new Date();
    const year = date.getFullYear();

    const showWarnings = clientInfo ? clientInfo?.warnings : true;

    const periodStr = !savings ? '' : (new Date(savings.start)).toLocaleString(undefined, {month: 'short', year: 'numeric'});
    const savingsNo = !savings ? '' : savings.savings.lifepowr + savings.savings.ev + savings.savings.solar;
    const period = !savings ? '' : `${(new Date(savings.start)).toLocaleString(undefined, {month: 'short'})} - ${(new Date(savings.end)).toLocaleString(undefined, {month: 'short'})} ${year}`;
    const healthStr = !savings ? '' : ((Number((savings.health || 0).toFixed(2))*100).toFixed(0)+' %');
    const earnings = !savings ? '' : savings?.earnings?.fcr;
    let evSavings;
    
    if(savings && savings.savings.ev) {
       evSavings=(<div style={{color: savings.savings.ev > 0 ? 'green' : 'red'}}>🚗⚡🔌: {savings.savings.ev.toFixed(2)} €</div>);
    }

    const healthValues = {
        '0.333': ['GoldenRod', '☢️'],
        '0.666': ['green', '✔️'],
    }

    const healthVals = Object.entries(healthValues).reduce(
        (acc, [k, v]) => {
            if(savings && savings.health) {
                if(savings.health > Number(k)) acc = v;
            }
            return acc;
        },
        ['red', '❌'],
    )

    let savingsTooltip = !savings ? '' : <SavingsComponent savings={savings.savings} />;

    useEffect(
        ()=>{
            const now = new Date();
            const monthNo = 6;
            setLoading(true)
            API.get(
                "IO_API",
                `/devices/${deviceId}/savings`,
                {
                    queryStringParameters: {
                        aggregate:'mo',
                        entries: monthNo,
                    }
                }
            ).then(
                (res)=>{
                    const { data = [] } = res;
                    const now = new Date();
                    now.setMonth(now.getMonth() - 1);
                    const newSavings = data.filter(obj => (new Date(obj.start)).getMonth() + 1 === now.getMonth() + 1).pop();
                    console.log(now, newSavings);
                    /* const newSavings = data.reduce(
                        (acc, obj, idx) => {
                            acc = { health: 0, savings: { lifepowr: 0, ev: 0, solar: 0 }, start: Infinity, end: 0 };
                            const { savings = {}, start, health } = obj || {};
                            const { end } = acc || {};
                            if(start < acc.start) acc.start = start;
                            if(start > acc.end) acc.end = start;
                            acc.health = idx > 0 ? (idx - 1) / (idx) * acc.health + health / idx : health;
                            Object.entries(savings).forEach(([k, v]) => {
                                acc.savings[k] = isNaN(acc.savings[k]) ? v : acc.savings[k] + v;
                            });
                            return acc;
                        },
                        //{ health: 0, savings: { lifepowr: 0, ev: 0, solar: 0 }, start: Infinity, end: 0 }
                        null,
                    ) */
                    setSavings(newSavings);
                }
            ).catch(
                e=>{
                    console.log("SAVINGS ERROR", e);
                }
            )
            .finally(
                ()=>setLoading(false)
            )
        },
        []
    );

    /** Info used to handle general status chart*/
    const chartProps = {
        socketUrl: "test2",
        chartFlag: "General Status",
        table: "inverter",
        rtKey: "inverter",
        deviceId,
        userMode,
        admin,
        customCharts
    }

    /** Info obtained to display Statistics on top of the component */
    let bills = shadow?.reported?.bills;
    const oneLineStatistics = [
        {
            name: 'Reduced Emission',
            Icon: SunDark,
            value: 'TBA',
            unit: '',
            carbon: 1,  //evaluates to TRUE
            percentage: '10',
            direction: 1 ,//evaluates to TRUE > UP
            style : 'dark'
        },
        {
            name: <span><img className='inlineIcon' src={Savings} alt='decorative'/> {loading ? '' : periodStr}</span>,
            Icon: EcoHouse,
            value: (
                <span style={{color: "black"}}>
                    {!loading ? `${(savingsNo ? formatPrice(savingsNo) : '')}` : 'Loading. . .'}
                </span>
            ),
            unit: '',
            carbon: 0,  //evaluates to FALSE
            percentage: '10',
            direction: null,
            style : 'dark',
            info: !loading ? savingsTooltip : '',
        }
    ];

    if (earnings !== undefined && earnings !== null && earnings !== '') {
        oneLineStatistics.push(
            {
                name: <span><img className='inlineIcon' src={Earnings} alt='decorative'/> {loading ? '' : periodStr}</span>,
                Icon: Gridbalancing,
                value: (
                    <span style={{color: "black"}}>
                        {!loading ? `${(earnings ? formatPrice(earnings) : '')}` : 'Loading. . .'}
                    </span>
                ),
                unit: '',
                carbon: 0,  //evaluates to FALSE
                percentage: '10',
                direction: null,
                style : 'dark',
            info: !loading ? <SavingContainer><SavingFootnoteContainer>{EARNINGS_FOOTNOTE}</SavingFootnoteContainer></SavingContainer> : '',
            },
        );
    }

    return (
        <div className='device-general-status'>
            <div className='device-general-status__statistics'>
                {oneLineStatistics.map((props, index) =>
                    <div className='device-general-status__statistics__div' key={`statistic_key_${index}`}>
                        <OneLineStatistics key={`device-general-status__statistics__div-device${index}`} {...props} />
                    </div>
                )}
            </div>
            <DeviceChartComponent {...chartProps} />
        </div>
    );
}

export default connect( (state) => { const {  shadow  } = state.system || {}; return { shadow }; }, null, null )(DeviceGeneralStatus);
