import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './device-chart.styles.scss';
import Chart from '@lifepowr/components/src/components-shared/chart-component/chart.component';
import DeviceDropdown from '../device-drop-down/device-dropdown.component';
import { CUSTOM, fetchDeviceData, sortData } from '../utils'
import { DAY, HALF_HOUR, initialPlotData, newPlotData } from '@lifepowr/components/src/utils/chart-utils'

import useMediaQuery from '@mui/material/useMediaQuery';

/**
 * @param {string} title chart title
 * @param {string} deviceId thingName
 * @param {Object} customCharts chart definition
 * @param {string} dimensions used on requestbody
 * @param {string} table used to request chart and dropdown data
 * @param {string} rtKey used on requestbody
 */
const DeviceChart = ({ title, deviceId, customCharts, userMode, admin, dimensions, rtKey, rtData = {} }) => {
    const now = (new Date()).getTime() / 1000;
    const [timeRange, setTimeRange] = useState(() => [now - (60 * DAY), now]);
    const [loading, setLoading] = useState(() => false);
    const [requestData, setRequestData] = useState(() => (null));
    const [plotData, setPlotData] = useState(() => initialPlotData);
    const [realTime, setRealTime] = useState(() => false)
    const [dropDownValue, setDropDownValue] = useState(false);
    const [globalLayout, setGlobalLayout] = useState(() => ({}));
    const [resetDefaultRange, setResetDefaultRange] = useState(() => ({}));
    const [firstSocketRequest, setFirstSocketRequest] = useState(() => false);
    const mobileMode = !useMediaQuery('(min-width:600px)');


    /**
     * reset the visible range of the chart.
     */
    const setGlobalSeetings = (globalSettings, timestamps) => {
        const response = { ...globalSettings }
        if (!realTime) {
            const range = timeRange[1] - timeRange[0];
            let dateInit = new Date(timeRange[0] * 1000);
            let dateEnd = new Date((timeRange[0] + range) * 1000);

            if (timestamps.length){
                dateInit = new Date(new Date(timestamps[timestamps.length - 1].timestamp )- (range * 1000));
                dateEnd = new Date(timeRange[1] * 1000);
            }
            else{
                dateEnd = new Date();
                dateInit = new Date( dateEnd - range * 1000 );
            }

            response['xaxis'] = {
                autorange: false,
                range: [dateInit, dateEnd],
                ...response['xaxis']
            }
            response['xaxis2'] = {
                autorange: false,
                range: [dateInit, dateEnd],
                ...response['xaxis2']
            }
        } else if (timestamps.length) {
            const firstItem = new Date(timestamps[0].timestamp)
            const lastItem = new Date(timestamps[timestamps.length - 1].timestamp);
            response['xaxis'] = { autorange: false, range: [firstItem, lastItem] }
            response['xaxis2'] = { autorange: false, range: [firstItem, lastItem] }
        } else {
            const firstItem = new Date(timeRange[0] * 1000)
            const lastItem = new Date(timeRange[1] * 1000)
            response['xaxis'] = { autorange: false, range: [firstItem, lastItem] }
            response['xaxis2'] = { autorange: false, range: [firstItem, lastItem] }
        }
        if (response['yaxis']) {
            response['yaxis'] = { ...globalSettings['yaxis'] }
            delete response['yaxis'].range
        }
        if (response['yaxis2']) {
            response['yaxis2'] = { ...globalSettings['yaxis2'] }
            delete response['yaxis2'].range
        }
        setGlobalLayout(response)
    }

    // function used to create the newPlotData for the chart
    const updatePlotData = (sortedPlotData) => {
        if (sortedPlotData) {
            const { timestamps, ...dataMeasures } = sortedPlotData
            let newTimeseries = [{}];
            const processed = timestamps.map(timestamp => {
                const initialValue = timestamp.includes('Z') ? timestamp : `${timestamp.split(" ").join("T").slice(0, -6)}Z`
                return Object.entries(dataMeasures).reduce((acc, [measureName, measureArray]) => {
                    const valueExists = measureArray.filter(obj => obj.time === timestamp)[0];
                    acc[measureName] = valueExists ? valueExists.value : null;
                    return acc;
                }, { timestamp: initialValue });
            });
            if (dropDownValue?.value.startsWith(CUSTOM)) {
                const label = dropDownValue.value.split(CUSTOM)[1]
                const { calc = () => undefined, layouts = {}, global: globalSettings = {} } = customCharts[label];
                setGlobalSeetings(globalSettings, processed);
                const timeseriesProcessed = calc(processed, undefined, timeRange);
                newTimeseries = Object.entries(layouts).map(([tsName, layout]) => {
                    let [x, y] = timeseriesProcessed[tsName] || [[new Date(timeRange[1])], [null]]
                    
                    if(x.length < 1 && y.length < 1) [x, y] = [[new Date(timeRange[1])], [null]];
                    
                    return { x, y, ...layout };
                });
            } else {
                setGlobalSeetings({}, processed);
                newTimeseries = processed.reduce((acc, datum) => {
                    if (!acc.length) {
                        acc.push(newPlotData());
                    }
                    acc[0].x.push(new Date(datum.timestamp));
                    acc[0].y.push(datum[dropDownValue.value]);
                    acc[0].name = dropDownValue.label;
                    return acc;
                }, []);
            }
            const data = (!newTimeseries?.length) ? newPlotData(timeRange) : newTimeseries;
            setPlotData(data)
        }
    }

    // used to update the chart PlotData based on the socket
    useEffect(() => {
        const { timestamp } = rtData;
        const searchKeys = rtKey.split('.');
        let rtDatum, currentSearch;
        if (firstSocketRequest && requestData && !loading && timestamp) {
            const time = (new Date(timestamp)).toISOString();
            searchKeys.forEach(key => {
                if (currentSearch && key in currentSearch) currentSearch = currentSearch[key];
                else if (key in rtData) currentSearch = rtData[key];
            });
            if (currentSearch) {
                rtDatum = currentSearch;
                const newData = Object.entries(requestData).reduce((acc, [measureName, measureArray]) => {
                    acc[measureName] = measureArray;
                    if (measureName in rtDatum) {
                        acc[measureName].push({ time, value: rtDatum[measureName], dimensions: {} });
                        acc[measureName].shift();
                    }
                    return acc;
                }, {});
                newData.timestamps.push(time);
                newData.timestamps.shift();
                updatePlotData(newData)
            }
        }
    }, [firstSocketRequest, rtData, requestData, loading]);

    /**
     *  requests new values from AWS once the timeRange or dropDown 
     *  is changed within the chart
     * */
    useEffect(() => {
        if (dropDownValue) {
            setLoading(true);
            const measures = dropDownValue.value.startsWith(CUSTOM) ? dropDownValue.measures : [dropDownValue.value];
            const isSavings = dropDownValue.value.toLowerCase().includes('savings') ? 'savings' : 'inverter';
            const table = dropDownValue.table || isSavings;
            fetchDeviceData(deviceId, table, dimensions, timeRange, measures)
                .then(data => {
                    const sortedPlotData = sortData(data);
                    setRequestData(sortedPlotData)
                    updatePlotData(sortedPlotData)
                    setFirstSocketRequest(realTime)
                })
                .catch(err => console.error("ERROR FETCHING DEVICE DATA", err))
                .finally(() => setLoading(false));
        }
    }, [dropDownValue, deviceId, timeRange, realTime, setRequestData]);

    /**
     *  'resets' chartRange option when dimensions (battery) is changed.
     */
    useEffect(() => {
        dimensions && Object.keys(resetDefaultRange).length && resetDefaultRange.click()
    }, [dimensions])

    const showPlot = (dropDownValue);
    const chartProps = { showPlot, globalLayout, loading, plotData, timeRange, setTimeRange, setRealTime, setResetDefaultRange, userMode, realTimeMeasure: HALF_HOUR, admin }

    return (
        <div className='device-chart-general-status'>
            <div className='device-chart-general-status__header'>
                <DeviceDropdown
                    key={`deviceDropdown-${title}`}
                    setDropDownValue={setDropDownValue}
                    customCharts={customCharts}
                    userMode={userMode}
                    queryStringParameters={{ db: 'sampleDB' }}
                    disabled={loading}
                    mobileMode={mobileMode}
                    dropDownValue={dropDownValue}
                />
            </div>
            <div className='device-chart-general-status__graph'>
               {dropDownValue ? <Chart key={`device-chart-general-status${title}`} {...chartProps} /> : null}
            </div>
        </div>
    )
}

DeviceChart.propTypes = {
    customCharts: PropTypes.any.isRequired,
    dropDownOptions: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired
    }))
};

export default connect(({ system }) => ({ rtData: (system?.rtData || {}) }), null)(DeviceChart);
