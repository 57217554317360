import React, { useState, useEffect } from 'react';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Alert from '@mui/material/Alert';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import PersonIcon from '@mui/icons-material/Person';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Badge from '@mui/material/Badge';
import Select from '@mui/material/Select';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { API } from 'Amplify';
import { merge, isEqual } from 'lodash';
import base64 from 'base-64';

import Icon from './wallbox.jpg';
import './styles.scss';

import { chargerImg, chargersImg, ocppImg, fullGif } from "./images";

const url = `https://api.wall-box.com/`;

function count (state = {}){
	let count = 0;

	Object.entries(state).forEach( ([username, {chargers = []}]) => {
		count = count + chargers.length;
	});

	return count;
}

function Accounts(props){
	const { action, state, choices, setChoices } = props;
	const [accounts, setAccounts] = useState([]);

	const { username = null, password = '' } = choices;
	const onChangeUname = (ev) => {
		const val = ev.target.value;
		setChoices( old => ({...old, username: val}));
	};
	const onChangePword = (ev) => {
		const val = ev.target.value;
		setChoices( old => ({...old, password: val}));
	};

	useEffect(
		() => {
			const currentAccounts = [];

			Object.entries(state).forEach(
				([id, charger]) => {
					const { username } = charger;

					if(username) currentAccounts.push(username);
				}
			);
			currentAccounts.push('');

			setAccounts([...(new Set(currentAccounts))]);
		},
		[state]
	);

	useEffect( () => {
		//const {[username] : {chargers: currentChargers = []} = {}} = state;
		const currentChargers = [];
		
		Object.entries(state).forEach(
			([id, charger]) => {
				const {username: chgUsername} = charger || {};

				if(username === chgUsername) currentChargers.push(charger);
			}
		);

		setChoices(old => ({...old, chargers: currentChargers}));
	}, [username]);

	const cards = accounts.map( (account) => {
		const label = account === "" ? "Add Account" : account;
		const Icon = account!=="" ? PersonIcon : PersonAddIcon;

		return (
			<Card key={label}>
				<CardActionArea onClick={ () => setChoices( old => ({username: account, ...old}) ) }>
					<CardContent className="wallbox-account-content">
						<Icon className="wallbox-account-content-icon"/>
						<Typography >{label}</Typography>
					</CardContent>
				</CardActionArea>
			</Card>
		);
	});

	return ( username === null ? 
			<div className="wallbox-account-cards">{cards}</div> :
			<div className="wallbox-account-login">
				<TextField value={username} onChange={onChangeUname} label="Username" />
				<TextField value={password} onChange={onChangePword} type="password" label="Password" />
			</div>
	);
};

function ChargerCard(props){
	const {thingName, select, included, image, id, jwt, charger, setCharger} = props;
	const { numberOfPhases, maxChargingCurrent, maxAvailableCurrent } = charger || {};
	const [phases, setPhases] = useState(numberOfPhases);
	const [current, setCurrent] = useState({maxChargingCurrent, maxAvailableCurrent});

	useEffect( () => {
		async function request(){
			let max_available_current, max_charging_current, rep;

			rep = await API.post( "IO_API", `/ev/wallbox`, { body: { data: { jwt, resource: 'charger', id } } } );

			( { config_data : { max_available_current = 0, max_charging_current = 0 } = {} } = {} = rep);

			return {maxAvailableCurrent: max_available_current, maxChargingCurrent: max_charging_current};
		}
		request()
			.then((res) => {
				setCurrent(res);
			})
			.catch((e) => {
				console.log("ERROR", e);
			});
	}, []);

	useEffect( () => {
		setCharger({numberOfPhases: phases, ...current });
	}, [phases, current]);

	const onChange = (ev) => {
		const val = ev?.target?.value || 1;
		setPhases(val);
	}

	return (
		<div className="wallbox-tab">
			<FormControl>
				<InputLabel>
					Number of phases
				</InputLabel>
				<Select disabled={!Boolean(included)} value={phases} onChange={onChange}>
					<MenuItem disabled value={undefined}>
						<Typography variant="inherit">
							Select one
						</Typography>
					</MenuItem>
					<MenuItem value={1}>
						<Typography variant="inherit">
							Single-phase
						</Typography>
					</MenuItem>
					<MenuItem value={3}>
						<Typography variant="inherit">
							Three-phase
						</Typography>
					</MenuItem>
				</Select>
			</FormControl>
		</div>
	);
}

function Chargers(props){
	const { loading, setButtonDisabled, setLoading, choices, setChoices, setError, thingName, Charger, state } = props;
	const { chargers : existingChargers = [], jwt, username } = choices;
	const {[username]: {chargers : stateChargers = []} = {} } = state || {};
	const [ groups, setGroups ] = useState([]);
	const [ chargers, setChargers ] = useState({});
	const [ validChargers, setValidChargers ] = useState(true);

	useEffect(()=>{
		async function request(){
			let groupsRequest, rep;

			rep = await API.post( "IO_API", `/ev/wallbox`, { body: { data: { jwt, resource: 'chargers' } } } );

			( { result : { groups : groupsRequest = [] } = {} } = rep);

			return groupsRequest;
		}
		request()
			.then((res) => {
				setGroups(res);
			})
			.catch((e) => {
				console.log("ERROR", e);
			});
	}, [username]);

	useEffect( () => {
		const {[username]: {chargers : sChgs = []} = {} } = state || {};
		const exChgs = [...existingChargers];
		let equals = exChgs.length === sChgs.length;
		let brk = false;

		for (var i = 0, l=sChgs.length; i < l; i++) {
			for (var k = 0, j=exChgs.length; i < j; k++) {
				const {id: id1} = exChgs[k] || {};
				const {id: id2} = sChgs[k] || {};

				if(id1===id2 && !isEqual(exChgs[k], sChgs[i])){
					equals = false;
					brk = true;
					break;
				}
			}
			if(brk) break;
		}

		setValidChargers(!equals && !loading);
	}, [loading, existingChargers, state, username]);

	const cards = [];

	const handlePhaseChange = (key, value) => {
		setPhases( old => {
			old[key] = value;
			return old;
		});
	};

	groups.forEach((group) => {
		const {chargers : groupChargers = [], name: groupName} = group || {};

		groupChargers.forEach( (charger) => {
			const {name, image, statusDescription, chargerType, id, uid} = charger;
			const label = `${groupName} - ${name} (${chargerType})`;
			const included = [...existingChargers].filter( ({id: existingId}) => existingId === id )[0];
			const key = `lifepowr-wallbox-${id}`;
			const select = (obj, replace = false) => {
				const {id: objId} = obj
				const inc = [...existingChargers].filter( ({id: existingId}) => existingId === objId )[0];
				if(!inc && !replace){
					setChargers( old => {
						const { [id]: oldChg = {}} = old;
						return({...old, [id]: {...oldChg, ...obj}});
					});
					setChoices(old => {
						const {chargers: chgs = []} = old;
						return ({...old, chargers: [...chgs, {...(inc ? inc : {}), ...obj}]});
					});
				}
				else{
					setChargers( old => {
						const { [id]: oldChg = {}} = old;
						return({...old, [id]: {...oldChg, ...obj}});
					});
					setChoices( old =>{
						const {chargers: chgs = []} = old;
						const toAdd = replace ? [{...(inc ? inc : {}), ...obj}] : [];
						if(inc) chgs.splice(existingChargers.indexOf(inc), 1, ...toAdd);
						return ({...old, chargers: [...chgs]});
					});
				}
			};

			const onChange = (valid) => {
				setButtonDisabled(!valid || !validChargers);
			}

			const onClick = (charger) => {
				const { [id]: storedCharger } = chargers;
				const includedCharger = included || {id, name: label};

				select({...includedCharger, ...storedCharger});
			};

			const setCharger = (newCharger, rep = true) => {
				console.log("HERE", {...(included || {id, name: label}), ...newCharger}, JSON.stringify(newCharger));
				select({...(included || {id, name: label, label: name, model: chargerType, serial: uid}), ...newCharger}, rep);
			};
			const valid = (included ? Object.entries(included).every( ([k, v]) => v !== undefined) : false) || (existingChargers.length < 1 && stateChargers.length > 0);

			const chargerProps = {key, label, charger: included, onClick, image, state: included || {id, name: label}, setCharger, valid, onChange};
			const cardProps = { jwt, thingName, key, select, included, image, label, handlePhaseChange, id };

			cards.push(
				<Charger {...chargerProps}>
					<ChargerCard {...cardProps}/>
				</Charger>
			);
		});
	});

	if(loading) return <div>Loading . . .</div> 

	return cards.length > 0 ? (
		<div className="wallbox-account-chargers">
			{cards}
		</div>
	) : (<Typography className="wallbox-account-chargers-empty" variant="h6">No chargers found on this account</Typography>);
}

function Confirm(props){
	const { state = {}, choices, setChoices } = props;
	const { chargers = [], username } = choices;
	let totalChargers = [];
	let accounts = (<Typography variant="h6">No chargers selected</Typography>);
	const updatedState = merge({}, state, {[username]: chargers});

	if(Object.keys(updatedState).length > 0){
		accounts = Object.entries(updatedState).map( ([account, accChargers = []]) => {
			let points = (<Typography className="wallbox-charger-list-item" variant="subtitle1">No chargers selected</Typography>);
			let chargers = accChargers;

			if(chargers.length > 0){
				points = chargers.map( ({id, name, maxChargingCurrent, numberOfPhases}) => {
					return (<ListItem key={`${accounts}-${id}`}><Typography className="wallbox-charger-list-item" variant="subtitle1">{name}</Typography></ListItem>)
				});
			}
			return (
				<List key={account}>
					<ListItem><Typography variant="h6">{account}</Typography></ListItem>
					{points}
				</List>
			);
		});
	}

	return (
		<div className="wallbox-confirm">
			<Typography variant="h5">Please confirm your new list of chargers:</Typography>
			<List className="charger-list">{accounts}</List>
		</div>
	);
}

const steps = [
	{
		Component: Accounts,
		label: "Log in",
		action: async (props) => {
			const { choices, setChoices, setLoading, setError, thingName } = props;
			const { username, password } = choices;

			let rep, repJwt;
			const headers = {
				'Authorization': `Basic ${base64.encode( username + ":" + password )}`,
				"Accept": "application/json",
				"Content-Type": "application/json;charset=UTF-8"
			};

			rep = await API.post( "IO_API", `/ev/wallbox`, { body: { data: { username, password, resource: 'auth' } } } );

			( { jwt: repJwt } = rep );

			setChoices(old => ({jwt: repJwt, ...old}));
		}
	},
	{
		Component:  Chargers, 
		label: "Chargers"
	},
	{
		Component: Confirm,
		label: "Confirm",
		action: async ({state, choices, submit}) => {
			const { chargers, username, password } = choices;
			const newState = Object.assign({}, state);

			chargers.forEach(charger => {
				const { id } = charger;
				charger.enabled = true;
				charger.label = charger.name;
				newState[id] = { username, password, ...charger };
			});

			return (Object.keys(newState).length > 0 ? newState : undefined);
		}
	}
];

function OCPP(props){
	const {id, IdComp, url, UrlComp, CopyInputLabel, setWaitPairing} = props;
	const [step, setStep] = useState(0);
	const steps = [
		'Setup charger',
	];

/*	const components = [
		(props) => {
			return (
				<Alert severity="info">
					Log in to <a href="https://my.wallbox.com/" target="_blank" className='lifepowr-link'>my.wallbox.com</a> 
				</Alert>
			);
		},
		(props) => {
			return (
				<div>
					<Alert severity="info">Navigate to your chargers list and choose charger to pair</Alert>
					<Alert severity="warning">NOTE: Ensure charger is compatible with OCPP</Alert>
					<div><img src={chargersImg} alt="chargersImg" className="wallbox-ocpp-img"/></div>
				</div>
			);
		},
		(props) => {
			return (
				<div>
					<Alert severity="info">On your charger dashboard, navigate to the OCPP tab</Alert>
					<div><img src={chargerImg} alt="chargerImg" className="wallbox-ocpp-img"/></div>
				</div>
			);
		},
		(props) => {
			const {IdComp, UrlComp, id, url, CopyInputLabel} = props;
			return(
				<div className="wallbox-ocpp-steps">
					<div><img src={ocppImg} alt="ocppImg" className="wallbox-ocpp-img"/></div>
					<div>1. Flip the switch labeled 'Websocket Connection'</div>
					<div>2. <CopyInputLabel fullWidth multiline value={url} label={'URL'} /></div>
					<div>3. <CopyInputLabel fullWidth value={id} label={'Charge Point Identity'} /></div>
					<div>4. Leave password field empty</div>
					<div>5. Check the box after accepting the Terms and Conditions</div>
					<div>6. Click the save button</div>
				</div>
			);
		}
	];
	const activeProps = { id, IdComp, url, UrlComp, CopyInputLabel,  };
	const ActiveComponent = components[step];
	const next = () => {
		if(step < steps.length) setStep(step + 1);
	}
	const back = () => {
		if(step > 0) setStep(step - 1);
	}

	useEffect(
		() => {
			if(step === steps.length && setWaitPairing) setWaitPairing(true);
		},
		[step ],
	);*/

	return (
		<div className='wallbox-ocpp'>
			<div>
				<img src={fullGif} alt="wallbogif" className="wallbox-gif-img"/>
			</div>
			<div className="wallbox-ocpp-steps">
				<List component="div" dense className="wallbox-ocpp-steps">
					<ListItem>
						<ListItemText
							primary={'Log in to https://my.wallbox.com/'}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary="Navigate to your chargers list and choose charger to pair"
							secondary="NOTE: Ensure charger is compatible with OCPP"
						>
						</ListItemText>
					</ListItem>
					<ListItem>
						<ListItemText
							primary="Navigate to your chargers list and choose charger to pair"
							secondary="NOTE: Ensure charger is compatible with OCPP"
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary="Fill out all the values with the ones supplied"
						/>
					</ListItem>
					<List dense component="div" className="wallbox-ocpp-steps-innner">
						<ListItem>
							<ListItemText
								primary="1. Flip the switch labeled 'Websocket Connection'"
							/>
						</ListItem>
						<ListItem>
							<ListItemText
								primary="2. Fill the URL field with the URL value below"
								secondary="Click the icon to copy the value to your clipboard"
							/>
						</ListItem>
						<ListItem>
							<ListItemText
								primary="3. Fill the ID field with the ID value below"
								secondary="Click the icon to copy the value to your clipboard"
							/>
						</ListItem>
						<ListItem>
							<ListItemText
								primary="4. Leave password field empty"
							/>
						</ListItem>
						<ListItem>
							<ListItemText
								primary="5. Check the box after accepting the Terms and Conditions"
							/>
						</ListItem>
						<ListItem>
							<ListItemText
								primary="6. Click the save button"
							/>
						</ListItem>
					</List>
				</List>
			</div>
			<Button className='wallbox-ocpp-confirm-wait' onClick={() => setWaitPairing(true)}>Ready</Button>
		</div>
	);

	/*return (
		<div className='wallbox-ocpp'>
			<Stepper activeStep={step}>
				{
					steps.map( (label, idx) => {
						const stepProps = { completed: false, key: idx };
						return (
							<Step {...stepProps}>
								<StepLabel>{label}</StepLabel>
							</Step>
						);
					})
				}
			</Stepper>
			{ ActiveComponent ? <ActiveComponent {...activeProps}/>: null }
			<div>
				{
					step === 0 ? null :
					<Button onClick={back}>
						back
						</Button>
				}
				{
					step === steps.length ? null :
						<Button onClick={next}>
							Next
						</Button>
				}
			</div>
		</div>
	);*/
}

const ocpp = OCPP;
const vendorNames = ['Wall Box Chargers'];

export default { Icon, count, steps, ocpp, vendorNames };