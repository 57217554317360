/**
 * Searches the current url for relevant search parameters
 * @return {Object} Object with keys:values as the search parameters
 */
function getSearchUrl() {
	const { location, URLSearchParams } = window;
	const { search } = location;
	const searchURL = new URLSearchParams(search);
	const searchURLObj = {};

	//Iterate the search parameters.
	for (let p of searchURL) {
		const [key, value] = p;

		searchURLObj[key] = value;
	}

	return searchURLObj;
}

/**
 * Sets current url's search parameters to the provided search parameters
 * User can decide whether the browser history is replaced or pushed into
 * @param {Object}  searchParams  Object with key: value as the parameter name and values respectively
 * @param {Boolean} replace       Whether the browser's history is replaced or not
 */
function setUrl(searchParams, replace = false) {
	const { location, URLSearchParams, history } = window;
	const { pathname, protocol, host } = location;

	const search = new URLSearchParams('');

	Object.entries(searchParams).forEach(([key, val]) => {
		search.append(key, val);
	});

	const path = `${protocol}//${host}${pathname}?${search.toString()}`;

	if (replace) {
		history.replaceState({ path }, "", path);
	}
	else {
		history.pushState({ path }, "", path);
	}
}

function getQuery() {
	const test = new URLSearchParams(window.location.search);
	const test2 = Object.fromEntries(test.entries());
	return test2;
}

function getPathName() {
	const { location: { pathname } } = window;
	return pathname
}

function getFleetUrl() {
	const getCurrentPath = getPathName();
	return getCurrentPath.split('/')[1]
}

export { getSearchUrl, setUrl, getFleetUrl, getPathName, getQuery };