import React from 'react';
import { Doughnut } from "react-chartjs-2";
import { Chart, registerables } from 'chart.js';
import './round-graph.styles.scss';

Chart.register(...registerables);

/**
 * @param { max } number Max value to display in graph
 * @param { current } number Current value read from socket for this type of unit 
 * @param { type } string Type of units read to be displayed in this component passed to round graph to display in graph tooltip
 * @param { bgColorI } string Two colors to be in the doughnut graph one highlight another background
 * @param { bgColorII } string Two colors to be in the doughnut graph one highlight another background
 *
 * @returns - A graph matching the values sent as input to this function.
 */
const RoundGraph = ( input ) => {
    const { remaining, current, type, backgroundColorI, backgroundColorII, cutout = 78} = input;  
    // GRAPH rendering options
    const graphOptions = { plugins: { legend: { display: false } }, elements: { arc: { borderWidth: 0 } }, cutout: '85%'};
    // GRAPH values to display and other configs
    var graphData = { 
        maintainAspectRatio: false, 
        responsive: true, 
        labels: [type , ''], 
        datasets: [ { data: [current, remaining], 
        backgroundColor: [backgroundColorI, backgroundColorII], 
        hoverBackgroundColor: []}]
    };
    return (
        <div className='round-graph'  >
            <Doughnut data={graphData} options={graphOptions} width={100} height={100}/>
        </div>
    );
}
export default RoundGraph;