import validator from "@rjsf/validator-ajv8";
import { steps, stepsMap } from "../config-wizard-steps";

function transformErrors(form, path) {
  const formData = form?.state?.formData[path] ?? {};
  return (errors) =>
    errors.reduce((errorsList, error) => {
      const isPropDirty =
        error.property.replace(`.${path}`, "").slice(1) in formData;
      return isPropDirty ? [...errorsList, error] : errorsList;
    }, []);
}

export const generateStepSchema = (step, data) =>
  step.schemaFunction ? step.schemaFunction(data) : step.schema;

export const validateStep = (stepReference, data, changes) => {
  if (!data || !changes) return false;

  let step;
  let stepId;
  if (typeof stepReference === "number") {
    step = steps[stepReference];
    stepId = step.id;
  } else if (typeof stepReference === "string") {
    step = stepsMap[stepReference];
    stepId = stepReference;
  } else {
    return false;
  }

  if (step.nonFunctional) return true;

  const formData = changes.get(stepId);

  if (!formData) return true;

  const schema = generateStepSchema(step, data);

  let isValid = false;
  if (step.validateFunction) {
    isValid = step.validateFunction(schema, formData);
  } else {
    isValid = validator.isValid(schema, formData, schema);
  }

  // TODO: Visualize validation
  // let validationData;
  // if (step.validateFormData) {
  //   validationData = step.validateFormData(formData, schema);
  // } else {
  //   validationData = validator.validateFormData(formData, schema);
  // }
  // console.log("validationData", validationData);

  return isValid;
};

export const BRAND_MODEL_UI_SCHEMA = {
  "ui:options": {
    title: "",
    description: "",
  },
  brand: {
    "ui:widget": "hidden",
  },
  model: {
    "ui:title": "Model",
  },
};

export const isReadyToSave = (data, changes) =>
  steps.every((step) => validateStep(step.id, data, changes));
