import React, { useState, useEffect } from 'react';

import { Amplify, Auth, API } from 'aws-amplify';

function useTerms() {
	const [terms, setTerms] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	
	const fetchTerms = async () => {
		setLoading(true);
		try {
			const res = await API.get("IO_API", `/terms`);
			setTerms(res);
		} catch (e) {
			console.log(e);
			setError(`Could not load terms, please try again later - ${e}`);
		}
		setLoading(false);
	}

	useEffect(
		() => {
			fetchTerms();
		},
		[]
	);

	if (error) return { error };
	return terms;
}

export { useTerms }