import React, { useEffect, useState } from 'react';
import { useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DashboardIcon from '@mui/icons-material/Dashboard';
import RefreshIcon from '@mui/icons-material/Refresh';
import EditIcon from '@mui/icons-material/Edit';
import Autocomplete from '@mui/material/Autocomplete';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import PersonIcon from '@mui/icons-material/Person';
import DescriptionIcon from '@mui/icons-material/Description';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Menu from '@mui/material/Menu';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';

import useUser from '../../utils/useUser';

import { merge, mergeWith } from 'lodash';

import JSZip from 'jszip';
import { saveAs } from 'file-saver';

import { API } from "Amplify.js";

function base64ToBuffer(str){
    str = window.atob(str); // creates a ASCII string
    var buffer = new ArrayBuffer(str.length),
        view = new Uint8Array(buffer);
    for(var i = 0; i < str.length; i++){
        view[i] = str.charCodeAt(i);
    }
    return buffer;
}

function fleetTreeGen(fleets){
	return fleets ? fleets.reduce( (acc, fleet) => {
		const { thingGroupName, parentGroupNames = [] } = fleet;
		const objectAgnostic = {};
		let ref = objectAgnostic;

		[...parentGroupNames, thingGroupName].forEach( (name, index, array) => {
			const toInsert = {};

			if(index === array.length -1) Object.entries(fleet).forEach(([k,v]) => toInsert[k]=v);
			else toInsert.fleets = {};

			ref[name] = toInsert;

			ref = ref[name].fleets;
		});

		acc = merge(objectAgnostic, acc);

		return acc;
	}, {}) : null;
}

function FleetModal(props){
	const { fleet, setFleet, users, setFleets, currentParent, admin, user, refresh, setFleetsShow } = props;
	const { action, ...fleetData } = fleet || {};
	const { thingGroupName, attributes } = fleetData;
	const { type } = attributes || {};
	const [ newFleet, setNewFleet ] = useState({});
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [replace, setReplace] = useState(false);
	const [thingNameReplace, setThingNameReplace] = useState(null);
	const { thingGroupName: thingGroupNameNew, thingGroupDescription: thingGroupDescriptionNew, attributes: attributesNew = {} } = newFleet || {};
	const {
		userId: userIdNew,
		supportEmail: supportEmailNew,
		accountingEmail: accountingEmailNew,
		contactEmail: contactEmailNew,
		language: languageNew,
		partnerShare: partnerShareNew,
		userShare: userShareNew,
		operationalRate: operationalRateNew,
		noComms: noCommsNew,
	} = attributesNew;
	const { username } = user || {};
	const showEditUser = admin || username !== userIdNew;

	let title, submit, cancel, inputs = [], errorMsg, displayNameArray, displayName, buttons, warning, inputLoaded;

	cancel = () => {
		setNewFleet({});
		setFleet(null);
		setError(null);
	};

	submit = async () => {
		const { thingGroupName: newName, thingGroupDescription: newDescription, attributes: newAttributes = {}, parentGroupNames = [] } = newFleet;
		const toAdd = { thingGroupName: newName, thingGroupDescription: newDescription, attributes: newAttributes, parentGroupNames: (action === 'post' ? [...parentGroupNames, thingGroupName] : parentGroupNames) };
		const body = { thingGroupDescription: toAdd.thingGroupDescription, thingGroupName: toAdd.thingGroupName, ...newAttributes, parentGroupName: (action === 'post' ? thingGroupName : undefined ) };

		try{
			setLoading(true);
			setError(null);
			if(action === 'apikey'){
				setNewFleet(null);
				const result = await API.get("IO_API", `/fleet/apikey`, { headers: {"Content-Type": "application/json"}, queryStringParameters: { thingGroupName } });
				setNewFleet(result);
				setLoading(false);
			}
			else if(action === 'certificates'){
				const { value: apikey } = await API.get("IO_API", `/fleet/apikey`, { headers: {"Content-Type": "application/json"}, queryStringParameters: { thingGroupName } });

				const result = await API.get("IO_API", `/fleet/certificates`, { headers: {"Content-Type": "application/json", "X-API-Key": apikey}, queryStringParameters: { thingNameReplace: thingNameReplace ? thingNameReplace : undefined } });
				const buffer = base64ToBuffer(result);
				const zip = new JSZip();

				zip.loadAsync(buffer, {base64: true, checkCRC32: true}).then(zipR => {
					zip.generateAsync({type: 'blob'}).then(content => {
						saveAs(content, "certificates.zip");
						cancel();
					});
				});
				setLoading(false);
			}
			else{
				await API[action]("IO_API", `/fleet`, { headers: {"Content-Type": "application/json"}, body });
				const fleetsLocalAction = JSON.parse(window.localStorage.getItem('fleets')) || [];

				if(["post", "del"].includes(action)){
					setFleetsShow(
						o => {
							o.push(thingGroupName, ...parentGroupNames);
							return o;
						}
					);
					fleetsLocalAction.push({...toAdd, action, thingGroupName: action==='post'? `${thingGroupName}_${newName}` : newName});
				}

				localStorage.setItem('fleets', JSON.stringify(fleetsLocalAction));
				setFleets(old => {
					switch(action){
						case "post":
							old.push({...toAdd, action});
							break;
						case "del":
							old.splice(old.findIndex(fleet => fleet.thingGroupName === newName), 1, {...toAdd, action});
							break;
						case "put":
							old.splice(old.findIndex(fleet => fleet.thingGroupName === newName), 1, toAdd);
							break;
						default:
							break;
					}
					return old;
				});
				setLoading(false);
				cancel();
			}
			refresh();
		}
		catch(err){
			console.log("ERROR", err, Object.keys(err));
			const {response = {data:{body: `${err}`}}} = err || {};
			setError(response);
			setLoading(false);
		}
	};

	useEffect( () => {
		const { action: currentAction, thingGroupName: currentName, thingGroupDescription : currentDescription, attributes: { userId : currentId, supportEmail: currentSupportEmail, accountingEmail: currentAccountingEmail, contactEmail: currentContactEmail, language: currentLanguage, partnerShare: currentPartnerShare, userShare: currentUserShare, operationalRate: currentOperationalRate, noComms: currentNoComms } = {}, parentGroupNames } = fleet || {};
		const currentFleet = currentAction === 'post' ? { attributes: { userId: action === 'post' ? undefined : currentId, supportEmail: currentSupportEmail, accountingEmail: currentAccountingEmail, contactEmail: currentContactEmail, language: currentLanguage }, parentGroupNames } : { thingGroupName: currentName, thingGroupDescription: currentDescription, attributes: { userId: currentId, supportEmail: currentSupportEmail, accountingEmail: currentAccountingEmail, contactEmail: currentContactEmail, language: currentLanguage, partnerShare: currentPartnerShare, userShare: currentUserShare, operationalRate: currentOperationalRate, noComms: currentNoComms }, parentGroupNames };

		setNewFleet(currentFleet);
		if(action === 'apikey') submit();
	}, [fleet, action]);

	const usersOptions = (users || []).map(u => ({ label: u.email, value: u.email, key: u.userId, userId: u.userId }));

	const onChange = (ev, val, reason) => {
		const { target : { name, value, inputMode, min, max, checked, type } = {} } = ev;
		const allowedFields = ['userId', 'supportEmail', 'accountingEmail', 'contactEmail', 'language', 'partnerShare', 'operationalShare', 'userShare', 'noComms'];
		const formatRegular = /[ ``´!ª«»º@#$%^&*()+=[\]{};':"\\|,<>/?~]/;
		if(name && (value || value === '')){
			if(name === "thingGroupName" && formatRegular.test(value)) return 0;
			if(inputMode === "numeric") {
				if (isNaN(value) || Number(value) < min || (max!=='' && Number(value) > max)) return 0;
			}
			if(allowedFields.includes(name)) setNewFleet( old => {
				return merge({}, old, {attributes: {[name]: type !== 'checkbox' ? value : JSON.stringify(checked)}})
			});
			else setNewFleet(old => ({...old, [name]: value}));
		} else if (reason) { // Because using Autocomplete
			const ret = val ? val.userId : userIdNew;
			let toReturn = ret;
			if (reason === 'clear') toReturn = '';
			setNewFleet(old => {
				return merge({}, old, {attributes: {userId: toReturn}});
			});
		}
		setError(null);
	};



	inputs.push(
		(
			<TextField multiline key="thingGroupDescription" name="thingGroupDescription" variant="filled" label="Description" onChange={onChange} value={thingGroupDescriptionNew || ''}/>
		),
		(
			<TextField select multiline key="language" name="language" variant="filled" label="Language" onChange={onChange} value={languageNew || ''}>
				<MenuItem value='En'>En</MenuItem>
				<MenuItem value='Du'>Du</MenuItem>
			</TextField>
		),
		
	);

	if (showEditUser) {
		inputs.push(
			(
				<Autocomplete
					key="userId"
					name="userId"
					variant="filled"
					label="User"
					onChange={onChange}
					value={
						(users || []).filter((u)=>u.userId === userIdNew).pop()?.email
					}
					options={usersOptions}
					renderOption={(props, option) => {
						return (
							<li {...props} key={option.value}>
							{option.label}
							</li>
						);
					}}
					renderInput={(params) =>
						{
							return (
								<TextField {...params} variant="outlined" label='User' />
							);
						}
					}
				/>
				/*	<MenuItem disabled value="">Choose user</MenuItem>
					{
						(users || []).map(user => <MenuItem key={user.userId} value={user.userId}>{user.email}</MenuItem>)
					}
				</Autocomplete>*/
			),
		);
	}

	if (admin) {
		if (type === 'base') {
			inputs.push(
				(
					<TextField multiline key="supportEmail" name="supportEmail" variant="filled" label="Support Email" onChange={onChange} value={supportEmailNew || ''}/>
				),
				(
					<TextField multiline key="accountingEmail" name="accountingEmail" variant="filled" label="Accounting Email" onChange={onChange} value={accountingEmailNew || ''}/>
				),
				(
					<TextField multiline key="contactEmail" name="contactEmail" variant="filled" label="Contact Email" onChange={onChange} value={contactEmailNew || ''}/>
				),
				(
					<TextField inputProps={{ inputMode: 'numeric', min: 0, max: 1 }} key="partnerShare" name="partnerShare" variant="filled" label="Partner Share" onChange={onChange} value={partnerShareNew || ''}/>
				),
				(
					<TextField inputProps={{ inputMode: 'numeric', min: 0, max: 1 }} key="userShare" name="userShare" variant="filled" label="User Share" onChange={onChange} value={userShareNew || ''}/>
				),
				(
					<FormGroup key='noComms' ><FormControlLabel label="Mute communications" control={<Checkbox name='noComms' checked={noCommsNew ? Boolean(JSON.parse(noCommsNew)) : false} onChange={onChange}></Checkbox>}></FormControlLabel></FormGroup>
				),
			);
		} else if (type) {
			inputs.push(
				(
					<TextField inputProps={{ inputMode: 'numeric', min: 0, max: 1 }} type="number" key="operationalRate" name="operationalRate" variant="filled" label="Operational Rate" onChange={onChange} value={operationalRateNew || ''}/>
				),
			);
		}
	} else {

	}

	if(thingGroupName && (currentParent || currentParent === "")){
		displayNameArray = currentParent !== "" ? thingGroupName.split(`${currentParent}_`) : [" ", ...thingGroupName];
		displayName = displayNameArray.length > 1 ? displayNameArray.slice(1).join('') : displayNameArray[0];
	}

	const confirmText = loading ? <CircularProgress size={20} /> : 'Confirm';

	buttons = (
		<React.Fragment>
			<Button disabled={!Boolean(thingGroupNameNew) || loading} onClick={submit}>{confirmText}</Button>
			<Button onClick={cancel}>Cancel</Button>
		</React.Fragment>
	);

	warning = (
		<Typography variant="subtitle1" display="block" gutterBottom>
			NOTE: Actions may take up to 1 minute to take effect
		</Typography>
	);

	switch(action){
		case "post":
			title = `Add fleet to ${displayName}`;
			inputs.unshift(
				<TextField required error={!Boolean(thingGroupNameNew)} name="thingGroupName" key="thingGroupName" onChange={onChange} value={thingGroupNameNew || ''} label="Fleet name"  variant="filled"/>
			);
			break;
		case "put":
			title = `Edit ${displayName}`;
			break;
		case "del":
			inputs = (<div>Are you sure ?</div>);
			title = `Delete ${displayName}`;
			break;
		case "apikey":
			const { value } = newFleet || {};
			title = `${displayName} - API Key`;
			inputLoaded = (
				<React.Fragment>
					{value}
					<IconButton
                        onClick={async () => await window.navigator.clipboard.writeText(value)}
                        size="large"><FileCopyIcon /></IconButton>
				</React.Fragment>
			)
			inputs = loading ?  <span>Loading . . .</span> : <Typography component="span" style={{justifySelf: 'center'}} variant="h6">{inputLoaded}</Typography>;
			buttons = null;
			warning = null;
			break;
		case "certificates":
			title = `${displayName} - Onboard Credentials`;
			inputs = [
				"Create onboarding credentials for this fleet?",
				<FormControlLabel
					control={<Checkbox checked={replace} onChange={() => {setReplace(!replace); setThingNameReplace('')}} name="replace" />}
					label="Replace existing"
				/>

			]
			if(replace) inputs.push(<TextField onChange={(ev) => setThingNameReplace(ev.target.value)} label='Thing Name' placeholder="ex: QJik1UFaL_ukkGhntkr10"></TextField>);
			warning = null;
			break;
		case "contacts":
			title = `${displayName} - Contacts`;
			inputs = [
				<Stack direction="column" spacing={1}>
			      <Chip onClick={async () => await window.navigator.clipboard.writeText(supportEmailNew)} disabled={!supportEmailNew} label={`Support: ${supportEmailNew || 'N/A'}`} variant="outlined" />
			      <Chip onClick={async () => await window.navigator.clipboard.writeText(contactEmailNew)} disabled={!contactEmailNew} label={`Contact: ${contactEmailNew || 'N/A'}`} variant="outlined" />
			      <Chip onClick={async () => await window.navigator.clipboard.writeText(accountingEmailNew)} disabled={!accountingEmailNew} label={`Accounting: ${accountingEmailNew || 'N/A'}`} variant="outlined" />
			    </Stack>
			]
			warning = null;
			buttons = null;
		default:
			break;
	}

	if(error){
		console.log(error.data);
		errorMsg = `${error.data}`
	}

	let style = {padding: "10px", display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(225px, 1fr))', gridGap: '25px'};
	if (action === 'del') {
		style = {};
	}

	return(
		<Dialog
			open={Boolean(action)}
			onClose={cancel}
			maxWidth='md'
			fullWidth={true}
		>
			<DialogTitle component="div" variant="h5">{title}</DialogTitle>
			<DialogContent component="div" >
				<Alert severity={errorMsg ? 'error' : 'warning'}>{errorMsg ? errorMsg : warning}</Alert>
				<DialogContentText component="div"  style={style}>
					{ inputs }
				</DialogContentText>
				<DialogActions>
					{buttons}
				</DialogActions>
			</DialogContent>
		</Dialog>
	);
}

function FleetLabel(props){
	const { name, setFleet, fleet, parent, setCurrentParent, setAnchorEl, admin, parentType, refresh } = props;
	const { attributes, secondary } = fleet || {};
	const { type = parentType } = attributes || {};
	const { action, thingGroupName } = fleet;
	const { path } = useRouteMatch();
	const history = useHistory();
	const { search } = useLocation();
	let displayText;

	const showMenu = (ev) =>  {
		const fleetEdited = JSON.parse(JSON.stringify(merge({ attributes: { type } }, fleet)))
		setFleet(fleetEdited);
		setCurrentParent(parent);
		setAnchorEl(ev.currentTarget);
		ev.stopPropagation();
	};

	const dasboardClick = (ev) => {
		history.push(`${thingGroupName}${search}`);

		ev.stopPropagation();
	};

	const textMapping = {
		post: 'creating . . .',
		del: 'removing . . .'
	};

	const css = {display: "flex", alignItems: 'center'/* gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))" */};
	displayText = Boolean(action) ? `${name} (${textMapping[action]})` : name;
	if(action) {
		css.color = "#868686";
	}

	const showEdit = !secondary;

	const buttons = ( thingGroupName ?
		<>
			<IconButton onClick={dasboardClick} size="large">
				<DashboardIcon/>
			</IconButton>
			{ !showEdit ? null : <IconButton onClick={showMenu} size="large">
				<AddIcon />
			</IconButton> }
		</> : null
	);

	return (
		<div style={css}>
			<div>{displayText}</div>
			{ 
				Boolean(action) ?
					<IconButton onClick={refresh} size="large">
						<RefreshIcon/>
					</IconButton> 
					: buttons 
			}
		</div>
	);
}

function FleetMenu(props){

	const { admin, fleet, setFleet, anchorEl, closeMenu, findObj, user } = props;
	const { attributes: { apiKey, userId, contactEmail, accountingEmail, supportEmail } = {} } = fleet || {};
	const { username } = user || {};

	const showDelete = admin || userId !== username;

	const options = {
		"Contacts": {
			Icon: PermContactCalendarIcon,
			value: userId,
			onClick: () => {
				setFleet({...fleet, action: 'contacts'});
				closeMenu();
			}
		},
		/* "Certificates": {
			Icon: CardMembershipIcon,
			onClick: () => {
				setFleet({...fleet, action: 'certificates'});
				closeMenu();
			}
		}, */
		"Edit Fleet": {
			Icon: EditIcon,
			value: true,
			onClick: () => {
				setFleet({...fleet, action: 'put'});
				closeMenu();
			}
		},
		"Add Fleet": {
			Icon: AddToPhotosIcon,
			value: true,
			onClick: () => {
				setFleet({...fleet, action: 'post'});
				closeMenu();
			}
		}
	}

	if (showDelete) {
		options["Delete Fleet"] = {
			Icon: DeleteForeverIcon,
			value: true,
			onClick: () => {
				setFleet({...fleet, action: 'del'});
				closeMenu();
			}
		}
	}

	if (admin) {
		/*options["API Key"] = {
			Icon: VpnKeyIcon,
			value: apiKey,
			onClick: async () => {
				setFleet({...fleet, action: 'apikey'});
				closeMenu();
			}
		};*/
		options["User"] = {
			Icon: PersonIcon,
			value: userId,
			onClick: () => findObj(1, userId)
		};
	}

	return (
		<Menu
			anchorEl={anchorEl}
			open={Boolean(anchorEl)}
			onClose={() => closeMenu()}
		>
			{
				Object.entries(options).map( ([text, option]) => {
					const {Icon, value, onClick= () => window.alert(`${text}: ${value}`)} = option;

					return <MenuItem onClick={onClick} key={text}><Icon />{text}</MenuItem>
				})
			}
		</Menu>
	)
}

function FleetBranch(props){
	const { name, user, setFleet, fleet, setAnchorEl, parent, setCurrentParent, admin, parentType, refresh } = props;
	const { action, fleets = {}, ...fleetData } = fleet;
	const { thingGroupDescription, attributes } = fleetData;
	const { type = parentType } = attributes || {};

	const branches = Object.entries(fleets).map( ([fleetName, fleet]) => <FleetBranch refresh={refresh} user={user} id={name} parentType={type} admin={admin} setCurrentParent={setCurrentParent} parent={name} setAnchorEl={setAnchorEl} key={fleetName} name={fleetName} fleet={fleet} setFleet={setFleet}/>);

	const displayNameArray = parent !== "" ? name.split(`${parent}_`) : [" ", ...name];
	const displayName = displayNameArray.length > 1 ? displayNameArray.slice(1).join('') : displayNameArray[0];

	const label = <FleetLabel refresh={refresh} user={user} parentType={type} admin={admin} name={displayName} setFleet={setFleet} fleet={fleet} parent={parent} setCurrentParent={setCurrentParent} setAnchorEl={setAnchorEl}/>;

	return(
		<TreeItem itemId={name} nodeId={name} label={label}>
			<div>
				<div style={{display: 'inline-flex', alignItems: 'center'}}>
					<DescriptionIcon /> { thingGroupDescription ? thingGroupDescription : "No description" }
				</div>
				<div>
					{branches}
				</div>
			</div>
		</TreeItem>
	);
}

function FleetList(props){

	const { fleets, users, setFleets, loading, refresh, setError, findObj, admin } = props;
	const [fleet, setFleet] = useState(null);
	const [currentParent, setCurrentParent] = useState(null);
	const [anchorEl, setAnchorEl] = useState(null);
	const [user, loadingUser, errorUser] = useUser();
	const [fleetsShow, setFleetsShow] = useState([]);
	// Will probably need to deal with a user collection error here
	if(loading) return <div>Loading . . .</div>;
	if(!fleets || fleets.length < 1) return <div>No fleets found</div>;

	const fleetTree = fleetTreeGen(fleets);
	const closeMenu = () => setAnchorEl(null);

	const branches = Object.entries(fleetTree).length > 0 ? Object.keys(fleetTree).sort().map( name =>
		<FleetBranch
			id={name}
			setCurrentParent={setCurrentParent}
			parent={""} setAnchorEl={setAnchorEl}
			setFleet={setFleet}
			key={name}
			name={name}
			fleet={fleetTree[name]}
			admin={admin}
			user={user}
			refresh={refresh}
		/>
	) : "No fleets found";

	return (
		!loadingUser ? 
			<React.Fragment>
				<SimpleTreeView
					defaultCollapseIcon={<ExpandMoreIcon />}
					defaultExpandIcon={<AccountTreeIcon />}
					style={{maxHeight: '100%', overflowY: 'auto'}}
					defaultExpandedItems={fleetsShow}
				>
					{branches}
				</SimpleTreeView>
				<FleetMenu admin={admin} findObj={findObj} closeMenu={closeMenu} anchorEl={anchorEl} setFleet={setFleet} fleet={fleet} user={user}/>
				<FleetModal admin={admin} currentParent={currentParent} setFleetsShow={setFleetsShow} setFleets={setFleets} setError={setError} refresh={refresh} users={users} fleet={fleet} user={user} setFleet={setFleet}/>
			</React.Fragment>
		: <div>Loading. . .</div>
	);
}

export default FleetList;