import React from "react";

import getClientInfo from "../../clients";

function Footer(props){
	let clientInfo = getClientInfo();
    let text = `Lifepowr © ${(new Date()).getFullYear()}`;

    if (clientInfo) text = clientInfo.footer ?? '';
 
	return (
		<div>{text}</div>
	);
}

export default Footer;
