import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';

import useSocket from '@lifepowr/components/src/components/socket/useSocket';

import './styles.scss';

import { red, green } from '@mui/material/colors';

function Reboot(props){

	const [result, setResult] = useState({});
	const [loading, setLoading] = useState(false);
	const { onClose = () => {} } = props;
	const { deviceName } = useParams();
	const { publish } = useSocket();
	const taskTopic = `fleet/devices/${deviceName}/state/interact`;
	const { text, color } = result;

	useEffect( () => {
		async function send(){
			try{
				const res = confirm('Reboot device? (May take up to 1 minute)');
				let result; 
				
				if(res){
					result = await publish(taskTopic, {task: 'reboot'}, true);
					console.log("GOT IT", result);
					alert('Device should reboot in a few seconds');
				}
				if(res && result){
					//setResult({text: 'Device should reboot in a few seconds', color: green[500]});
				}

			}
			catch(e){
				setLoading(false);
				alert(`An error occurred - ${e}`)
				//setResult({text: `An error occurred - ${err}`, color: red[500]});
			}
			onClose();
		}
		send();
	}, []);

	const sending = async () => {
		let res;
		setLoading(true);
		try{
			res = await publish(taskTopic, {task: 'reboot'}, true);
			setLoading(false);
			setResult({text: 'Device should reboot in a few seconds', color: green[500]});
			setTimeout( () => {
				onClose();
			}, 2000);
		}
		catch(err){
			setLoading(false);
			setResult({text: `An error occurred - ${err}`, color: red[500]});
		}
	};

	let content = <div>Loading . . .</div>

	if(!loading) content = (
		<div>
			<div>
				<Button onClick={sending}>
					Yes
				</Button>
				<Button onClick={onClose}>
					No
				</Button>
			</div>
			{ text && color ? <div style={{color}}>{text}</div> : null}
		</div>
	);

	/*
		<div className="reboot-wrapper">
			<Typography variant="h5" gutterBottom>
				Please confirm
			</Typography>
			{ content }
		</div>
	 */

	return null;
}

export default Reboot;