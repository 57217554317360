import React from 'react';
import './house-composition-status-element.styles.scss'
import RoundGraph from '@lifepowr/components/src/components-shared/round-graph-component/round-graph.component';
import HouseWifi from '@lifepowr/components/src/assets/icons/device-house-wifi.png'

/**
 * Renders a circle with a round graph and a labek with statistics. 
 * @param {name, iconsrc, color, colorII, showWifi, label, value, max} 
 * name > Name is to be used in the doughnut graph, 
 * iconsrc > Icon url to be displayed on the side on the round graph, 
 * color > This is the color for the main result , 
 * colorII > This one is the color for the remaining value, 
 * showWifi > Controls the display of the wifi icon on the side, 
 * label > keeps the text shown in the center of the circle , 
 * value> Is the current value for the statistics , 
 * max  > max value or "the other part" of the doughnut graph
 * @returns a circle component with a round graph and a labek with statistics. 
 */
function HouseCompositionStatusElement(props){
    const {name, iconsrc, color, colorII, showWifi, label, value, max, clickable, cutout, charge} = props;

    return (
        <div className={`status-element`}  >
        
            <div className={clickable ? 'status-element__circle-wrapper clickable' : 'status-element__circle-wrapper'}>

                <div className='status-element__circle'>
                    <RoundGraph current={value} remaining={max-value} type={name} backgroundColorI={color} backgroundColorII={colorII} cutout={cutout}/>
                    <p className='status-element__circle__label text-center'>
                        <span className='font-weight-bold'>{name}</span>
                        <br/>
                        <span className={`status-element__circle__label-power ${label < 0? 'red': '' }`}>{isNaN(label) ? label : Math.abs(label) }{!isNaN(label)?'W':''}</span>
                    </p>
                </div>

                <div className={`status-element__circle-icon ${iconsrc ? "" : "d-none"}`} style={{backgroundColor: color}}>
                { 
                    iconsrc ? <img src={iconsrc} alt='house composition round simbol'/> : <span></span>
                }
                </div>
                <div className='status-element__wifi'>
                    {showWifi && <img src={HouseWifi} alt='house composition wi fi' />}            
                </div>    
                <span className={`status_element__charge`} style={{color}}>
                    {charge ? `${charge}%` : null}
                </span>
            </div>
        </div>
    );
};

export default HouseCompositionStatusElement;