export default {
    "6kW-3P3G25": { maxACDCPower: 6000, maxBatteryCurrent: 25, maxMPPTPower: 9000, maxBatteryChargePower: 6000, maxBatteryDischargePower: 6000 },
    "8kW-3P3G25": { maxACDCPower: 8000, maxBatteryCurrent: 25, maxMPPTPower: 12000, maxBatteryChargePower: 8000, maxBatteryDischargePower: 8000 },
    "10kW-3P3G40": { maxACDCPower: 10000, maxBatteryCurrent: 40, maxMPPTPower: 15000, maxBatteryChargePower: 10000, maxBatteryDischargePower: 10000 },
    "12kW-3P3G40": { maxACDCPower: 12000, maxBatteryCurrent: 40, maxMPPTPower: 18000, maxBatteryChargePower: 12000, maxBatteryDischargePower: 12000 },
    "15kW-3P3G40": { maxACDCPower: 15000, maxBatteryCurrent: 40, maxMPPTPower: 22500, maxBatteryChargePower: 15000, maxBatteryDischargePower: 15000 },
    "20kW-3P3G40": { maxACDCPower: 20000, maxBatteryCurrent: 40, maxMPPTPower: 30000, maxBatteryChargePower: 20000, maxBatteryDischargePower: 20000 },
    "3KW-1P3G30": { maxACDCPower: 3000, maxBatteryCurrent: 30, maxMPPTPower: 4500, maxBatteryChargePower: 3000, maxBatteryDischargePower: 3000 },
    "4.2KW-1P3G30": { maxACDCPower: 5000, maxBatteryCurrent: 30, maxMPPTPower: 7500, maxBatteryChargePower: 5000, maxBatteryDischargePower: 5000 },
    "6KW-1P3G30": { maxACDCPower: 6000, maxBatteryCurrent: 30, maxMPPTPower: 9000, maxBatteryChargePower: 6000, maxBatteryDischargePower: 6000 },
};