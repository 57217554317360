import { useMediaQuery as _useMediaQuery } from 'usehooks-ts'

/**
 * The function `useMediaQuery` returns an object with properties indicating whether the screen size
 * matches specific media query conditions.
 */
const useMediaQuery = () => ({
    isSmall: _useMediaQuery('(min-width: 375px) and (max-width: 768px)'),
    isMedium: _useMediaQuery('(min-width: 768px) and (max-width: 1125px)'),
    isLarge: _useMediaQuery('(min-width: 1125px) and (max-width: 1200px)'),
    isXLarge: _useMediaQuery('(min-width: 1200px) and (max-width: 1400px)'),
    isXXLarge: _useMediaQuery('(min-width: 1400px)'),
})

export default useMediaQuery