const SET_ADMIN = 'SET_ADMIN';

const initialState = {
	admin: false,
};

function adminReducer(state = initialState, action = {}) {
	const { type, data } = action;


	switch (type) {
		case SET_ADMIN:
			return { admin: true };
		default:
			return state;
	}
}

// Action creators
const setAdmin = (data) => ({ type: SET_ADMIN, data });

export { setAdmin };
export default adminReducer;
