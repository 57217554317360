import COLOR from "./colors-variables";

const now = (new Date()).getTime() / 1000;
const day = (60 * 60 * 24);

//info used as initial chart settings
export const initialPlotData = [{
    x: [new Date(now * 1000), new Date((now - day) * 1000)],
    y: [null, null],
    type: 'scatter',
    initialPlotData: true
}];

//info used as initial chart settings based on timeRange and yValue
export const newPlotData = (date, yValue) => {
    const xAxis = date ? [new Date(date)] : []
    const yAxis = yValue ? [yValue] : []
    return {
        x: xAxis,
        y: yAxis,
        type: 'scatter',
        mode: 'lines',
        marker: { color: COLOR.redNormal }
    }
}

export const HALF_HOUR = 30;
export const HOUR = 60;
export const DAY = HOUR * 24;
export const MONTH = 30 * DAY;