import React, { useState, useEffect } from 'react';
import './historical-plot-bars.styles.scss';
import { connect } from 'react-redux';

import useSocket from '@lifepowr/components/src/components/socket/useSocket';

function convertValues(values){
    const {current, unit, max = 0} = values;
    const prefixes = [["G", 1e9], ["M", 1e6], ["k", 1e3], ["", 1]];
    const display = {};

    for (var i = 0; i < prefixes.length; i++) {
        let [prefix, power] = prefixes[i];
        display.current = current / power;
        display.max = max / power;
        display.unit = `${prefix}${unit}`;

        if(Math.abs(display.current) > 1 && Math.abs(display.max) > 1) break;
    }

    return display;
}

/**
 * This component is responsible for the rendering of the whole GRID / ACTIVATED / FREQUENCY data info
 * 
 * @param {title, textFirst, textSecond} 
 * title - Graph title
 * textFirst - first text paragraph 
 * textSecond - second text paragraph 
 * @returns Component fully rendered with title , texts and as many bar as have been configured in barsToDisplay 
 * > bars will display inline due to d-flex flex-row and width = 33% on scss .
 */
const HistoricalPlotBars = ({ title, textFirst, textSecond, fleetRt }) => {
    //get value from socket
    const [freqDataFromSocket, setFreqData] = useState({});
    //calculating frequency
    const frequencyCalc = !!freqDataFromSocket && freqDataFromSocket['frequency'] ? freqDataFromSocket['frequency'].frequency : 500000;
    //const { frequencyCalc: { frequencyCalc = 500000 } = {} } = freqDataFromSocket;
    const frequencyValue = ((frequencyCalc - 500000) / 10000) * 1000;

    const { subscribe, unsubscribe } = useSocket();
    const { Grid = {} } = fleetRt || {};
    const { MeterPower  = 0, MeterPowerFiltered = 0, frequencyResponseAllocation = 10000, frequencyResponseActivation = 0, monthPeak = 0 } = Grid[Object.keys(Grid)[0]] || {};
    //const activatedValue = MTActivepowerR + MTActivepowerS + MTActivepowerT;
    const gridValue = convertValues({current: MeterPowerFiltered, max: monthPeak, unit: "W"});
    const activatedValue = convertValues({current: frequencyResponseActivation, max: frequencyResponseAllocation, unit: "W"});

    //capture socket event
    useEffect(() => {
        subscribe(`fleet/frequency`, (data) => { const { value } = data; setFreqData(value); });
        return () => { unsubscribe(`fleet/frequency`); }
        
    }, []);

    //Three object one per bar to display and respective rendering info 
    
    //var activatedVariables = { label: 'Activated', color: '#e16161', value: activatedValue, direction: activatedValue < 0 ? 0 : 1, unit: 'Mv', top: '', height: '' }
    
    /** calculations for display of frequency bar */
    let maxCSSBarEm = 4;
    let moduleUpDown = 200;
    let gridUpDown = gridValue.max / 2;
    let activatedUpDown = activatedValue.max / 2;
    let freqHeight = ( frequencyValue < 200 && frequencyValue > -200) ? (Math.abs(frequencyValue) * maxCSSBarEm ) / moduleUpDown : 0;
    let gridHeight = ( gridValue.current < gridValue.max / 2 && gridValue.current > gridValue.max / 2 * -1) ? (Math.abs(gridValue.current) * maxCSSBarEm ) / gridUpDown : 0;
    let activatedHeight = ( activatedValue.current < activatedValue.max / 2 && activatedValue.current > activatedValue.max / 2 * -1) ? (Math.abs(activatedValue.current) * maxCSSBarEm ) / activatedUpDown : 0;

    var gridVariables = {
        label: 'Grid', color: '#e16161',
        color: gridValue.current < 0 ? '#E16153' : '#0e5c5c' , 
        value: gridValue.current.toFixed(2),
        direction: gridValue.current < 0 ? 0 : 1,
        unit: gridValue.unit,
        top: gridValue.current.toFixed(0) < 0 ? '3.9em' : ((4-gridHeight)-0.2)+'em' ,
        height: gridHeight+'em' 
    }
    var activatedVariables = {
        label: 'Activated',
        color: activatedValue.current < 0 ? '#E16153' : '#0e5c5c' , 
        value: activatedValue.current.toFixed(2),
        direction: activatedValue.current < 0 ? 0 : 1,
        unit: activatedValue.unit,
        top: activatedValue.current.toFixed(0) < 0 ? '3.9em' : ((4-activatedHeight)-0.2)+'em' ,
        height: activatedHeight+'em' 
    }
    var frequencyVariables = { 
        label: 'Frequency', 
        color: frequencyValue < 0 ? '#E16153' : '#0e5c5c' , 
        value: frequencyValue.toFixed(1), 
        direction: frequencyValue < 0 ? 0 : 1, 
        unit: "mHz", 
        top: frequencyValue.toFixed(0) < 0 ? '3.9em' : ((4-freqHeight)-0.2)+'em' , 
        height: freqHeight+'em' 
    }
    
    //wrap info up for map bellow
    const barsToDisplay = [gridVariables, activatedVariables, frequencyVariables];

    //Returns one bar for each element in barsToDisplay
    return (
        <div className='historical-plot-bars container'>
            <div className='row'>
                <h2 className='col-12 historical-plot-bars__subtitle mb-3'>{title}</h2>
            </div>
            <div className='row'>
                <div className='col-12 col-xl-4'>
                    <p className='historical-plot-bars__text mt-4'>{textFirst}<br /><br />{textSecond}</p>
                </div>
                <div className='col-12 col-xl-8'>
                    <div className='historical-plot-bars__bar-line-index' >
                        <p className='text-center'>Primary Control</p>
                    </div>
                    <div className='historical-plot-bars__bar-graphs mt-4 row d-flex flex-row'>
                        {!!barsToDisplay?.length && barsToDisplay.map((element, index) => (
                            <div key={`historical-plot-bars__${index}`}>
                                <span className='historical-plot-bars__bar-graphs-title mb-2' >{barsToDisplay[index].label}</span>
                                
                                <div className='historical-plot-bars__bar-graphs-bar' >
                                    <div className='historical-plot-bars__bar-graphs-background'>
                                        <div className='historical-plot-bars__bar-graphs-line'></div>
                                        <div className='historical-plot-bars__bar-graphs-foreground' 
                                            style={{ backgroundColor: barsToDisplay[index].color,
                                                     top: barsToDisplay[index].top,
                                                     height: barsToDisplay[index].height }}>
                                        </div>
                                    </div>                                
                                </div>
                                
                                <div className='historical-plot-bars__bar-graphs-legend'>
                                    <span className={`historical-plot-bars__bar-graphs-values ${barsToDisplay[index].direction ? 'green' : 'red'}`}>{barsToDisplay[index].value}</span>
                                    <span className='historical-plot-bars__bar-graphs-values-label'>{barsToDisplay[index].unit}</span>
                                </div>
                            </div>
                        ))}
                        <div className='historical-plot-bars__bar-zero'>0</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProp = (state) => {
    const { fleetRt } = state?.systems || {};
    return { fleetRt };
};

export default connect(mapStateToProp, null)(HistoricalPlotBars);
