import { Typography } from "@mui/material";
import StepComponent from "../components/step.component";
import formSchema from "../../device-details-component/formSchema.json";

export default {
  title: "Address",
  description: "Address details of the installation site.",
  helperText: {
    header: (
      <>
        <Typography variant="h6">
          Enter the full installation address of the FlexiObox.
        </Typography>
        <Typography variant="body" fontWeight={500}>
          ℹ️ This is necessary for running FlexiO fluently (eg. weather
          prediction, DSO,...)
        </Typography>
      </>
    ),
  },
  component: StepComponent,
  schema: formSchema.properties.address,
  uiSchema: {
    "ui:options": {
      title: false,
      classNames: "address-container dense",
    },
  },
};
