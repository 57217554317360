import Button from "@mui/material/Button";
import Link from "@mui/material/Button";

const { getQuery } = require("../utils/url");

import InsaverLogo from './insaver/insaver_logo.jsx';
import SunergyLogo from './sunergy/sunergy_logo.jsx';

const clients =  {
  'smarthomecontrol.be': {
    title: 'Insaver Smart Home Control',
    idp: 'Insaver',
    loginClass: 'loginFormHidden',
    footer: <>Insaver - <Link target="_blank" href="https://insaver.be/helpdesk-shc/">Helpdesk</Link></>,
    logo: InsaverLogo,
    ean: false,
    iban: false,
    tou: false,
    settings: false,
    allowAdvancedMode: false,
    warnings: false,
    signOut: 'https://my.smarthomecontrol.be/user/logout',
  },
  'sunergy.nl': {
    title: 'Sunergy',
    idp: 'Sunergy',
    loginClass: 'loginForm',
    footer: <>Sunergy - <Link target="_blank" href="https://sunergy.nl/contact">Contact</Link></>,
    logo: SunergyLogo,
    ean: false,
    iban: false,
    tou: false,
    settings: false,
    allowAdvancedMode: false,
    warnings: false,
    
  },
  //for testing purposes
  'internetofthings.nl': {
    title: 'Sunergy',
    idp: 'Sunergy',
    loginClass: 'loginForm',
    footer: <>Sunergy - <Link target="_blank" href="https://sunergy.nl/contact">Contact</Link></>,
    logo: SunergyLogo,
    ean: false,
    iban: false,
    tou: false,
    settings: false,
    allowAdvancedMode: false,
    warnings: false,
    
  },
  'sunergy.io-comms.com': {
    title: 'Sunergy',
    idp: 'Sunergy',
    loginClass: 'loginForm',
    footer: <>Sunergy - <Link target="_blank" href="https://sunergy.nl/contact">Contact</Link></>,
    logo: SunergyLogo,
    ean: false,
    iban: false,
    tou: false,
    settings: false,
    allowAdvancedMode: false,
    warnings: false,
    
  },
};

function getClientInfo(clientString) {
  if (!clientString) {
    const { client } = getQuery();
    clientString = client ?? window.location.hostname;
    
  }

  for (const [k, v] of Object.entries(clients)) {
    if (clientString.includes(k)) {
       return v;
    }
  }
}

export default getClientInfo;
