import React from "react";

import './unit-display.styles.scss'

/**
 * 
 * @param {unit} char with the unit to be rendered
 * @param {carbon} boolean indicating whether it's to render a CO2 with styles 
 * @returns A Unit to be displayed.
 */
const UnitDisplay = ({unit, carbon}) => (
    <span className='unit-display'>
        {unit}
    </span>
);

export default UnitDisplay;

