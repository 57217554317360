import React, { useState, useEffect } from 'react';
import useSocket from '@lifepowr/components/src/components/socket/useSocket';
import { useParams } from 'react-router';

import OneLineStatistics from "@lifepowr/components/src/components-shared/one-line-statistics/one-line-statistics.component";
import FieldDropDown from "@lifepowr/components/src/components-shared/field-drop-down-component/field-drop-down.component";
import HomeHouseNetowrk from "@lifepowr/components/src/assets/images/image-home-house-network.png";

import SunWhite from '@lifepowr/components/src/assets/icons/statistics-sun-white.png'
import FlowerWhite from '@lifepowr/components/src/assets/icons/statistics-flower-white.png'
import GraphicWhite from '@lifepowr/components/src/assets/icons/statistics-graphic-white.png'

import './status-green.styles.scss'
/**
 * This functional component is responsible for rendering a green component with a dropdown and several one line statistics fully customizable. 
 * These values should be updated according to values received from a service which at the moment has not been implemented. 
 * 
 * Drop down is populated by the object dropDownOptions
 * When the dropdown is clicked should values on the oneLineStatistics object should be updated. //TODO
 */
const StatusGreen = () => {
    const { fleetOption } = useParams();
    const [fleetData, setFleetData] = useState({});
    const {Billing: { nullBill = 0, IOBill = 0 } = {}} = fleetData || {};
    
    // SOCKET SUBSCRIPTION to read values
    const { subscribe, unsubscribe } = useSocket();
    useEffect(() => {
        subscribe(`fleet/${fleetOption}/data`, (data) => {
            const { value } = data;
            setFleetData(value);
        });
        return () => { unsubscribe(`fleet/${fleetOption}/data`); }

    }, [fleetOption]);

    const fleetDataTitle = fleetData.Savings ? `Monthly Saving (${(new Date(fleetData.Savings.time)).toLocaleString(undefined, {month: 'short', year: 'numeric'})})` : 'Monthly Saving';
    const fleetDataValue = fleetData.Savings ? (fleetData.Savings.lifepowr + fleetData.Savings.solar + fleetData.Savings.ev).toFixed(2) : '0';
    // const fleetSavingsHealth = 

    const healthValues = {
        '0.333': ['GoldenRod', '☢️'],
        '0.666': ['green', '✔️'],
    }
    const healthVals = Object.entries(healthValues).reduce(
        (acc, [k, v]) => {
            if(fleetData.Savings && fleetData.Savings.health) {
                if(fleetData.Savings.health > Number(k)) acc = v;
            }
            return acc;
        },
        ['red', '❌'],
    )

    let evSavingsDiv;
    if (fleetData.Savings && fleetData.Savings.ev) evSavingsDiv = (
        <div style={{color: fleetData.Savings.ev > 0 ? 'green' : 'red'}}>
            🚗⚡🔌: {fleetData.Savings.ev.toFixed(2)} €
        </div>
    );
    let fleetDataTooltip = fleetData.Savings && Object.entries(fleetData.Savings) > 0 ? (
        <div style={{display: 'grid', justifyItems: 'center'}}>
            <div style={{color: (fleetData.Savings.lifepowr || 0) > 0 ? 'green' : 'red'}}>
                🔋: {fleetData.Savings.lifepowr.toFixed(2)} €
            </div>
            <div style={{color: (fleetData.Savings.solar || 0) > 0 ? 'green' : 'red'}}>
                ☀️: {fleetData.Savings.solar.toFixed(2)} €
            </div>
            {evSavingsDiv}
            {/*<div style={{color: healthVals[0]}}>🩺: {Number(fleetData.Savings.health*100).toFixed(0)} % {healthVals[1]}</div>*/}
        </div>
    ) : null;

    const oneLineStatistics = [
        {
            name: 'Reduced Emission',
            Icon: SunWhite,
            value: 'TBA',
            unit: '',
            carbon: 1,  //evaluates to TRUE
            percentage: '10',
            direction: 1 //evaluates to TRUE > UP
        },
        {
            name: fleetDataTitle,
            Icon: FlowerWhite,
            value: fleetDataValue,// Math.abs(nullBill - IOBill).toFixed(2) ,
            unit: '€',
            carbon: 0,  //evaluates to FALSE
            percentage: Math.abs((nullBill - IOBill) / nullBill * 100 ).toFixed(0),
            direction: Math.sign(((nullBill - IOBill) / nullBill * 100 ).toFixed(2)), //evaluates to FALSE > DOWN
            info: fleetDataTooltip,
        },
        /* {
            name: 'Balancing Income',
            Icon: GraphicWhite,
            value: '0',
            unit: '€',
            carbon: 0,  //evaluates to FALSE
            percentage: '12',
            direction: 0 //evaluates to FALSE > DOWN\
        } */
    ]

    // Options for dropdown
    const dropDownOptions = [
        { value: 'March', label: 'Monthly' },
        { value: '2021', label: 'Annual' }
    ]
    return (
        <div className='green-status'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-md-7 '>
                        <div className='green-status__image'>
                            <img src={HomeHouseNetowrk} alt='green arrow apply filter' />
                        </div>
                    </div>
                    <div className='col-12 col-md-5'>
                        <div className='green-status__dropdown'>
                            <FieldDropDown disabled isGraph={0} showLabel={0} options={dropDownOptions} value={dropDownOptions[0]} placeholder={dropDownOptions[0].label} onClick={() => { }} />
                        </div>
                        <div className='green-status__statistics'>
                            {oneLineStatistics.map((props, index) => <OneLineStatistics key={`one-line-statistics${index}`} {...props} />)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StatusGreen;

