import React from 'react';
import DateView from '@lifepowr/components/src/components-shared/date-component/date-view.component';
import CircularProgressLoader from '@lifepowr/components/src/components-shared/circular-progress-loader-component/circular-progress-loader.component';
import Red from '@lifepowr/components/src/assets/icons/filter-red.png'
import RedArrow from '@lifepowr/components/src/assets/icons/filter-arrow-red.png'
import Green from '@lifepowr/components/src/assets/icons/filter-green.png'
import GreenArrow from '@lifepowr/components/src/assets/icons/filter-arrow-green.png'
import Yellow from '@lifepowr/components/src/assets/icons/filter-yellow.png'
import YellowArrow from '@lifepowr/components/src/assets/icons/filter-arrow-yellow.png'
import './color-filter.styles.scss';
/**
 * @param { handleClick } function to handle the click event
 * @param { greenDevices } number with the total of devices with green statues
 * @param { yellowDevices } number with the total of devices with yellow statues
 * @param { redDevices } number with the total of devices with red statues
 * 
 * @returns A renderization of color filters that execute a specific function upon click
 */
const ColorFilters = (prop) => {
    const { handleClick, filterError } = prop

    const filters = [
        { name: 'total', Arrow: GreenArrow, Icon: Green, color: 'black', label: 'Delivery Points', showDateView: true, queryString: '*' },
        { name: 'attention', Arrow: GreenArrow, Icon: Red, color: 'red', label: 'Alerts', queryString: 'shadow.reported.operational: false AND shadow.reported.batCtrMode: normal AND connectivity.connected: true' },
        { name: 'offline', Arrow: GreenArrow, Icon: Yellow, color: 'grey', label: 'Devices Offline', queryString: 'connectivity.connected: false' },
    ]

    if(filterError) return <div className='color-filters'> {filterError} </div>

    return (
        <div className='color-filters'>
            {filters.map(({ name, color, label, queryString, showDateView, Icon, Arrow }) => (
                <div key={`color-filter__${name}`} className={`color-filters__${color} p-lg-3`} onClick={() => { handleClick(queryString, name) }}>
                    <span className='color-filters__title'>{label}</span>
                    {/*showDateView && <span className='color-filters__date d-none d-lg-block'>{<DateView />}</span>*/}
                    <div><span className='color-filters__icon'><img src={Icon} alt={`filter devices with ${color} status`} /></span></div>
                    <span className='color-filters__total'>{(prop[name] == null && <CircularProgressLoader size='2.5rem' />) || prop[name]}</span>
                    <span className='color-filters__arrow'><img src={Arrow} alt={`${color} arrow apply filter`} /></span>
                </div>
            ))}
        </div>
    );
}

export default ColorFilters;