import React, { useState, useEffect } from 'react';
import useSocket from '@lifepowr/components/src/components/socket/useSocket';
import StatusYellow from "../status-yellow-component/status-yellow.component";
import './status-yellow-group.styles.scss'
import { useParams } from 'react-router';

/** Info for yellow component rendering - should be in the same order as objectWithAllPropertiesValues */
const individualComponentInfo = [
	{ title: 'Consumption', text: 'Power draw by residential loads.', type: 'consumption' },
	{ title: 'Energy Available', text: 'Energy stored across the fleet.', type: 'energy' },
	{ title: 'Solar Production', text: 'Fleet solar panel power output.', type: 'pv' }
]

/**
 * This component is always listening to a socket in order to retrieve values : { consumption, energy, pv }
 * Fleet Data is obtained by useState() to perform calculations
 * 
 * These values are used to render individual yellow components together with individualComponentInfo
 */
const StatusYellowGroup = () => {
	const { fleetOption } = useParams()
	// get state data 
	const [fleetData, setFleetData] = useState({});
	const { Household = {}, Grid = {} } = fleetData;

	// format state data 
	const HInner = (Object.entries(Household)[0] || [])[1] || {};
	const GInner = (Object.entries(Grid)[0] || [])[1] || {};
	const { LoadPowerFiltered = 0, SoC = 0, SoCmax = 0, totalPeakSolarPower = 0, totalPVPowerFiltered = 0 } = HInner;
	const { MeterPower = 0, MTActivepowerR = 0, MTActivepowerS = 0, MTActivepowerT = 0, monthPeak = 0 } = GInner;

	// calculated values 
	const consumption = { max: (monthPeak || 5000), current: Math.round(Math.abs(LoadPowerFiltered) * 100) / 100, unit: 'W' };
	const energy = { max: (SoCmax * 1000) || 5000, current: Math.round((SoC * 1000) * 100) / 100, unit: 'Wh' };
	const pv = { max: (totalPeakSolarPower) || 5000, current: Math.round((totalPVPowerFiltered) * 100) / 100, unit: 'W' };
	// grouping values
	const objectWithAllPropertiesValues = { consumption, energy, pv };

	// SOCKET SUBSCRIPTION to read values
	const { subscribe, unsubscribe } = useSocket();
	useEffect(() => {
		subscribe(`fleet/${fleetOption}/data`, (data) => {
			const { value } = data;

			setFleetData(value);
		});
		return () => { unsubscribe(`fleet/${fleetOption}/data`); }

	}, [fleetOption]);

	return (
		<div className='status-yellow-group row'>
			{!!individualComponentInfo?.length && individualComponentInfo.map((element, index) => (
				<div key={`status_yellow_group__${index}`} className='col-12 col-md-6 col-lg-4'>
					<div className='status_yellow_group__component'>
						<StatusYellow {...element} values={objectWithAllPropertiesValues[element.type]} />
					</div>
				</div>
			))}
		</div>
	)
}

export default StatusYellowGroup;
