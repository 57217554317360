import React, { useEffect, useState } from 'react';
import useSocket from '@lifepowr/components/src/components/socket/useSocket';
import { connect } from 'react-redux';

import GreenStatus from "./components/status-green-component/status-green.component";
import HistoricalPlotBars from "./components/historical-plot-bars-component/historical-plot-bars.component";
import StatusYellowGroup from "./components/status-yellow-group-component/status-yellow-group-component";
import HistoricalPlotFleet from "@lifepowr/components/src/modules/fleet-module/components/historical-plot-fleet-component/historical-plot-fleet.component";

import './fleet-page.styles.scss'
import fleetDesc from '@lifepowr/components/src/utils/graphDescs/fleet'
import * as fleetCreators from "@lifepowr/components/src/store/systems";
import { useParams } from 'react-router';

const { default: df, ...fleetActionCreators } = fleetCreators;

/** Labels/Title used to filter the data from AWS and plot each chart on this page*/
const chartProps = Object.entries(fleetDesc).map(([chartFlag, obj]) => {
    const optionList = obj.layouts;
    const dropDownOptions = Object.values(optionList).map(option => ({ value: option.name, label: option.name, innerTitle: option.title }));
    return { title: `${chartFlag} Data`, chartFlag, dropDownOptions };
});

/**
 * Fleet Page component renders several fleet related components. 
 * It's loaded on the "/" route
 * @returns The component which agregates Fleet selection / Green Status / Historical Plots / YEllow Group and PLOTS 
 */
const FleetPage = ({ setFleetRt = () => undefined }) => {
    const [socketData, setSocketData] = useState(() => { return {} });
    const { subscribe, unsubscribe } = useSocket();
    const { fleetOption } = useParams();

    // SOCKET SUBSCRIPTION to get updated values on realTime
    useEffect(() => {
        if (fleetOption?.length) {
            subscribe(`fleet/${fleetOption}/data`, (data) => {
                const { value } = data;
                if(value){
                    setSocketData(value);
                    setFleetRt(value);
                }
            })
            return () => { unsubscribe(`fleet/${fleetOption}/data`) }
        }
    }, []);

    return <>
        <div className='fleet-page'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 fleet-page__components-wrapper'>
                        <div className='fleet-page__components-wrapper__green-component mb-3 mb-lg-0'><GreenStatus /></div>
                        <div className='fleet-page__components-wrapper__plot-bars'>
                            <HistoricalPlotBars
                                title='Fleet x Grid interaction'
                                textFirst='The Grid power bar indicates the total power being injected or drawn from the grid by our fleet at this very moment.'
                                textSecond='The Activated power bar indicates the amount of power which is currently activated to balance the EU grid, as a response to the frequency deviation'
                            />
                        </div>
                        <div className='fleet-page__components-wrapper__yellow-component-wrapper container'>
                            <div className='row'>
                                <div className='col-12'>
                                    <StatusYellowGroup />
                                </div>
                            </div>
                        </div>
                        {!!chartProps?.length && chartProps.map((props, index) => (
                            <div key={`component-wrapper__${index}`} className='fleet-page__components-wrapper__plot-lines'>
                                <HistoricalPlotFleet {...props} key={`historicalPlotFleet__${index}`} socketData={socketData} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default connect(null, { ...fleetActionCreators })(FleetPage);
