import { Link, Typography } from "@mui/material";
import { generateBatterySchema } from "../../device-details-component/device-details-utils";
import { BRAND_MODEL_UI_SCHEMA } from "../shared";
import BatteryPanelsStep from "../components/battery-step.component";

export default {
  title: "Battery",
  description:
    "Select and configure the battery system, for energy storage purposes.",
  helperText: {
    header: (
      <>
        <Typography variant="h6">Select the correct battery.</Typography>
        <Typography variant="body" fontWeight={500}>
          ℹ️ Mind: Only FlexiO compatible batteries can be selected.
        </Typography>
      </>
    )
  },
  component: BatteryPanelsStep,
  schemaFunction: ({ settings, schemas }) => {
    const { batteryBrandModelSchema, batteryDetailsSchema } =
      generateBatterySchema(settings, schemas);

    return {
      batteryBrandModel: {
        $id: "batteryBrandModel",
        properties: {
          batteryBrandModel: batteryBrandModelSchema,
        },
      },
      battery: {
        $id: "battery",
        properties: {
          battery: batteryDetailsSchema
            ? {
                ...batteryDetailsSchema[1],
              }
            : null,
        },
      },
    };
  },
  uiSchema: {
    batteryBrandModel: { batteryBrandModel: BRAND_MODEL_UI_SCHEMA },
    battery: {
      "ui:options": {
        title: "",
        description: "",
      },
      battery: {
        "ui:options": {
          title: "",
          description: "",
        },
        ...Object.fromEntries(
          [
            "batteryType",
            "cellMinVoltage",
            "cellRatedVoltage",
            "cellMaxVoltage",
            "cellMaxCurrent",
            "balancingCurrent",
          ].map((key) => [key, { "ui:widget": "hidden" }])
        ),
      },
    },
  },
};
