import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Modal from '@mui/material/Modal';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import InputLabel from '@mui/material/InputLabel';
import { DataGrid, GRID_STRING_COL_DEF } from '@mui/x-data-grid';

import { API } from "Amplify.js";

function UserModal(props){
	const {open, onClose, refresh, roles, edit, remove} = props;
	const [user, setUser] = useState({
		email: "",
		role: "Owner",
		firstName: "",
		lastName: "",
		phone: "",
		company: "",
		language: "",
	});
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(null);

	React.useEffect(
		() => {
			if (edit) {
				setUser(edit);
			}
		},
		[edit],
	);

	const {firstName, lastName, role, email, phone, language, company} = user;
	
	let errorMsg;

	const create = () => {
		setLoading(true);
		setError(null);
		API.post("IO_API", `/user`, { headers: {"Content-Type": "application/json"}, body: user })
			.then( res => {
				setLoading(false);
				onClose();
				refresh();
			})
			.catch(err => {
				setLoading(false);
				setError(err.response);
			});
	};

	const submit = async () => {
		let func = 'post';
		let body = user;
		if (edit) {
			func = 'put';
			body = {
				email: user.email,
				role: user.role,
				firstName: user.firstName,
				lastName: user.lastName,
				phone: user.phone,
				company: user.company,
				language: user.language,
				userId: user.id,
			};
		} else if (remove) {
			func = 'del';
			body = { userId: remove.id };
		}

		setLoading(true);
		setError(null);

		try {
			await API[func](
				"IO_API",
				`/user`,
				{ headers: {"Content-Type": "application/json"},
				body
			});
			onClose();
			refresh();
		} catch (e) {
			setError(e.response);
		}
		setLoading(false);
	}

	const onChange = (e) => {
		const {target: {name, value} = {}} = e;

		if(value || value === "") setUser(old => ({...old, [name]: value}));
	};

	let title = "Add User";
	let content = (
		<React.Fragment>
			<TextField name="firstName" onChange={onChange} value={firstName} label="First Name"/>
			<TextField name="lastName" onChange={onChange} value={lastName} label="Last Name"/>
			<TextField name="email" onChange={onChange} value={email} label="Email"/>
			<TextField name="company" onChange={onChange} value={company} label="Company"/>
			<TextField name="phone" onChange={onChange} value={phone} label="Phone"/>
			<FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
				<InputLabel>Language</InputLabel>
				<Select key="language" name="language" label="Language" onChange={onChange} value={language}>
					<MenuItem value='' disabled>Choose one</MenuItem>
					<MenuItem value='En'>En</MenuItem>
					<MenuItem value='Du'>Du</MenuItem>
				</Select>
			</FormControl>
			{errorMsg ? 
				<Alert severity="error">
					{errorMsg}
				</Alert>
				: null
			}
		</React.Fragment>
	);
	let disabled = Object.keys(user).length < 1 || Object.entries(user).some(([k,v]) => !Boolean(v)) || loading;
	if (edit) {
		title = "Edit User";
	}
	if (remove) {
		title = "Remove User";
		content = (
			<DialogContentText>
				Are you sure you want to remove this user?
			</DialogContentText>
		);
		disabled = false;
	}


	if(error) errorMsg = `${error.data}`;

	return (
		<Dialog
			open={open}
			onClose={onClose}
		>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>
				{ content }
			</DialogContent>
			<DialogActions>
				<Button disabled={disabled} style={{float:"right"}} onClick={submit}>
					{loading ?
						<CircularProgress size={20}/> :
						'Submit'
					}
				</Button>
			</DialogActions>
		</Dialog>
	); 
}

function Users(props){
	const {admin, users, refresh, setError, loading, findObj, fleets} = props;
	const [add, setAdd] = useState(false);
	const [edit, setEdit] = useState(false);
	const [remove, setRemove] = useState(false);
	const roles = ["Manager", "Owner"];

	const openModal = add || Boolean(edit) || Boolean(remove);

	if(loading) return <div>Loading . . .</div>;
	if(!users) return <div>No Users found</div>;

	function InteractUser(props){
		const {row: user} = props;
		return (
			<React.Fragment>
				<IconButton aria-label="expand row" size="small" onClick={()=>{setAdd(false); setEdit(user); setRemove(null)}}>
					<EditIcon />
				</IconButton>
				<IconButton aria-label="expand row" size="small" onClick={()=>{setAdd(false); setEdit(null); setRemove(user)}}>
					<DeleteForeverIcon />
				</IconButton>
			</React.Fragment>
		);
	}

	const columns = [
		{ field: 'email', headerName: 'Email', flex: 2 },
		{ field: 'firstName', headerName: 'First Name', flex: 1 },
		{ field: 'lastName', headerName: 'Last Name', flex: 1 },
		{ field: 'company', headerName: 'Company', flex: 1 },
		{ field: 'phone', headerName: 'Phone', flex: 1 },
		{ field: 'language', headerName: 'Language', flex: 1 },
		//{ field: 'role', headerName: 'Role', flex: 1 },
		{ field: 'createDate', headerName: 'Created', flex: 1 },
		//{ field: 'modifyDate', headerName: 'Modified', flex: 1 },
		{ field: 'status', headerName: 'Status', flex: 1 },
		{ 
			...GRID_STRING_COL_DEF,
			field: 'actions',
			headerName: 'Actions',
			flex: 1,
			type: 'custom',
			resizable: false,
			filterable: false,
			sortable: false,
			editable: false,
			groupable: false,
			display: 'flex',
			renderCell: (params) => <InteractUser {...params} />,
		},
		{
			...GRID_STRING_COL_DEF,
			field: 'fleets',
			headerName: 'Fleets',
			flex: 3,
			type: 'custom',
			resizable: false,
			filterable: false,
			sortable: false,
			editable: false,
			groupable: false,
			display: 'flex',
			renderCell: (params) => {
				const {fleets} = params.row;
				return (
					<React.Fragment>
						{fleets.map((fleet, i) => {
							const {name, thingGroupName, parentGroupNames = []} = fleet;
							const parent = parentGroupNames.at(-1);
							const fleetName = parent ? thingGroupName.split(`${parent}_`).pop() : thingGroupName
							return (
								<Chip key={fleetName} label={fleetName} onClick={() => findObj(0, thingGroupName)} clickable variant="outlined" />
							);
						})}
					</React.Fragment>
				);
			},
		},
	];

	const rows = users.map(
		(user) => {
			const {userId, name = "", firstName, lastName, phone, company, language, role, email, metadata: {UserCreateDate, UserLastModifiedDate, UserStatus}} = user || {};
			const row = {
				fleets: fleets.filter(f=>f.attributes?.userId === user.userId),
				role,
				email,
				firstName,
				lastName,
				company,
				language,
				phone,
				createDate: UserCreateDate,
				modifyDate: UserLastModifiedDate,
				status: UserStatus,
				actions: ()=> <InteractUser user={user}/>,
				id: userId,
			};
			return row;
		},
	);

	return (
		<React.Fragment>
			<DataGrid
				rows={rows}
				columns={columns}
				pageSizeOptions={[5]}
				checkboxSelection
				disableSelectionOnClick
				components={
					{
						Toolbar: ()=>{
							return (
								<Button onClick={() => {setAdd(true); setEdit(null); setRemove(null);}}>
									Add User
								</Button>
							);
						}
					}
				}
			>
			</DataGrid>
			<UserModal roles={roles} edit={edit} remove={remove} refresh={refresh} open={openModal} onClose={() => {setAdd(false); setEdit(null); setRemove(null);}}/>
		</React.Fragment>
	);
}

export default Users
