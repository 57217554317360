import React from "react";
import { useLocation } from 'react-router-dom';

import { DevicePage, DevicesPage, NotFoundPage } from "@lifepowr/components/src/modules";
import { Header, NavBar, Footer } from "@lifepowr/components/src/components-core";

import PolicyAuth from "@lifepowr/components/src/components/PolicyAuth";

import { legacy } from "@lifepowr/components";

const { Bones } = legacy;


function LandingPage() {
	const base = {
		Header,
		Navigation,
		Footer,
	};
	const { search } = useLocation();

	const routes = {
		'/devices': {
			components: {
				...base,
				Content: DevicesPage,
			},
			exact: true,
			strict: true,
		},
		'/devices/:deviceName/:pathName': {
			components: {
				...base,
				Content: DevicePage,
			},
		},
		'/notfound': {
			components: {
				...base,
				Content: NotFoundPage
			}
		}
	};

	const redirects = [
		{
			from: '/',
			to: `/devices${search}`,
			exact: true,
		},
		{
			from: '/devices/:deviceName',
			to: `/devices/:deviceName/general-status${search}`,
			exact: true,
		},
		{
			to: `/notfound`,
			exact: true
		},
	];

	const bonesProps = {
		routes,
		redirects,
		headerProps: {
			userMode: true,
		},
		contentProps: {
			userMode: true,
		},
		navProps: {
			userMode: true,
		},
		footerProps: {
			userMode: true,
		}
	};

	return (
		<PolicyAuth>
			<Bones {...bonesProps}/>
		</PolicyAuth>
	);
}

function Navigation(props){
	return (
		<NavBar {...props}/>
	);
}

export default LandingPage;

