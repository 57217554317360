import React from 'react';
import './field-drop-down.styles.scss';

import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css'

/**
 * 
 * @param { isGraph } boolean indicates whether this dropdown is displayed on a graph 
 * @param { showLabel } boolean indicated whether to show the inner label for the input
 * @param { options } object with options to pass inside the drop down
 * @param { value } number indicating the selected option 
 * @param { placeholder } string with the text for this input 
 * @param { onClick } function executed on click
 * @param { onOptionChange } function executed on change
 * @returns A single dropdown already styled for coherence along the webpage. 
 */
const FieldDropDown = ({ isGraph, showLabel, options, value, placeholder, onClick, onOptionChange, disabled }) => (
    <div key="FieldDropDown__key" className={`my-drop-down ${isGraph ? "graph" : ""} ${showLabel ? "" : "no-label"}`} onClick={onClick} >
        <Dropdown disabled={disabled} ey={`${options}`} options={options} value={value || null} placeholder={' '} onChange={onOptionChange} />
        { !value ? <div className="FieldDropDown__placeholder">{placeholder}</div> : null }
    </div>
);

export default FieldDropDown;