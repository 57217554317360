import React, { useEffect, useState } from 'react';
import { API } from 'Amplify';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import InfiniteScroll from 'react-infinite-scroller';
import { Grid } from '@mui/material';

import CircularProgressLoader from '@lifepowr/components/src/components-shared/circular-progress-loader-component/circular-progress-loader.component';
import ColorFilters from "./components/color-filter-component/color-filter.component";
import SingleDevice from "./components/single-device-component/single-device.component";

import { EmsMode } from "@lifepowr/components/src/utils/emsModes";

import { DataGrid, useGridApiContext, GridToolbarContainer } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';
import Alert from '@mui/material/Alert';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import * as systemCreators from "./../../store/system";
import useSocket from '@lifepowr/components/src/components/socket/useSocket';
import './devices-page.styles.scss';
import LoadingOrManualFetch from './components/loading-or-manual-fetch-component/loading-or-manual-fetch.component';
import { useParams } from 'react-router';
import { Link, useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import { AccountStatus, generatedAccountStatus } from "@lifepowr/components/src/utils/account-status";
import useMediaQuery from "@lifepowr/components/src/utils/use-media-query";
import { FilterSection, FiltersContainer, FiltersMainContainer, COMMON_KEY, FILTER_DELIMITER } from './components/devices-filters/devices-filters.component';

const { default: System, ...systemActionCreators } = systemCreators;
/**
 * NOTE: Sorting should be done on the backend to reduce front end rendering times.
 * Sort function by a user-defined key
 * @param  {string} key  The object's key to sort by
 * @return {function}    The sort function
 */
const sortByKey = (key) => {
    return (a, b) => {
        let aKey, bKey;

        if (key === 'thingName') {
            ({ [key]: aKey } = a);
            ({ [key]: bKey } = b);
        }
        else {
            ({ [key]: aKey } = (a.attributes || {}));
            ({ [key]: bKey } = (b.attributes || {}));
        }

        if (aKey < bKey) return -1;
        if (aKey > bKey) return 1;
        return 0;
    };
}

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

const ActionDescriber = (props) => {
    const { execution, id, complete, resolve } = props;
    const [state, setState] = useState('Waiting');
    const [error, setError] = useState('');
    const completeStr = complete !== undefined ? 'Complete' : 'Error';

    useEffect(
        () => {
            if (execution) {
                setState('In Progress');
                execution
                    .then((val) => {
                        setState('Complete');
                        resolve(true);
                    })
                    .catch((e) => {
                        const {
                            response: { data } = {},
                        } = e || {};
                        setState('Error');
                        setError(`${data || e}`);
                        resolve(false);
                    });

            }
            else if(complete) setState(completeStr);
        },
        [execution]
    );

    let alertAction = (
        <div>
            Undefined state
        </div>
    );
    let severity = 'warning'

    if (state === 'Error') {
        severity = 'error';
        alertAction = (
            <Tooltip title={error}>
                <div>
                    {'Error'}
                </div>
            </Tooltip>
        );
    }
    else if (state === 'Complete') {
        severity = 'success';
        alertAction = null
    }
    else if (state === 'In Progress' || state === 'Waiting') {
        severity = 'info';
        alertAction = (
            <div>
                {state}
            </div>
        );
    }

    return (
        <Alert severity={severity} action={alertAction}>
            {id}
        </Alert>
    );
}

const DataGridToolbar = (props) => {
    const { fleetOption } = useParams();
    const history = useHistory();
    const apiRef = useGridApiContext();
    const onClick = () => console.log(apiRef.current.getSelectedRows());
    const [show, setShow] = useState(false);
    const [open, setOpen] = useState(false);
    const [actions, setActions] = useState([]);
    const [fleets, setFleets] = useState([]);
    const [selectedFleet, setSelectedFleet] = useState('');
    const [removeFleets, setRemoveFleets] = useState(false);

    const buttonActions = {
        fleet: {
            label: 'Fleet Change',
            action: async (obj) => {
                const { fleets = [], id } = obj;
                const fleetsRemove = removeFleets ? fleets.filter((fleet) => fleet.startsWith(fleetOption)) : [];
                const body = { // Maybe consider multiple fleets to be used here
                    fleetsAdd: [selectedFleet],
                    fleetsRemove,
                }
                console.log(obj, body);
                await API.post("IO_API", `/devices/${id}/fleets`, { body });
            },
        }
    };
    const { [open]: {label} = {} } = buttonActions;

    const dialogProps = {
        open: Boolean(open),
        fullWidth: true,
    };

    const handleClose = () => {
        setOpen(false);
        window.location.reload();
    }

    const startActions = () => {
        const {[open]: {action} = {}} = buttonActions;
        const rows = apiRef.current.getSelectedRows();
        const acts = [];

        setActions(
            old => old.map(
                ([id, obj]) => ([id, { ...obj, execution: action(obj)}])
            )
        );
    }

    useEffect(
        () => {
            API.get("IO_API", `/fleet/all`, { headers: {"Content-Type": "application/json"} })
                .then(r => setFleets(r))
                .catch((e) => console.log('COULD NOT FETCH FLEETS', e));
        },
        []
    );

    useEffect(
        () => {
            const rows = apiRef.current.getSelectedRows();
            const acts = [];
            if (open) for (const row of rows.entries()){
                acts.push(row);
            }
            setActions(acts);
        },
        [open]
    )

    useEffect(
        () => {
            const selectHandler = () => {
                const rows = apiRef.current.getSelectedRows();
                setShow(rows.size > 0);
            }

            return apiRef.current.subscribeEvent('selectionChange', selectHandler);
        },
        [apiRef],
    );

    const buttons = Object.entries(buttonActions).map(
        ([key, obj]) => {
            return <Button key={key} onClick={() => setOpen(key)}>{obj.label}</Button>
        }
    );

    const goToFleet = () => {
        if (selectedFleet) {
            setOpen(false);
            history.push(`../../${selectedFleet}/devices`);
        }
    }

    const progressNumbers = actions.reduce((acc,[id, {complete, execution}]) => complete === undefined && execution ? acc + 1 : acc, 0);
    const failNumbers = actions.reduce((acc,[id, {complete, execution}]) => complete === false ? acc + 1 : acc, 0);
    const dialogComplete = actions.every(([id, {complete, execution}]) => complete !== undefined);
    const fleetSelectShow = open === 'fleet';
    const goDisabled = (open === 'fleet' && !selectedFleet) // Add || here for more

    let dialogButtons = (
        <>
            <Button disabled={goDisabled} onClick={startActions}>Go</Button>
            <Button onClick={handleClose}>Cancel</Button>
        </>
    );
    if(progressNumbers > 0) dialogButtons = (
        <>{progressNumbers} actions in Progress ({failNumbers} failed)</>
    );
    if(dialogComplete) dialogButtons = (
        <>
            (Complete - {failNumbers} failures)
            <Button onClick={handleClose}>Leave</Button>
            {progressNumbers === 0 && open === 'fleet' ? <Button onClick={goToFleet}>Go to fleet</Button> : null }

        </>
    );

    /* const fleetSelector = (
        <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Select Fleet</InputLabel>
            <Select
                disabled={progressNumbers > 0}
                value={selectedFleet}
                onChange={(ev) => setSelectedFleet(ev.target.value)}
            >
                { fleets.filter(({thingGroupName}) => thingGroupName !== fleetOption).map(
                    ({thingGroupName}) => <MenuItem key={thingGroupName} value={thingGroupName}>{thingGroupName}</MenuItem>
                ) }
            </Select>
        </FormControl>
    ); */

    const fleetSelector = (
        <Autocomplete
          disablePortal
          className="fleetChangerSelector"
          value={selectedFleet || null}
          isOptionEqualToValue={
            (o, v)=>o.label===v
          }
          onChange={(ev, value) => setSelectedFleet((value || {}).label)}
          options={
            fleets.filter(
                ({thingGroupName, thingGroupId}) => thingGroupName !== fleetOption).map(
                    ({thingGroupName, thingGroupId})=>({label: thingGroupName, key: thingGroupId}),
                )
          }
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.key}>
                {option.label}
              </li>
            );
          }}
          renderInput={(params) => <TextField {...params} label="Select fleet" variant="outlined" />}
        />
    );

    const actionsList = actions.map( action => {
        const [id, object] = action;
        const actionProps = {
            ...object,
            resolve: (state) => {
                setActions(
                    old => old.map(
                        ([oldId, oldObj]) => oldId === id ? [id, {...oldObj, complete: state}] : [oldId, oldObj]
                    )
                );
            }
        }
        return <ActionDescriber {...actionProps}/>
    })

    return show ? (
        <GridToolbarContainer>
            <Dialog {...dialogProps}>
                <DialogTitle>
                    {label}
                </DialogTitle>
                <DialogActions>
                    <div className="fleetChangerSelectorDialog">
                        {fleetSelectShow && fleetSelector}
                        <FormControlLabel className="fleetChangerCheckbox" control={<Checkbox onClick={(ev)=>setRemoveFleets(ev.target.checked)} checked={removeFleets}/>} label="Remove from current fleet" />
                    </div>
                </DialogActions>
                <DialogContent>
                    <div className='actionsList'>{actionsList}</div>
                </DialogContent>
                <DialogActions>
                    {dialogButtons}
                </DialogActions>
            </Dialog>
            <Stack className='device-list-actions' direction="row" justifyContent="flex-end">
                {buttons}
            </Stack>
        </GridToolbarContainer>
    ) : null;
}



// TODO: Remove Mock data once feature is fully deployed
// const EMS_AGGREGATION_MOCK_DATA = [
//     { keyValue: "normal", count: 1910 },
//     { keyValue: "safe", count: 741 },
//     { keyValue: "uninitialized", count: 227 },
//     { keyValue: "noEMS", count: 141 },
//     { keyValue: "inactive", count: 36 },
//     { keyValue: "dumbBattery", count: 24 },
//     { keyValue: "balance", count: 2 },
//   ].reduce((acc, { keyValue, count }) => {
//     acc[keyValue] = count;
//     return acc;
//   }, {});
    
//   const FAILED_DIAGNOSTICS_AGGREGATION_MOCK_DATA = [
//     { keyValue: "ems", count: 345 },
//     { keyValue: "connected", count: 20 },
//     { keyValue: "ev", count: 880 },
//     { keyValue: "evDiscovery", count: 40 },
//     { keyValue: "iomanager", count: 34 },
//     { keyValue: "modbus", count: 87 },
//     { keyValue: "linked", count: 44},
//     { keyValue: "p1", count: 908 },
//     { keyValue: "eastron", count: 678 },
//     { keyValue: "board", count: 47 },
//     { keyValue: "configured", count: 10 },
//     { keyValue: "ioapi", count: 3 },
//     { keyValue: "iodaemon", count: 1 },
//     { keyValue: "update", count: 6 },
//   ].reduce((acc, { keyValue, count }) => {
//     acc[keyValue] = count;
//     return acc;
//   }, {});

const MAX_FILTERED_ALLOWED = 10
const MAX_ITEMS_REQUESTED = 100
const INITIAL_REQUEST_SEARCH = { maxResults: MAX_ITEMS_REQUESTED, inputStr: '*', queryString: '*', page: 0 };
/**
 * @returns
 * This component renders a list of the available devices, available filters and keys to sort the devices
 * Search performs a new service call everytime onChange is triggered.
 */
const DevicesPage = ({ setSystem, setSystemRtData, setSystemShadow, userMode, admin }) => {
    const { fleetOption } = useParams();
    let { path, url } = useRouteMatch();
    const fixedUrl = url[url.length -1] === '/' ? url.slice(0, -1) : url;
    const { admin: adminRole } = admin || {};
    //const match = useRouteMatch();
    //keeps devices
    const [systemArray, setsystemArray] = useState(() => []);

    //for request
    const [requestSearch, setRequestSearch] = useState(() => INITIAL_REQUEST_SEARCH);
    const [pageRows, setPageRows] = useState({});
    const [pageTokens, setPageTokens] = useState({0: null});
    const [isLoading, setIsLoading] = useState(() => false);
    const [loading, setLoading] = useState(() => true);

    //for pagination
    const [first, setFirst] = useState(() => true);
    const [total, setTotal] = useState(0);
    const [lastIndex, setLastIndex] = useState(() => undefined);

    //for devices filter
    const { subscribe, unsubscribe } = useSocket();
    const [filterValues, setFilterValues] = useState(() => null);
    const [filterError, setFilterError] = useState( () => null);
    const [filterValue, setFilterValue] = useState('');
    const [checkedMap, setCheckedMap] = useState(new Set())
    const { search } = useLocation();
    const mediaQuery = useMediaQuery()
    const [selectedFilterTab, setSelectedFilterTab] = useState(COMMON_KEY);

    // SORTING
    const [sortKey, setSortKey] = useState(() => 'thingName');

    useEffect(() => {
        /*if (fleetOption?.length) {
            subscribe(`fleet/${fleetOption}/data`, (data) => {
                const { value: { deviceStatus } } = data
                setFilterValues(deviceStatus);
            });
            return () => { unsubscribe(`fleet/${fleetOption}/data`) }
        }*/
        setsystemArray([]);
        setPageRows({});
        const { inputStr } = requestSearch;
        if (!userMode && fleetOption?.length) {
            setLoading(true);
            API.get("IO_API", `/fleet/aggregate`, { headers: {"Content-Type": "application/json"}, queryStringParameters: { thingGroupName: fleetOption, queryString: inputStr }})
                .then((res) => {
                    setFilterValues(res);
                    // TODO: Remove Mock data once feature is fully deployed
                    // setFilterValues({...res, ems: EMS_AGGREGATION_MOCK_DATA, failedDiagnostics: FAILED_DIAGNOSTICS_AGGREGATION_MOCK_DATA});

                    setFilterError(null);
                    setLoading(false);
                })
                .catch( err => {
                    const { response: { data : { message } = {} } = {} } = err;

                    setFilterError(message);
                    setLoading(false);
                });
        }
        if (userMode) {
            setLoading(false);
        }
    }, [fleetOption, requestSearch]);

    //Perform request to obtain devices when queryString is changed
    useEffect(() => {
        const { page, inputStr, name, ...restRequestSearch } = requestSearch;
        if (API) {
            let queryString;
            if (userMode) queryString = `${requestSearch.inputStr} AND ${requestSearch.queryString}`;
            else queryString = `thingGroupNames:${fleetOption} AND ${requestSearch.inputStr} AND ${requestSearch.queryString}`;

            setIsLoading(true);
            if(page in pageRows){
                setsystemArray(old => (
                    pageRows[page]
                ));
                setIsLoading(false);
            } else {
                API.get("IO_API", `/devices`, { queryStringParameters: { ...restRequestSearch, queryString } })
                    .then(({ things, nextToken, total }) => {
                        if (things){
                            setsystemArray((old) => (
                                things.map(thing => {
                                    if(!('attributes' in thing)) return {...thing, attributes: {}};
                                    return thing;
                                })
                            ));
                            setPageRows( old => {
                                delete old[(page - 3)];
                                delete old[(page + 3)];
                                return  {...old, [page]: things.filter(thing => ('attributes' in thing))}
                            });
                        }
                        setFirst(false);
                        setTotal(total)
                        setPageTokens( old => (
                            {...old, [(page + 1)]: nextToken}
                        ));
                        setLastIndex(nextToken);
                    })
                    .catch(err => console.error('Error getting devices', err))
                    .finally(() => setIsLoading(false));
            }
        }
    }, [requestSearch]);

    const resetFiltersValue = () => {
        setPageRows({});
        setsystemArray([])
        setFirst(true);
        setLastIndex(null);
    }
    const { page } = requestSearch;

    const sortedDeviceArray = [...systemArray].sort(sortByKey(sortKey || "thingName"));

    // Filters
    const devicesAttributesFiltered = sortedDeviceArray.reduce((acc, system) => new Set([...acc, ...Object.keys(system.attributes || {})]), (new Set(['thingName'])));
    var dropDownFilters = [];
    devicesAttributesFiltered.forEach(element => { dropDownFilters.push({ value: element, label: element }) });

    //update search filter request on CHANGE event and perform a new request.
    const updateRequestFilterOnChange = debounce((val) => {
        const newRequestSearch = { ...requestSearch, inputStr: val.length ? val : '*', page: 0 };
        delete newRequestSearch.nextToken;
        resetFiltersValue();
        setRequestSearch(newRequestSearch);
    }, 400);

    const hasMore = (!!lastIndex || !!first);

    //Load more function to pass to infinite scroll
    const loadMore = () => {
        if (!isLoading && hasMore) {
            setRequestSearch((old) => { return { ...old, nextToken: lastIndex } });
        }
    };
    const onPageChange = (newPage) => {
        const {[newPage]: pageToken} = pageTokens;
        const newToken = pageToken !== undefined ? pageToken : lastIndex;
        const newRequestSearch = { ...requestSearch, nextToken: newToken, page: newPage };

        if(newToken === null){
            delete newRequestSearch.nextToken;
        }
        setsystemArray([]);
        console.log("SETTNING", JSON.stringify(newRequestSearch), pageTokens[newPage], newPage, pageTokens);
        setRequestSearch(newRequestSearch);
    }

    const handleClick = (queryString) => handleSubmitFilterValue(`${COMMON_KEY}${FILTER_DELIMITER}${queryString}`, true)

    const calculateTotalSelected = () => {
      return Array.from(checkedMap).filter(key => !key.includes(`${COMMON_KEY}${FILTER_DELIMITER}`)).length;
    }

    // API is restricted to 12 queries max.
    const shouldDisableCheckboxList = () => calculateTotalSelected() >= MAX_FILTERED_ALLOWED

    // Toggles the values inside checkedMap based on their checked status
    const handleSubmitFilterValue = (groupNameValueKey, selected) => {
      // If the filter is of type common, then submit the value and reset the map
      const [groupName] = groupNameValueKey.split(FILTER_DELIMITER);
      if (groupName === COMMON_KEY) {
        setCheckedMap(new Set().add(groupNameValueKey));
        return;
      }

      // Handle other filter types
      setCheckedMap((map) => {
        // Clear the map from any `common` filter value
        map.forEach((value) => {
          const [groupNameLookup] = value.split(FILTER_DELIMITER);
          if (groupNameLookup === COMMON_KEY) map.delete(value);
        });
        // Toggle the value based on the selected value
        selected ? map.add(groupNameValueKey) : map.delete(groupNameValueKey);
        return new Set(map);
      });
    };

    // Once checkedMap, we set the filterQuery based on the values stored inside checkedMap
    useEffect(() => {
      const mapping = {
        ems: (valueKey) => `shadow.reported.batCtrMode: ${valueKey}*`,
        failedDiagnostics: (valueKey) =>
          `(shadow.name.diagnostics.reported.${valueKey}.status: false )`,
      };
      // Construct the queryString from the selected checked values
      const queryBits = Array.from(checkedMap).reduce(
        (acc, groupNameValueKey) => {
            const [groupName, valueKey] = groupNameValueKey.split(FILTER_DELIMITER);
            if (groupName === COMMON_KEY) return valueKey;
            
            const mappingMethod = mapping[groupName];
            const queryString = mappingMethod(valueKey);

            if (groupName in acc) acc[groupName].push(queryString);
            else acc[groupName] = [queryString];
            return acc;
        },
        {}
      );
      let queryString
      if ((typeof queryBits) === 'string') queryString = queryBits;
      else queryString = Object.entries(queryBits).map(
        ([groupName, clause]) => `(${clause.join(' OR ')})`,
      ).join(' AND ');
      /* const queryString = Array.from(checkedMap)
        .map((groupNameValueKey) => {
          const [groupName, valueKey] =
            groupNameValueKey.split(FILTER_DELIMITER);

          if (groupName === COMMON_KEY) return valueKey;

          const mappingMethod = mapping[groupName];

          return mappingMethod(valueKey);
        })
        .join(" OR "); */
      console.log("HERE", checkedMap, queryBits, queryString);

      submitRequestSearch({
        // If nothing is checked we default "*" to load everything
        queryString: queryString ? `(${queryString})` : "*",
      });
    }, [checkedMap]);

    const submitRequestSearch = ({ queryString }) => {
        console.log('WHAT?!', queryString);
        const newRequestSearch = { ...requestSearch, queryString, page: 0 };
        delete newRequestSearch.nextToken;
        resetFiltersValue();
        setRequestSearch(newRequestSearch);
    }

    // const emsModesByStringValue = emsModes.reduce((map, emsMode) => (map[emsMode.stringValue] = emsMode, map), {});

    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            minWidth: 80,
            flex: 1,
            renderCell: params => {
                const { value = 'Unnamed', id } = params;
                const name = value.split("_").join(" ")
                return (
                    <Link className='single-device-link' to={`${fixedUrl}/${id}${search}`}>
                        <Button className='link-button'>{name}</Button>
                    </Link>
                );
            }
        },
        {
            field: 'thingName',
            headerName: 'ID',
            minWidth: 50,
            flex: 0.66
        },
        {
            field: 'connected',
            headerName: 'Online',
            type: 'boolean',
            minWidth: 100,
            flex: 0.3
        },
        {
            field: 'operational',
            headerName: 'Operational',
            type: 'boolean',
            minWidth: 130,
            flex: 0.5
        },
        {
            field: "accountStatus",
            headerName: "Account",
            minWidth: 70,
            flex: 0.3,        
            renderCell: ({ value, id }) => (
                <Link to={`${fixedUrl}/${id}/device-details${search}`}>
                    <Grid container justifyContent="center">
                        <AccountStatus status={value} />
                    </Grid>
                </Link>                
            ),
        },        
        {
            field: 'batCtrMode',
            headerName: 'Control Mode',
            minWidth: 130,
            flex: 0.5,
            renderCell: params => {
                const { value } = params;
                return <EmsMode mode={value}/> // emsModesByStringValue[value || ""].labelWithDescription();
            },
        },
        {
            field: 'reference',
            headerName: 'Reference',
            minWidth: 50,
            flex: 0.5,
        },
        {
            field: 'fleet',
            headerName: 'Fleet',
            minWidth: 130,
            flex: 0.5,
            renderCell: params => {
                const { value = 'Unnamed', row: { fleetName = '', fleets = [] } = {} } = params;
                const name = value.split("_").join(" ");

                const buttons = fleets.length > 0 ?
                    fleets.filter(n => n !== fleetOption).map(
                        n => <Link key={n} className='single-device-link' to={`../../${n}/devices${search}`}> <Chip onClick={() => {}} label={n} /></Link>
                    ) : 'N/A';

                return fleets ? (
                    <Stack direction="row" spacing={1}>
                        {buttons}
                    </Stack>
                ) : 'N/A';
            }
        }
    ];

    const rows = sortedDeviceArray.map((device, index) => {
        const { thingName, attributes = {}, shadow, thingGroupNames = [] } = device;
        const { name: namedShadows = {}, reported: { hybrid, retrofit, batCtrMode, connected, operational, configuration, diagnostics = {} } = {} } = shadow || {};
        const { batControlMode = {} } = namedShadows;
        const { reported: { mode: modeShadow = {} } = {} } = batControlMode || {};
        const { reference = 'N/A' } = configuration || {};
        const { name, account, email } = attributes;
        const accountStatus = generatedAccountStatus(account, email);
        const localThingGroup = [...thingGroupNames];
        const rawFleet = localThingGroup.pop();
        const parentFleet = localThingGroup.pop();

        // if(rawFleet === fleetOption)
        const fleet = rawFleet.replace(`${parentFleet}_`, '');

        /* const operational = Object.entries(diagnostics).reduce( (acc, [diagnostic, value]) => {

            if(diagnostic !== 'linked')acc = ((typeof value) === 'object') ? acc && value.status : acc;

            return acc;
        }, true); */

        return { name, accountStatus, id: thingName, thingName, hybrid, retrofit, batCtrMode: modeShadow, connected, operational, fleet, fleetName: rawFleet, fleets: thingGroupNames, reference };
    });

    /** PAGINATION PROPS  */
    const scrollProps = {
        className: "row devices-page__filtersNdevices__device-wrapper",
        pageStart: 0,
        hasMore,
        loadMore,
        initialLoad: false,
        loader: <LoadingOrManualFetch {...{ loadMore, isLoading, key: 0 }} />,
        useWindow: false
    };

    let containerClassName = 'col-12 col-lg-7 col-xl-8 mt-3 mt-lg-0';
    if(userMode) containerClassName = 'col-12 col-lg-12 col-xl-12 mt-3 mt-lg-0';

    const onClick = () => {
        updateRequestFilterOnChange(filterValue);
    }

    const returnPress = (e) => {
        if(e.key === 'Enter'){
            onClick();
        }
    }

    const adorn = (
        <InputAdornment position="start">
            <IconButton onClick={onClick}>
                <SearchIcon />
            </IconButton>
        </InputAdornment>
    );

    let content = (
        <>
            <h1 className='devices-page__title mr-auto'>My devices</h1>
            {
                (isLoading && first && <div className='devices-page__filtersNdevices__loader'><CircularProgressLoader /></div>) ||
                    <InfiniteScroll {...scrollProps}>
                    {!!sortedDeviceArray?.length && sortedDeviceArray.map((device, index) => (
                        <div key={`single-device-wrapper-${index}`} id={`single-device-wrapper-${index}`} className='col-12 col-lg-6 col-xl-3 device-device-device'>
                            <div className='devices-page__filtersNdevices__device'>
                                <SingleDevice
                                    userMode={userMode}
                                    name={device.attributes.name ? device.attributes.name : 'Unnamed system'}
                                    id={device.thingName}
                                    connected={device?.connectivity?.connected || false}
                                    reported={device?.shadow?.reported}
                                />
                            </div>
                        </div>
                    ))}
                </InfiniteScroll>
            }
        </>
    );

    
    if (!userMode) {
        const filterSections = [
          {
            sectionId: COMMON_KEY,
            title: "Main",
            selectedFilterTab,  
            setSelectedFilterTab ,         
            content: (
              <ColorFilters
                {...filterValues}
                filterError={filterError}
                handleClick={handleClick}
              />
            ),
          },
          {
            sectionId: "ems_failed_diagnostics",
            title: "Filters",
            selectedFilterTab,  
            setSelectedFilterTab , 
            totalSelected: calculateTotalSelected(),
            content: (
              <FiltersContainer
                filterValues={filterValues}
                isChecked={(value) => checkedMap.has(value)}
                onChange={handleSubmitFilterValue}
                checkBoxListDisabled={shouldDisableCheckboxList()}
              />
            ),
          },
        ];
        content = (
          <>
            <FiltersMainContainer>
              {/* Search text input */}
              <TextField
                style={{
                  marginBottom:
                    mediaQuery.isSmall || mediaQuery.isMedium ? undefined : 10,
                }}
                fullWidth={true}
                variant="outlined"
                placeholder="Ex.: user email"
                type="text"
                name="search-input"
                onChange={(ev) => setFilterValue(`${ev.target.value}*`)}
                onKeyDown={returnPress}
                InputProps={{
                  endAdornment: adorn,
                }}
              />

              {/* Main filters and checkbox sections */}
              {filterSections.map((sectionProps) => (
                <FilterSection
                  key={sectionProps.sectionId}
                  {...sectionProps}
                />
              ))}
            </FiltersMainContainer>
            <DataGrid
              rows={rows}
              columns={columns}
              components={{ Toolbar: adminRole ? DataGridToolbar : null }}
              pageSize={MAX_ITEMS_REQUESTED}
              rowCount={total}
              paginationMode="server"
              onPageChange={onPageChange}
              page={page}
              checkboxSelection={adminRole}
              disableSelectionOnClick
              loading={isLoading}
              rowsPerPageOptions={[MAX_ITEMS_REQUESTED]}
            />
          </>
        );
    }

    return (
        <div className='devices-page'>
            {content}
        </div>
    )
}

const mapStateToProp = (state) => {
    const adminStore = state?.admin;
    return {admin: adminStore};
}

export default connect(mapStateToProp, { ...systemActionCreators })(DevicesPage);
