import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { Check, PriorityHigh, Close } from "@mui/icons-material";

/**
 * @readonly
 * @enum {string}
 */
const ACCOUNT_STATUS = {
  LINKED_AND_REGISTERED: "LINKED_AND_REGISTERED",
  NOT_LINKED: "NOT_LINKED",
  LINKED_NOT_REGISTERED: "LINKED_NOT_REGISTERED",
};

/**
 * @readonly
 * @enum {string}
 */
const accountStatusConfig = {
  LINKED_AND_REGISTERED: {
    label: "User linked and registered",
    icon: Check,
    color: "success",
  },
  NOT_LINKED: {
    label: "No user linked",
    icon: Close,
    color: "error",
  },
  LINKED_NOT_REGISTERED: {
    label: "User linked but not yet registered",
    icon: PriorityHigh,
    color: "secondary",
  },
};

/**
 * It determines the account status based on the presence of an account ID and email.
 * @returns {ACCOUNT_STATUS} the account status based on the provided accountId and email.
 */
function generatedAccountStatus(accountId, email) {
  switch (true) {
    case !!accountId && !!email:
      return ACCOUNT_STATUS.LINKED_AND_REGISTERED;

    case !accountId && !!email:
      return ACCOUNT_STATUS.LINKED_NOT_REGISTERED;

    case !accountId && !email:
    default:
      return ACCOUNT_STATUS.NOT_LINKED;
  }
}

function AccountStatus({ status }) {
  const { label, icon: Icon, color } = accountStatusConfig[status];
  return (
    <Tooltip title={label}>
      <Icon fontSize="small" color={color} />
    </Tooltip>
  );
}

export { ACCOUNT_STATUS, generatedAccountStatus, AccountStatus };
