const SET_CHARGERS = 'SET_CHARGERS';
const SET_ALL_CHARGERS = 'SET_ALL_CHARGERS';

const initialState = {};

function chargersReducer(state = initialState, action = {}) {
	const { type, data = {} } = action;

	switch (type) {
		case SET_ALL_CHARGERS:
			return { ...data };
		case SET_CHARGERS:
			return Object.fromEntries(Object.entries({ ...state, ...data }).filter( ([_, v]) => v ));
		default:
			return state;
	}
}

// Action creators
const setChargers = (data) => ({ type: SET_CHARGERS, data });
const setFullChargers = (data) => ({ type: SET_ALL_CHARGERS, data });

export { setChargers, setFullChargers };
export default chargersReducer;