export default {
    'Primo GEN24 3.0': { maxACDCPower: 3000, maxMPPTPower: 3110, maxBatteryChargePower: 3110, maxBatteryDischargePower: 3110 },
    'Primo GEN24 3.6': { maxACDCPower: 3600, maxMPPTPower: 3810, maxBatteryChargePower: 3810, maxBatteryDischargePower: 3810 },
    'Primo GEN24 4.0': { maxACDCPower: 4000, maxMPPTPower: 4140, maxBatteryChargePower: 4140, maxBatteryDischargePower: 4140 },
    'Primo GEN24 4.6': { maxACDCPower: 4600, maxMPPTPower: 4750, maxBatteryChargePower: 4750, maxBatteryDischargePower: 4750 },
    'Primo GEN24 5.0': { maxACDCPower: 5000, maxMPPTPower: 5170, maxBatteryChargePower: 5170, maxBatteryDischargePower: 5170 },
    'Primo GEN24 6.0': { maxACDCPower: 6000, maxMPPTPower: 6200, maxBatteryChargePower: 6200, maxBatteryDischargePower: 6200 },
    'Primo GEN24 10.0': { maxACDCPower: 10000, maxMPPTPower: 10300, maxBatteryChargePower: 10300, maxBatteryDischargePower: 10300 },
    'Symo GEN24 3.0': { maxACDCPower: 3000, maxMPPTPower: 3150, maxBatteryChargePower: 3150, maxBatteryDischargePower: 3150 },
    'Symo GEN24 4.0': { maxACDCPower: 4000, maxMPPTPower: 4180, maxBatteryChargePower: 4180, maxBatteryDischargePower: 4180 },
    'Symo GEN24 5.0': { maxACDCPower: 5000, maxMPPTPower: 5200, maxBatteryChargePower: 5200, maxBatteryDischargePower: 5200 },
    'Symo GEN24 6.0': { maxACDCPower: 6000, maxMPPTPower: 6220, maxBatteryChargePower: 6220, maxBatteryDischargePower: 6220 },
    'Symo GEN24 8.0': { maxACDCPower: 8000, maxMPPTPower: 8260, maxBatteryChargePower: 8260, maxBatteryDischargePower: 8260 },
    'Symo GEN24 10.0': { maxACDCPower: 10000, maxMPPTPower: 10300, maxBatteryChargePower: 10300, maxBatteryDischargePower: 10300 },
  }