const UPDATE_MATCH = "UPDATE_MATCH"

const initialState = {
	path: "/fleet",
	url: "/fleet"
};

function matchReducer(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case UPDATE_MATCH:
			return payload;
		default:
			return state;
	}
}

// Action creators
const setMatch = (match) => ({ type: UPDATE_MATCH, payload: match })

export { setMatch };
export default matchReducer;