import React from 'react';

import Badge from '@mui/material/Badge';

import './device-nav-menu-option.styles.scss'

/**
 * Component renders the navigation on main menus for the device page.
 * @param { id, handleClick, name, isActive, showUnderline, disabled } 
 * { 
 * id -> menu option id 
 * handleClick -> function to execute on click
 * name -> title to appear as navigation option
 * isActive -> defines whether the current option has been clicked 
 * showUnderline -> To display the underlines
 * disabled  -> To display as disabled.
 * }
 * 
 * @returns 
 */
const DeviceNavMenuOptions = ({ id, handleClick, name, isActive, showUnderline, disabled, warning }) => (
    <div id={`device-nav-menu-${id}`} className={`device-nav-menu-option ${disabled ? 'disabled' : ''}`} onClick={disabled === true ? null : handleClick}>
        <Badge color="secondary" badgeContent={warning ? '!' : 0}><span className={`${isActive ? "" : "device-nav-menu-option__gray"}`}>{name}</span></Badge>
        <span className={`${isActive && showUnderline ? "device-nav-menu-option__underline" : ""}`} ></span>
    </div>
);
export default DeviceNavMenuOptions;