import React, { useState, useEffect, useRef } from 'react';
import * as ReactDOM from 'react-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import BuildIcon from '@mui/icons-material/Build';
import CloseIcon from '@mui/icons-material/Close';
import CodeIcon from '@mui/icons-material/Code';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import MinimizeIcon from '@mui/icons-material/Minimize';
import ReplayIcon from '@mui/icons-material/Replay';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { nanoid } from 'nanoid';
import { Rnd } from 'react-rnd';
import { connect } from 'react-redux';

import Console from './console';
import Reboot from './reboot';

import './styles.scss';

const tools = {
	Console: {
		Component: Console,
		Icon: CodeIcon,
		layout: {},
		rndProps: {
			minWidth: '300px',
			minHeight: '300px',
		},
		admin: true,
	},
	Reboot: {
		Component: Reboot,
		Icon: ReplayIcon,
		layout: {},
		silent: true,
		admin: false,
	}
};

function Window(props){

	const { Component, detached, silent, minimizers, close = () => console.log("CLOSE REQUEST"), id, name, rndProps: compRndProps = {} } = props;
	const compProps = { id, onClose: close };
	const [minimized, setMinimized] = useState(false);
	const [minimizer, setMinimizer] = useState(() => {});
  const [container, setContainer] = useState(null);
  const newWindow = useRef(null);
	const rndProps = {
		...compRndProps,
		default: {x: 0, y: -300},
		style: {display: minimized ? 'none' : undefined},
		className: "window-rnd",
		cancel:".cancel"
	}

	minimizers.current = {
		...minimizers.current, 
		[name]: () => {
			return () => {
				setMinimized(!minimized)
			};
		}
	};

	useEffect( () => {
		setMinimizer(minimizers.current[name]);
	}, []);

	let windowName = name || "Unknown App";
	let render;

	if(silent){
		render =  (
			<Component {...compProps} />
		);
	} else if(!detached){
		render = (
			<Rnd {...rndProps} >
				<Paper className='window-wrapper'>
					<div className="window-title">
						<div>{windowName}</div>
						<div> <Button onClick={minimizer}> <MinimizeIcon /></Button> <Button onClick={close}><CloseIcon /></Button></div>
					</div>
					{Component ? <Component {...compProps} /> : null}
				</Paper>
			</Rnd>
		);
	}

	
	return render;

}

function Tools(props){

	const { system, admin } = props;
	const { 
      shadow : {
        reported = {}
      } = {},
    } = system || {};
    const { connected } = reported;
	const [show, setShow] = useState(false);
	const [windows, setWindows] = useState(null);
	const minimizers = useRef({});

	const compsIcons = Object.entries(tools).map( ([name, comp]) => {
		const { Icon, admin: adminComp } = comp;

		const onClick = () => {
			if(name === 'Console'){
				window.open('./console', '', "width=600,height=400,left=200,top=200");
			}
			else{
				setWindows( old => {
					const oldObj = old || {};
					const { [name] : current = { ...comp, name , id: nanoid()} } = oldObj;

					return {...old, [name]: {...current}};
				});
				
				if(name in minimizers.current){
					const minimizer = minimizers.current[name]();
					minimizer();
				}
			}
		}

		return !adminComp || admin ? (
			<SpeedDialAction 
				key={name}
				onClick={onClick}
				icon={<Icon />}
				tooltipTitle={name}
			/>
		): null;
	});

	const windowsRender = Object.entries(windows || {}).map( ([name, comp], idx) => {
		const { id, Component } = comp;
		const windowProps = {
			...comp,
			key: `el${idx}`,
			close: () => {
				setWindows( (old) => {
					const oldObj = old || {};
					const { [name] : current, ...rest } = oldObj;

					return rest;
				})
			},
			minimizers,
		};

		return	<Window {...windowProps} />
	});

	return (
		<>
			<SpeedDial 
				className="toolBelt"
				ariaLabel="Tool belt"
				icon={<SettingsIcon />}
				open={show}
				hidden={!connected}
				onOpen={() => setShow(true)}
				onClose={() => setShow(false)}
				direction="right"
			>
				{compsIcons}
			</SpeedDial>
			<div className="io-tool-windows">
				{windowsRender}
			</div>
		</>
	);
}

const StoreTools = connect(
  (state) => ({
    system: state.system
  }),
  null,
  null
)(Tools);

export default StoreTools;

