import { Box, Typography } from "@mui/material";
import StepComponent from "./step.component";
import { useState } from "react";
import {
  getBatteryDefaultDetails,
  getBatteryDefinitionFromBrandModel,
} from "../../device-details-component/device-details-utils";
import { get } from "lodash";
import StepContainer from "./step-container.component";
import { STEP_IDS } from "../config-wizard-steps";

export default function BatteryPanelsStep({ stepProps, onChange, ...props }) {
  const { formData, schema, uiSchema } = stepProps;
  const [selectedBatterySchema, setSelectedBatterySchema] = useState({});
  const [selectedBatteryFormData, setSelectedBatteryFormData] = useState(null);
  const batteryBrandModelProps = {
    ...stepProps,
    formData: { batteryBrandModel: formData?.batteryBrandModel },
    schema: schema.batteryBrandModel,
    uiSchema: uiSchema.batteryBrandModel,
    liveValidate: true,
    skipContainer: true,
  };

  const batteryProps = {
    ...stepProps,
    helperText: null,
    formData: {
      battery: selectedBatteryFormData
        ? selectedBatteryFormData
        : formData?.battery,
    },
    schema: {
      ...schema.battery,
      properties: {
        battery: {
          ...(schema.battery.properties.battery
            ? schema.battery.properties.battery
            : selectedBatterySchema),
        },
      },
    },
    uiSchema: uiSchema.battery,
    liveValidate: !!props.state?.get(stepProps?.stepId)?.battery,
    skipContainer: true,
  };

  const onBatteryBrandModelChange = (stepId, formRef) => (key) => {
    const { batteryBrandModel } = get(formRef, "current.state.formData", {});
    if (!batteryBrandModel) return;
    const batteryDefinition = getBatteryDefinitionFromBrandModel(
      props.schemas,
      batteryBrandModel
    );
    if (!batteryDefinition) return;
    const [_, definition] = batteryDefinition;
    setSelectedBatterySchema(definition);
    setSelectedBatteryFormData(getBatteryDefaultDetails(batteryDefinition));
    onChange(stepId, formRef)(key);
  };

  const { brand, model } = props.state.get(STEP_IDS.CONVERTER) || {};

  const batteryNotApplicable =
    !(brand && model) && props.CONVERTER?.brand === "DefaultInverter";

  return (
    <StepContainer>
      {batteryNotApplicable ? (
        <Typography variant="body" fontWeight={500}>
          ℹ️ Mind: To configure Battery, an inverter must be configured first
        </Typography>
      ) : (
        <>
          <Box>
            <StepComponent
              {...props}
              onChange={onBatteryBrandModelChange}
              partialKey="batteryBrandModel"
              stepProps={batteryBrandModelProps}
            />
          </Box>
          <Box>
            <StepComponent
              {...props}
              onChange={onChange}
              partialKey="battery"
              stepProps={batteryProps}
            />
          </Box>
        </>
      )}
    </StepContainer>
  );
}
