import FieldDropDown from '@lifepowr/components/src/components-shared/field-drop-down-component/field-drop-down.component';
import React, { useState, useEffect } from 'react'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import useMediaQuery from '@mui/material/useMediaQuery';

import { API } from 'Amplify';
import { CUSTOM } from '../utils'
import { useParams } from 'react-router-dom'

import { connect } from 'react-redux';

function sortOption(a, b) {
  var nameA = a.label.toUpperCase(); // ignore upper and lowercase
  var nameB = b.label.toUpperCase(); // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  // names must be equal
  return 0;
}

const createOption = (paramValue, paramLabel, tableName, raw = false) => {
    let label = paramLabel;
    if (paramLabel === 'stateOfCharge') {
        label = 'Battery State of Charge';
    } else if (label === 'GoodwePVRetro') {
        label = 'InverterPVRetro';
    }
    return (
        {
            value: paramValue.includes(CUSTOM) || !tableName || raw ? paramValue : `${tableName}:${paramValue}`,
            label,
            key: paramLabel,
            table: tableName,
        }
    );
};

const DeviceDropdown = ({ mobileMode, dropDownValue, setDropDownValue, customCharts, queryStringParameters, userMode, global: { advanced } = {} }) => {
    const { table } = queryStringParameters;
    const [loadingOptions, setLoadingOptions] = useState(() => false);
    const [requestedOptions, setRequestedOptions] = useState({ });
    const [dropDownOptions, setDropDownOptions] = useState(() => []);
    const [currentParameters, setCurrentParameters] = useState(() => null);

    const { deviceName: thingName } = useParams();

    useEffect(() => {
        if ((queryStringParameters) && (!currentParameters || (currentParameters['db'] !== queryStringParameters['db'] || currentParameters['table'] !== queryStringParameters['table']))) {
            setCurrentParameters(queryStringParameters)
        }
    }, [queryStringParameters, currentParameters]);

    useEffect(() => {
        if (requestedOptions || userMode) {
            const customOptions = Object.keys(customCharts).map(chartName => createOption(CUSTOM + chartName, chartName));//.sort(sortOption);
            let allOptions = [
                ...customOptions.map((o)=>({ type: 'group', name: 'Custom charts', label: o.label, value: o.value, key: o.key })),
            ];
            
            if(table){
                const singleOptions = Object.keys(requestedOptions).map(chartName => createOption(chartName, chartName, 'inverter')).sort(sortOption)
                allOptions.push(
                    ...singleOptions.map((o)=>({ type: 'group', name: 'Single charts', label: o.label, value: o.value, key: o.key })),
                );
            } else {
                Object.entries(requestedOptions).forEach( ([tableName, tableMeasures]) => {
                    const tableOptions = Object.keys(tableMeasures).map(chartName => createOption(chartName, chartName, tableName)).sort(sortOption)
                    allOptions.push(
                        ...tableOptions.map((o)=>({ type: 'group', name: tableName, label: o.label, value: o.value, key: o.key })),
                    );
                });
            }
            console.log(allOptions);
            setDropDownOptions(allOptions)
        }
    }, [requestedOptions, customCharts, userMode])

    useEffect(() => {
        if (currentParameters) {
            setLoadingOptions(true);
            API.get("IO_API", `/devices/${thingName}/timestream`, { queryStringParameters: currentParameters })
                .then((res) => {
                    if(userMode && !advanced){
                        const filter = ['stateOfCharge'];
                        const filteredBody = Object.entries(res).reduce( (acc, [k, v]) => {
                            if(filter.includes(k)) acc[k] = v;
                            return acc;
                        },{});
                        setRequestedOptions(filteredBody);
                    }
                    else{
                        const filterOut = ['alfen', 'phoenixcontact', 'easee', 'etrel', 'wallbox', 'ocpp'];
                        const filteredBody = Object.entries(res).reduce( (acc, [k, v]) => {
                            const interiorEntries = Object.entries(v);
                            const filtered = Object.fromEntries(
                                interiorEntries.map(([mapK, mapV])=>(!filterOut.some(f=>mapK.includes(f)) ? [mapK, mapV] : undefined)).filter(obj=>obj),
                            )
                            acc[k] = filtered;
                            return acc;
                        },{});
                        setRequestedOptions(filteredBody);
                    }
                })
                .catch(err => console.error("ERROR GETTING VARIABLES", err))
                .finally(() => setLoadingOptions(false));
        }
    }, [customCharts, currentParameters, userMode, advanced]);

    useEffect(
        () => {
            let selectedMeasure = {};
            if (dropDownOptions?.length && !mobileMode) {
                const customOptionsKey = Object.keys(customCharts)[0];
                selectedMeasure = (customOptionsKey) ? { ...customCharts[customOptionsKey], label: customOptionsKey, value: CUSTOM + customOptionsKey } : dropDownOptions[1]?.item[0]
                setDropDownValue(selectedMeasure);
            }
        },
        [dropDownOptions, customCharts, setDropDownValue, mobileMode],
    );

    const onSelect = (e) => {
        console.log('WHYYYYY?!', e);
        if (e) {
            const [selectedTableStr, selectedMeasureStr] = e.value.split(':');
            const selectedMeasure = (e.value.includes(CUSTOM)) ? { ...customCharts[e.value.split(CUSTOM)[1]], label: e.value.split(CUSTOM)[1], value:e.value } : createOption(selectedMeasureStr, selectedMeasureStr, selectedTableStr, true) // createOption(e.value, e.label, e.table)
            setDropDownValue(selectedMeasure)
        }
    }

    //if(largerMatch) defaultOption = dropDownOptions[0]?.items[0] || dropDownOptions[1]?.items[0];

    /*
    <FieldDropDown
                key="device-fieldDropDownInner"
                options={dropDownOptions}
                value={dropDownValue}
                placeholder="Select a chart"
                onOptionChange={onSelect}
            />
     */

    const autocompleteOptions = dropDownOptions
    return (
        <div key="device__FieldDropDownWrapper" className="device-dropdown">  {
            (!!loadingOptions && <h1>LOADING...</h1>) ||
            <Autocomplete
                  disablePortal
                  className="fleetChangerSelector"
                  value={dropDownValue || null}
                  isOptionEqualToValue={
                    (o, v)=>o.label===v
                  }
                  groupBy={(o)=>o.name}
                  onChange={(ev, value) => onSelect(value)}
                  options={dropDownOptions}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.value}>
                        {option.label}
                      </li>
                    );
                  }}
                  renderInput={(params) => <TextField {...params} label="Select plot" variant="outlined" />}
            />
        }</div>
    )
}

export default connect(
    ({global}) => ({ global }),
    null,
    null
)(DeviceDropdown);