/**
 * This module declares the main Amplify object
 * Exports a "fake" reducer to be used in the stored for acquiring the object
 */

import {
  Auth, API, PubSub, Amplify,
} from 'aws-amplify';
import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers';

// This is kinda ugly but probably will have no other option here - we'll see
// const {REACT_APP_API_GATEWAY_URL_DEV,REACT_APP_API_GATEWAY_URL, NODE_ENV} = process.env;
const {
  REACT_APP_API_GATEWAY_URL,
  REACT_APP_COGNITO_REGION,
  REACT_APP_COGNITO_USER_POOL_ID,
  REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
  REACT_APP_COGNITO_IDENTITY_POOL_ID,
  REACT_APP_PUBSUB_REGION,
  REACT_APP_PUBSUB_ENDPOINT,

} = process.env;
// const development = NODE_ENV === "development";
const gwUrl = REACT_APP_API_GATEWAY_URL;

const authOptions = {
  Auth: {
    region: REACT_APP_COGNITO_REGION, // 'us-east-1',
    userPoolId: REACT_APP_COGNITO_USER_POOL_ID, // 'us-east-1_sPVHG4IqJ',
    userPoolWebClientId: REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID, // '6bc2um9fsb31gnilt3mcmivcsf',
    identityPoolId: REACT_APP_COGNITO_IDENTITY_POOL_ID, // "us-east-1:f38052f0-afa1-4216-a1c5-ef2fbb925727"
    cookieStorage: {
      domain: window.location.hostname,
      secure: false,
    },
  },
  API: {
    endpoints: [
      {
        name: 'IO_API',
        endpoint: gwUrl,
        custom_header: async () => {
          let token = '';
          try {
            token = (await Auth.currentSession()).getIdToken().getJwtToken();
          } catch (e) {
            console.log('error', e);
          }
          if (token) return { Authorization: `Bearer ${token}` };
          return {};
        },
      },
    ],
  },
};

Amplify.configure(authOptions);

Amplify.Logger.LOG_LEVEL = process.env.NODE_ENV === 'development' ? 'DEBUG' : '';

Amplify.addPluggable(new AWSIoTProvider({
  aws_pubsub_region: REACT_APP_PUBSUB_REGION, // "eu-west-1",
  aws_pubsub_endpoint: REACT_APP_PUBSUB_ENDPOINT, // "wss://asfjfwm1u8ru-ats.iot.eu-west-1.amazonaws.com/mqtt"
}));

export { API, PubSub, Auth };
