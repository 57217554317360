import React from 'react';
import './dot-loading-animation.styles.scss';

/**
 * To reflect the boolean into an animation direction.
 */
const animationDirection = [
    { direction: 1, class: 'dot-animation' },
    { direction:-1, class: 'dot-animation-reverse' }
];

/**
 * 
 * @param { color } string with a color for the dots 
 * @param { direction } boolean indicating the direction of the animation movement either outward on inward depends on the -/+ of the measure  
 * @param { dotNumber } number of dots to be displayed on the animation used to accomodate smaller screens
 * @returns A set of dots that together display an animation
 */
const DotAnimation = ({ color, direction, dotNumber }) => {
	const dirClass = direction ? animationDirection.filter( element => element.direction === direction )?.pop().class : 'dot-animation-stop';
	let elClass = 'power-arrow';
	let elStyle = {borderColor: color}
	if(dirClass === 'dot-animation-stop'){
		elStyle = {backgroundColor: color};
		elClass = 'dot';
	}

	return (
	    <span className={ dirClass }>
	        {
	            [...Array(dotNumber).keys()].map( (el) => <span key={`dot__${el}`} className={elClass} style={elStyle}></span> )
	        }
	    </span>
	);

};

export default DotAnimation;