const SET_SYSTEM = 'SET_SYSTEM';
const SET_CONNECTIVITY = 'SET_CONNECTIVITY';
const SET_SYSTEM_SHADOW = 'SET_SYSTEM_SHADOW';
const SET_SYSTEM_RTDATA = 'SET_SYSTEM_RTDATA';

const { merge, mergeWith, isArray } = require("lodash");

const initialState = null;

function customizer(oldVal, newVal, key, object, source, stack){    
	if(isArray(oldVal)){
		return newVal;
	}
}

function systemReducer(state = initialState, action = {}) {
	const { type, data, name } = action;

	switch (type) {
		case SET_SYSTEM:
			return data;
		case SET_CONNECTIVITY:
			return {...state, connected: data};
		case SET_SYSTEM_SHADOW:
			const { shadow = {} } = state || {};
			const { desired, reported } = shadow;
			const { desired: desiredData = {}, reported: reportedData = {} } = data || {};
			let newDesired, newReported, newShadow;

			if (data === null) {
				newDesired = {};
				newReported = {};
			}
			else {
				if (name === "batControlMode") {
					newDesired = mergeWith({}, desired, { batControlMode: desiredData.mode }, customizer);
					newReported = mergeWith({}, reported, { batControlMode: reportedData.mode || 0 }, customizer);
				}
				else {
					newDesired = mergeWith({}, desired, (name ? { [name]: desiredData } : desiredData), customizer);
					newReported = mergeWith({}, reported, (name ? { [name]: reportedData } : reportedData), customizer);
				}
			}

			newShadow = mergeWith({}, shadow, { desired: newDesired, reported: newReported }, customizer);

			return { ...state, shadow: newShadow };
		case SET_SYSTEM_RTDATA:
			const { rtData } = state || {};
			const newRtData = merge({}, rtData, data);
			const { timestamp } = data || {};
			const { timestamp: existingTs } = state || {};

			if (!existingTs || timestamp !== existingTs) return { ...state, rtData: newRtData };
			return state;
		default:
			return state;
	}
}

// Action creators
const setSystem = (data) => ({ type: SET_SYSTEM, data });
const setSystemConnected = (data) => ({ type: SET_CONNECTIVITY, data });
const setSystemShadow = (data, name) => ({ type: SET_SYSTEM_SHADOW, data, name });
const setSystemRtData = (data) => ({ type: SET_SYSTEM_RTDATA, data });

export { setSystem, setSystemShadow, setSystemRtData, setSystemConnected };
export default systemReducer;

