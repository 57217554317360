import { Typography } from "@mui/material";
import StepComponent from "../components/step.component";
const pattern = "^[a-zA-Z0-9_.,@/:#=\\[\\]\\s-]*$";

export default {
  title: "User info",
  description:
    "Basic information about the user, including an easy to remember device name.",
  helperText: {
    header: (
      <Typography variant="h6">
        {" "}
        FlexiObox device name and user email.
      </Typography>
    ),
  },
  component: StepComponent,
  schema: {
    required: ["name", "email"],
    properties: {
      name: {
        $id: "basic_name",
        type: "string",
        pattern,
        title: "FlexiObox device name",
        description:
          "Give this FlexiObox a recognizable name where you can search for in the Fleet",
      },
      email: {
        $id: "basic_email",
        type: "string",
        format: "email",
        title: "Email address",
        description:
          "Enter user's email address. This address must be the email address which the user wants to use for logging in on the MyFlexiO App",
      },
    },
  },
  uiSchema: {
    name: {},
    email: {
      "ui:options": {
        inputType: "email",
      },
      "ui:widget": "email",
    },
  },
  transformErrors: (errors) => {
    return errors.map((error) => {
      if (error.name === "pattern" && error.property === ".name") {
        error.message =
          "Please enter only letters, numbers, spaces, and the following special characters: _.,@/:#=[]-";
      }
      return error;
    });
  },
};
