import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { nanoid } from 'nanoid';


import useSocket from '@lifepowr/components/src/components/socket/useSocket';
import * as systemCreators from "@lifepowr/components/src/store/system";
import { connect } from 'react-redux';
import { API, Auth } from 'Amplify';

import ConsoleInner from '@lifepowr/components/src/modules/device-module/components/tools/console';

const { default: df, ...systemActionCreators } = systemCreators;

function Console(props){
	const { system, setSystem, setSystemShadow, setSystemRtData, setSystemConnected } = props;
	const { attributes: { name : systemName = "Unnamed system" } = {} } = system || {};
    const { deviceName: thingName } = useParams();
    const { subscribe, publish } = useSocket();
    let thingStatus = true;
    
    const { pathName, batteryPathName } = useParams();

    const [admin, setAdmin] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingName, setLoadingName] = useState(false);
    const [editName, setEditName] = useState(false);
    const [currentName, setCurrentName] = useState(systemName);
    const [errorName, setErrorName] = useState('');
    const [updateAttrs, setUpdateAtrrs] = useState(null);

    /**
     * used to update socket data
     */
    useEffect(() => {
        const dataTopic = `fleet/devices/${thingName}/data`;
        const updateTopic = `$aws/events/thing/${thingName}/updated`;

        /**
         * function to subscribe information associated with 
         * a shadow '@param {string} name' reported on the current device
         */
        const hookShadowOnSocket = ([name, shadow]) => {
            const shadowPrefix = createShadowPrefix(thingName, name);
            
            subscribe(`${shadowPrefix}/update/accepted`, ({ value: { state } }) => {
                const { desired, reported } = state || {};
                setSystemShadow({ desired, reported }, name);
            });

            if(name){
                setSystemShadow(shadow, name);  
            }
        }

        API.get("IO_API", `/devices/${thingName}`)
            .then( async ({ things = [] }) => {
                    try{
                        let shadows;
                        const thing = things.pop();

                        if(thing){
                            const { connectivity: { connected } = {} } = thing;
                            setSystem(thing);
                            setSystemConnected(connected);
                        }
                        shadows = await publish(`fleet/devices/${thingName}/state/get`, {}, true);
                        
                        Object.entries(shadows).forEach(hookShadowOnSocket);
                        subscribe(dataTopic, ({ value }) => {
                            setSystemRtData(value || {})
                        });
                        subscribe(updateTopic, ({value}) => {
                            const { attributes } = value;
                            if(attributes) setUpdateAtrrs(attributes);
                        });
                        subscribe(`$aws/events/presence/connected/${thingName}`, (wut) => {
                            setSystemConnected(true);
                        });
                        subscribe(`$aws/events/presence/disconnected/${thingName}`, (wut) => {
                            setSystemConnected(false);
                        });
                    }
                    catch(e){
                        setError(`${e}`);
                    }
                    setLoading(false);
            })
            .catch(e => {
                const { response: { data : { message } = {} } = {} } = e;
                setError(message);
                setLoading(false);
            });
    }, [setSystem, setSystemConnected, setSystemRtData, setSystemShadow, thingName, publish, subscribe]);

	useEffect(
        () => {
            if (!window.document.title.includes(systemName) && systemName !== 'Unnamed system') {
                window.document.title = `FlexIO Console - ${systemName}`;
            }
        },
        [systemName],
    );

    useEffect( () => {
        if(system && updateAttrs){
            const newSystem = Object.assign({}, system, {attributes: updateAttrs});
            setUpdateAtrrs(null);
            setSystem(newSystem);
        }
    }, [updateAttrs, system, setSystem]);

    if(loading) return ( <div>Loading . . .</div> );

	const onClose = () => {
		window.close();
	}

	return (
		<ConsoleInner id={nanoid()} onClose={onClose}/>
	);
}

const mapStateToProp = (state) => {
    const system = state?.system || {};
    const { connected, code, shadow } = system;
    const { reported } = shadow || {};
    const { diagnostics } = reported || {};
    return { connected, system, code, diagnostics };
}

export default connect(mapStateToProp, { ...systemActionCreators })(Console);

