import { useState, useEffect } from 'react';
import { Auth, API } from '@lifepowr/components/src/Amplify';

function PolicyAuth(props) {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect( () => {
		Auth.currentCredentials()
			.then( async (info) => {
				const { identityId: IdentityId } = info;

				if(!IdentityId) throw new Error('Could not find IdentityId');

				try{
					const res = await API.post("IO_API", `/auth/iot-policy`, { body: { IdentityId } })
					const { body } = res;

					// if(body) window.location.reload();
					setError(null);
					setLoading(false);
				}
				catch(err){
					console.log("ERROR", err);
					setLoading(false);
					setError(`ERROR ${err}`);
				}
			})
			.catch( err => {
				setLoading(false);
				setError(`ERROR ${err}`);
				console.log("GET INFO ERROR", err);
			});
	}, []);

	// This loading is the reason for the many loading happening
	// Removing it will make the page look better
	// However, there is a (small) chance that an error will be thrown when the user is new
	// Manual refresh should fix those problems
	// if(loading) return (<div>Loading . . .</div>);
	if(error) return (<div>{error}</div>);

	return( 
		<>
			{props.children}
		</>
	);
}

export default PolicyAuth;