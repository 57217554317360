import React from 'react'
import PropTypes from 'prop-types';
import { DAY, HOUR, MONTH } from './chart-utils';

const cssStyle = {
	container: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-evenly',
		alignItems: 'baseline',
		fontSize: '12px',
		fontWeight: 'normal',
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: '0.83',
		letterSpacing: 'normal',
		textAlign: 'left',
	},
	btnSelected: {
		color: '#0e5c5c',
		borderRadius: '8px',
		backgroundColor: '#abd1d1',
		fontSize: '12px',
		fontWeight: '600'
	},
	btnDefault: {
		color: '#b4b4b4',
		borderRadius: '8px',
		backgroundColor: 'transparent',
		fontSize: '12px',
	}
}

/**
 * Range prop, provides information rearding the several possible time ranges to preform timeseries requests
 * @param {function} onChange function trigged when an range element is clicked
 * @param {number} range an element value to be selected
 * @param {boolean} loading used to disable onChange function on onclick event
 */
const ChartRange = ({ onChange, range, loading, disableRealTime, setDefaultOption = null, realTimeMeasure = HOUR }) => {

	const realTimeOption = { value: realTimeMeasure, label: "Real Time" }

	const timeRangeOptions = [
		{ value: 12 * HOUR, label: "12 hs" },
		{ value: DAY, label: "1 day" },
		{ value: 7 * DAY, label: "1 week" },
		{ value: MONTH, label: "1 month" },
		{ value: 3 * MONTH, label: "3 months" },
		{ value: 6 * MONTH, label: "6 months" },
		{ value: 365 * DAY, label: "1 year" }
	];

	const ranges = []
	if (!disableRealTime)
		ranges.push(realTimeOption)

	ranges.push(...timeRangeOptions)

	return <>
		<div className="row">
			<div className='col-12 py-2' style={cssStyle.container}>
				<b><span>Show </span></b>{
					ranges.map((element, index) =>
						<label
							ref={node => (element.value == DAY) && setDefaultOption && setDefaultOption(node)}
							key={`range-item__${index}`}
							className={`btn btn-sm ${loading ? 'disabled' : ''}`}
							style={cssStyle[`btn${range === element.value ? 'Selected' : 'Default'}`]}
							onClick={() => !loading && onChange(element.value)}
							children={element.label}
						/>
					)
				}
			</div>
		</div >
	</>
}

ChartRange.propTypes = {
	onChange: PropTypes.func.isRequired,
	range: PropTypes.number,
	loading: PropTypes.bool,
	disableRealTime: PropTypes.bool,
};

export default ChartRange;