import React from 'react';

import { Redirect, Route, Switch } from 'react-router-dom';

import './styles.scss';

function Bones(props){
	const {
		routes = {},
		redirects = [],
	} = props;
	let {
		headerProps = {},
		navProps = {},
		contentProps = {},
		footerProps = {},
	} = props;

	const renderRoutes = Object.entries(routes).map(([path, {components, fullContent, ...rest}]) => {
		const {
			Header,
			Navigation,
			Content,
			Footer,
		} = components || {};

		const routeProps = {
			key: path,
			path,
			...rest,
		};

		let render = (
			<Route { ...routeProps }>
				<div className="device-container">
					<div className="header">
						{ Header ? <Header {...headerProps}/> : null }
					</div>
					<div className="navigation">
						{ Navigation ? <Navigation {...navProps}/> : null }
					</div>
					<div className="content">
						{ Content ? <Content {...contentProps}/> : null }
					</div>
					<div className="footer">
						{ Footer ? <Footer {...footerProps}/> : null }
					</div>
				</div>
			</Route>
		);

		if(fullContent) render = (
			<div className="device-container-full">
				<Content {...contentProps}/>
			</div>
		);

		return (
			<Route {...routeProps}>
				{render}
			</Route>
		);
	});

	const renderRedirects = redirects.map( (props, key) => <Redirect key={key} {...props} />);

	return (
		<Switch>
			{ renderRoutes }
			{ renderRedirects }
		</Switch>
	);
}

export default Bones;