const SET_SYSTEMS = 'SET_SYSTEMS';
const SET_FLEET = 'SET_FLEET';
const SET_SYSTEMS_RTDATA = 'SET_SYSTEMS_RTDATA';

const initialState = {
	systems: {},
	fleet: undefined,
	fleetRt: undefined,
};

function systemsReducer(state = initialState, action) {
	const { type, data } = action;

	switch (type) {
		case SET_SYSTEMS:
			return { ...state, ...data };
		case SET_FLEET:
			return { ...state, fleet: data };
		case SET_SYSTEMS_RTDATA:
			/*const { rtData } = state || {};
			const newRtData = merge({}, rtData, data);
			const { timestamp } = data || {};
			const { timestamp: existingTs } = state || {};

			if (!existingTs || timestamp !== existingTs) return { ...state, rtData: newRtData };*/
			return {...state, fleetRt: data};
		default:
			return state;
	}
}

// Action creators
const setSystems = (data) => ({ type: SET_SYSTEMS, data });
const setFleet = (data) => ({ type: SET_FLEET, data });
const setFleetRt = (data) => ({ type: SET_SYSTEMS_RTDATA, data });

export { setSystems, setFleet, setFleetRt };
export default systemsReducer;