import LoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

export default function SaveDialog({
  isOpen,
  onClose,
  onSave,
  onCancel,
  loading,
  readyToSave,
}) {
  return (
    <Dialog open={isOpen}>
      <DialogTitle>Save changes</DialogTitle>
      <DialogContent>
        <Typography variant="body1">Do want to save your changes?</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={onClose}>No</Button>
        <LoadingButton
          disabled={!readyToSave}
          onClick={onSave}
          loading={loading}
        >
          <span>Yes</span>
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
