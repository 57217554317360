import React from "react";

/**
 * renders a one line date 
 * @param {showWeekday} boolean indicating the need to a weed day to be displayed
 * @returns 
 */
const DateView = ({ showWeekday }) => {

  const today = new Date()
  const day = new Date(today)
  var days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
  var months = ['January','February','March','April','May','June','July','August','September','October','November','December'];
  
  let date = showWeekday ? 
    days[day.getDay()] + ', ' + months[day.getMonth()] + ' ' + day.getDate() + ', ' + day.getFullYear() :
    months[day.getMonth()] + ' ' + day.getDate() + ', ' + day.getFullYear()  

  return ( <div className="date-view"> <span>{date}</span> </div> );
}

export default DateView; 