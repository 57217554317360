export default {
  "SE2200H-RWS": { maxACDCPower: 2200, maxMPPTPower: 4400, maxBatteryChargePower: 5000, maxBatteryDischargePower: 5000 },
  "SE3000H-RWS": { maxACDCPower: 3000, maxMPPTPower: 6000, maxBatteryChargePower: 5000, maxBatteryDischargePower: 5000 },
  "SE3500H-RWS": { maxACDCPower: 3500, maxMPPTPower: 7000, maxBatteryChargePower: 5000, maxBatteryDischargePower: 5000 },
  "SE3680H-RWS": { maxACDCPower: 3680, maxMPPTPower: 7360, maxBatteryChargePower: 5000, maxBatteryDischargePower: 5000 },
  "SE4000H-RWS": { maxACDCPower: 4000, maxMPPTPower: 8000, maxBatteryChargePower: 5000, maxBatteryDischargePower: 5000 },
  "SE5000H-RWS": { maxACDCPower: 5000, maxMPPTPower: 10000, maxBatteryChargePower: 5000, maxBatteryDischargePower: 5000 },
  "SE6000H-RWS": { maxACDCPower: 6000, maxMPPTPower: 12000, maxBatteryChargePower: 5000, maxBatteryDischargePower: 5000 },
  "SE5K-RWS": { maxACDCPower: 5000, maxMPPTPower: 6750, maxBatteryChargePower: 5000, maxBatteryDischargePower: 5000 },
  "SE7K-RWS": { maxACDCPower: 7000, maxMPPTPower: 9450, maxBatteryChargePower: 5000, maxBatteryDischargePower: 5000 },
  "SE8K-RWS": { maxACDCPower: 8000, maxMPPTPower: 10800, maxBatteryChargePower: 5000, maxBatteryDischargePower: 5000 },
  "SE10K-RWS": { maxACDCPower: 10000, maxMPPTPower: 13500, maxBatteryChargePower: 5000, maxBatteryDischargePower: 5000 },
  "SE2200H": { maxACDCPower: 2200, maxMPPTPower: 4400, maxBatteryChargePower: 5000, maxBatteryDischargePower: 5000 },
  "SE3000H": { maxACDCPower: 3000, maxMPPTPower: 6000, maxBatteryChargePower: 5000, maxBatteryDischargePower: 5000 },
  "SE3500H": { maxACDCPower: 3500, maxMPPTPower: 7000, maxBatteryChargePower: 5000, maxBatteryDischargePower: 5000 },
  "SE3680H": { maxACDCPower: 3680, maxMPPTPower: 7360, maxBatteryChargePower: 5000, maxBatteryDischargePower: 5000 },
  "SE4000H": { maxACDCPower: 4000, maxMPPTPower: 8000, maxBatteryChargePower: 5000, maxBatteryDischargePower: 5000 },
  "SE5000H": { maxACDCPower: 5000, maxMPPTPower: 10000, maxBatteryChargePower: 5000, maxBatteryDischargePower: 5000 },
  "SE6000H": { maxACDCPower: 6000, maxMPPTPower: 12000, maxBatteryChargePower: 5000, maxBatteryDischargePower: 5000 },
};