/**
 * @typedef {Object} Diagnostic
 * @property {string} internalName
 * @property {string} humanName
 * @property {boolean} status
 * @property {string} details
 */

const diagnosticGroups = [
    [
        'Essential',
        [
            {internalName: 'connected', humanName: 'Connected',},
            {internalName: 'linked', humanName: 'Linked',},
            {internalName: 'modbus', humanName: 'Modbus',},
            {internalName: 'inverter', humanName: 'Inverter',},
            {internalName: 'bms', humanName: 'Battery Management System',},
            {internalName: 'p1', humanName: 'P1 port',},
            {internalName: 'eastron', humanName: 'Eastron meter',},
            {internalName: 'ems', humanName: 'Energy Management System',},
        ],
    ],
    [
        'EV',
        [
            {internalName: 'evDiscovery', humanName: 'EV (discovered)',},
            {internalName: 'ev', humanName: 'EV (controlled)',},
        ],
    ],
    [
        'System',
        [
            {internalName: 'configured', humanName: 'Configured',},
            {internalName: 'update', humanName: 'Update',},
            {internalName: 'board', humanName: 'Board',},
        ],
    ],
    [
        'Internal',
        [
            {internalName: 'iomanager', humanName: 'IO Manager',},
            {internalName: 'ioapi', humanName: 'IO API',},
            {internalName: 'iodaemon', humanName: 'IO Daemon',},
            {internalName: 'iogateway', humanName: 'IO Gateway',},
        ],
    ],
];

/**
 * Group diagnostic values, taking into account unknown diagnostics
 * @param {Object.<string, {status: boolean, details: string}>} diagnosticValues - The diagnostics as they come from the shadow
 * @returns {[string, Diagnostic[]][]} - The diagnostic groups, and their individual diagnostics
 */
function groupDiagnostics(diagnosticValues) {
    if (!diagnosticValues) {
        return [];
    }
    const seenDiagnostics = new Set();

    // Go over all passed in diagnostic values, place them in the right groups
    const allDiagnosticGroups = diagnosticGroups.map(([groupName, definitions]) => {
        return [
            groupName,
            definitions.map(def => {
                const value = diagnosticValues[def.internalName];
                if (!value) {
                    return null;
                }
                seenDiagnostics.add(def.internalName);
                return {
                    internalName: def.internalName,
                    humanName: def.humanName,
                    status: value?.status,
                    details: value?.details,
                };
            }).filter(d => d),
        ];
    });

    // Add ungrouped diagnostics (if any)
    const ungroupedDiagnostics = Object.entries(diagnosticValues)
        .filter(([key, value]) => !seenDiagnostics.has(key))
        .map(([key, value]) => {
            return {
                internalName: key,
                humanName: key,
                status: value?.status,
                details: value?.details,
            };
        });
    if (0 < ungroupedDiagnostics.length) {
        allDiagnosticGroups.push(['Other', ungroupedDiagnostics]);
    }

    return allDiagnosticGroups;
}

export {
    diagnosticGroups,
    groupDiagnostics,
};
