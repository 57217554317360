export default {
	"GW5KL-ET": { maxACDCPower: 5000, maxMPPTPower: 6650, maxBatteryChargePower: 7500, maxBatteryDischargePower: 7500 },
	"GW6.5KL-ET": { maxACDCPower: 6500, maxMPPTPower: 7980, maxBatteryChargePower: 8450, maxBatteryDischargePower: 8450 },
	"GW8KL-ET": { maxACDCPower: 8000, maxMPPTPower: 10640, maxBatteryChargePower: 9600, maxBatteryDischargePower: 9600 },
	"GW10K-ET": { maxACDCPower: 10000, maxMPPTPower: 13300, maxBatteryChargePower: 10000, maxBatteryDischargePower: 10000 },
	"GW15K-ET": { maxACDCPower: 15000, maxMPPTPower: 22500, maxBatteryChargePower: 15000, maxBatteryDischargePower: 15000 },
	"GW20K-ET": { maxACDCPower: 20000, maxMPPTPower: 30000, maxBatteryChargePower: 20000, maxBatteryDischargePower: 20000 },
	"GW25K-ET": { maxACDCPower: 10000, maxMPPTPower: 37500, maxBatteryChargePower: 25000, maxBatteryDischargePower: 25000 },
	"GW29.9K-ET": { maxACDCPower: 29900, maxMPPTPower: 45000, maxBatteryChargePower: 30000, maxBatteryDischargePower: 30000 },
	"GW30K-ET": { maxACDCPower: 30000, maxMPPTPower: 45000, maxBatteryChargePower: 30000, maxBatteryDischargePower: 30000 },
	"GW5K-BT": { maxACDCPower: 5000, maxMPPTPower: 0, maxBatteryChargePower: 5000, maxBatteryDischargePower: 5000 },
	"GW6K-BT": { maxACDCPower: 6000, maxMPPTPower: 0, maxBatteryChargePower: 6000, maxBatteryDischargePower: 6000 },
	"GW8K-BT": { maxACDCPower: 8000, maxMPPTPower: 0, maxBatteryChargePower: 8000, maxBatteryDischargePower: 8000 },
	"GW10K-BT": { maxACDCPower: 10000, maxMPPTPower: 0, maxBatteryChargePower: 10000, maxBatteryDischargePower: 10000 },
 	"GW3600-EH": { maxACDCPower: 3600, maxMPPTPower: 4800, maxBatteryChargePower: 3600, maxBatteryDischargePower: 3600 },
	"GW5000-EH": { maxACDCPower: 5000, maxMPPTPower: 6650, maxBatteryChargePower: 5000, maxBatteryDischargePower: 5000 },
	"GW6000-EH": { maxACDCPower: 6000, maxMPPTPower: 8000, maxBatteryChargePower: 6000, maxBatteryDischargePower: 6000 },
	"GW3k-BH": { maxACDCPower: 3000, maxMPPTPower: 0, maxBatteryChargePower: 3000, maxBatteryDischargePower: 3300 },
	"GW3600-BH": { maxACDCPower: 3600, maxMPPTPower: 0, maxBatteryChargePower: 3600, maxBatteryDischargePower: 4000 },
	"GW5000-BH": { maxACDCPower: 5000, maxMPPTPower: 0, maxBatteryChargePower: 5000, maxBatteryDischargePower: 5500 },
	"GW6000-BH": { maxACDCPower: 6000, maxMPPTPower: 0, maxBatteryChargePower: 6000, maxBatteryDischargePower: 6600 },
	"GW3648D-ES": { maxACDCPower: 3680, maxMPPTPower: 4600, maxBatteryChargePower: 3600, maxBatteryDischargePower: 3600 },
	"GW5048D-ES": { maxACDCPower: 4600, maxMPPTPower: 6500, maxBatteryChargePower: 4600, maxBatteryDischargePower: 4600 },
	"GW5000S-BP": { maxACDCPower: 5000, maxMPPTPower: 0, maxBatteryChargePower: 4700, maxBatteryDischargePower: 5300 },
	"GW3600S-BP": { maxACDCPower: 3680, maxMPPTPower: 0, maxBatteryChargePower: 3500, maxBatteryDischargePower: 3900 },
	"GW3048-EM": { maxACDCPower: 3000, maxMPPTPower: 3900, maxBatteryChargePower: 2400, maxBatteryDischargePower: 2400 },
	"GW3648-EM": { maxACDCPower: 3680, maxMPPTPower: 4600, maxBatteryChargePower: 2400, maxBatteryDischargePower: 2400 },
	"GW5048-EM": { maxACDCPower: 5000, maxMPPTPower: 6500, maxBatteryChargePower: 2400, maxBatteryDischargePower: 2400 },
	"GW3000-ES20": { maxACDCPower: 3000, maxMPPTPower: 4500, maxBatteryChargePower: 3000, maxBatteryDischargePower: 3200 },
	"GW3600-ES20": { maxACDCPower: 3680, maxMPPTPower: 5400, maxBatteryChargePower: 3600, maxBatteryDischargePower: 3900 },
	"GW3600M-ES20": { maxACDCPower: 3680, maxMPPTPower: 5400, maxBatteryChargePower: 3000, maxBatteryDischargePower: 3200 },
	"GW5000-ES20": { maxACDCPower: 5000, maxMPPTPower: 7500, maxBatteryChargePower: 5000, maxBatteryDischargePower: 5300 },
	"GW5000M-ES20": { maxACDCPower: 5000, maxMPPTPower: 7500, maxBatteryChargePower: 3000, maxBatteryDischargePower: 3200 },
	"GW6000-ES20": { maxACDCPower: 6000, maxMPPTPower: 9000, maxBatteryChargePower: 6000, maxBatteryDischargePower: 6300 },
	"GW6000M-ES20": { maxACDCPower: 6000, maxMPPTPower: 9000, maxBatteryChargePower: 3000, maxBatteryDischargePower: 3200 },
};