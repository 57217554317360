import React from 'react';
import { Button } from '@mui/material';
import CircularProgressLoader from '@lifepowr/components/src/components-shared/circular-progress-loader-component/circular-progress-loader.component';
import './loading-or-manual-fetch.styles.scss'
/**
 * Component that returns of the following:
 * 1. A progress wheel if a loading is being preformed
 * 2. A button that allows the manual calling of further loading if no loading is preformed
 * @param {[type]} props [description]
 */
const LoadingOrManualFetch = ({ isLoading, loadMore }) => (
    <div className='loading-more'>
        {
            (isLoading && <div className='loading-more__loader'><CircularProgressLoader /></div>) ||
            <div key={"loadMoreButton"}>
                <div className='btn button p-2' onClick={() => loadMore()}>Load More</div>
            </div>
        }
    </div>
)
export default LoadingOrManualFetch