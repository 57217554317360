import React, { useState, useEffect } from 'react';
import { API } from 'Amplify';
import { connect } from 'react-redux';
import { useParams } from 'react-router';

import Chart from '@lifepowr/components/src/components-shared/chart-component/chart.component';
import customCharts from '@lifepowr/components/src/utils/graphDescs/fleet';
import FieldDropDown from '@lifepowr/components/src/components-shared/field-drop-down-component/field-drop-down.component';

import useMediaQuery from '@mui/material/useMediaQuery';

import './historical-plot-fleet.styles.scss';
import { DAY, HOUR, HALF_HOUR } from '@lifepowr/components/src/utils/chart-utils';
/**
 * Requests timeseries data from AWS
 * @param {string} fleet The fleet label 
 * @param {Array} timeRange The array with time range [timeStart, timeEnd] of the data needed
 * @returns {Object} timeseries value for Grid and HouseHold used among the fleetPage
 */
const fetchData = ({ fleet, timeRange }) => {
    const [timeStart, timeEnd] = timeRange;
    const body = { fleet, timeStart, timeEnd, table: 'inverter', db: 'sampleDB' };
    return API
        .post("IO_API", `/fleet/timeseries`, { body })
        .then((result) => {
            return result;
        })
        .catch( err => console.log("ERROR", err));
}

/**
 * Requests timeseries data from AWS
 * @param {string} title the chart title
 * @param {string} chart the chart label used to filter the data from AWS
 * @param {string} fleet The fleet label on AWS request
 */
const HistoricalPlotFleet = ({ title, chartFlag, dropDownOptions, socketData, setRequest }) => {
    const now = (new Date()).getTime() / 1000;
    const day = (60 * DAY);

    //info used as initial chart settings
    const initialPlotData = [{
        x: [new Date(now * 1000), new Date((now - day) * 1000)],
        y: [null, null],
        type: 'scatter',
        initialPlotData: true
    }];

    const [selectedOption, setSelectedOption] = useState(null);
    const [timeRange, setTimeRange] = useState(() => [now - day, now]);
    const [loading, setLoading] = useState(() => false);
    const [plotData, setPlotData] = useState(() => initialPlotData);
    const [chartData, setChartData] = useState(() => initialPlotData);
    const { fleetOption } = useParams();
    const mobileMode = !useMediaQuery('(min-width:600px)');

    const [startX, setStartX] = useState(() => ({}));
    const [endX, setEndX] = useState(() => ({}));

    useEffect(
        () => {
            if(!mobileMode && dropDownOptions){
                setSelectedOption(dropDownOptions[0]);
            }
        },
        [mobileMode, dropDownOptions]
    );

    //used to update new FleetGraph's PlotData based on the socket
    /*useEffect(() => {
        // merge current data with newData from websocket
        const updatePlotData = (currentData, newData) => {
            let updatedData = []
            if (currentData?.length && newData?.length) {
                currentData.forEach(element => {
                    const index = newData.map(e => e.name).indexOf(element.name);
                    if (index !== -1) {
                        element.x.push(newData[index].x[0])
                        element.x.shift()
                        element.y.push(newData[index].y[0])
                        element.y.shift()
                        newData.splice(index, 1);
                    }
                })
                if (newData?.length) {
                    currentData.push(newData);
                }
                updatedData.push(...currentData);
            } else {
                updatedData.push(...newData);
            }
            if (updatedData.length) {
                setPlotData(updatedData);
            }
        }

        const { calc, layouts } = customCharts[chartFlag];
        const newChartData = socketData[chartFlag];
        const toUpdate = Math.abs(timeRange[1] - (new Date()).getTime()/1000) < 900;
        if (!!newChartData && toUpdate) {
            const currentTimeSeries = (plotData[0].initialPlotData) ? [] : plotData;
            const dataPoints = calc(newChartData);
            const newTimeseries = Object.entries(layouts).map(([name, layout]) => {
                const { [name]: [x, y] } = dataPoints;
                return { x, y, ...layout };
            });
            updatePlotData(currentTimeSeries, newTimeseries);
        }
    }, [socketData, chartFlag]);*/

    /**
     *  requests new values from AWS once the timeRange is changed within the chart
     * */
    useEffect(() => {
        // Used to create the newPlotData for the chart
        const createPlotData = (requestedData) => {
            const { calc, layouts } = customCharts[chartFlag];
            const chartData = requestedData[chartFlag];
            if (!!chartData) {
                const dataPoints = calc(chartData);
                const newTimeseries = Object.entries(layouts).map(([name, layout]) => {
                    const { [name]: [x, y] } = dataPoints;
                    return { x, y, ...layout };
                });
                return newTimeseries
            }
        }

        setLoading(true);
        fetchData({ fleet: fleetOption, timeRange })
            .then(data => setPlotData(createPlotData(data)))
            .catch(err => console.error("ERROR", err))
            .finally(() => setLoading(false));
    }, [fleetOption, timeRange, chartFlag]);

    useEffect(() => {
        if(selectedOption){
            const newPlotValue = plotData.filter(item => item.name === selectedOption.value);

            setChartData(newPlotValue)
        }
    }, [selectedOption, plotData])

    useEffect(() => {
        if (chartData?.length) {
            const [starTime, endTime] = timeRange;
            /*const timestampList = chartData[0].x
            setStartX(timestampList[0])
            const endDate = timestampList[timestampList.length - 1]
            setEndX(endDate)*/
            setStartX(new Date(starTime * 1000));
            setEndX(new Date(endTime * 1000));
        }
    }, [chartData, timeRange])

    const chartProps = {
        ...customCharts[chartFlag],
        timeRange: timeRange,
        setTimeRange,
        loading,
        plotData: chartData,
        globalLayout: { xaxis: { range: [startX, endX] } },
    }

    return (
        <div className='historical-plot-fleet'>
            <div className='historical-plot-fleet__header'>
                <h1 className='pt-4 mr-auto'>{title}</h1>
                <FieldDropDown
                    key={`fleet${title}-fieldDropDownInner`}
                    options={dropDownOptions}
                    placeholder="Select an option"
                    value={selectedOption}
                    onOptionChange={(e) => setSelectedOption(e)}
                />
            </div>
            <div className='historical-plot-fleet__graph'>
                {selectedOption ? <Chart {...chartProps} /> : null }
            </div>
        </div>
    )
}

const stateToProps = (state) => {
    const { systems: { fleetRt } = {} } = state || {};
    return { fleetRt };
}

export default connect(stateToProps)(HistoricalPlotFleet);
