import React, { useState, useEffect } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom'
// import { green, red } from '@mui/material/colors';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import IconButton from '@mui/material/IconButton';
// import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import './device-page.styles.scss';

// import BreadCrumbs from './components/bread-crumbs-component/bread-crumbs.component';
import HouseComposition from './components/house-composition-component/house-composition.component';
import DeviceGeneralStatus from './components/device-general-status-component/device-general-status.component';
// import DeviceBatteryStatus from './components/device-battery-status-component/device-battery-status.component';
import DeviceIOSettings from './components/device-io-settings-component/device-io-settings.component';
import DeviceDetails from './components/device-details-component/device-details.component';
import DeviceDiagnostics from './components/device-diagnostics-component';
import DeviceNavMenuOptions from './components/device-nav-menu-option-component/device-nav-menu-option.component';
// import ConsoleRender from './components/console-render-component/console-render.component';
import Tools from './components/tools';
import WifiIcon from '@lifepowr/components/src/assets/icons/device-house-wifi.png';
import NoWifiIcon from '@lifepowr/components/src/assets/icons/device-house-no-wifi.png';

import validator from '@rjsf/validator-ajv8';
import Form from '@rjsf/mui';

import EanPic from '@lifepowr/components/src/assets/images/ean.png';
import balancingSchema from './components/device-details-component/balancingSchema';
// import { useElementSize } from 'usehooks-ts';

/**
 * used to handle the useSocket store
 */
import useSocket from '@lifepowr/components/src/components/socket/useSocket';
import * as systemCreators from "@lifepowr/components/src/store/system";
import { connect } from 'react-redux';
import { API, Auth } from 'Amplify';

import getClientInfo from "../../clients";

const { default: df, ...systemActionCreators } = systemCreators;

/**
 * Retuned components according to menu selection
 * @param {*} param0
 * @returns
 */
const DeviceBody = ({ value, deviceId, location, userMode, admin }) => {
    switch (value) {
        //case 1: return <DeviceBatteryStatus userMode={userMode} deviceId={deviceId} location={location} />
        case 2: return ( userMode ? null : <DeviceIOSettings deviceId={deviceId} /> )
        case 3: return <DeviceDetails userMode={userMode} admin={admin} deviceId={deviceId}/>
        case 4: return ( userMode ? null : <DeviceDiagnostics admin={admin} userMode={userMode}  deviceId={deviceId}/> )
        default: return <DeviceGeneralStatus userMode={userMode} deviceId={deviceId} admin={admin}/>
    }
}

const createShadowPrefix = (deviceName, shadowName) => {
    const response = `$aws/things/${deviceName}/shadow`
    if (shadowName)
        return response + `/name/${shadowName}`
    return response
}

/**
 * This component renders the Device Details page and coordinates the presentation of other components.
 * @param {*} props
 * @returns
 */
const DeviceContent = ({ setSystem, setSystemShadow, setSystemRtData, setSystemConnected, connected, code, system, location, userMode, diagnostics = {}, balancing, ean, adminStore }) => {
    const { attributes: { name : systemName = "Unnamed system" } = {} } = system || {};
    const { admin: adminRole } = adminStore;
    const { deviceName: thingName } = useParams();
    const { subscribe, publish, unsubscribe } = useSocket();
    const { ean: eanBalancing } = balancing || {};

    const { pathName, batteryPathName } = useParams();

    const [admin, setAdmin] = useState(adminRole);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingName, setLoadingName] = useState(false);
    const [editName, setEditName] = useState(false);
    const [currentName, setCurrentName] = useState(systemName);
    const [errorName, setErrorName] = useState('');
    const [updateAttrs, setUpdateAtrrs] = useState(null);
    const [thingStatus, setThingStatus] = useState(true);
    const [eanFormData, setEanFormData] = useState({ean: eanBalancing});
    const [eanOpen, setEanOpen] = useState(false);
    const [eanLoading, setEanLoading] = useState(false);
    const [eanError, setEanError] = useState(null);

    const { search } = useLocation();


    useEffect(
        () => {
            let clientInfo = getClientInfo();

            const showIbanFederated = clientInfo ? clientInfo?.iban : true;
            setEanOpen((!eanBalancing) && showIbanFederated);
        },
        [balancing?.ean, eanBalancing, balancing]
    );

    /** MAIN menu options */
    const variable = [
        { id: 0, uri: 'general-status', name: 'General Status' },
        { id: 3, uri: 'device-details', name: 'Device Details' }
    ];

    if(!userMode){
        variable.splice(1, 0, { id: 2, uri: 'io-settings', name: 'IO Settings' });
        variable.splice(2, 0, { id: 4, uri: 'io-diagnostics', name: 'IO Diagnostics' , warning: !thingStatus });
        //variable.splice(3, 0, { id: 1, uri: 'battery-status', name: 'Battery Status' });
    }

    const [active, setActive] = useState(() => (variable.find(item => item.uri.includes(pathName)) || {}).id);

    useEffect(
        () => {
            if(diagnostics){
                const result = Object.entries(diagnostics || {}).reduce(
                    (acc, [key, value]) => {
                        const { status } = value || {};

                        return status !== undefined ? status && acc  : acc;
                    },
                    true,
                )
                setThingStatus(result);
            }
        },
        [diagnostics]
    )

    useEffect(
        () => {
            if (!window.document.title.includes(systemName) && systemName !== 'Unnamed system') {
                window.document.title = `${window.document.title} ${systemName}`;
            }
        },
        [systemName],
    );

    /**
     * used to update socket data
     */
    useEffect(() => {
        const dataTopic = `fleet/devices/${thingName}/data`;
        const updateTopic = `$aws/events/thing/${thingName}/updated`;

        /**
         * function to subscribe information associated with
         * a shadow '@param {string} name' reported on the current device
         */
        const hookShadowOnSocket = ([name, shadow]) => {
            const shadowPrefix = createShadowPrefix(thingName, name);

            subscribe(`${shadowPrefix}/update/accepted`, ({ value: { state } }) => {
                const { desired, reported } = state || {};
                setSystemShadow({ desired, reported }, name);
            });

            if(name){
                setSystemShadow(shadow, name);
            }
        }

        /**
         * function to subscribe information associated with
         * a shadow '@param {string} name' reported on the current device
         */
        const unHookShadowOnSocket = ([name, shadow]) => {
            const shadowPrefix = createShadowPrefix(thingName, name);

            unsubscribe(`${shadowPrefix}/update/accepted`);
        }

        API.get("IO_API", `/devices/${thingName}`)
            .then( async (thing) => {
                    try{
                        let shadows;

                        if(thing){
                            const { connectivity: { connected } = {} } = thing;
                            setSystem(thing);
                            setSystemConnected(connected);
                        }
                        shadows = await publish(`fleet/devices/${thingName}/state/get`, {}, true);

                        Object.entries(shadows).forEach(hookShadowOnSocket);
                        subscribe(dataTopic, ({ value }) => {
                            /* const {TotalInvPower, ACFrequency, temperature, PV1Power, PV2Power, batteryVoltageInv, batteryCurrentInv, stateOfCharge, MeterPower, backupPower, loadPower, stateOfHealth, inverterPVRetro, monthPeak, MeterPowerFiltered, MeterPowerPosPartFiltered, MeterPowerNegPartFiltered, TotalInvPowerFiltered, LoadPowerFiltered, EnergyLossFiltered, ...rest} =  value.inverter || {}
                            console.log(rest, new Date()); */
                            setSystemRtData(value || {})
                        });
                        subscribe(updateTopic, ({value}) => {
                            const { attributes } = value;
                            if(attributes) setUpdateAtrrs(attributes);
                        });
                        subscribe(`$aws/events/presence/connected/${thingName}`, (wut) => {
                            setSystemConnected(true);
                        });
                        subscribe(`$aws/events/presence/disconnected/${thingName}`, (wut) => {
                            setSystemConnected(false);
                        });
                    }
                    catch(e){
                        setError(`${e}`);
                    }
                    setLoading(false);
            })
            .catch(e => {
                const { response: { data : { message } = {} } = {} } = e;
                setError(message);
                setLoading(false);
            });

        return async () => {
            const shadows = await publish(`fleet/devices/${thingName}/state/get`, {}, true);

            unsubscribe(dataTopic);
            Object.entries(shadows).forEach(unHookShadowOnSocket);
        }
    }, [setSystem, setSystemConnected, setSystemRtData, setSystemShadow, thingName, publish, subscribe]);

    useEffect( () => {
        if(system && updateAttrs){
            const newSystem = Object.assign({}, system, {attributes: updateAttrs});
            setUpdateAtrrs(null);
            setSystem(newSystem);
        }
    }, [updateAttrs, system, setSystem]);

    /* useEffect( () => {
        const { user: { attributes: { "custom:role": userRole } = {} } = {} } = Auth;

        if(userRole === 'Admin') setAdmin(true);
    }, []); */

    useEffect( () => {
        setCurrentName(systemName.split("_").join(" "));
    }, [systemName]);

    const submitName = async () => {
        setLoadingName(true);
        setErrorName('');
        try{
            const body = {name: currentName}
            await API.post("IO_API", `/devices/${thingName}/name`, { body });
            setEditName(false);
        }
        catch(e){
            setErrorName(`${e}`);
        }
        setLoadingName(false);
    }

    const nameButtonDisable = currentName === systemName.split("_").join(" ") || !currentName || loadingName;

    async function onFormSubmit(ev){
        setEanLoading(true);
        setEanError(null);
        try {
            const { formData: body } = ev;
            const res = await API.post("IO_API", `/devices/${thingName}/balancing`, { body });
            console.log(res);
        } catch (e) {
            const { response: { data } = {} } = e;
            setEanError(data || e);
        }
        setEanLoading(false);
    }

    function onFormChange(ev){
        const { formData } = ev;
        setEanFormData(formData);
    }

    if(loading) return ( <div>Loading . . .</div> );
    if(Object.keys(system) < 1) return <div>Could not find this device!</div>


    return <>
        <Dialog open={Boolean(editName)} onClose={() => setEditName(false)}>
            { errorName ? <div className="requestError">Error: {errorName}</div> : null}
            <div>
                <TextField label="Device Name" value={currentName} onChange={ (ev) => {setCurrentName(ev.target.value)}}/>
                <IconButton disabled={nameButtonDisable} onClick={submitName} size="large">{loadingName ? <CircularProgress/> : <CheckIcon/>}</IconButton>
            </div>
        </Dialog>
        <Dialog
            open={userMode && eanOpen}
        >
            <DialogTitle>To join the revolution, provide your EAN</DialogTitle>
            <DialogContent>
                <div>So your FlexBox can participate in balancing markets, we require your meter EAN. You can find it:</div>
                <img src={EanPic} alt="Logo" className="eanPic"/>
                <ol>
                    <li>On all invoices from your energy supplier</li>
                    <li>On the quote for a new connection</li>
                    <li>On the sticker on your meter box</li>
                </ol>
                <Form
                    schema={balancingSchema}
                    validator={validator}
                    onSubmit={onFormSubmit}
                    onChange={onFormChange}
                    formData={eanFormData}
                    extraErrors={eanError ? { Error: { '__errors': [eanError] } } : {}}
                    disabled={eanLoading}
                >
                    <Stack spacing={2} direction="row">
                        <Button disabled={eanLoading} type="submit" variant="contained">{ eanLoading ? <CircularProgress /> : 'Submit' }</Button>
                    </Stack>
                </Form>
            </DialogContent>
        </Dialog>
        {error ? <div>Error: {error}</div> : null }
        <div className='device-page'>
            <div className='device-page__left'>
                <div className='composition_wrapper'>
                    <Typography variant="h5" className='device-page__title'>
                        <img src={connected ? WifiIcon : NoWifiIcon} alt='connectivity-icon' />
                        { ` ${systemName.split("_").join(" ")}` }
                        <IconButton onClick={() => {setEditName(true)}} size="large"><EditIcon /></IconButton>
                    </Typography>
                    <HouseComposition />
                </div>
                <div className={`device-page__console ${!connected ? 'device-page__console__notSelected' : ''}`}>
                    { !userMode ? <Tools admin={admin}/> : null }
                </div>
            </div>
            <div className='device-page__right'>
                <div className='device-nav'>
                    <div className='device-nav-menu'>
                        {variable?.map(({ name, id, uri, warning }, index) => (
                            <div key={`device-nav-menu_${index}`}>
                                <Link to={batteryPathName ? `../${uri}${search}` : `./${uri}${search} `}>
                                    <DeviceNavMenuOptions {...{ id, name, warning }} isActive={active === id} handleClick={() => setActive(id)} showUnderline />
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='device-body'>
                    <DeviceBody userMode={userMode} value={active} location={location} admin={admin} deviceId={thingName} />
                </div>
            </div>
        </div>
    </>;
}

const mapStateToProp = (state) => {
    const adminStore = state?.admin;
    const system = state?.system || {};
    const { connected, code, shadow } = system;
    const { reported, desired } = shadow || {};
    const { diagnostics } = reported || {};
    const { balancing, ean } = desired || {};
    return { connected, system, code, diagnostics, adminStore, balancing, ean };
}

export default connect(mapStateToProp, { ...systemActionCreators })(DeviceContent);
