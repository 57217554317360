import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';

export default () => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    useEffect(
        ()=>{
            const fetch = async () => {
                console.log("LOADING USER");
                setLoading(true);
                try {
                    const user = await Auth.currentAuthenticatedUser();
                    setUser(user);
                } catch (e) {
                    setError(e);
                    setUser(null);
                }
                console.log("LOADED USER");
                setLoading(false);
            };
            fetch();
            return () => {};
        },
        []
    );

    return [user, loading, error];
}
