import React, { useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import NavBarOption from "../nav-bar-option/nav-bar-option.component";
import FleetWhite from "@lifepowr/components/src/assets/icons/icon-fleet-white.png";
import FleetYellow from "@lifepowr/components/src/assets/icons/icon-fleet-yellow.png";
import DevicesWhite from "@lifepowr/components/src/assets/icons/icon-devices-white.png";
import DevicesYellow from "@lifepowr/components/src/assets/icons/icon-devices-yellow.png";
import { getFleetUrl, getPathName } from "@lifepowr/components/src/utils/url";

import './nav-bar.styles.scss';
/**
 * Class handles rendering of NAV BAR.
 * Updates state to display active URL by toggeling image displayed.
 * Upon refresh reads current url from window and when constructing defines active navoption.
 * Links to clicked location.
 * State update is performed on callback
 */
const NavBar = (props) => {
    const { userMode } = props;
    const { fleetOption } = useParams();
    const { search } = useLocation();

    /**
    * Menu options to be displayed
    */
    const items = [
        {
            id: 0,
            uri: `/${fleetOption}`,
            name: 'Fleet Overview',
            Image: FleetWhite,
            ImageActive: FleetYellow
        },
        {
            id: 1,
            uri: `/${fleetOption}/devices`,
            name: 'Devices',
            Image: DevicesWhite,
            ImageActive: DevicesYellow
        }
    ]

    if( userMode ){
        items.splice(0, 1);
        items[0].uri = "/devices";
    }

    const [active, setActive] = useState(() => getPathName().includes('/devices') ? 1 : 0)

    const onClick = (id) => {
        const fleetExists = Boolean(getFleetUrl());
        if (fleetExists) setActive(id)
    }

    return (
        <div className='nav-bar'>
            <div className="container">
                <div className="row nav-bar__wrapper">
                    <div className='nav-bar__wrapper__options col-12  col-md-6 col-lg-9'>
                        {items?.map(({ id, uri, name, Image, ImageActive }, index) => (
                            <div key={`nav-bar-wrapper-option__${index}`} className='nav-bar__wrapper__option'>
                                <Link className='nav-bar-wrapper-option-link' to={`${uri}${search}`} replace>
                                    <NavBarOption
                                        name={name}
                                        Image={active === id ? ImageActive : Image}
                                        onClick={() => { onClick(id) }}
                                        isActive={active === id}
                                    />
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NavBar;