/**
 * This module fetches all individual reducers and creates a single store for use with the application
 */
import { createStore, combineReducers } from 'redux';
//import AmplifyReducer from "Amplify.js";
import SystemsReducer from "./systems.js";
import ChargersReducer from "./chargers.js";
import SystemReducer from "./system.js";
import MatchReducer from "./match.js";
import GlobalReducer from "./global.js";
import AdminReducer from "./admin.js";

const persisted = JSON.parse(localStorage.getItem('lifepowr.myio.global')) || undefined;

const rootReducer = combineReducers({
	//Amplify: AmplifyReducer,
	systems: SystemsReducer,
	system: SystemReducer,
	match: MatchReducer,
	global: GlobalReducer,
	chargers: ChargersReducer,
	admin: AdminReducer,
});

const store = createStore(rootReducer, {global: persisted});

store.subscribe( () => {
	const { global: globalSettings }= store.getState();

	localStorage.setItem('lifepowr.myio.global', JSON.stringify(globalSettings));
});

export default store;