import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';

import { useElementSize } from 'usehooks-ts';
import DotAnimation from '@lifepowr/components/src/components-shared/dot-loading-animation/dot-loading-animation.component';

import EvComponent from '@lifepowr/components/src/modules/device-module/components/house-composition-component/components/ev-component';
import HouseCompositionStatusElement from './components/house-composition-status-element-component/house-composition-status-element.component';
import HouseSun from '@lifepowr/components/src/assets/icons/device-house-status-sun.png';
import HousePower from '@lifepowr/components/src/assets/icons/device-house-status-power.png';
import HouseCar from '@lifepowr/components/src/assets/icons/device-house-status-car.png';
import HouseBattery from '@lifepowr/components/src/assets/icons/device-house-status-battery.png';
import CompositionCircles from '@lifepowr/components/src/assets/images/image-device-composition-circles.png';
import CompositionHouse from '@lifepowr/components/src/assets/images/image-device-composition-house.png';
import './house-composition.styles.scss'

import { API } from 'Amplify';
import zIndex from '@mui/material/styles/zIndex';


/**
 * Accumulator function that calculates the charge of a list of batteries returned by the protocol
 * @param  {Array} acc   Accumulator value containing the average value and count of valid entries
 * @param  {string} id   Unique id of the battery
 * @param  {Object} obj  Object that describes the battery
 * @return {Array}       Returns the updated accumulator
 */
function calculateCharge(acc, [id, obj]) {

    var { stateOfCharge: SoC } = obj;
    var cellCharge = SoC || 0;

    if (!isNaN(SoC)) {
        if (!acc) acc = [0, 0];
        var [avg, count] = acc;
        count = count + 1;
        avg = count > 0 ? avg + (cellCharge - avg) / count : cellCharge;
        return [Math.round(avg), count];
    }

    return acc;
}

/**
 * FUnctional component responsible for rendering the house animation in the device page. 
 * Performs a series of calculations and keeps displaying the statistics for the device
 * @param {*} props -> Series of info related to device statistics.
 * @returns 
 */
const HouseComposition = (props) => {
    const {// ACFrequency,
        batteryVoltageInv, batteryVoltageInvFiltered, batteryCurrentInv, batteryCurrentInvFiltered,
        PV1PowerFiltered, PV2PowerFiltered, MeterPower, MeterPowerFiltered, totalEVPower,
        LoadPowerFiltered, LoadPower, BackupVoltage, BackupCurrent, gridPower, socFiltered, PVRetrofit1PowerFiltered, PVRetrofit2PowerFiltered, totalPVPowerFiltered
    } = props?.system?.rtData?.inverter || {};
    const { thingName } = props?.system || {};
    const { connected, code = {}, realTimeData } = props?.system?.shadow?.reported;
    const { version, buildTime } = code;

    //State
    const [DialogComponent, setDialogComponent] = useState(null);
    const [realTime, setRealTime] = useState(realTimeData);
    const [realTimeError, setRealTimeError] = useState('');

    //TestComp
    function Test(){
        return <div>HELOO, I AM BODY</div>
    }

    useEffect(
        () => {
            async function setRealTimeData() {
                try{
                    if (!realTimeData && !realTime) {
                        const res = await API.post("IO_API", `/devices/${thingName}/realTime`, {});
                        setRealTime(true);
                    }
                } catch(e) {
                    console.log("ERROR REAL TIME", e);
                    setRealTime(false);
                    setRealTimeError(`${e}`);
                }
            }
            setRealTimeData();
        },
        [realTimeData, realTime]
    )

    //Calculations 
    const residenceP = props.system?.rtData ? Math.round( ((!isNaN(LoadPowerFiltered) ? LoadPowerFiltered : LoadPower) )) : 'N/A'
    const pvP = props.system?.rtData ? Math.round(totalPVPowerFiltered || 0) : 'N/A';
    const gridP = props.system?.rtData ? Math.round((MeterPowerFiltered ? MeterPowerFiltered : MeterPower)) : 'N/A';
    const batteryP = props.system?.rtData ? Math.round(( (batteryVoltageInvFiltered ? batteryVoltageInvFiltered : batteryVoltageInv) * (batteryCurrentInvFiltered ? batteryCurrentInvFiltered : batteryCurrentInv))) : 'N/A';
    const evP = props.system?.rtData ? Math.round(totalEVPower) : 'N/A'

    //battery change calculation using accumulated function on top
    const batteryChargeCalculation = Object.entries((props?.system?.rtData?.batteries || {})).reduce(calculateCharge, undefined);
    const batteryCharge = socFiltered ? Math.round(socFiltered * 10 ) / 10 : 0; //!!batteryChargeCalculation ? batteryChargeCalculation[0] : 0;
    const evCharge = 100; //TODO

    //Object to render the whole animation. only necessary to update this object. data will be used to pass to child component which renders each circle graph
    var renderingInfo = {
        residence: {
            name: 'Home',
            value: 100,
            label: isNaN(residenceP) ? 'N/A' : Math.round(residenceP),
            max: 100,
            graphColor: 'black',
            graphColorII: 'gray',
            cutout: 47
        },
        pv: {
            name: 'Solar',
            value: 100,
            label: isNaN(pvP) ? 'N/A' : Math.round(pvP),
            max: 100,
            iconsrc: HouseSun,
            graphColor: '#f9bc60',
            graphColorII: '#f9deb5',
            animationDirection: -1 * Math.sign(pvP),
            cutout: 41
        },
        grid: {
            name: 'Grid',
            value: 100,
            label: gridP,
            max: 100,
            iconsrc: HousePower,
            graphColor: '#e16161',
            graphColorII: '#ffbdbd',
            animationDirection: Math.sign(gridP),
            cutout: 40
        },
        ev: {
            name: 'EV',
            value: 100, //TODO
            label: evP,
            max: 100,
            iconsrc: HouseCar,
            graphColor: '#7c9e63',
            graphColorII: '#d3e2c8',
            animationDirection: -1 * Math.sign(evP),
            onClick: () => setDialogComponent(EvComponent),
            cutout: 40
        },
        battery: {
            name: 'Battery',
            value: batteryCharge,    //GRAPH VALUE FOR PERCENT CALCULATION
            label: isNaN(batteryP) ? 'N/A' : Math.round(batteryP), //POWER VALUES 
            max: 100,
            iconsrc: HouseBattery,
            graphColor: '#4c9292',
            graphColorII: '#cee2e2',
            showWifi: true,
            animationDirection: -1 * Math.sign(batteryP),
            charge: batteryCharge,
            cutout: 41
        },
    }

    let width = 'xs';
    let buildETA = buildTime ? new Date(buildTime + 1000 * 60 * 60 * 24 * 30) : null;
    let buildText = version && buildTime ? `v${version}` : '';


    const [squareRef, { width: elWidth, height }] = useElementSize();
    const [houseRef, { width: houseWidth, houseHeight }] = useElementSize();
    let wrapperCss = {};
    let buttonCss = {
        position: 'absolute',
        zIndex: 100000,
        width: '100%',
        height:'100%'
    };
    if (!realTimeData && realTime) wrapperCss = {
        "opacity": "0.5",
        "filter": "grayscale(1)"
    }

    if(useMediaQuery('(min-width:375)')) width = 'sm';
    if(useMediaQuery('(min-width:750px)')) width = 'md';
    if(useMediaQuery('(min-width:1125px)')) width = 'md2';
    if(useMediaQuery('(min-width:1500px)')) width = 'lg';
    if(useMediaQuery('(min-width:2250px)')) width = 'xl';

    //<img className='image' src={CompositionCircles} alt='circles background' />

    // { (!realTimeData && realTime) ? <IconButton onClick={()=>setRealTime(false)} aria-label="restart"><AutorenewIcon /></IconButton> : null}

    return (
        <>
            <div className='house-composition d-block'>
                { realTimeError ? <Alert severity="error">{realTimeError}</Alert> : null}
                <div className='house-composition-wrapper row' style={wrapperCss}>
                    { (!realTimeData && realTime) ? <Button onClick={()=>setRealTime(false)} style={buttonCss}></Button> : null }
                    <div className='house-composition__house-wrapper col-6'>
                        <div className='house-composition__house-circle'>
                            <img className='house-composition-house__circles position' src={CompositionCircles} alt='circles background' />
                            <img className='house-composition-house__house position' src={CompositionHouse} alt='house' />
                        </div>
                        <div className='house-composition__status'>
                            <span className='house-composition__residence'>
                                <HouseCompositionStatusElement
                                    name={renderingInfo['residence'].name}
                                    color={renderingInfo['residence'].graphColor}
                                    colorII={renderingInfo['residence'].graphColorII}
                                    label={renderingInfo['residence'].label}
                                    value={renderingInfo['residence'].value}
                                    max={renderingInfo['residence'].max}
                                    cutout={renderingInfo['residence'].cutout}/>

                            </span>
                        </div>
                    </div>
                    <div className='house-composition__status-wrapper col-6'>
                        {Object.entries(renderingInfo).reduce((result, [k, v], index) => {
                            if (index > 0) { //Skips residence
                                const { onClick } = v;
                                result.push(
                                    <div key={`house-composition__${index}`} className={`house-composition__${k}`}>
                                        <div className={`house-composition__${k}__ellipsis`}>
                                            <div>
                                                <DotAnimation
                                                    color={renderingInfo[k].graphColor}
                                                    direction={renderingInfo[k].animationDirection}
                                                    dotNumber={(window.innerWidth > 600 && window.innerWidth < 996) || window.innerWidth > 1200 ? 5 : 4} />
                                            </div>
                                        </div>
                                        <div onClick={onClick || (() => {})} className={`house-composition__${k}__element house-composition__button`}>
                                            <HouseCompositionStatusElement
                                                name={v.name}
                                                iconsrc={v.iconsrc}
                                                color={v.graphColor}
                                                colorII={v.graphColorII}
                                                label={v.label}
                                                value={v.value}
                                                max={v.max}
                                                clickable={Boolean(onClick)}
                                                showWifi={v.showWifi}
                                                cutout={v.cutout}
                                                charge={v.charge}
                                                />
                                        </div>
                                       
                                    </div>
                                )
                            }
                            return result;
                        }, [])}
                    </div>
                </div>
            </div>
            { buildText ? <div className="house-composition-ems-version">{buildText}</div> : null }
            { DialogComponent ? 
                <Dialog fullWidth maxWidth={width} open={Boolean(DialogComponent)} onClose={() => {setDialogComponent(null)}}>
                    <DialogComponent/>
                </Dialog> : null 
            }
        </>
    )
}

export default connect((state) => ({ system: state.system }), null, null)(HouseComposition);