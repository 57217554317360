import { Box, Typography } from "@mui/material";
import { isReadyToSave } from "../shared";
import LoadingButton from "@mui/lab/LoadingButton";

export default function SummaryStep({ data, state, error, loading, onSubmit }) {
  const readyToSave = isReadyToSave(data, state);
  return (
    <Box
      height={400}
      display="grid"
      sx={{ placeContent: "center" }}
      gap="10px"
      textAlign="center"
    >
      {error && (
        <Box display="grid" gap={4}>
          <Box>
            <Typography variant="h4" paddingBottom="10px">
              Issues saving data
            </Typography>
            <Typography variant="body" fontWeight={500}>
              It seems we ran into an issue saving some of all of your changes,
              error:
              {error}
            </Typography>
          </Box>
        </Box>
      )}

      {readyToSave && !error && (
        <Box display="grid" gap={4}>
          <Box>
            <Typography variant="h4" paddingBottom="10px">
              All set 🚀{" "}
            </Typography>
            <Typography variant="body" fontWeight={500}>
              Save the configuration if ready or go back and change.
              <br />
              Click close for leaving without saving.{" "}
            </Typography>
          </Box>
          <LoadingButton
            variant="contained"
            loading={loading}
            onClick={onSubmit}
          >
            Save
          </LoadingButton>
        </Box>
      )}

      {!readyToSave && (
        <Box display="grid" gap={4}>
          <Box>
            <Typography variant="h4" paddingBottom="10px">
              Attention ❗️{" "}
            </Typography>
            <Typography variant="body" fontWeight={500}>
              Please, review the steps with errors before saving.
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
}
