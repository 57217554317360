import { API } from 'Amplify';
import { union } from 'lodash';

export const CUSTOM = 'custom:';

/**
 * @param {string} thingName 
 * @param {string} table 
 * @param {*} dimensions 
 * @param {Array<number>} timeRange 
 * @param {Array<string>} measures 
 * @returns result requested
 */
export const fetchDeviceData = (thingName, table, dimensions, timeRange, measures) => {
    let aggregate
    const [timeStart, timeEnd] = timeRange;
    const interval = (timeEnd - timeStart); // secs

    // Converting to minutes that ensure the number of poins is guaranteed
    /*if (interval > totalPoints * granularity) { // To be accurate, should divide by granularity
        preAgg = Math.ceil(interval * granularity / 60 / totalPoints);
        aggregate = `${preAgg}m`;
    }*/

    if( interval < 900) aggregate = '1m';
    else if( interval < 60 * 60 * 24 * 1.1 ) aggregate = `1m`;
    else if( interval < 60 * 60 * 24 * 7.1 ) aggregate = `15m`;
    else if( interval < 60 * 60 * 24 * 30.1 ) aggregate = `1h`;
    else if( interval < 60 * 60 * 24 * 365 ) aggregate = `1d`;
    else aggregate = `1w`;

    const body = { timeStart, timeEnd, table, measures, db: 'sampleDB', aggregate: table === 'savings' ? undefined : aggregate, dimensions, optimal: true };
    return API
        .post("IO_API", `/devices/${thingName}/timestream`, { body })
        .then((res) => {
            if(Object.keys(body).length < 1) return null;
            return res;
        })
        .catch(err => console.log(err));
};

const comparableByTime = (a, b) => {
    if (a.time < b.time) return -1;
    if (a.time > b.time) return 1;
    return 0;
};

export const sortData = (requestedData) => {
    const dataObj = Object.entries(requestedData || {}).reduce((acc, [measureName, measureArray]) => {
        const timeOrdered = measureArray.sort(comparableByTime);
        acc[measureName] = timeOrdered;
        //acc.timestamps = union(acc.timestamps, timeOrdered.map(obj => `${obj.time.split(" ").join("T").slice(0, -6)}Z`));
        acc.timestamps = union(acc.timestamps, timeOrdered.map(obj => obj.time));
        return acc;
    }, { timestamps: [] });
    return dataObj;
};