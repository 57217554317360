import React from "react";
import { styled } from '@mui/material/styles';

import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import Typography from '@mui/material/Typography';

import UnitDisplay from "../unit-display/unit-display.component";
import './one-line-statistics.styles.scss'

import { ReactComponent as ArrowUp } from '@lifepowr/components/src/assets/icons/icon-arrow-up.svg';
import { ReactComponent as ArrowDown } from '@lifepowr/components/src/assets/icons/icon-arrow-down.svg';

const TooltipWithContent = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'transparent',
      padding: '0',
    },
  }));
  
/**
 * @param {name} string with the name to be displayed as the title of this statistic
 * @param {iconUrl} string with the url for the image that accompanies this component
 * @param {value} string with the statistic value
 * @param {unit} string with the unit for the value
 * @param {carbon} boolean indicating whether this is to add an extra 'co' to the statistic
 * @param {percentage} string with the the percentage up or down
 * @param {direction} boolean indicating the direction - 0 down 1 up
 * @param {style} boolen indiccating whether this is to be displayed in lighter or darker colors since the same component is rendered in the green and batery status components
 * @returns One line statistics with a specific style and up and down arrows indicating the fluctuation of the values.
 */
const OneLineStatistics = ({name, Icon, value, unit, carbon, percentage, direction, style, info}) => {
    return (
        <div className={`d-flex flex-row one-line-statistics`}>
            <span className={`${style===`dark` ? 'mr-3' : ''} one-line-statistics__icon`} >
                <img src={Icon} alt='decorative'/>
            </span>
            <div className='flex-column'>
                <span className={`${style===`dark` ? `dark` : ''} one-line-statistics__name`}>{name}</span> <br/>
                <div className='pl-1 flex-row'>
                    <React.Fragment>
                        <TooltipWithContent
                            title={info}
                        >
                            <span className={`${style===`dark` ? `dark` : ''} pr-1 one-line-statistics__value-unit`}>
                                {value}
                                <UnitDisplay unit={unit} carbon={carbon}/>
                            </span>
                        </TooltipWithContent>
                    </React.Fragment>
                </div>
            </div>
        </div>
    );
}
                        /* <span className={`flex-row
                                ${style!==`dark` && direction ? 'one-line-statistics__percentage-direction-yellow' :
                                style!==`dark` && !direction ? 'one-line-statistics__percentage-direction-blue' :
                                style===`dark` && direction > 0 ? 'one-line-statistics__percentage-direction-green' : 'one-line-statistics__percentage-direction-red' }`}>
                            <span>{direction > 0 ? '+' : '-' }</span>
                            <span>{percentage}%</span>
                            <span className='one-line-statistics__percentage-direction-arrow'>{ direction > 0 ? <ArrowUp/> : <ArrowDown/>}</span>
                        </span> */

export default OneLineStatistics;

