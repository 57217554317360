import { Box } from "@mui/material";
import { isInvertorHybrid } from "../../device-details-component/device-details-utils";
import StepComponent from "./step.component";
import { STEP_IDS } from "../config-wizard-steps";
import StepContainer from "./step-container.component";
import { useMediaQuery, useTheme } from "@mui/material";

export default function PVPanelsStep({ stepProps, ...props }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const { formData, schema, uiSchema } = stepProps;
  const pvArraysProps = {
    ...stepProps,
    formData: { pvArrays: formData.pvArrays },
    schema: schema.pvArrays,
    uiSchema: uiSchema.pvArrays,
    liveValidate: !!props.state?.get(props?.stepId)?.pvArrays,
    skipContainer: true,
  };
  const pvArraysRetrofitProps = {
    ...stepProps,
    formData: { pvArraysRetrofit: formData.pvArraysRetrofit },
    schema: schema.pvArraysRetrofit,
    uiSchema: uiSchema.pvArraysRetrofit,
    liveValidate: !!props.state?.get(stepProps?.stepId)?.pvArraysRetrofit,
    skipContainer: true,
  };

  const inverter =
    props.state.get(STEP_IDS.CONVERTER) || props[STEP_IDS.CONVERTER];
  const showPvArrays = isInvertorHybrid(inverter);
  return (
    <StepContainer sx={{ px: fullScreen ? 0 : 4 }}>
      {showPvArrays ? (
        <Box>
          <StepComponent
            partialKey="pvArrays"
            {...props}
            stepProps={pvArraysProps}
          />
        </Box>
      ) : null}
      <Box>
        <StepComponent
          partialKey="pvArraysRetrofit"
          {...props}
          stepProps={pvArraysRetrofitProps}
        />
      </Box>
    </StepContainer>
  );
}
