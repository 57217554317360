import React, { useState, useEffect, useRef } from 'react';
import validator from '@rjsf/validator-ajv8';
import Form from "@rjsf/core";
import { merge } from 'lodash';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';

import './device-io-settings-form.styles.scss';

//import Form from '@rjsf/bootstrap-4';

/**
 * Form component, receives a set of objects and values and a template
 * creates a form where the user can alter the values before submitting them using a provided function
 * @param {Object} props The component's properties
 */
function DeviceIOSettingsForm(props){
    const {checkedCheckbox, schema = {}, formData = {}, onSubmit: userSubmit = () => undefined} = props;
    const [innerData, setInnerData] = useState({});
    const [noSubmit, setNoSubmit] = useState(null);
    const [editing, setEditing] = useState(false);
    const [uiSchema, setUiSchema] = useState({});
    const formRef = useRef(null);

    useEffect( () => {
        if(!editing) setInnerData(formData);
    }, [editing, formData]);

    useEffect(
        () => {
            const { properties = {} } = schema || {};
            const order = [];

            Object.entries(properties).forEach( 
                ([property, value]) => {
                    const {type = ''} = value;
                    if(type === 'boolean') order.push(property);
                },
            );
            order.push('*');

            setUiSchema(
                {
                    "ui:order": order
                },
            );
        },
        [schema]
    );

    const formProps = {
        className: "schemaForm",
        schema,
        validator,
        uiSchema,
        formData: innerData,
        ref: formRef,
        disabled: !editing,
        onChange: (ev) => {
            const { errors = [], formData: newData } = ev;
            // ABSOLUTELY NECESSARY MAGIC - MAYBE RJSF v6 FIXES THIS
            // CONSIDER USING THIS FOR ALL ONEOF, ANYOF AND ALLOF FIELDS
            const base = document.getElementById('root_converters_0_battery__anyof_select')
            const defs = schema?.definitions || {};
            let found = null;
            let batObj = null
            const localData = JSON.parse(JSON.stringify(newData));
            Object.entries(defs).forEach(([k, v])=>{
                const { title } = v || {};
                const { options = [], selectedIndex } = base || {}; // Why is this a select rjsf?!!!?!!?
                let textContent = '';
                if(selectedIndex && options){
                    textContent = (options[selectedIndex] || {}).text;
                }

                if (title && textContent && title === textContent) {
                    const { properties = {} } = v || {};
                    const conv = localData?.converters;
                    const battery = conv[0]?.battery;
                    let mustUpdate = false;
                    Object.entries(properties).forEach(([k1, v1]) =>{
                        if (!(k1 in battery) || battery[k1] === undefined) {
                            // console.log('PROBLEM', k1, v1, battery);
                            const { default: df } = v1;

                            battery[k1] = df;
                        }
                    });
                }
            });
            const conv2 = localData?.converters;
            const bat2 = conv2[0]?.battery;
            // END OF ABSOLUTELY NECESSARY MAGIC
            setInnerData(localData);
            setNoSubmit(errors.length > 0);
        }
    };

    const buttonProps = {
        className: 'submit-form-button',
        disabled: noSubmit,
        style: {float: "right"},
        onClick: (ev) => {
            const { current: {state: { formData : newData = {} } = {} } = {} } = formRef || {};
            userSubmit(newData);
            setEditing(false);
        },
    };

    let control = (
        <Checkbox onClick={() => setEditing(!editing)} />
    );

    return(
        <div className="device-io-settings__form">
            <div className="device-io-settings__button-checkbox-wrapper">
                <FormControlLabel checked={editing} control={control} label="Override" />
                { editing ? <Button {...buttonProps}>SUBMIT</Button> : null }
            </div>
            <Form liveValidate  {...formProps} > </Form>
        </div>
    );
}

export default DeviceIOSettingsForm;