import React from "react";

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { useHistory } from 'react-router-dom';

import Lost from '../../assets/images/Connection_lost.png';

import './not-found-component.styles.scss'

function NotFound(){
	const history = useHistory();
	console.log(history);
	return (
		<div className='lost-page'>
			<Typography variant="h5" >Path Not Found</Typography>
			<img src={Lost}/>
			<div>
				<Button onClick={()=>history.goBack()}>Go Back</Button>
				<Button onClick={()=>history.push('/')}>Go Home</Button>
			</div>
		</div>
	);
}

export default NotFound