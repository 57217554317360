export default {
	"RHI-3K-48ES-5G": { maxACDCPower: 3000, maxMPPTPower: 7000, maxBatteryChargePower: 3000, maxBatteryDischargePower: 3000 },
	"RHI-3.6K-48ES-5G": { maxACDCPower: 3600, maxMPPTPower: 7000, maxBatteryChargePower: 3000, maxBatteryDischargePower: 3000 },
	"RHI-4.6K-48ES-5G": { maxACDCPower: 4600, maxMPPTPower: 8000, maxBatteryChargePower: 5000, maxBatteryDischargePower: 5000 },
	"RHI-5K-48ES-5G": { maxACDCPower: 5000, maxMPPTPower: 8000, maxBatteryChargePower: 5000, maxBatteryDischargePower: 5000 },
	"RHI-6K-48ES-5G": { maxACDCPower: 6000, maxMPPTPower: 8000, maxBatteryChargePower: 5000, maxBatteryDischargePower: 5000 },
	"RHI-3P5K-HVES-5G": { maxACDCPower: 5000, maxMPPTPower: 8000, maxBatteryChargePower: 5000, maxBatteryDischargePower: 5000 },
	"RHI-3P6K-HVES-5G": { maxACDCPower: 6000, maxMPPTPower: 9600, maxBatteryChargePower: 6000, maxBatteryDischargePower: 6000 },
	"RHI-3P8K-HVES-5G": { maxACDCPower: 8000, maxMPPTPower: 12800, maxBatteryChargePower: 8000, maxBatteryDischargePower: 8000 },
	"RHI-3P10K-HVES-5G": { maxACDCPower: 10000, maxMPPTPower: 16000, maxBatteryChargePower: 10000, maxBatteryDischargePower: 10000 },
	"S5-EH1P3K-L": { maxACDCPower: 3000, maxMPPTPower: 4800, maxBatteryChargePower: 3000, maxBatteryDischargePower: 3000 },
	"S5-EH1P3.6K-L": { maxACDCPower: 3600, maxMPPTPower: 5700, maxBatteryChargePower: 3000, maxBatteryDischargePower: 3000 },
	"S5-EH1P4.6K-L": { maxACDCPower: 4600, maxMPPTPower: 8000, maxBatteryChargePower: 5000, maxBatteryDischargePower: 5000 },
	"S5-EH1P5K-L": { maxACDCPower: 5000, maxMPPTPower: 8000, maxBatteryChargePower: 5000, maxBatteryDischargePower: 5000 },
	"S5-EH1P6K-L": { maxACDCPower: 6000, maxMPPTPower: 8000, maxBatteryChargePower: 5000, maxBatteryDischargePower: 5000 },
	"RAI-3K-48ES-5G": { maxACDCPower: 3000, maxMPPTPower: 0, maxBatteryChargePower: 3000, maxBatteryDischargePower: 3000 },
};