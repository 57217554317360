export default {
	'1x EasySolar 12/1600/70': { maxACDCPower: 1300, maxBatteryChargePower: 1008, maxBatteryDischargePower: 1008, maxMPPTPower: 700 },
	'1x EasySolar 24/1600/40': { maxACDCPower: 1300, maxBatteryChargePower: 1152, maxBatteryDischargePower: 1152, maxMPPTPower: 1400 },
	'1x Multi RS Solar 48/6000': { maxACDCPower: 5300, maxBatteryChargePower: 5000, maxBatteryDischargePower: 5000, maxMPPTPower: 4000 },
	'1x MultiPlus-II 12/3000/120-32': { maxACDCPower: 2400, maxBatteryChargePower: 1728, maxBatteryDischargePower: 1728, maxMPPTPower: 100000 },
	'1x MultiPlus-II 24/3000/70-32': { maxACDCPower: 2400, maxBatteryChargePower: 2016, maxBatteryDischargePower: 2016, maxMPPTPower: 100000 },
	'1x MultiPlus-II 48/3000/35-32': { maxACDCPower: 2400, maxBatteryChargePower: 2016, maxBatteryDischargePower: 2016, maxMPPTPower: 100000 },
	'1x MultiPlus-II 24/5000/120-50': { maxACDCPower: 4000, maxBatteryChargePower: 3456, maxBatteryDischargePower: 3456, maxMPPTPower: 100000 },
	'1x MultiPlus-II 48/5000/70-50': { maxACDCPower: 4000, maxBatteryChargePower: 4032, maxBatteryDischargePower: 4032, maxMPPTPower: 100000 },
	'1x MultiPlus-II 48/8000/110-100': { maxACDCPower: 6400, maxBatteryChargePower: 6336, maxBatteryDischargePower: 6336, maxMPPTPower: 100000 },
	'1x MultiPlus-II 48/10000/140-100': { maxACDCPower: 8000, maxBatteryChargePower: 8064, maxBatteryDischargePower: 8064, maxMPPTPower: 100000 },
	'1x MultiPlus-II 48/15000/200-100': { maxACDCPower: 12000, maxBatteryChargePower: 11520, maxBatteryDischargePower: 11520, maxMPPTPower: 100000 },
	'1x MultiPlus-II GX 24/3000/70-32': { maxACDCPower: 2400, maxBatteryChargePower: 2016, maxBatteryDischargePower: 2016, maxMPPTPower: 100000 },
	'1x MultiPlus-II GX 48/3000/35-32': { maxACDCPower: 2400, maxBatteryChargePower: 2016, maxBatteryDischargePower: 2016, maxMPPTPower: 100000 },
	'1x MultiPlus-II GX 48/5000/70-50': { maxACDCPower: 4000, maxBatteryChargePower: 4032, maxBatteryDischargePower: 4032, maxMPPTPower: 100000 },
	'1x MultiPlus 12/500/20': { maxACDCPower: 430, maxBatteryChargePower: 288, maxBatteryDischargePower: 288, maxMPPTPower: 100000 },
	'1x MultiPlus 24/500/10': { maxACDCPower: 430, maxBatteryChargePower: 288, maxBatteryDischargePower: 288, maxMPPTPower: 100000 },
	'1x MultiPlus 48/500/6': { maxACDCPower: 430, maxBatteryChargePower: 346, maxBatteryDischargePower: 346, maxMPPTPower: 100000 },
	'1x MultiPlus 12/800/35': { maxACDCPower: 700, maxBatteryChargePower: 504, maxBatteryDischargePower: 504, maxMPPTPower: 100000 },
	'1x MultiPlus 24/800/16': { maxACDCPower: 700, maxBatteryChargePower: 461, maxBatteryDischargePower: 461, maxMPPTPower: 100000 },
	'1x MultiPlus 48/800/9': { maxACDCPower: 700, maxBatteryChargePower: 518, maxBatteryDischargePower: 518, maxMPPTPower: 100000 },
	'1x MultiPlus 12/1200/50': { maxACDCPower: 1000, maxBatteryChargePower: 720, maxBatteryDischargePower: 720, maxMPPTPower: 100000 },
	'1x MultiPlus 24/1200/25': { maxACDCPower: 1000, maxBatteryChargePower: 720, maxBatteryDischargePower: 720, maxMPPTPower: 100000 },
	'1x MultiPlus 48/1200/13': { maxACDCPower: 1000, maxBatteryChargePower: 749, maxBatteryDischargePower: 749, maxMPPTPower: 100000 },
	'1x MultiPlus 12/1600/70': { maxACDCPower: 1300, maxBatteryChargePower: 1008, maxBatteryDischargePower: 1008, maxMPPTPower: 100000 },
	'1x MultiPlus 24/1600/40': { maxACDCPower: 1300, maxBatteryChargePower: 1152, maxBatteryDischargePower: 1152, maxMPPTPower: 100000 },
	'1x MultiPlus 48/1600/20': { maxACDCPower: 1300, maxBatteryChargePower: 1152, maxBatteryDischargePower: 1152, maxMPPTPower: 100000 },
	'1x MultiPlus 12/2000/80': { maxACDCPower: 1600, maxBatteryChargePower: 1152, maxBatteryDischargePower: 1152, maxMPPTPower: 100000 },
	'1x MultiPlus 24/2000/50': { maxACDCPower: 1600, maxBatteryChargePower: 1440, maxBatteryDischargePower: 1440, maxMPPTPower: 100000 },
	'1x MultiPlus 48/2000/25': { maxACDCPower: 1600, maxBatteryChargePower: 1440, maxBatteryDischargePower: 1440, maxMPPTPower: 100000 },
	'1x MultiPlus 12/3000/120': { maxACDCPower: 2400, maxBatteryChargePower: 1728, maxBatteryDischargePower: 1728, maxMPPTPower: 100000 },
	'1x MultiPlus 24/3000/70': { maxACDCPower: 2400, maxBatteryChargePower: 2016, maxBatteryDischargePower: 2016, maxMPPTPower: 100000 },
	'1x MultiPlus 48/3000/35': { maxACDCPower: 2400, maxBatteryChargePower: 2016, maxBatteryDischargePower: 2016, maxMPPTPower: 100000 },
	'1x MultiPlus 24/5000/120': { maxACDCPower: 4000, maxBatteryChargePower: 3456, maxBatteryDischargePower: 3456, maxMPPTPower: 100000 },
	'1x MultiPlus 48/5000/70': { maxACDCPower: 4000, maxBatteryChargePower: 4032, maxBatteryDischargePower: 4032, maxMPPTPower: 100000 },
	'1x Quattro 12/3000/120-50/50': { maxACDCPower: 2400, maxBatteryChargePower: 1728, maxBatteryDischargePower: 1728, maxMPPTPower: 100000 },
	'1x Quattro 24/3000/70-50/50': { maxACDCPower: 2400, maxBatteryChargePower: 2016, maxBatteryDischargePower: 2016, maxMPPTPower: 100000 },
	'1x Quattro 12/5000/220-100/100': { maxACDCPower: 4000, maxBatteryChargePower: 3168, maxBatteryDischargePower: 3168, maxMPPTPower: 100000 },
	'1x Quattro 24/5000/120-100/100': { maxACDCPower: 4000, maxBatteryChargePower: 3456, maxBatteryDischargePower: 3456, maxMPPTPower: 100000 },
	'1x Quattro 48/5000/70-100/100': { maxACDCPower: 4000, maxBatteryChargePower: 4032, maxBatteryDischargePower: 4032, maxMPPTPower: 100000 },
	'1x Quattro 24/8000/200-100/100': { maxACDCPower: 6400, maxBatteryChargePower: 5760, maxBatteryDischargePower: 5760, maxMPPTPower: 100000 },
	'1x Quattro 48/8000/110-100/100': { maxACDCPower: 6400, maxBatteryChargePower: 6336, maxBatteryDischargePower: 6336, maxMPPTPower: 100000 },
	'1x Quattro 48/10000/140-100/100': { maxACDCPower: 8000, maxBatteryChargePower: 8064, maxBatteryDischargePower: 8064, maxMPPTPower: 100000 },
	'1x Quattro 48/15000/200-100/100': { maxACDCPower: 12000, maxBatteryChargePower: 11520, maxBatteryDischargePower: 11520, maxMPPTPower: 100000 },
	'1x Quattro-II 24/5000/120-50': { maxACDCPower: 4000, maxBatteryChargePower: 3456, maxBatteryDischargePower: 3456, maxMPPTPower: 100000 },
	'1x Quattro-II 48/5000/70-50': { maxACDCPower: 4000, maxBatteryChargePower: 4032, maxBatteryDischargePower: 4032, maxMPPTPower: 100000 },
	'2x MultiPlus-II 12/3000/120-32': { maxACDCPower: 4800, maxBatteryChargePower: 3456, maxBatteryDischargePower: 3456, maxMPPTPower: 100000 },
	'2x MultiPlus-II 24/3000/70-32': { maxACDCPower: 4800, maxBatteryChargePower: 4032, maxBatteryDischargePower: 4032, maxMPPTPower: 100000 },
	'2x MultiPlus-II 48/3000/35-32': { maxACDCPower: 4800, maxBatteryChargePower: 4032, maxBatteryDischargePower: 4032, maxMPPTPower: 100000 },
	'2x MultiPlus-II 24/5000/120-50': { maxACDCPower: 8000, maxBatteryChargePower: 6912, maxBatteryDischargePower: 6912, maxMPPTPower: 100000 },
	'2x MultiPlus-II 48/5000/70-50': { maxACDCPower: 8000, maxBatteryChargePower: 8064, maxBatteryDischargePower: 8064, maxMPPTPower: 100000 },
	'2x MultiPlus-II GX 24/3000/70-32': { maxACDCPower: 4800, maxBatteryChargePower: 4032, maxBatteryDischargePower: 4032, maxMPPTPower: 100000 },
	'2x MultiPlus-II GX 48/3000/35-32': { maxACDCPower: 4800, maxBatteryChargePower: 4032, maxBatteryDischargePower: 4032, maxMPPTPower: 100000 },
	'2x MultiPlus-II GX 48/5000/70-50': { maxACDCPower: 8000, maxBatteryChargePower: 8064, maxBatteryDischargePower: 8064, maxMPPTPower: 100000 },
	'2x MultiPlus 12/500/20': { maxACDCPower: 860, maxBatteryChargePower: 576, maxBatteryDischargePower: 576, maxMPPTPower: 100000 },
	'2x MultiPlus 24/500/10': { maxACDCPower: 860, maxBatteryChargePower: 576, maxBatteryDischargePower: 576, maxMPPTPower: 100000 },
	'2x MultiPlus 48/500/6': { maxACDCPower: 860, maxBatteryChargePower: 692, maxBatteryDischargePower: 692, maxMPPTPower: 100000 },
	'2x MultiPlus 12/800/35': { maxACDCPower: 1400, maxBatteryChargePower: 1008, maxBatteryDischargePower: 1008, maxMPPTPower: 100000 },
	'2x MultiPlus 24/800/16': { maxACDCPower: 1400, maxBatteryChargePower: 922, maxBatteryDischargePower: 922, maxMPPTPower: 100000 },
	'2x MultiPlus 48/800/9': { maxACDCPower: 1400, maxBatteryChargePower: 1036, maxBatteryDischargePower: 1036, maxMPPTPower: 100000 },
	'2x MultiPlus 12/1200/50': { maxACDCPower: 2000, maxBatteryChargePower: 1440, maxBatteryDischargePower: 1440, maxMPPTPower: 100000 },
	'2x MultiPlus 24/1200/25': { maxACDCPower: 2000, maxBatteryChargePower: 1440, maxBatteryDischargePower: 1440, maxMPPTPower: 100000 },
	'2x MultiPlus 48/1200/13': { maxACDCPower: 2000, maxBatteryChargePower: 1498, maxBatteryDischargePower: 1498, maxMPPTPower: 100000 },
	'2x MultiPlus 12/1600/70': { maxACDCPower: 2600, maxBatteryChargePower: 2016, maxBatteryDischargePower: 2016, maxMPPTPower: 100000 },
	'2x MultiPlus 24/1600/40': { maxACDCPower: 2600, maxBatteryChargePower: 2304, maxBatteryDischargePower: 2304, maxMPPTPower: 100000 },
	'2x MultiPlus 48/1600/20': { maxACDCPower: 2600, maxBatteryChargePower: 2304, maxBatteryDischargePower: 2304, maxMPPTPower: 100000 },
	'2x MultiPlus 12/2000/80': { maxACDCPower: 3200, maxBatteryChargePower: 2304, maxBatteryDischargePower: 2304, maxMPPTPower: 100000 },
	'2x MultiPlus 24/2000/50': { maxACDCPower: 3200, maxBatteryChargePower: 2880, maxBatteryDischargePower: 2880, maxMPPTPower: 100000 },
	'2x MultiPlus 48/2000/25': { maxACDCPower: 3200, maxBatteryChargePower: 2880, maxBatteryDischargePower: 2880, maxMPPTPower: 100000 },
	'2x MultiPlus 12/3000/120': { maxACDCPower: 4800, maxBatteryChargePower: 3456, maxBatteryDischargePower: 3456, maxMPPTPower: 100000 },
	'2x MultiPlus 24/3000/70': { maxACDCPower: 4800, maxBatteryChargePower: 4032, maxBatteryDischargePower: 4032, maxMPPTPower: 100000 },
	'2x MultiPlus 48/3000/35': { maxACDCPower: 4800, maxBatteryChargePower: 4032, maxBatteryDischargePower: 4032, maxMPPTPower: 100000 },
	'2x MultiPlus 24/5000/120': { maxACDCPower: 8000, maxBatteryChargePower: 6912, maxBatteryDischargePower: 6912, maxMPPTPower: 100000 },
	'2x MultiPlus 48/5000/70': { maxACDCPower: 8000, maxBatteryChargePower: 8064, maxBatteryDischargePower: 8064, maxMPPTPower: 100000 },
	'2x Quattro 12/3000/120-50/50': { maxACDCPower: 4800, maxBatteryChargePower: 3456, maxBatteryDischargePower: 3456, maxMPPTPower: 100000 },
	'2x Quattro 24/3000/70-50/50': { maxACDCPower: 4800, maxBatteryChargePower: 4032, maxBatteryDischargePower: 4032, maxMPPTPower: 100000 },
	'2x Quattro 12/5000/220-100/100': { maxACDCPower: 8000, maxBatteryChargePower: 6336, maxBatteryDischargePower: 6336, maxMPPTPower: 100000 },
	'2x Quattro 24/5000/120-100/100': { maxACDCPower: 8000, maxBatteryChargePower: 6912, maxBatteryDischargePower: 6912, maxMPPTPower: 100000 },
	'2x Quattro 48/5000/70-100/100': { maxACDCPower: 8000, maxBatteryChargePower: 8064, maxBatteryDischargePower: 8064, maxMPPTPower: 100000 },
	'2x Quattro 24/8000/200-100/100': { maxACDCPower: 12800, maxBatteryChargePower: 11520, maxBatteryDischargePower: 11520, maxMPPTPower: 100000 },
	'2x Quattro 48/8000/110-100/100': { maxACDCPower: 12800, maxBatteryChargePower: 12672, maxBatteryDischargePower: 12672, maxMPPTPower: 100000 },
	'2x Quattro 48/10000/140-100/100': { maxACDCPower: 16000, maxBatteryChargePower: 16128, maxBatteryDischargePower: 16128, maxMPPTPower: 100000 },
	'2x Quattro 48/15000/200-100/100': { maxACDCPower: 24000, maxBatteryChargePower: 23040, maxBatteryDischargePower: 23040, maxMPPTPower: 100000 },
	'2x Quattro-II 24/5000/120-50': { maxACDCPower: 8000, maxBatteryChargePower: 6912, maxBatteryDischargePower: 6912, maxMPPTPower: 100000 },
	'2x Quattro-II 48/5000/70-50': { maxACDCPower: 8000, maxBatteryChargePower: 8064, maxBatteryDischargePower: 8064, maxMPPTPower: 100000 },
	'3x MultiPlus-II 12/3000/120-32': { maxACDCPower: 7200, maxBatteryChargePower: 5184, maxBatteryDischargePower: 5184, maxMPPTPower: 100000 },
	'3x MultiPlus-II 24/3000/70-32': { maxACDCPower: 7200, maxBatteryChargePower: 6048, maxBatteryDischargePower: 6048, maxMPPTPower: 100000 },
	'3x MultiPlus-II 48/3000/35-32': { maxACDCPower: 7200, maxBatteryChargePower: 6048, maxBatteryDischargePower: 6048, maxMPPTPower: 100000 },
	'3x MultiPlus-II 24/5000/120-50': { maxACDCPower: 12000, maxBatteryChargePower: 10368, maxBatteryDischargePower: 10368, maxMPPTPower: 100000 },
	'3x MultiPlus-II 48/5000/70-50': { maxACDCPower: 12000, maxBatteryChargePower: 12096, maxBatteryDischargePower: 12096, maxMPPTPower: 100000 },
	'3x MultiPlus-II 48/8000/110-100': { maxACDCPower: 19200, maxBatteryChargePower: 19008, maxBatteryDischargePower: 19008, maxMPPTPower: 100000 },
	'3x MultiPlus-II 48/10000/140-100': { maxACDCPower: 24000, maxBatteryChargePower: 24192, maxBatteryDischargePower: 24192, maxMPPTPower: 100000 },
	'3x MultiPlus-II 48/15000/200-100': { maxACDCPower: 36000, maxBatteryChargePower: 34560, maxBatteryDischargePower: 34560, maxMPPTPower: 100000 },
	'3x MultiPlus-II GX 24/3000/70-32': { maxACDCPower: 7200, maxBatteryChargePower: 6048, maxBatteryDischargePower: 6048, maxMPPTPower: 100000 },
	'3x MultiPlus-II GX 48/3000/35-32': { maxACDCPower: 7200, maxBatteryChargePower: 6048, maxBatteryDischargePower: 6048, maxMPPTPower: 100000 },
	'3x MultiPlus-II GX 48/5000/70-50': { maxACDCPower: 12000, maxBatteryChargePower: 12096, maxBatteryDischargePower: 12096, maxMPPTPower: 100000 },
	'3x MultiPlus 12/500/20': { maxACDCPower: 1290, maxBatteryChargePower: 864, maxBatteryDischargePower: 864, maxMPPTPower: 100000 },
	'3x MultiPlus 24/500/10': { maxACDCPower: 1290, maxBatteryChargePower: 864, maxBatteryDischargePower: 864, maxMPPTPower: 100000 },
	'3x MultiPlus 48/500/6': { maxACDCPower: 1290, maxBatteryChargePower: 1038, maxBatteryDischargePower: 1038, maxMPPTPower: 100000 },
	'3x MultiPlus 12/800/35': { maxACDCPower: 2100, maxBatteryChargePower: 1512, maxBatteryDischargePower: 1512, maxMPPTPower: 100000 },
	'3x MultiPlus 24/800/16': { maxACDCPower: 2100, maxBatteryChargePower: 1383, maxBatteryDischargePower: 1383, maxMPPTPower: 100000 },
	'3x MultiPlus 48/800/9': { maxACDCPower: 2100, maxBatteryChargePower: 1554, maxBatteryDischargePower: 1554, maxMPPTPower: 100000 },
	'3x MultiPlus 12/1200/50': { maxACDCPower: 3000, maxBatteryChargePower: 2160, maxBatteryDischargePower: 2160, maxMPPTPower: 100000 },
	'3x MultiPlus 24/1200/25': { maxACDCPower: 3000, maxBatteryChargePower: 2160, maxBatteryDischargePower: 2160, maxMPPTPower: 100000 },
	'3x MultiPlus 48/1200/13': { maxACDCPower: 3000, maxBatteryChargePower: 2247, maxBatteryDischargePower: 2247, maxMPPTPower: 100000 },
	'3x MultiPlus 12/1600/70': { maxACDCPower: 3900, maxBatteryChargePower: 3024, maxBatteryDischargePower: 3024, maxMPPTPower: 100000 },
	'3x MultiPlus 24/1600/40': { maxACDCPower: 3900, maxBatteryChargePower: 3456, maxBatteryDischargePower: 3456, maxMPPTPower: 100000 },
	'3x MultiPlus 48/1600/20': { maxACDCPower: 3900, maxBatteryChargePower: 3456, maxBatteryDischargePower: 3456, maxMPPTPower: 100000 },
	'3x MultiPlus 12/2000/80': { maxACDCPower: 4800, maxBatteryChargePower: 3456, maxBatteryDischargePower: 3456, maxMPPTPower: 100000 },
	'3x MultiPlus 24/2000/50': { maxACDCPower: 4800, maxBatteryChargePower: 4320, maxBatteryDischargePower: 4320, maxMPPTPower: 100000 },
	'3x MultiPlus 48/2000/25': { maxACDCPower: 4800, maxBatteryChargePower: 4320, maxBatteryDischargePower: 4320, maxMPPTPower: 100000 },
	'3x MultiPlus 12/3000/120': { maxACDCPower: 7200, maxBatteryChargePower: 5184, maxBatteryDischargePower: 5184, maxMPPTPower: 100000 },
	'3x MultiPlus 24/3000/70': { maxACDCPower: 7200, maxBatteryChargePower: 6048, maxBatteryDischargePower: 6048, maxMPPTPower: 100000 },
	'3x MultiPlus 48/3000/35': { maxACDCPower: 7200, maxBatteryChargePower: 6048, maxBatteryDischargePower: 6048, maxMPPTPower: 100000 },
	'3x MultiPlus 24/5000/120': { maxACDCPower: 12000, maxBatteryChargePower: 10368, maxBatteryDischargePower: 10368, maxMPPTPower: 100000 },
	'3x MultiPlus 48/5000/70': { maxACDCPower: 12000, maxBatteryChargePower: 12096, maxBatteryDischargePower: 12096, maxMPPTPower: 100000 },
	'3x Quattro 12/3000/120-50/50': { maxACDCPower: 7200, maxBatteryChargePower: 5184, maxBatteryDischargePower: 5184, maxMPPTPower: 100000 },
	'3x Quattro 24/3000/70-50/50': { maxACDCPower: 7200, maxBatteryChargePower: 6048, maxBatteryDischargePower: 6048, maxMPPTPower: 100000 },
	'3x Quattro 12/5000/220-100/100': { maxACDCPower: 12000, maxBatteryChargePower: 9504, maxBatteryDischargePower: 9504, maxMPPTPower: 100000 },
	'3x Quattro 24/5000/120-100/100': { maxACDCPower: 12000, maxBatteryChargePower: 10368, maxBatteryDischargePower: 10368, maxMPPTPower: 100000 },
	'3x Quattro 48/5000/70-100/100': { maxACDCPower: 12000, maxBatteryChargePower: 12096, maxBatteryDischargePower: 12096, maxMPPTPower: 100000 },
	'3x Quattro 24/8000/200-100/100': { maxACDCPower: 19200, maxBatteryChargePower: 17280, maxBatteryDischargePower: 17280, maxMPPTPower: 100000 },
	'3x Quattro 48/8000/110-100/100': { maxACDCPower: 19200, maxBatteryChargePower: 19008, maxBatteryDischargePower: 19008, maxMPPTPower: 100000 },
	'3x Quattro 48/10000/140-100/100': { maxACDCPower: 24000, maxBatteryChargePower: 24192, maxBatteryDischargePower: 24192, maxMPPTPower: 100000 },
	'3x Quattro 48/15000/200-100/100': { maxACDCPower: 36000, maxBatteryChargePower: 34560, maxBatteryDischargePower: 34560, maxMPPTPower: 100000 },
	'3x Quattro-II 24/5000/120-50': { maxACDCPower: 12000, maxBatteryChargePower: 10368, maxBatteryDischargePower: 10368, maxMPPTPower: 100000 },
	'3x Quattro-II 48/5000/70-50': { maxACDCPower: 12000, maxBatteryChargePower: 12096, maxBatteryDischargePower: 12096, maxMPPTPower: 100000 },
	'4x MultiPlus-II 12/3000/120-32': { maxACDCPower: 9600, maxBatteryChargePower: 6912, maxBatteryDischargePower: 6912, maxMPPTPower: 100000 },
	'4x MultiPlus-II 24/3000/70-32': { maxACDCPower: 9600, maxBatteryChargePower: 8064, maxBatteryDischargePower: 8064, maxMPPTPower: 100000 },
	'4x MultiPlus-II 48/3000/35-32': { maxACDCPower: 9600, maxBatteryChargePower: 8064, maxBatteryDischargePower: 8064, maxMPPTPower: 100000 },
	'4x MultiPlus-II 24/5000/120-50': { maxACDCPower: 16000, maxBatteryChargePower: 13824, maxBatteryDischargePower: 13824, maxMPPTPower: 100000 },
	'4x MultiPlus-II 48/5000/70-50': { maxACDCPower: 16000, maxBatteryChargePower: 16128, maxBatteryDischargePower: 16128, maxMPPTPower: 100000 },
	'4x MultiPlus-II GX 24/3000/70-32': { maxACDCPower: 9600, maxBatteryChargePower: 8064, maxBatteryDischargePower: 8064, maxMPPTPower: 100000 },
	'4x MultiPlus-II GX 48/3000/35-32': { maxACDCPower: 9600, maxBatteryChargePower: 8064, maxBatteryDischargePower: 8064, maxMPPTPower: 100000 },
	'4x MultiPlus-II GX 48/5000/70-50': { maxACDCPower: 16000, maxBatteryChargePower: 16128, maxBatteryDischargePower: 16128, maxMPPTPower: 100000 },
	'4x MultiPlus 12/500/20': { maxACDCPower: 1720, maxBatteryChargePower: 1152, maxBatteryDischargePower: 1152, maxMPPTPower: 100000 },
	'4x MultiPlus 24/500/10': { maxACDCPower: 1720, maxBatteryChargePower: 1152, maxBatteryDischargePower: 1152, maxMPPTPower: 100000 },
	'4x MultiPlus 48/500/6': { maxACDCPower: 1720, maxBatteryChargePower: 1384, maxBatteryDischargePower: 1384, maxMPPTPower: 100000 },
	'4x MultiPlus 12/800/35': { maxACDCPower: 2800, maxBatteryChargePower: 2016, maxBatteryDischargePower: 2016, maxMPPTPower: 100000 },
	'4x MultiPlus 24/800/16': { maxACDCPower: 2800, maxBatteryChargePower: 1844, maxBatteryDischargePower: 1844, maxMPPTPower: 100000 },
	'4x MultiPlus 48/800/9': { maxACDCPower: 2800, maxBatteryChargePower: 2072, maxBatteryDischargePower: 2072, maxMPPTPower: 100000 },
	'4x MultiPlus 12/1200/50': { maxACDCPower: 4000, maxBatteryChargePower: 2880, maxBatteryDischargePower: 2880, maxMPPTPower: 100000 },
	'4x MultiPlus 24/1200/25': { maxACDCPower: 4000, maxBatteryChargePower: 2880, maxBatteryDischargePower: 2880, maxMPPTPower: 100000 },
	'4x MultiPlus 48/1200/13': { maxACDCPower: 4000, maxBatteryChargePower: 2996, maxBatteryDischargePower: 2996, maxMPPTPower: 100000 },
	'4x MultiPlus 12/1600/70': { maxACDCPower: 5200, maxBatteryChargePower: 4032, maxBatteryDischargePower: 4032, maxMPPTPower: 100000 },
	'4x MultiPlus 24/1600/40': { maxACDCPower: 5200, maxBatteryChargePower: 4608, maxBatteryDischargePower: 4608, maxMPPTPower: 100000 },
	'4x MultiPlus 48/1600/20': { maxACDCPower: 5200, maxBatteryChargePower: 4608, maxBatteryDischargePower: 4608, maxMPPTPower: 100000 },
	'4x MultiPlus 12/2000/80': { maxACDCPower: 6400, maxBatteryChargePower: 4608, maxBatteryDischargePower: 4608, maxMPPTPower: 100000 },
	'4x MultiPlus 24/2000/50': { maxACDCPower: 6400, maxBatteryChargePower: 5760, maxBatteryDischargePower: 5760, maxMPPTPower: 100000 },
	'4x MultiPlus 48/2000/25': { maxACDCPower: 6400, maxBatteryChargePower: 5760, maxBatteryDischargePower: 5760, maxMPPTPower: 100000 },
	'4x MultiPlus 12/3000/120': { maxACDCPower: 9600, maxBatteryChargePower: 6912, maxBatteryDischargePower: 6912, maxMPPTPower: 100000 },
	'4x MultiPlus 24/3000/70': { maxACDCPower: 9600, maxBatteryChargePower: 8064, maxBatteryDischargePower: 8064, maxMPPTPower: 100000 },
	'4x MultiPlus 48/3000/35': { maxACDCPower: 9600, maxBatteryChargePower: 8064, maxBatteryDischargePower: 8064, maxMPPTPower: 100000 },
	'4x MultiPlus 24/5000/120': { maxACDCPower: 16000, maxBatteryChargePower: 13824, maxBatteryDischargePower: 13824, maxMPPTPower: 100000 },
	'4x MultiPlus 48/5000/70': { maxACDCPower: 16000, maxBatteryChargePower: 16128, maxBatteryDischargePower: 16128, maxMPPTPower: 100000 },
	'4x Quattro 12/3000/120-50/50': { maxACDCPower: 9600, maxBatteryChargePower: 6912, maxBatteryDischargePower: 6912, maxMPPTPower: 100000 },
	'4x Quattro 24/3000/70-50/50': { maxACDCPower: 9600, maxBatteryChargePower: 8064, maxBatteryDischargePower: 8064, maxMPPTPower: 100000 },
	'4x Quattro 12/5000/220-100/100': { maxACDCPower: 16000, maxBatteryChargePower: 12672, maxBatteryDischargePower: 12672, maxMPPTPower: 100000 },
	'4x Quattro 24/5000/120-100/100': { maxACDCPower: 16000, maxBatteryChargePower: 13824, maxBatteryDischargePower: 13824, maxMPPTPower: 100000 },
	'4x Quattro 48/5000/70-100/100': { maxACDCPower: 16000, maxBatteryChargePower: 16128, maxBatteryDischargePower: 16128, maxMPPTPower: 100000 },
	'4x Quattro 24/8000/200-100/100': { maxACDCPower: 25600, maxBatteryChargePower: 23040, maxBatteryDischargePower: 23040, maxMPPTPower: 100000 },
	'4x Quattro 48/8000/110-100/100': { maxACDCPower: 25600, maxBatteryChargePower: 25344, maxBatteryDischargePower: 25344, maxMPPTPower: 100000 },
	'4x Quattro 48/10000/140-100/100': { maxACDCPower: 32000, maxBatteryChargePower: 32256, maxBatteryDischargePower: 32256, maxMPPTPower: 100000 },
	'4x Quattro 48/15000/200-100/100': { maxACDCPower: 48000, maxBatteryChargePower: 46080, maxBatteryDischargePower: 46080, maxMPPTPower: 100000 },
	'4x Quattro-II 24/5000/120-50': { maxACDCPower: 16000, maxBatteryChargePower: 13824, maxBatteryDischargePower: 13824, maxMPPTPower: 100000 },
	'4x Quattro-II 48/5000/70-50': { maxACDCPower: 16000, maxBatteryChargePower: 16128, maxBatteryDischargePower: 16128, maxMPPTPower: 100000 },
	'5x MultiPlus-II 12/3000/120-32': { maxACDCPower: 12000, maxBatteryChargePower: 8640, maxBatteryDischargePower: 8640, maxMPPTPower: 100000 },
	'5x MultiPlus-II 24/3000/70-32': { maxACDCPower: 12000, maxBatteryChargePower: 10080, maxBatteryDischargePower: 10080, maxMPPTPower: 100000 },
	'5x MultiPlus-II 48/3000/35-32': { maxACDCPower: 12000, maxBatteryChargePower: 10080, maxBatteryDischargePower: 10080, maxMPPTPower: 100000 },
	'5x MultiPlus-II 24/5000/120-50': { maxACDCPower: 20000, maxBatteryChargePower: 17280, maxBatteryDischargePower: 17280, maxMPPTPower: 100000 },
	'5x MultiPlus-II 48/5000/70-50': { maxACDCPower: 20000, maxBatteryChargePower: 20160, maxBatteryDischargePower: 20160, maxMPPTPower: 100000 },
	'5x MultiPlus-II GX 24/3000/70-32': { maxACDCPower: 12000, maxBatteryChargePower: 10080, maxBatteryDischargePower: 10080, maxMPPTPower: 100000 },
	'5x MultiPlus-II GX 48/3000/35-32': { maxACDCPower: 12000, maxBatteryChargePower: 10080, maxBatteryDischargePower: 10080, maxMPPTPower: 100000 },
	'5x MultiPlus-II GX 48/5000/70-50': { maxACDCPower: 20000, maxBatteryChargePower: 20160, maxBatteryDischargePower: 20160, maxMPPTPower: 100000 },
	'5x MultiPlus 12/500/20': { maxACDCPower: 2150, maxBatteryChargePower: 1440, maxBatteryDischargePower: 1440, maxMPPTPower: 100000 },
	'5x MultiPlus 24/500/10': { maxACDCPower: 2150, maxBatteryChargePower: 1440, maxBatteryDischargePower: 1440, maxMPPTPower: 100000 },
	'5x MultiPlus 48/500/6': { maxACDCPower: 2150, maxBatteryChargePower: 1730, maxBatteryDischargePower: 1730, maxMPPTPower: 100000 },
	'5x MultiPlus 12/800/35': { maxACDCPower: 3500, maxBatteryChargePower: 2520, maxBatteryDischargePower: 2520, maxMPPTPower: 100000 },
	'5x MultiPlus 24/800/16': { maxACDCPower: 3500, maxBatteryChargePower: 2305, maxBatteryDischargePower: 2305, maxMPPTPower: 100000 },
	'5x MultiPlus 48/800/9': { maxACDCPower: 3500, maxBatteryChargePower: 2590, maxBatteryDischargePower: 2590, maxMPPTPower: 100000 },
	'5x MultiPlus 12/1200/50': { maxACDCPower: 5000, maxBatteryChargePower: 3600, maxBatteryDischargePower: 3600, maxMPPTPower: 100000 },
	'5x MultiPlus 24/1200/25': { maxACDCPower: 5000, maxBatteryChargePower: 3600, maxBatteryDischargePower: 3600, maxMPPTPower: 100000 },
	'5x MultiPlus 48/1200/13': { maxACDCPower: 5000, maxBatteryChargePower: 3745, maxBatteryDischargePower: 3745, maxMPPTPower: 100000 },
	'5x MultiPlus 12/1600/70': { maxACDCPower: 6500, maxBatteryChargePower: 5040, maxBatteryDischargePower: 5040, maxMPPTPower: 100000 },
	'5x MultiPlus 24/1600/40': { maxACDCPower: 6500, maxBatteryChargePower: 5760, maxBatteryDischargePower: 5760, maxMPPTPower: 100000 },
	'5x MultiPlus 48/1600/20': { maxACDCPower: 6500, maxBatteryChargePower: 5760, maxBatteryDischargePower: 5760, maxMPPTPower: 100000 },
	'5x MultiPlus 12/2000/80': { maxACDCPower: 8000, maxBatteryChargePower: 5760, maxBatteryDischargePower: 5760, maxMPPTPower: 100000 },
	'5x MultiPlus 24/2000/50': { maxACDCPower: 8000, maxBatteryChargePower: 7200, maxBatteryDischargePower: 7200, maxMPPTPower: 100000 },
	'5x MultiPlus 48/2000/25': { maxACDCPower: 8000, maxBatteryChargePower: 7200, maxBatteryDischargePower: 7200, maxMPPTPower: 100000 },
	'5x MultiPlus 12/3000/120': { maxACDCPower: 12000, maxBatteryChargePower: 8640, maxBatteryDischargePower: 8640, maxMPPTPower: 100000 },
	'5x MultiPlus 24/3000/70': { maxACDCPower: 12000, maxBatteryChargePower: 10080, maxBatteryDischargePower: 10080, maxMPPTPower: 100000 },
	'5x MultiPlus 48/3000/35': { maxACDCPower: 12000, maxBatteryChargePower: 10080, maxBatteryDischargePower: 10080, maxMPPTPower: 100000 },
	'5x MultiPlus 24/5000/120': { maxACDCPower: 20000, maxBatteryChargePower: 17280, maxBatteryDischargePower: 17280, maxMPPTPower: 100000 },
	'5x MultiPlus 48/5000/70': { maxACDCPower: 20000, maxBatteryChargePower: 20160, maxBatteryDischargePower: 20160, maxMPPTPower: 100000 },
	'5x Quattro 12/3000/120-50/50': { maxACDCPower: 12000, maxBatteryChargePower: 8640, maxBatteryDischargePower: 8640, maxMPPTPower: 100000 },
	'5x Quattro 24/3000/70-50/50': { maxACDCPower: 12000, maxBatteryChargePower: 10080, maxBatteryDischargePower: 10080, maxMPPTPower: 100000 },
	'5x Quattro 12/5000/220-100/100': { maxACDCPower: 20000, maxBatteryChargePower: 15840, maxBatteryDischargePower: 15840, maxMPPTPower: 100000 },
	'5x Quattro 24/5000/120-100/100': { maxACDCPower: 20000, maxBatteryChargePower: 17280, maxBatteryDischargePower: 17280, maxMPPTPower: 100000 },
	'5x Quattro 48/5000/70-100/100': { maxACDCPower: 20000, maxBatteryChargePower: 20160, maxBatteryDischargePower: 20160, maxMPPTPower: 100000 },
	'5x Quattro 24/8000/200-100/100': { maxACDCPower: 32000, maxBatteryChargePower: 28800, maxBatteryDischargePower: 28800, maxMPPTPower: 100000 },
	'5x Quattro 48/8000/110-100/100': { maxACDCPower: 32000, maxBatteryChargePower: 31680, maxBatteryDischargePower: 31680, maxMPPTPower: 100000 },
	'5x Quattro 48/10000/140-100/100': { maxACDCPower: 40000, maxBatteryChargePower: 40320, maxBatteryDischargePower: 40320, maxMPPTPower: 100000 },
	'5x Quattro 48/15000/200-100/100': { maxACDCPower: 60000, maxBatteryChargePower: 57600, maxBatteryDischargePower: 57600, maxMPPTPower: 100000 },
	'5x Quattro-II 24/5000/120-50': { maxACDCPower: 20000, maxBatteryChargePower: 17280, maxBatteryDischargePower: 17280, maxMPPTPower: 100000 },
	'5x Quattro-II 48/5000/70-50': { maxACDCPower: 20000, maxBatteryChargePower: 20160, maxBatteryDischargePower: 20160, maxMPPTPower: 100000 },
	'6x MultiPlus-II 12/3000/120-32': { maxACDCPower: 14400, maxBatteryChargePower: 10368, maxBatteryDischargePower: 10368, maxMPPTPower: 100000 },
	'6x MultiPlus-II 24/3000/70-32': { maxACDCPower: 14400, maxBatteryChargePower: 12096, maxBatteryDischargePower: 12096, maxMPPTPower: 100000 },
	'6x MultiPlus-II 48/3000/35-32': { maxACDCPower: 14400, maxBatteryChargePower: 12096, maxBatteryDischargePower: 12096, maxMPPTPower: 100000 },
	'6x MultiPlus-II 24/5000/120-50': { maxACDCPower: 24000, maxBatteryChargePower: 20736, maxBatteryDischargePower: 20736, maxMPPTPower: 100000 },
	'6x MultiPlus-II 48/5000/70-50': { maxACDCPower: 24000, maxBatteryChargePower: 24192, maxBatteryDischargePower: 24192, maxMPPTPower: 100000 },
	'6x MultiPlus-II GX 24/3000/70-32': { maxACDCPower: 14400, maxBatteryChargePower: 12096, maxBatteryDischargePower: 12096, maxMPPTPower: 100000 },
	'6x MultiPlus-II GX 48/3000/35-32': { maxACDCPower: 14400, maxBatteryChargePower: 12096, maxBatteryDischargePower: 12096, maxMPPTPower: 100000 },
	'6x MultiPlus-II GX 48/5000/70-50': { maxACDCPower: 24000, maxBatteryChargePower: 24192, maxBatteryDischargePower: 24192, maxMPPTPower: 100000 },
	'6x MultiPlus 12/500/20': { maxACDCPower: 2580, maxBatteryChargePower: 1728, maxBatteryDischargePower: 1728, maxMPPTPower: 100000 },
	'6x MultiPlus 24/500/10': { maxACDCPower: 2580, maxBatteryChargePower: 1728, maxBatteryDischargePower: 1728, maxMPPTPower: 100000 },
	'6x MultiPlus 48/500/6': { maxACDCPower: 2580, maxBatteryChargePower: 2076, maxBatteryDischargePower: 2076, maxMPPTPower: 100000 },
	'6x MultiPlus 12/800/35': { maxACDCPower: 4200, maxBatteryChargePower: 3024, maxBatteryDischargePower: 3024, maxMPPTPower: 100000 },
	'6x MultiPlus 24/800/16': { maxACDCPower: 4200, maxBatteryChargePower: 2766, maxBatteryDischargePower: 2766, maxMPPTPower: 100000 },
	'6x MultiPlus 48/800/9': { maxACDCPower: 4200, maxBatteryChargePower: 3108, maxBatteryDischargePower: 3108, maxMPPTPower: 100000 },
	'6x MultiPlus 12/1200/50': { maxACDCPower: 6000, maxBatteryChargePower: 4320, maxBatteryDischargePower: 4320, maxMPPTPower: 100000 },
	'6x MultiPlus 24/1200/25': { maxACDCPower: 6000, maxBatteryChargePower: 4320, maxBatteryDischargePower: 4320, maxMPPTPower: 100000 },
	'6x MultiPlus 48/1200/13': { maxACDCPower: 6000, maxBatteryChargePower: 4494, maxBatteryDischargePower: 4494, maxMPPTPower: 100000 },
	'6x MultiPlus 12/1600/70': { maxACDCPower: 7800, maxBatteryChargePower: 6048, maxBatteryDischargePower: 6048, maxMPPTPower: 100000 },
	'6x MultiPlus 24/1600/40': { maxACDCPower: 7800, maxBatteryChargePower: 6912, maxBatteryDischargePower: 6912, maxMPPTPower: 100000 },
	'6x MultiPlus 48/1600/20': { maxACDCPower: 7800, maxBatteryChargePower: 6912, maxBatteryDischargePower: 6912, maxMPPTPower: 100000 },
	'6x MultiPlus 12/2000/80': { maxACDCPower: 9600, maxBatteryChargePower: 6912, maxBatteryDischargePower: 6912, maxMPPTPower: 100000 },
	'6x MultiPlus 24/2000/50': { maxACDCPower: 9600, maxBatteryChargePower: 8640, maxBatteryDischargePower: 8640, maxMPPTPower: 100000 },
	'6x MultiPlus 48/2000/25': { maxACDCPower: 9600, maxBatteryChargePower: 8640, maxBatteryDischargePower: 8640, maxMPPTPower: 100000 },
	'6x MultiPlus 12/3000/120': { maxACDCPower: 14400, maxBatteryChargePower: 10368, maxBatteryDischargePower: 10368, maxMPPTPower: 100000 },
	'6x MultiPlus 24/3000/70': { maxACDCPower: 14400, maxBatteryChargePower: 12096, maxBatteryDischargePower: 12096, maxMPPTPower: 100000 },
	'6x MultiPlus 48/3000/35': { maxACDCPower: 14400, maxBatteryChargePower: 12096, maxBatteryDischargePower: 12096, maxMPPTPower: 100000 },
	'6x MultiPlus 24/5000/120': { maxACDCPower: 24000, maxBatteryChargePower: 20736, maxBatteryDischargePower: 20736, maxMPPTPower: 100000 },
	'6x MultiPlus 48/5000/70': { maxACDCPower: 24000, maxBatteryChargePower: 24192, maxBatteryDischargePower: 24192, maxMPPTPower: 100000 },
	'6x Quattro 12/3000/120-50/50': { maxACDCPower: 14400, maxBatteryChargePower: 10368, maxBatteryDischargePower: 10368, maxMPPTPower: 100000 },
	'6x Quattro 24/3000/70-50/50': { maxACDCPower: 14400, maxBatteryChargePower: 12096, maxBatteryDischargePower: 12096, maxMPPTPower: 100000 },
	'6x Quattro 12/5000/220-100/100': { maxACDCPower: 24000, maxBatteryChargePower: 19008, maxBatteryDischargePower: 19008, maxMPPTPower: 100000 },
	'6x Quattro 24/5000/120-100/100': { maxACDCPower: 24000, maxBatteryChargePower: 20736, maxBatteryDischargePower: 20736, maxMPPTPower: 100000 },
	'6x Quattro 48/5000/70-100/100': { maxACDCPower: 24000, maxBatteryChargePower: 24192, maxBatteryDischargePower: 24192, maxMPPTPower: 100000 },
	'6x Quattro 24/8000/200-100/100': { maxACDCPower: 38400, maxBatteryChargePower: 34560, maxBatteryDischargePower: 34560, maxMPPTPower: 100000 },
	'6x Quattro 48/8000/110-100/100': { maxACDCPower: 38400, maxBatteryChargePower: 38016, maxBatteryDischargePower: 38016, maxMPPTPower: 100000 },
	'6x Quattro 48/10000/140-100/100': { maxACDCPower: 48000, maxBatteryChargePower: 48384, maxBatteryDischargePower: 48384, maxMPPTPower: 100000 },
	'6x Quattro 48/15000/200-100/100': { maxACDCPower: 72000, maxBatteryChargePower: 69120, maxBatteryDischargePower: 69120, maxMPPTPower: 100000 },
	'6x Quattro-II 24/5000/120-50': { maxACDCPower: 24000, maxBatteryChargePower: 20736, maxBatteryDischargePower: 20736, maxMPPTPower: 100000 },
	'6x Quattro-II 48/5000/70-50': { maxACDCPower: 24000, maxBatteryChargePower: 24192, maxBatteryDischargePower: 24192, maxMPPTPower: 100000 },
	'9x MultiPlus-II 12/3000/120-32': { maxACDCPower: 21600, maxBatteryChargePower: 15552, maxBatteryDischargePower: 15552, maxMPPTPower: 100000 },
	'9x MultiPlus-II 24/3000/70-32': { maxACDCPower: 21600, maxBatteryChargePower: 18144, maxBatteryDischargePower: 18144, maxMPPTPower: 100000 },
	'9x MultiPlus-II 48/3000/35-32': { maxACDCPower: 21600, maxBatteryChargePower: 18144, maxBatteryDischargePower: 18144, maxMPPTPower: 100000 },
	'9x MultiPlus-II 24/5000/120-50': { maxACDCPower: 36000, maxBatteryChargePower: 31104, maxBatteryDischargePower: 31104, maxMPPTPower: 100000 },
	'9x MultiPlus-II 48/5000/70-50': { maxACDCPower: 36000, maxBatteryChargePower: 36288, maxBatteryDischargePower: 36288, maxMPPTPower: 100000 },
	'9x MultiPlus-II GX 24/3000/70-32': { maxACDCPower: 21600, maxBatteryChargePower: 18144, maxBatteryDischargePower: 18144, maxMPPTPower: 100000 },
	'9x MultiPlus-II GX 48/3000/35-32': { maxACDCPower: 21600, maxBatteryChargePower: 18144, maxBatteryDischargePower: 18144, maxMPPTPower: 100000 },
	'9x MultiPlus-II GX 48/5000/70-50': { maxACDCPower: 36000, maxBatteryChargePower: 36288, maxBatteryDischargePower: 36288, maxMPPTPower: 100000 },
	'9x MultiPlus 12/1200/50': { maxACDCPower: 9000, maxBatteryChargePower: 6480, maxBatteryDischargePower: 6480, maxMPPTPower: 100000 },
	'9x MultiPlus 24/1200/25': { maxACDCPower: 9000, maxBatteryChargePower: 6480, maxBatteryDischargePower: 6480, maxMPPTPower: 100000 },
	'9x MultiPlus 48/1200/13': { maxACDCPower: 9000, maxBatteryChargePower: 6741, maxBatteryDischargePower: 6741, maxMPPTPower: 100000 },
	'9x MultiPlus 12/1600/70': { maxACDCPower: 11700, maxBatteryChargePower: 9072, maxBatteryDischargePower: 9072, maxMPPTPower: 100000 },
	'9x MultiPlus 24/1600/40': { maxACDCPower: 11700, maxBatteryChargePower: 10368, maxBatteryDischargePower: 10368, maxMPPTPower: 100000 },
	'9x MultiPlus 48/1600/20': { maxACDCPower: 11700, maxBatteryChargePower: 10368, maxBatteryDischargePower: 10368, maxMPPTPower: 100000 },
	'9x MultiPlus 12/2000/80': { maxACDCPower: 14400, maxBatteryChargePower: 10368, maxBatteryDischargePower: 10368, maxMPPTPower: 100000 },
	'9x MultiPlus 24/2000/50': { maxACDCPower: 14400, maxBatteryChargePower: 12960, maxBatteryDischargePower: 12960, maxMPPTPower: 100000 },
	'9x MultiPlus 48/2000/25': { maxACDCPower: 14400, maxBatteryChargePower: 12960, maxBatteryDischargePower: 12960, maxMPPTPower: 100000 },
	'9x MultiPlus 12/3000/120': { maxACDCPower: 21600, maxBatteryChargePower: 15552, maxBatteryDischargePower: 15552, maxMPPTPower: 100000 },
	'9x MultiPlus 24/3000/70': { maxACDCPower: 21600, maxBatteryChargePower: 18144, maxBatteryDischargePower: 18144, maxMPPTPower: 100000 },
	'9x MultiPlus 48/3000/35': { maxACDCPower: 21600, maxBatteryChargePower: 18144, maxBatteryDischargePower: 18144, maxMPPTPower: 100000 },
	'9x MultiPlus 24/5000/120': { maxACDCPower: 36000, maxBatteryChargePower: 31104, maxBatteryDischargePower: 31104, maxMPPTPower: 100000 },
	'9x MultiPlus 48/5000/70': { maxACDCPower: 36000, maxBatteryChargePower: 36288, maxBatteryDischargePower: 36288, maxMPPTPower: 100000 },
	'9x Quattro 12/3000/120-50/50': { maxACDCPower: 21600, maxBatteryChargePower: 15552, maxBatteryDischargePower: 15552, maxMPPTPower: 100000 },
	'9x Quattro 24/3000/70-50/50': { maxACDCPower: 21600, maxBatteryChargePower: 18144, maxBatteryDischargePower: 18144, maxMPPTPower: 100000 },
	'9x Quattro 12/5000/220-100/100': { maxACDCPower: 36000, maxBatteryChargePower: 28512, maxBatteryDischargePower: 28512, maxMPPTPower: 100000 },
	'9x Quattro 24/5000/120-100/100': { maxACDCPower: 36000, maxBatteryChargePower: 31104, maxBatteryDischargePower: 31104, maxMPPTPower: 100000 },
	'9x Quattro 48/5000/70-100/100': { maxACDCPower: 36000, maxBatteryChargePower: 36288, maxBatteryDischargePower: 36288, maxMPPTPower: 100000 },
	'9x Quattro 24/8000/200-100/100': { maxACDCPower: 57600, maxBatteryChargePower: 51840, maxBatteryDischargePower: 51840, maxMPPTPower: 100000 },
	'9x Quattro 48/8000/110-100/100': { maxACDCPower: 57600, maxBatteryChargePower: 57024, maxBatteryDischargePower: 57024, maxMPPTPower: 100000 },
	'9x Quattro 48/10000/140-100/100': { maxACDCPower: 72000, maxBatteryChargePower: 72576, maxBatteryDischargePower: 72576, maxMPPTPower: 100000 },
	'9x Quattro 48/15000/200-100/100': { maxACDCPower: 108000, maxBatteryChargePower: 103680, maxBatteryDischargePower: 103680, maxMPPTPower: 100000 },
	'9x Quattro-II 24/5000/120-50': { maxACDCPower: 36000, maxBatteryChargePower: 31104, maxBatteryDischargePower: 31104, maxMPPTPower: 100000 },
	'9x Quattro-II 48/5000/70-50': { maxACDCPower: 36000, maxBatteryChargePower: 36288, maxBatteryDischargePower: 36288, maxMPPTPower: 100000 },
	'12x MultiPlus-II 12/3000/120-32': { maxACDCPower: 28800, maxBatteryChargePower: 20736, maxBatteryDischargePower: 20736, maxMPPTPower: 100000 },
	'12x MultiPlus-II 24/3000/70-32': { maxACDCPower: 28800, maxBatteryChargePower: 24192, maxBatteryDischargePower: 24192, maxMPPTPower: 100000 },
	'12x MultiPlus-II 48/3000/35-32': { maxACDCPower: 28800, maxBatteryChargePower: 24192, maxBatteryDischargePower: 24192, maxMPPTPower: 100000 },
	'12x MultiPlus-II 24/5000/120-50': { maxACDCPower: 48000, maxBatteryChargePower: 41472, maxBatteryDischargePower: 41472, maxMPPTPower: 100000 },
	'12x MultiPlus-II 48/5000/70-50': { maxACDCPower: 48000, maxBatteryChargePower: 48384, maxBatteryDischargePower: 48384, maxMPPTPower: 100000 },
	'12x MultiPlus-II GX 24/3000/70-32': { maxACDCPower: 28800, maxBatteryChargePower: 24192, maxBatteryDischargePower: 24192, maxMPPTPower: 100000 },
	'12x MultiPlus-II GX 48/3000/35-32': { maxACDCPower: 28800, maxBatteryChargePower: 24192, maxBatteryDischargePower: 24192, maxMPPTPower: 100000 },
	'12x MultiPlus-II GX 48/5000/70-50': { maxACDCPower: 48000, maxBatteryChargePower: 48384, maxBatteryDischargePower: 48384, maxMPPTPower: 100000 },
	'12x MultiPlus 12/1200/50': { maxACDCPower: 12000, maxBatteryChargePower: 8640, maxBatteryDischargePower: 8640, maxMPPTPower: 100000 },
	'12x MultiPlus 24/1200/25': { maxACDCPower: 12000, maxBatteryChargePower: 8640, maxBatteryDischargePower: 8640, maxMPPTPower: 100000 },
	'12x MultiPlus 48/1200/13': { maxACDCPower: 12000, maxBatteryChargePower: 8988, maxBatteryDischargePower: 8988, maxMPPTPower: 100000 },
	'12x MultiPlus 12/1600/70': { maxACDCPower: 15600, maxBatteryChargePower: 12096, maxBatteryDischargePower: 12096, maxMPPTPower: 100000 },
	'12x MultiPlus 24/1600/40': { maxACDCPower: 15600, maxBatteryChargePower: 13824, maxBatteryDischargePower: 13824, maxMPPTPower: 100000 },
	'12x MultiPlus 48/1600/20': { maxACDCPower: 15600, maxBatteryChargePower: 13824, maxBatteryDischargePower: 13824, maxMPPTPower: 100000 },
	'12x MultiPlus 12/2000/80': { maxACDCPower: 19200, maxBatteryChargePower: 13824, maxBatteryDischargePower: 13824, maxMPPTPower: 100000 },
	'12x MultiPlus 24/2000/50': { maxACDCPower: 19200, maxBatteryChargePower: 17280, maxBatteryDischargePower: 17280, maxMPPTPower: 100000 },
	'12x MultiPlus 48/2000/25': { maxACDCPower: 19200, maxBatteryChargePower: 17280, maxBatteryDischargePower: 17280, maxMPPTPower: 100000 },
	'12x MultiPlus 12/3000/120': { maxACDCPower: 28800, maxBatteryChargePower: 20736, maxBatteryDischargePower: 20736, maxMPPTPower: 100000 },
	'12x MultiPlus 24/3000/70': { maxACDCPower: 28800, maxBatteryChargePower: 24192, maxBatteryDischargePower: 24192, maxMPPTPower: 100000 },
	'12x MultiPlus 48/3000/35': { maxACDCPower: 28800, maxBatteryChargePower: 24192, maxBatteryDischargePower: 24192, maxMPPTPower: 100000 },
	'12x MultiPlus 24/5000/120': { maxACDCPower: 48000, maxBatteryChargePower: 41472, maxBatteryDischargePower: 41472, maxMPPTPower: 100000 },
	'12x MultiPlus 48/5000/70': { maxACDCPower: 48000, maxBatteryChargePower: 48384, maxBatteryDischargePower: 48384, maxMPPTPower: 100000 },
	'12x Quattro 12/3000/120-50/50': { maxACDCPower: 28800, maxBatteryChargePower: 20736, maxBatteryDischargePower: 20736, maxMPPTPower: 100000 },
	'12x Quattro 24/3000/70-50/50': { maxACDCPower: 28800, maxBatteryChargePower: 24192, maxBatteryDischargePower: 24192, maxMPPTPower: 100000 },
	'12x Quattro 12/5000/220-100/100': { maxACDCPower: 48000, maxBatteryChargePower: 38016, maxBatteryDischargePower: 38016, maxMPPTPower: 100000 },
	'12x Quattro 24/5000/120-100/100': { maxACDCPower: 48000, maxBatteryChargePower: 41472, maxBatteryDischargePower: 41472, maxMPPTPower: 100000 },
	'12x Quattro 48/5000/70-100/100': { maxACDCPower: 48000, maxBatteryChargePower: 48384, maxBatteryDischargePower: 48384, maxMPPTPower: 100000 },
	'12x Quattro 24/8000/200-100/100': { maxACDCPower: 76800, maxBatteryChargePower: 69120, maxBatteryDischargePower: 69120, maxMPPTPower: 100000 },
	'12x Quattro 48/8000/110-100/100': { maxACDCPower: 76800, maxBatteryChargePower: 76032, maxBatteryDischargePower: 76032, maxMPPTPower: 100000 },
	'12x Quattro 48/10000/140-100/100': { maxACDCPower: 96000, maxBatteryChargePower: 96768, maxBatteryDischargePower: 96768, maxMPPTPower: 100000 },
	'12x Quattro 48/15000/200-100/100': { maxACDCPower: 144000, maxBatteryChargePower: 138240, maxBatteryDischargePower: 138240, maxMPPTPower: 100000 },
	'12x Quattro-II 24/5000/120-50': { maxACDCPower: 48000, maxBatteryChargePower: 41472, maxBatteryDischargePower: 41472, maxMPPTPower: 100000 },
	'12x Quattro-II 48/5000/70-50': { maxACDCPower: 48000, maxBatteryChargePower: 48384, maxBatteryDischargePower: 48384, maxMPPTPower: 100000 },
	'15x MultiPlus 12/1200/50': { maxACDCPower: 15000, maxBatteryChargePower: 10800, maxBatteryDischargePower: 10800, maxMPPTPower: 100000 },
	'15x MultiPlus 24/1200/25': { maxACDCPower: 15000, maxBatteryChargePower: 10800, maxBatteryDischargePower: 10800, maxMPPTPower: 100000 },
	'15x MultiPlus 48/1200/13': { maxACDCPower: 15000, maxBatteryChargePower: 11235, maxBatteryDischargePower: 11235, maxMPPTPower: 100000 },
	'15x MultiPlus 12/1600/70': { maxACDCPower: 19500, maxBatteryChargePower: 15120, maxBatteryDischargePower: 15120, maxMPPTPower: 100000 },
	'15x MultiPlus 24/1600/40': { maxACDCPower: 19500, maxBatteryChargePower: 17280, maxBatteryDischargePower: 17280, maxMPPTPower: 100000 },
	'15x MultiPlus 48/1600/20': { maxACDCPower: 19500, maxBatteryChargePower: 17280, maxBatteryDischargePower: 17280, maxMPPTPower: 100000 },
	'15x MultiPlus 12/2000/80': { maxACDCPower: 24000, maxBatteryChargePower: 17280, maxBatteryDischargePower: 17280, maxMPPTPower: 100000 },
	'15x MultiPlus 24/2000/50': { maxACDCPower: 24000, maxBatteryChargePower: 21600, maxBatteryDischargePower: 21600, maxMPPTPower: 100000 },
	'15x MultiPlus 48/2000/25': { maxACDCPower: 24000, maxBatteryChargePower: 21600, maxBatteryDischargePower: 21600, maxMPPTPower: 100000 },
	'15x MultiPlus 12/3000/120': { maxACDCPower: 36000, maxBatteryChargePower: 25920, maxBatteryDischargePower: 25920, maxMPPTPower: 100000 },
	'15x MultiPlus 24/3000/70': { maxACDCPower: 36000, maxBatteryChargePower: 30240, maxBatteryDischargePower: 30240, maxMPPTPower: 100000 },
	'15x MultiPlus 48/3000/35': { maxACDCPower: 36000, maxBatteryChargePower: 30240, maxBatteryDischargePower: 30240, maxMPPTPower: 100000 },
	'15x MultiPlus 24/5000/120': { maxACDCPower: 60000, maxBatteryChargePower: 51840, maxBatteryDischargePower: 51840, maxMPPTPower: 100000 },
	'15x MultiPlus 48/5000/70': { maxACDCPower: 60000, maxBatteryChargePower: 60480, maxBatteryDischargePower: 60480, maxMPPTPower: 100000 },
	'15x Quattro 12/3000/120-50/50': { maxACDCPower: 36000, maxBatteryChargePower: 25920, maxBatteryDischargePower: 25920, maxMPPTPower: 100000 },
	'15x Quattro 24/3000/70-50/50': { maxACDCPower: 36000, maxBatteryChargePower: 30240, maxBatteryDischargePower: 30240, maxMPPTPower: 100000 },
	'15x Quattro 12/5000/220-100/100': { maxACDCPower: 60000, maxBatteryChargePower: 47520, maxBatteryDischargePower: 47520, maxMPPTPower: 100000 },
	'15x Quattro 24/5000/120-100/100': { maxACDCPower: 60000, maxBatteryChargePower: 51840, maxBatteryDischargePower: 51840, maxMPPTPower: 100000 },
	'15x Quattro 48/5000/70-100/100': { maxACDCPower: 60000, maxBatteryChargePower: 60480, maxBatteryDischargePower: 60480, maxMPPTPower: 100000 },
	'15x Quattro 24/8000/200-100/100': { maxACDCPower: 96000, maxBatteryChargePower: 86400, maxBatteryDischargePower: 86400, maxMPPTPower: 100000 },
	'15x Quattro 48/8000/110-100/100': { maxACDCPower: 96000, maxBatteryChargePower: 95040, maxBatteryDischargePower: 95040, maxMPPTPower: 100000 },
	'15x Quattro 48/10000/140-100/100': { maxACDCPower: 120000, maxBatteryChargePower: 120960, maxBatteryDischargePower: 120960, maxMPPTPower: 100000 },
	'15x Quattro-II 24/5000/120-50': { maxACDCPower: 60000, maxBatteryChargePower: 51840, maxBatteryDischargePower: 51840, maxMPPTPower: 100000 },
	'15x Quattro-II 48/5000/70-50': { maxACDCPower: 60000, maxBatteryChargePower: 60480, maxBatteryDischargePower: 60480, maxMPPTPower: 100000 },
	'18x MultiPlus 12/1200/50': { maxACDCPower: 18000, maxBatteryChargePower: 12960, maxBatteryDischargePower: 12960, maxMPPTPower: 100000 },
	'18x MultiPlus 24/1200/25': { maxACDCPower: 18000, maxBatteryChargePower: 12960, maxBatteryDischargePower: 12960, maxMPPTPower: 100000 },
	'18x MultiPlus 48/1200/13': { maxACDCPower: 18000, maxBatteryChargePower: 13482, maxBatteryDischargePower: 13482, maxMPPTPower: 100000 },
	'18x MultiPlus 12/1600/70': { maxACDCPower: 23400, maxBatteryChargePower: 18144, maxBatteryDischargePower: 18144, maxMPPTPower: 100000 },
	'18x MultiPlus 24/1600/40': { maxACDCPower: 23400, maxBatteryChargePower: 20736, maxBatteryDischargePower: 20736, maxMPPTPower: 100000 },
	'18x MultiPlus 48/1600/20': { maxACDCPower: 23400, maxBatteryChargePower: 20736, maxBatteryDischargePower: 20736, maxMPPTPower: 100000 },
	'18x MultiPlus 12/2000/80': { maxACDCPower: 28800, maxBatteryChargePower: 20736, maxBatteryDischargePower: 20736, maxMPPTPower: 100000 },
	'18x MultiPlus 24/2000/50': { maxACDCPower: 28800, maxBatteryChargePower: 25920, maxBatteryDischargePower: 25920, maxMPPTPower: 100000 },
	'18x MultiPlus 48/2000/25': { maxACDCPower: 28800, maxBatteryChargePower: 25920, maxBatteryDischargePower: 25920, maxMPPTPower: 100000 },
	'18x MultiPlus 12/3000/120': { maxACDCPower: 43200, maxBatteryChargePower: 31104, maxBatteryDischargePower: 31104, maxMPPTPower: 100000 },
	'18x MultiPlus 24/3000/70': { maxACDCPower: 43200, maxBatteryChargePower: 36288, maxBatteryDischargePower: 36288, maxMPPTPower: 100000 },
	'18x MultiPlus 48/3000/35': { maxACDCPower: 43200, maxBatteryChargePower: 36288, maxBatteryDischargePower: 36288, maxMPPTPower: 100000 },
	'18x MultiPlus 24/5000/120': { maxACDCPower: 72000, maxBatteryChargePower: 62208, maxBatteryDischargePower: 62208, maxMPPTPower: 100000 },
	'18x MultiPlus 48/5000/70': { maxACDCPower: 72000, maxBatteryChargePower: 72576, maxBatteryDischargePower: 72576, maxMPPTPower: 100000 },
  };





	