import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { FitAddon } from 'xterm-addon-fit';
import { throttle } from 'lodash';
import { Terminal } from 'xterm';
import { useParams } from 'react-router-dom';

import useSocket from '@lifepowr/components/src/components/socket/useSocket';

import 'xterm/css/xterm.css';

import './styles.scss';

function Console(props) {

	const { subscribe, publish } = useSocket();
	const { id, onClose = () => undefined, system } = props;
	const { shadow: { reported = {} } = {}, } = system || {};
	const { connected } = reported;
	const { deviceName: thingName } = useParams();
	const stupid = useParams();
	const [connect, setConnect] = useState(false);
	const [error, setError] = useState(null);
	const [, setCounter] = useState(0);
	const [toWrite, setToWrite] = useState([]);
	const [prevToWrite, setPrevToWrite] = useState([]);
	const [terminal,] = useState(new Terminal({ cursorBlink: true }));
	const [fitAddon,] = useState(new FitAddon());
	const baseTopic = `fleet/devices/${thingName}/console/${id}`;
	//const stateRef = useRef();
	const stdinTopic = `${baseTopic}/stdin`;
	const stdoutTopic = `${baseTopic}/stdout`;
	const configTopic = `${baseTopic}/config`;
	const taskTopic = `fleet/devices/${thingName}/state/interact`;
	const element = `systemConsole-${id}`;

	const consoleConnect = async () => {

		subscribe(stdoutTopic, (data) => {
			const { value } = data;
			const { type } = value;
			const { [type]: internal, counter } = value;

			switch (type) {
				case "data":
					setToWrite([internal, counter]);
					break;
				case "exit":
					terminal.clear();
					terminal.write("Terminal exiting. . .");
					setTimeout(onClose, 3000);
					break;
				default:
					console.log("UNEXPECTED DATA TYPE", value);
			}
		});

		const reply = await publish(taskTopic, { task: 'console', args: { id } }, true);
		if (id !== reply.consoleId) throw new Error(`Wrong id supplied ${id} =/= ${reply.consoleId}`);


		return true;
	}

	useEffect(
		() => {
			setPrevToWrite(
				old => {
					const [internal, counter] = toWrite;
					const [prevInternal, prevCounter] = old;

					console.log()
					if(internal !== prevInternal || prevCounter !== counter) terminal.write(internal);

					return [internal, counter];
				}
			)
		},
		[toWrite],
	);

	useEffect(
		() => {

			const publishResize = throttle((rows, cols) => {
				publish(configTopic, {rows, cols});
			}, 1000);

			const resizeHandler = (entries) => {
				const {contentRect: {height, width} = {}} = entries[0] || {};
				
				if(height !== 0 && width !== 0) fitAddon.fit();
				const { rows, cols } = terminal;
				
				publishResize(rows, cols);
			}

			const dataHandler = (data) => {
				// Update counter here !
				setCounter((old) => {
					publish(stdinTopic, {data, counter: old});
					return old + 1; 
				});
			}
			
			const terminalContainer = document.getElementById(element);
			const resizeObserver = new ResizeObserver(resizeHandler);

			resizeObserver.observe(terminalContainer);
			terminal.open(terminalContainer);
			terminal.write("Connecting...");
			terminal.onData(dataHandler);

			consoleConnect()
				.then( connected => {
					terminal.clear();
					terminal.reset();
					terminal.loadAddon(fitAddon);
					resizeHandler({});
					setConnect(true);
				})
				.catch(e => setError(e.toString()));
		},
		[]
	);

	useEffect( 
		() => {
			if(connect && connected === false){
				terminal.clear();
				terminal.write("Connection lost, exiting. . .");
				console.log('WHATHADASDAS', connected, connect);
				setTimeout( onClose, 3000 );
			}
		},
		[connect, connected]
	);

	useEffect(
		() => {
			if(error){
				terminal.reset();
				terminal.write(`An error occurred: ${error}`);
				console.log("ERRROR", error);
				setTimeout( onClose, 3000 );
			}
		},
		[error]
	);

	return (
		<div className="io-console cancel" id={element} />
	);
}

const StoreConsole = connect((state) => ({ system: state.system }), null, null)(Console);

export default StoreConsole;
