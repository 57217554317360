/**
 * Const used to define the colors used on the charts, regarding to its metrics definition
 */
const COLOR = {
    White: '#fff',
    grayLightest: '#f8f8f8',
    grayLight: '#f2f2f2',
    grayNormal: '#e8e4e0',
    SOC: '#c8c8c8',
    grayDark: '#b4b4b4',
    grayDarker: '#002f2f',
    LOAD: '#001e1e',

    greenLighest: '#F6FBFB',
    greenLight: '#D7EAEA',
    CUMULATIVE: '#ABD1D1',
    greenNormal: '#79b0b0',
    BATTERY: '#4c9292',
    greenEvenDarker: '#207272',
    FREQUENCY: '#0e5c5c',
    EV: '#7c9e63',

    redLighest: '#ffbdbd',
    redLight: '#ff9494',
    GRID: '#e16161',
    redNormal: '#c03a3a',
    INSTANT: '#9c1b1b',
    CHARGE: '#002F2F',
    DISCHARGE: '#79B0B0',
    INJECT: '#f9bc60',
    COMSUME: '#e16161',

    yellowLighest: '#ffe0b1',
    yellowLight: '#ffce86',
    PV: '#f9bc60',
    yellowNormal: '#df9d39',
    yellowDarkest: '#b9791a',
    yellow300: '#edb546',
    teal400: '#5c9091',
    pink300: '#d46a66',
};

export default COLOR;