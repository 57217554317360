import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import createPlotlyComponent from 'react-plotly.js/factory';

import Plotly from "plotly.js";

import ChartRange from '@lifepowr/components/src/utils/chart-range';
import CircularProgressLoader from '@lifepowr/components/src/components-shared/circular-progress-loader-component/circular-progress-loader.component';
import './chart.styles.scss';

import { matrix, transpose } from 'mathjs';

const Plot = createPlotlyComponent(Plotly);

const Chart = ({ showPlot, title, timeRange, setTimeRange, plotData, loading, globalLayout, setRealTime, setResetDefaultRange = null, realTimeMeasure, admin }) => {
    const [revision, setRevision] = useState(() => 0)
    const [datarevision, setDatarevision] = useState(() => 0)

    const initialTime = timeRange[0]
    const finalTime = timeRange[1]
    const [defaultOption, setDefaultOption] = useState(() => { });

    /**
     * refresh the chart
     */
    useEffect(() => {
        if (plotData && globalLayout) {
            setRevision(old => old + 1)
            setDatarevision(old => old + 1)
        }
    }, [globalLayout, plotData])

    const rangeProps = {
        range: Math.round((finalTime - initialTime) / 60),
        onChange: (newValue) => {
            !!setRealTime && setRealTime((newValue === realTimeMeasure));
            if (!!newValue) {
                const now = (new Date()).getTime() / 1000;
                setTimeRange([now - newValue * 60, now]);
            }
        },
        loading,
        disableRealTime: !setRealTime,
        setDefaultOption,
        realTimeMeasure
    };

    const plotProps = {
        data: plotData,
        onRelayout: (e, a, b) => {
            const { "xaxis.range[0]": startStr, "xaxis.range[1]": endStr } = e;
            if (startStr && endStr) {
                const [start, end] = [(new Date(startStr.replace(" ", "T"))).getTime() / 1000, (new Date(endStr.replace(" ", "T"))).getTime() / 1000];
                if (startStr && endStr) {
                    setTimeRange([start, end]);
                    !!setRealTime && setRealTime(false);
                }
            }
        },
        onButtonClicked: (...args) => {
            const [ argsObj = {} ] = args || [];
            const { button = {} } = argsObj;
            const { label, args: argsButton = [] } = button;

            if (label === 'Download' && admin === true ) {
                const [ name, data ] = argsButton;
                let csv = ''
                const test = data.reduce(
                    (acc, line) => {
                        const { name, x, y } = line;
                        const labelCol = name;

                        acc.push(
                            [
                                `${name}_time`,
                                ...x.map(d=>d.toISOString()),
                            ],
                        );
                        acc.push(
                            [
                                name,
                                ...y.map(e=>(e === undefined ? 'N/A' : e)),
                            ],
                        );
                        return acc;
                    },
                    [],
                );

                const mat = matrix(test);
                const csvMat = transpose(mat);
                const max = 10
                let start = 0;
                csvMat.forEach(
                    (el, index) => {
                        start += 1;
                        
                        if (start < max) console.log(el, index);
                        const [elX, elY] = index;
                        const extra = elY === 0 ? '\n' : '';
                        const comma = elY === 0 ? '' : ',';
                        csv += `${csv ? extra : ''}${comma}${el}`;
                    },
                    '',
                );
                var hiddenElement = document.createElement('a');  
                hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);  
                hiddenElement.target = '_blank';  
                  
                //provide the name for the CSV file to be downloaded  
                hiddenElement.download = 'lifepowr_chart_data.csv';  
                hiddenElement.click();  
            }
            /* const cols = newObj.reduce((acc, {x, y, name})=>{

            }); */
        },
        layout: {
            autosize: true,
            title,
            //dragmode: 'pan',
            hovermode: 'closest',
            // legend: { xanchor: 'right', },
            legend: { "orientation": "h", y: -0.275, x: 1, xanchor: 'right', yanchor: 'bottom' },
            margin: { r: 15, t: 0, b: 0, },
            showlegend: true,
            datarevision,
            updatemenus: admin ? [
                {
                    buttons: [
                        {
                            args: ['data', plotData],
                            label: 'Download',
                            method: 'skip',
                            className: 'testing',
                        }
                    ],
                    direction: 'left',
                    showactive: true,
                    x: 0.1,
                    y: 1.1,
                    xanchor: 'left',
                    yanchor: 'top',
                    type: 'buttons'
                },
            ] : undefined,
            ...globalLayout,
        },
        config: {
            displayModeBar: true,
            //scrollZoom: true,
            responsive: true,
            displaylogo: false,
            modeBarButtonsToRemove: ['zoom2d', 'resetScale2d', 'select2d', 'lasso2d', 'hoverClosestCartesian', 'hoverCompareCartesian', 'toImage']
        },
        useResizeHandler: true,
        style: { width: "100%", height: "100%" },
        revision,
    };

    useEffect(() => {
        if (defaultOption && setResetDefaultRange) {
            setResetDefaultRange(defaultOption)
        }
    }, [defaultOption])

    let renderPlot;

    if(showPlot !== undefined) renderPlot = showPlot;
    else renderPlot = true;

    return (
        <div className="chart" style={!!loading ? { opacity: 0.4 } : {}}>
            <ChartRange {...rangeProps} />
            { renderPlot ? <Plot {...plotProps} /> : null }
            {!!loading && <div className="loading"><CircularProgressLoader /></div>}
        </div>
    );
}

Chart.propTypes = {
    globalLayout: PropTypes.any.isRequired,
    title: PropTypes.string,
    timeRange: PropTypes.arrayOf(PropTypes.any).isRequired,
    setTimeRange: PropTypes.func,
    plotData: PropTypes.arrayOf(PropTypes.any).isRequired,
    loading: PropTypes.bool,
    setRealTime: PropTypes.func,
    realTimeMeasure: PropTypes.number.isRequired,
};

export default Chart