import { Box } from "@mui/material";
import Form from "@rjsf/mui";
import { useRef } from "react";
import StepContainer from "./step-container.component";

export default function StepComponent({
  stepId,
  partialKey,
  onChange,
  onClear,
  stepProps,
}) {
  const formRef = useRef(null);
  const { header, footer } = stepProps.helperText || {};
  const content = (
    <>
      {header ? <Box>{header}</Box> : ""}
      <Form
        ref={formRef}
        onChange={() => onChange(stepId, formRef)(partialKey)}
        onClear={onClear(stepId)}
        {...stepProps}
      >
        <div></div>
      </Form>
      {footer ? <Box>{footer}</Box> : ""}
    </>
  );
  return stepProps.skipContainer ? (
    content
  ) : (
    <StepContainer>{content}</StepContainer>
  );
}
