import React, { useState, useEffect } from 'react';

import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Checkbox from '@mui/material/Checkbox';

import { API } from 'Amplify';

import { merge, isEqual } from 'lodash';

import Icon from './easee.png';
import IconCharger from './easeeCharger.jpg';
import './styles.scss';

function ChargerInfo(props){
	const { charger, setCharger, thingName, id } = props;
	const { numberOfPhases = 0 } = charger;
	const onChange = (ev, v) => {
		console.log(ev, v);
		setCharger({numberOfPhases: ev.target.value});
	}
	
	useEffect( () => {
		const rep = API.get( "IO_API", `/ev/easee`, { queryStringParameters: { thingName, chargerId: id } } )
			.then(({maxChargerCurrent, phaseMode}) => {
				setCharger({maxChargerCurrent, numberOfPhases});
			})
			.catch(err => {
				console.log("ERROR", err);
			});
	}, []);

	console.log(charger);

	return (
		<FormControl className="easee-chargers-input-form">
			<InputLabel>
				Number of phases
			</InputLabel>
			<Select value={numberOfPhases} onChange={onChange}>
				<MenuItem disabled value={0}>
					<Typography variant="inherit">
						Select one
					</Typography>
				</MenuItem>
				<MenuItem value={1}>
					<Typography variant="inherit">
						Single-phase
					</Typography>
				</MenuItem>
				<MenuItem value={3}>
					<Typography variant="inherit">
						Three-phase
					</Typography>
				</MenuItem>
			</Select>
		</FormControl>
	);
}

function ChargerIndividual(props){
	const { serialNumber, name, product, createdOn, addCharger, selected, static: staticProp } = props;

	return (
		<Card onClick={staticProp ? undefined : ()=>addCharger({serialNumber})}>
			<CardHeader
				title={name}
				action={
					staticProp ? null : <Checkbox checked={selected} style={{ backgroundColor: 'transparent' }}/>
				}
			/>
			<CardMedia
				component="img"
		        height="150"
		        image={IconCharger}
		        alt="charger"
			/>
			<CardContent>
				<Typography variant='subtitle1' color='text.secondary'>
					Type: {product}
				</Typography>
				<Typography variant='subtitle1' color='text.secondary'>
					S/N: {serialNumber}
				</Typography>
				<Typography variant='subtitle1' color='text.secondary'>
					Added: {createdOn ? createdOn.split('T')[0] : "Unknown"}
				</Typography>
			</CardContent>
		</Card>
	);
}

function Chargers(props){
	const { choices, chargers, setChargers, setButtonDisabled, state = [], Charger, thingName, setButtonTitle, setError, setChoices } = props;
	const { accessToken, username, password, devices = [], chosen = [] } = choices;
	const [actionId, setActionId] = useState('');
	const [actionPin, setActionPin] = useState('');
	const [loading, setLoading] = useState(false);
	const [action, setAction] = useState(null);
	let shouldSubmit = false;

	console.log("WHAT?!", state);

	function addCharger(c){
		const idx = chosen.indexOf(c.serialNumber);

		if(idx < 0) {
			setChoices(o=>({...o, chosen: [...chosen, c.serialNumber]}));
		} else {
			setChoices(o=>({...o, chosen: chosen.toSpliced(idx, 1)}));
		}
	}

	useEffect(
		() => {
			setButtonDisabled(chosen.length < 1);
		},
		[chosen]
	)

	useEffect(
		() => {
			async function getChargers() {
				setLoading(true);
				setError('');
				const res = await fetch(
					'https://api.easee.com/api/chargers',
					{
						method: 'GET',
						headers: {
							'content-type': 'application/json',
							'Authorization': `Bearer ${accessToken}`,
						}
					}
				);
				const result = await res.json();
				if (!res.ok) {
					const { status = 'Unknown', title = 'Unknown' } = result;
					setError(`${status} - ${title}`);
				}
				const devicesPromises = await Promise.allSettled(
					result.map(async ({id, name, createdOn})=>{
						const resTemp = await fetch(
							`https://api.easee.com/api/chargers/${id}/details`,
							{
								method: 'GET',
								headers: {
									'content-type': 'application/json',
									'Authorization': `Bearer ${accessToken}`,
								},
							},
						);
						const resultTemp = await resTemp.json();
						if (!resTemp.ok) {
							const { status: tmpStatus = 'Unknown', title: tmpTitle = 'Unknown' } = resultTemp;
							setError(`${tmpStatus} - ${tmpTitle}`);
						}
						return {...resultTemp, name, createdOn};
					})
				);
				const chgDevices = devicesPromises.map((d)=>d.value);
				setChoices(o=>({...o, devices: chgDevices}));
				setLoading(false);
			}
			getChargers().catch((e)=>setError(e));
		},
		[],
	);

	const components = devices.filter(({serialNumber})=>(!(serialNumber in state))).map((c)=>{
		const props = {
			key: c.serialNumber,
			...c,
			selected: chosen.indexOf(c.serialNumber) >= 0,
			addCharger
		};
		return (
			<ChargerIndividual {...props} />
		)
	})

	return (
		<div className="easee-chargers-list-new">
			{
				devices.length > 0 ? components : <div>No chargers available</div>
			}
		</div>
	);

	/* useEffect( () => {
		const {chargers : stateChargers = []} = state;
		const newChargers = Object.fromEntries(
			stateChargers.map( charger => {
				const { id } = charger;
				const localId = `easee-${id}`;

				return [localId, charger];
			})
		);

		setChargers(newChargers);
		setButtonTitle('Submit');

	}, []);

	useEffect( () => {
		const valid = Object.entries(chargers).every( ([label, charger = {}]) => {
			if(charger.toDelete) return true;
			return Object.entries(charger).every( ([k, v]) => Boolean(v));
		});
		const equal = Object.keys(chargers).length === state.length; //Play with this or maybe each state variable, dunno
		setButtonDisabled(!valid);
	}, [chargers]);

	const chargerComps = Object.entries(chargers || {}).map( ([name, charger]) => {
		const { id, toDelete } = charger;
		const localId = `easee-${id}`;
		const valid = Object.entries(charger).every( ([k, v]) => Boolean(v));

		const onClick = () => {
			setActionId(id);
			setAction('delete');
		};
		const setCharger = (newCharger, rep = true) => {
			const {[localId]: oldCharger} = chargers;
			const newState = merge(oldCharger, newCharger);

			setChargers({[localId]: newState});
		};
		const chargerProps = {onClick, charger, setCharger, label: localId, key: localId, valid };
		const chargerInfoProps = { thingName, charger, id };

		return !Boolean(toDelete) ? (
			<Charger {...chargerProps}>
				<ChargerInfo {...chargerInfoProps}/>
			</Charger>
		) : null;
	});

	const onChangeActionField = (type) => (ev , v) => {
		if(type === 'id') setActionId(ev.target.value);
		else if(type === 'pin') setActionPin(ev.target.value);
	};
	const newCharger = () => {
		setAction(!action ? 'add' : null);
		setActionId('');
	}

	const chargerSubmit = async () => {
		let chargerObj;

		setLoading(true);
		setError('');
		try{
			if(action === 'add'){
				const localId = `easee-${actionId}`;
				const rep = await API.post( "IO_API", `/ev/easee`, { body: { thingName, chargerId: actionId, pinCode: actionPin } } );
				chargerObj = {id: actionId, label: localId};
				setChargers({[localId]: chargerObj});
				setAction(null);
			}
			else if(action === 'delete'){
				const localId = `easee-${actionId}`;
				const {[localId]: localCharger} = chargers;
				//const rep = await API.del( "IO_API", `/ev/easee`, { body: { thingName, chargerId: actionId, pinCode: actionPin } } );
				setAction(null);
				setChargers({[localId]: {...localCharger, toDelete: actionPin}});
			}
		}
		catch(e){
			const body = e?.response?.data?.body || `${e}`;

			setError(`Could not ${action} charger: ${body}`);
			console.log("ERROR", `${e}`);
		}
		setLoading(false);
		setActionId('');
		setActionPin('');
	}
	let actionTitle = 'Add charger';
	if(action === 'delete') actionTitle = 'Remove device';
	const existsError = action==='add' && `easee-${actionId}` in chargers;

	const actionDiv =(
		<div className="easee-chargers-step-action">
			<Typography className={`${action ? '' : 'hidden'}`} variant={"h5"}>{actionTitle}</Typography>
			<TextField error={existsError} helperText={existsError ? 'Device already added' : ''} disabled={action !=='add'} className={`${action ? '' : 'hidden'}`} label={"Charger Id"} value={actionId} onChange={onChangeActionField('id')}/>
			<TextField className={`${action ? '' : 'hidden'}`} label={"Charger Pin"} type="password" value={actionPin} onChange={onChangeActionField('pin')}/>
			<Button disabled={!actionId || !actionPin || loading || existsError} className={`${action ? '' : 'hidden'}`} onClick={chargerSubmit}>{loading ? 'Loading...' : 'Submit'}</Button>
			<Button onClick={newCharger}>{!action ? 'Add Charger' : 'Cancel'}</Button>
		</div>
	);

	return (
		<div  className="easee-chargers-step-container">
			<div className={`easee-chargers-step-list ${action? 'hidden': ''}`}>
				{
					chargerComps.length > 0 ? 
						chargerComps : 
						<div className="easee-chargers-step-nochargers">No chargers found</div>
				}
			</div>
			{actionDiv}
		</div>
	); */
}

function Confirm(props){
	const { choices } = props;
	const { accessToken, username, password, devices = [], chosen = [] } = choices;

	const components = chosen.map((serial)=>{
		const charger = devices.filter(c=>c.serialNumber === serial).pop() || {};
		const props = {
			static: true,
			key: charger.serialNumber,
			...charger
		};

		return (
			<ChargerIndividual 
				{...props}
			/>
		);
	})

	return(
		<>
			<div>Confirm your choices</div>
			<div className="easee-chargers-list-new">
				{ components }
			</div>
		</>
	);
}

function count (state = []){
	const { chargers = [] } = state;

	return chargers.length;
}

function LogIn(props){
	const { setChoices } = props;
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');

	return (
		<div>
			<TextField
				label='Username'
				name="userName"
				onChange={(ev)=>setChoices((o)=>({...o, username: ev.target.value}))}
				autoComplete="easee-pw"
			/>
			<TextField
				label='Password'
				type="password"
				name="password"
				autoComplete="new-password"
				onChange={(ev)=>setChoices((o)=>({...o, password: ev.target.value}))}
			/>
		</div>
	);
}

const steps = [
	{
		Component: LogIn,
		label: 'LogIn',
		action: async (props) => {
			const { choices = {}, setChoices } = props;
			const { username, password } = choices;
			const res = await fetch(
				'https://api.easee.com/api/accounts/login',
				{
					method: 'POST',
					body: JSON.stringify({userName: username, password}),
					headers: {
						'content-type': 'application/json',
					}
				}
			);
			const result = await res.json();
			if (!res.ok) {
				const { status = 'Unknown', title = 'Unknown' } = result;
				throw new Error(`${status} - ${title}`);
			}

			setChoices(o => ({...o, ...result}));
		},
	},
	{
		Component: Chargers,
		label: "Chargers",
	},
	{
		Component: Confirm,
		label: "Confirm",
		action: async (props) => {
			const { choices, state } = props;
			const { chosen = [], devices = [], username, password } = choices || {};
			
			const newState = chosen.reduce(
				(acc, serial)=>{
					const charger = devices.filter((c)=>c.serialNumber===serial).pop() || {};
					acc[serial] = {
						enabled: true,
						label: charger.name,
						username,
						password,
					};
					return acc;
				},
				{},
			);

			return newState
		}
	}
];

export default { Icon, count, steps, title: 'Easee' };