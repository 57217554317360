import COLOR from '../colors-variables';
/**
 * This module contains the definitions for the custom charts in the application, this specific module defines the charts for the general data from individual systems
 */
const cumulative = {
  name: 'Cumulative (kWh)', type: 'scatter', mode: 'lines', marker: { color: COLOR.CUMULATIVE }, yaxis: 'y2',
};
const instant = {
  name: 'Instant (kWh)', type: 'scatter', mode: 'lines', marker: { color: COLOR.INSTANT },
};
const charge = {
  name: 'Charge (kWh)', type: 'bar', mode: 'lines', marker: { color: COLOR.CHARGE },
};
const discharge = {
  name: 'Discharge (kWh)', type: 'bar', mode: 'lines', /* yaxis: 'y2', */ marker: { color: COLOR.DISCHARGE },
};
const solar = {
  name: 'Solar (kWh)', type: 'bar', mode: 'lines', marker: { color: COLOR.PV },
};
const home = {
  name: 'Home (kWh)', type: 'bar', mode: 'lines', marker: { color: COLOR.LOAD },
};
const inject = {
  name: 'Inject (kWh)', type: 'bar', mode: 'lines', marker: { color: COLOR.INJECT },
};
const consume = {
  name: 'Consume (kWh)', type: 'bar', mode: 'lines', marker: { color: COLOR.CONSUME },
};

const batSavings = {
  name: 'Battery (€)', type: 'bar', mode: 'lines', marker: { color: COLOR.teal400 },
};
const pvSavings = {
  name: 'PV (€)', type: 'bar', mode: 'lines', marker: { color: COLOR.yellow300 },
};
const lpSavings = {
  name: 'Flexbox (€)', type: 'bar', mode: 'lines', marker: { color: COLOR.pink300 },
};

const yaxisCumulative = {
  title: 'Cumulative (kWh)', overlaying: 'y', side: 'right', /* autorange: true, */ rangemode: 'tozero', showgrid: false,
};
const yaxisInstant = { title: 'Energy (kWh)' };
const yaxisInstant2 = { title: 'Instant (kWh)', autorange: true, rangemode: 'tozero' };
const yaxisInstant3 = { title: 'Currency (€)' };

export default {
  General: {
    measures: [
      'MeterPowerFiltered', 'LoadPowerFiltered',
      'batteryVoltageInvFiltered', 'batteryCurrentInvFiltered',
      'totalPVPowerFiltered', 'totalEVPower',
    ],
    calc: (data, composite, range = []) => data.reduce((acc, datum) => {
      const {
        timestamp, MeterPowerFiltered, LoadPowerFiltered,
        batteryVoltageInvFiltered, batteryCurrentInvFiltered, totalPVPowerFiltered, totalEVPower,
      } = datum;
      const ts = new Date(timestamp);
      const batMult = batteryVoltageInvFiltered * batteryCurrentInvFiltered;

      const bat = !isNaN(batMult) ? batMult : null;
      const pv = totalPVPowerFiltered;
      const meter = MeterPowerFiltered;
      const load = LoadPowerFiltered;
      const ev = totalEVPower;

      acc.pv[1].push(pv);
      acc.pv[0].push(ts);
      acc.bat[1].push(bat);
      acc.bat[0].push(ts);
      acc.meter[1].push(meter);
      acc.meter[0].push(ts);
      acc.load[1].push(load);
      acc.load[0].push(ts);
      acc.ev[0].push(ts);
      acc.ev[1].push(ev);

      return acc;
    }, {
      pv: [[], []], bat: [[], []], meter: [[], []], load: [[], []], soc: [[], []], ev: [[], []],
    }),
    layouts: {
      pv: {
        name: 'Solar (W)',
        type: 'scatter',
        mode: 'lines',
        marker: { color: COLOR.PV },
      },
      bat: {
        name: 'Battery (W)',
        type: 'scatter',
        mode: 'lines',
        marker: { color: COLOR.BATTERY },
      },
      meter: {
        name: 'Grid (W)',
        type: 'scatter',
        mode: 'lines',
        marker: { color: COLOR.GRID },
      },
      load: {
        name: 'Home (W)',
        type: 'scatter',
        mode: 'lines',
        marker: { color: COLOR.LOAD },
      },
      ev: {
        name: 'EV (W)',
        type: 'scatter',
        mode: 'lines',
        marker: { color: COLOR.EV },
      },
    },
    global: { yaxis: { title: 'Power (W)', autorange: true } },
  },
  Home: {
    measures: ['LoadPower', 'LoadPowerFiltered'],
    calc: (data, composite, range = []) => {
      let charge = 0; let
        discharge = 0;
      let prevTimestamp = null;
      const [start = 0, end = 0] = range;
      const timeRange = end - start; let currentBlockTs; let block; let
        extra = 0;
      const now = new Date();

      if (timeRange <= 60 * 60 * 4) block = 60 * 1000.0;
      else if (timeRange < 60 * 60 * 24 * 7) block = 900 * 1000.0;
      else if (timeRange <= 60 * 60 * 24 * 30) {
        block = 60 * 60 * 24 * 1000.0;
        extra = now.getTimezoneOffset() * 60 * 1000;
      } else if (timeRange <= 60 * 60 * 24 * 365) {
        block = 60 * 60 * 24 * 7 * 1000.0;
        extra = now.getTimezoneOffset() * 60 * 1000;
      }

      return data.reduce((acc, datum, index) => {
        const { timestamp, LoadPower, LoadPowerFiltered } = datum;
        const { home: [chargeX = [], chargeY = []] } = acc;
        const ts = new Date(timestamp);
        let power = null;
        let dt = 0;

        if (!currentBlockTs) currentBlockTs = new Date(Math.round(ts.getTime() / (block)) * block);

        dt = prevTimestamp ? (ts - prevTimestamp) / 1000.0 / 3600.0 : 0;

        power = Math.abs(!isNaN(LoadPowerFiltered) ? LoadPowerFiltered : LoadPower) * dt / 1000.0;

        if (ts - currentBlockTs >= block || index === data.length - 1) {
          acc = {
            home: [
              [...chargeX, new Date(currentBlockTs.getTime() + extra)],
              [...chargeY, charge],
            ],
          };
          charge = 0;
          discharge = 0;
          currentBlockTs = new Date(Math.round(ts.getTime() / (block)) * block);
        }

        charge += power;

        prevTimestamp = ts;

        return acc;
      }, { home: [] });
    },
    layouts: { home },
    global: {
      yaxis: yaxisInstant,
    },
  },
  Solar: {
    measures: ['totalPVPowerFiltered'],
    calc: (data, composite, range = []) => {
      let charge = 0; let
        discharge = 0;
      let prevTimestamp = null;
      const [start = 0, end = 0] = range;
      const timeRange = end - start; let currentBlockTs; let block; let
        extra = 0;
      const now = new Date();

      if (timeRange <= 60 * 60 * 4) block = 60 * 1000.0;
      else if (timeRange < 60 * 60 * 24 * 7) block = 900 * 1000.0;
      else if (timeRange <= 60 * 60 * 24 * 30) {
        block = 60 * 60 * 24 * 1000.0;
        extra = now.getTimezoneOffset() * 60 * 1000;
      } else if (timeRange <= 60 * 60 * 24 * 365) {
        block = 60 * 60 * 24 * 7 * 1000.0;
        extra = now.getTimezoneOffset() * 60 * 1000;
      }

      return data.reduce((acc, datum, index) => {
        const {
          timestamp, totalPVPowerFiltered,
        } = datum;
        const { solar: [chargeX = [], chargeY = []] } = acc;
        const ts = new Date(timestamp);
        let power = null;
        let dt = 0;

        if (!currentBlockTs) currentBlockTs = new Date(Math.round(ts.getTime() / (block)) * block);

        dt = prevTimestamp ? (ts - prevTimestamp) / 1000.0 / 3600.0 : 0;

        power = ((totalPVPowerFiltered || 0)) * dt / 1000.0;

        if (ts - currentBlockTs >= block || index === data.length - 1) {
          acc = {
            solar: [
              [...chargeX, new Date(currentBlockTs.getTime() + extra)],
              [...chargeY, charge],
            ],
          };
          charge = 0;
          discharge = 0;
          currentBlockTs = new Date(Math.round(ts.getTime() / (block)) * block);
        }

        charge += power;

        prevTimestamp = ts;

        return acc;
      }, { solar: [] });
    },
    layouts: { solar },
    global: {
      yaxis: yaxisInstant,
    },
  },
  Batteries: {
    measures: ['batteryVoltageInv', 'batteryVoltageInvFiltered', 'batteryCurrentInv', 'batteryCurrentInvFiltered', 'batteryPowerNegPartFiltered', 'batteryPowerPosPartFiltered'],
    calc: (data, composite, range = []) => {
      let charge = 0; let
        discharge = 0;
      let prevTimestamp = null;
      const [start = 0, end = 0] = range;
      const timeRange = end - start; let currentBlockTs; let block; let
        extra = 0;
      const now = new Date();

      if (timeRange <= 60 * 60 * 4) block = 60 * 1000.0;
      else if (timeRange < 60 * 60 * 24 * 7) block = 900 * 1000.0;
      else if (timeRange <= 60 * 60 * 24 * 30) {
        block = 60 * 60 * 24 * 1000.0;
        extra = now.getTimezoneOffset() * 60 * 1000;
      } else if (timeRange <= 60 * 60 * 24 * 365) {
        block = 60 * 60 * 24 * 7 * 1000.0;
        extra = now.getTimezoneOffset() * 60 * 1000;
      }

      return data.reduce((acc, datum, index) => {
        const {
          timestamp, batteryVoltageInv, batteryVoltageInvFiltered, batteryCurrentInv, batteryCurrentInvFiltered, batteryPowerNegPartFiltered, batteryPowerPosPartFiltered,
        } = datum;
        const { charge: [chargeX = [], chargeY = []], discharge: [dischargeX = [], dischargeY = []] } = acc;
        const ts = new Date(timestamp);
        let bat = null; let
          batPower = null;
        let dcVal = null;
        let cVal = null;
        let dt = 0;

        if (!currentBlockTs) currentBlockTs = new Date(Math.round(ts.getTime() / (block)) * block);

        batPower = (batteryVoltageInvFiltered || batteryVoltageInv) * (batteryCurrentInvFiltered || batteryCurrentInv);
        dt = prevTimestamp ? (ts - prevTimestamp) / 1000.0 / 3600.0 : 0;

        dcVal = ((
          batteryPowerPosPartFiltered || (
            batPower > 0
              ? batPower
              : 0
          )
        ) * dt) / 1000.0;
        cVal = ((
          batteryPowerNegPartFiltered || (
            batPower < 0
              ? batPower
              : 0
          )
        ) * dt) / 1000.0;

        bat = batPower * dt / 1000.0;

        if (ts - currentBlockTs >= block || index === data.length - 1) {
          acc = {
            charge: [
              [...chargeX, new Date(currentBlockTs.getTime() + extra)],
              [...chargeY, charge],
            ],
            discharge: [
              [...dischargeX, new Date(currentBlockTs.getTime() + extra)],
              [...dischargeY, discharge],
            ],
          };
          charge = 0;
          discharge = 0;
          currentBlockTs = new Date(Math.round(ts.getTime() / (block)) * block);
        }

        discharge += dcVal;
        charge += cVal;

        prevTimestamp = ts;

        return acc;
      }, { charge: [], discharge: [] });
    },
    layouts: { charge, discharge },
    global: {
      yaxis: yaxisInstant,
      barmode: 'overlay',
    },
  },
  'Batteries (lines)': {
    measures: ['batteryVoltageInv', 'batteryVoltageInvFiltered', 'batteryCurrentInv', 'batteryCurrentInvFiltered'],
    calc: (data, composite) => {
      let cumulative = 0;
      let instant = 0;
      let prevTimestamp = null;
      return data.reduce((acc, datum) => {
        const {
          timestamp, batteryVoltageInv, batteryVoltageInvFiltered, batteryCurrentInv, batteryCurrentInvFiltered,
        } = datum;
        const ts = new Date(timestamp);
        let bat = null;
        let dt = 0;

        bat = (batteryVoltageInvFiltered || 0) * (batteryCurrentInvFiltered || 0);
        dt = prevTimestamp ? (ts.getTime() - prevTimestamp.getTime()) / 1000.0 / 3600.0 : 0;

        instant = bat * dt / 1000.0;
        cumulative += instant;

        if (Object.keys(acc).length < 1) acc = { cumulative: [[], []], instant: [[], []] };

        acc.cumulative[1].push(cumulative);
        acc.cumulative[0].push(ts);
        acc.instant[1].push(instant);
        acc.instant[0].push(ts);
        prevTimestamp = ts;

        return acc;
      }, {});
    },
    layouts: { cumulative, instant },
    global: {
      yaxis: yaxisInstant2,
      yaxis2: yaxisCumulative,
    },
  },
  Grid: {
    measures: ['MeterPower', 'MeterPowerFiltered', 'MeterPowerPosPartFiltered', 'MeterPowerNegPartFiltered'],
    calc: (data, composite, range = []) => {
      let charge = 0; let
        discharge = 0;
      let prevTimestamp = null;
      const [start = 0, end = 0] = range;
      const timeRange = end - start; let currentBlockTs; let block; let
        extra = 0;
      const now = new Date();

      if (timeRange < 60 * 60 * 4) block = 60 * 1000.0;
      else if (timeRange < 60 * 60 * 24 * 7) block = 900 * 1000.0;
      else if (timeRange <= 60 * 60 * 24 * 30) {
        block = 60 * 60 * 24 * 1000.0;
        extra = now.getTimezoneOffset() * 60 * 1000;
      } else if (timeRange <= 60 * 60 * 24 * 365) {
        block = 60 * 60 * 24 * 7 * 1000.0;
        extra = now.getTimezoneOffset() * 60 * 1000;
      }

      return data.reduce((acc, datum, index) => {
        const {
          timestamp, MeterPower, MeterPowerFiltered, MeterPowerPosPartFiltered, MeterPowerNegPartFiltered,
        } = datum;
        const { inject: [chargeX = [], chargeY = []], consume: [dischargeX = [], dischargeY = []] } = acc;
        const ts = new Date(timestamp);
        const bat = null; const
          meter = null;
        let dcVal = null;
        let cVal = null;
        let dt = 0;

        if (!currentBlockTs) currentBlockTs = new Date(Math.round(ts.getTime() / (block)) * block);

        dt = prevTimestamp ? (ts - prevTimestamp) / 1000.0 / 3600.0 : 0;
        // meterVal = (MeterPowerFiltered > 0 ? ) || MeterPowerFiltered
        dcVal = ((
          MeterPowerNegPartFiltered || (
            MeterPowerFiltered < 0
              ? MeterPowerFiltered
              : 0
          )
        ) * dt) / 1000.0;
        cVal = ((
          MeterPowerPosPartFiltered || (
            MeterPowerFiltered > 0
              ? MeterPowerFiltered
              : 0
          )
        ) * dt) / 1000.0;

        if (ts - currentBlockTs >= block || index === data.length - 1) {
          // eslint-disable-next-line no-param-reassign
          acc = {
            inject: [
              [...chargeX, new Date(currentBlockTs.getTime() + extra)],
              [...chargeY, charge],
            ],
            consume: [
              [...dischargeX, new Date(currentBlockTs.getTime() + extra)],
              [...dischargeY, discharge],
            ],
          };
          charge = 0;
          discharge = 0;
          currentBlockTs = new Date(Math.round(ts.getTime() / (block)) * block);
        }

        discharge += dcVal;
        charge += cVal;

        prevTimestamp = ts;

        return acc;
      }, { inject: [], consume: [] });
    },
    layouts: { inject, consume },
    global: {
      yaxis: yaxisInstant,
      barmode: 'overlay',
    },
  },
  /* Savings: {
    measures: ['loadOnlyBill', 'savingsDueToDumbBat', 'savingsDueToLifepowr', 'savingsDueToPV'],
    calc: (data, composite, range = []) => (
      data.reduce(
        (acc, datum, index) => {
          const {
            timestamp, savingsDueToDumbBat, savingsDueToLifepowr, savingsDueToPV
          } = datum;
          const {
            batSavings: [batX = [], batY = []],
            lpSavings: [lpX = [], lpY = []],
            pvSavings: [pvX = [], pvY = []],
          } = acc;
          let lp = 0;
          let pv = 0;
          let bat = 0;
          const ts = new Date(timestamp);

          if (savingsDueToLifepowr < 0) {
            bat = savingsDueToDumbBat + savingsDueToLifepowr;
            lp = 0;
          } else {
            lp = savingsDueToLifepowr;
            bat = savingsDueToDumbBat;
          }

          if (bat < 0) {
            pv = bat + savingsDueToPV;
            bat = 0;
          } else {
            pv = savingsDueToPV;
          }

          if (pv < 0) {
            pv = 0;
          }


          acc = {
            batSavings: [
              [
                ...batX, ts,
              ],
              [
                ...batY, bat,
              ],
            ],
            lpSavings: [
              [
                ...lpX, ts,
              ],
              [
                ...lpY, lp,
              ],
            ],
            pvSavings: [
              [
                ...pvX, ts,
              ],
              [
                ...pvY, pv,
              ],
            ],
          };
          return acc;
        },
        { batSavings: [[], []], pvSavings: [[], []], lpSavings: [[], []] },
      )
    ),
    layouts: { batSavings, pvSavings, lpSavings },
    global: {
      yaxis: yaxisInstant3,
      barmode: 'stack',
    },
  }, */
};
