import React, { useState, useEffect, isValidElement, cloneElement, Children } from 'react';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Badge from '@mui/material/Badge';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Tooltip from '@mui/material/Tooltip';
import Slider from '@mui/material/Slider';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import Alert from '@mui/material/Alert';

import AppsIcon from '@mui/icons-material/Apps';
import ListItemIcon from '@mui/material/ListItemIcon';
import FormControlLabel from '@mui/material/FormControlLabel';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SettingsInputComponentIcon from '@mui/icons-material/SettingsInputComponent';
import ScheduleIcon from '@mui/icons-material/Schedule';
import WarningIcon from '@mui/icons-material/Warning';
import FastForwardIcon from '@mui/icons-material/FastForward';
import SlowMotionVideoIcon from '@mui/icons-material/SlowMotionVideo';
//import EcoIcon from '@mui/icons-material/Eco';
import EnergySavingsLeafIcon from '@mui/icons-material/EnergySavingsLeaf';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DeleteIcon from '@mui/icons-material/Delete';
import HelpIcon from '@mui/icons-material/Help';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import SolarPowerIcon from '@mui/icons-material/SolarPower';
import ElectricCarIcon from '@mui/icons-material/ElectricCar';
import OtherHousesIcon from '@mui/icons-material/OtherHouses';
import PowerOffIcon from '@mui/icons-material/PowerOff';

import TimePicker from 'react-time-picker';

import { merge } from "lodash";

const modes = [
	// {value: 'trickle', label: 'Slow', Icon: SlowMotionVideoIcon, explanation: 'Slow mode: Constantly charges at the slowest possible rate (6A)'},
	{value: 'fast', label: 'Peak avoidance', Icon: FastForwardIcon, explanation: 'Your EV will charge as fast as it can without increasing the month peak.', exampleText: 'You want to have your EV ready to go as fast as possible without incurring excessive capacity tariff costs.'},
	{value: 'urgent', label: 'Urgent', Icon: WarningIcon, explanation: 'Your EV will charge at the requested rate, but if there are other large consumers it will slow down to avoid tripping the fuse.', controlText: 'Charging Current: the requested current to charge the EV at. FlexIO will deliver this unless it risks tripping the fuse.', exampleText: 'You want to have your EV ready to go as fast as possible no matter the cost.'},
	{value: 'smart', label: 'Smart', Icon: DirectionsCarIcon, explanation: 'Your EV will charge faster when energy is cheap and abundant, and slow down when energy is scarce and expensive.', exampleText: 'You want to have your EV ready to go short-term but only so fast as a cost-effective strategy allows. In the morning, energy is usually expensive so the car will slow down its charging rate. As the solar panels start producing power your EV will increase its charging rate in tandem to make maximal use of the solar panels.', controlText: 'Charging speed: FlexIO aims to reach faster or slower charging, depending on the circumstances. This is achieved over longer periods of time'},
	{value: 'economy', label: 'Eco', Icon: EnergySavingsLeafIcon, explanation: 'Your EV will only charge when the energy price is below your requested value.', controlText: 'Energy price: The maximum energy price you are willing to pay to charge their car.', exampleText: 'You don\'t need to have your EV available short-term and only want the best price. Entering a value of 0 €/kWh means charging your EV only when free electricity is available. This will happen when your solar panels are producing a lot of power or when you have dynamic tariff and energy price is negative. You can increase this to a value of, for example, 0,2 €/kWh hour. This will allow the EV to charge during off-peak periods'},
	{value: 'solar', label: 'Solar', Icon: SolarPowerIcon, explanation: 'Your EV will exclusively use your energy that would otherwise be injected into the grid.', exampleText: 'You don\'t need to have your EV available short-term and want to exclusively charge it with renewable energy'},
];

const extraModes = [
	{value: 'V2H', label: 'Vehicle-to-home', Icon: OtherHousesIcon, explanation: 'Your EV will work as a secondary home battery for self-sufficiency, the EMS will optimize which battery (car or home) will be used to compensate loads. Excess production (e.g. solar power) will be used to charge the car battery.', exampleText: 'If you turn on the oven the car battery can be used to compensate the load, avoiding additional costs on grid consumption'},
	{value: 'smartV2G', label: 'Smart vehicle-to-grid', Icon: ElectricCarIcon, explanation: 'Your EV will work alongside the home battery trying to make the most of the energy market.', exampleText: 'It will charge faster when energy prices are low and discharge when prices hit high values.'},
	{value: 'stop', label: 'Stop Charging', Icon: PowerOffIcon, explanation: 'Your EV will stop charging.', exampleText: 'E.g.: The user needs to stop the charging process from the charger side.'},
];

function StrategySimple(props){
	const { className, selected, strategy, setStrategy, charger, setCharger, chargerSettings, loading, disableFirst } = props;
	const { maxChargingCurrent = 0, maxAvailableCurrent = 32, maximumElectricityPrice=0, numberOfPhases = 1, chargerType } = charger || {};
	const { mode = 'slow' } = strategy || {};
	const [strat, setStrat] = useState(strategy);
	const { maxChargingCurrent: maxSettings } = chargerSettings || {};
	const defaultValue = Number.isNaN(maxChargingCurrent) ? (maxSettings || 6) : maxChargingCurrent;
	const defaultValuePrice = Number.isNaN(maximumElectricityPrice) ? 0 : maximumElectricityPrice;
	const [localCurrent, setLocalCurrent] = useState(defaultValue);
	const [localPrice, setLocalPrice] = useState(defaultValuePrice);
	const [localPercentage, setLocalPercentage] = useState((defaultValue / maxAvailableCurrent) * 100);
	const [first, setFirst] = useState(true);
	const change = (type) => (ev) => {
		let val = ev?.target?.value || ev;
		setStrat( old => ({...old, [type]: val}));
	}

	const showSlider = ['smart', 'urgent', 'economy', 'V2H', 'smartV2G'].includes(mode);
	const sliderValues = {
		economy: {
			valueFun: (val) => {
				return { maximumElectricityPrice: val };
			},
			localValueSet: (val) => {
				setLocalPrice(val);
			},
			value: localPrice,
			max: 1,
			label: 'Max. Electricity Price (€/kWh)',
			step: 0.01,
			showValue: true,
		},
		urgent: {
			valueFun: (val) => {
				return { maxChargingCurrent: val };
			},
			localValueSet: (val) => {
				setLocalCurrent(val);
			},
			value: Math.round(localCurrent),
			max: maxAvailableCurrent || 32,
			label: 'Max. Current (A)',
			step: 1,
			showValue: true,
		},
		smart: {
			valueFun: (val) => {
				return { maxChargingCurrent: (val / 100) * (maxAvailableCurrent || 32) };
			},
			localValueSet: (val) => {
				setLocalCurrent((val / 100) * (maxAvailableCurrent || 32));
			},
			value: localCurrent / (maxAvailableCurrent || 32) * 100,
			max: 100,
			label: '',
			step: 1,
			showValue: false,
			marks: [
				{
					value: 0,
					label: 'Slow',
				},
				{
					value: 100,
					label: 'Fast',
				},
			]
		},
		V2H: {
			valueFun: (val) => {
				return { maxChargingCurrent: (val / 100) * (maxAvailableCurrent || 32) };
			},
			localValueSet: (val) => {
				setLocalCurrent((val / 100) * (maxAvailableCurrent || 32));
			},
			value: localCurrent / (maxAvailableCurrent || 32) * 100,
			max: 100,
			label: '',
			step: 1,
			showValue: false,
			marks: [
				{
					value: 0,
					label: 'Slow',
				},
				{
					value: 100,
					label: 'Fast',
				},
			]
		},
		smartV2G: {
			valueFun: (val) => {
				return { maxChargingCurrent: (val / 100) * (maxAvailableCurrent || 32) };
			},
			localValueSet: (val) => {
				setLocalCurrent((val / 100) * (maxAvailableCurrent || 32));
			},
			value: localCurrent / (maxAvailableCurrent || 32) * 100,
			max: 100,
			label: '',
			step: 1,
			showValue: false,
			marks: [
				{
					value: 0,
					label: 'Slow',
				},
				{
					value: 100,
					label: 'Fast',
				},
			]
		}
	};

	useEffect(
		() => {
			setLocalCurrent(maxChargingCurrent);
		},
		[maxChargingCurrent],
	);

	useEffect(
		() => {
			setLocalPrice(maximumElectricityPrice);
		},
		[maximumElectricityPrice],
	);

	useEffect(() => {
		const {mode = 'trickle'} = strat || {};

		const newStrategy = {
			mode
		};
		if(first && disableFirst){
			setFirst(false);
		}
		else{
			setStrategy(newStrategy);
		}
	}, [strat]);

	const allModes = chargerType === 1 ? [...modes, ...extraModes] : modes;
	const options = allModes.map( ({value, label, Icon}, idx) => (
		<MenuItem value={value} key={idx}>
			<ListItemIcon>
				<Icon />
			</ListItemIcon>
			<Typography variant="inherit">
				{label}
			</Typography>

		</MenuItem>
	));

	const modeObj = allModes.filter( m => m.value === mode ).pop() || {};
	const { explanation = '', controlText, exampleText } = modeObj;

	const handleChange = (ev, val) => {
		const {[mode]: { valueFun } = {}} = sliderValues;

		if (valueFun) {
			setCharger(
				valueFun(val),
			)
		} else {
			console.log('Value Set Function not found', mode, sliderValues);
		}
	}

	const localSetter = (ev) => {
		const {[mode]: { localValueSet } = {}} = sliderValues;

		if (localValueSet) {
			localValueSet(ev.target.value);
		} else {
			console.log('Local Value Set Function not found', mode, sliderValues);
		}
	}

	/*
		<div>Start time <TimePicker locale={'fr-BE'} clearIcon={null} disableClock value={startTime} onChange={onChangeTime} disabled={!selected} /></div>
			<div><TextField label="Duration (hrs)" type="number" value={duration} onChange={change('duration')} disabled={!selected}/></div>
			<div><TextField label="Minimum EV range (km)" type="number" value={autonomy} onChange={change('autonomy')} disabled={!selected}/></div>
	*/
	const classNameStr = "ev-component-simple-strategy" + (className ? className : '');

	const sliderVals = sliderValues[mode];

	const phaseChange = (ev) => {
		setCharger({numberOfPhases :ev.target.value});
	}

	const exampleTooltip = exampleText ? <><br/>Example: {exampleText}</> : null;
	const tooltipMode = (
		<div>
			{explanation}
			{exampleTooltip}
		</div>
	);

	return (
		<div className={classNameStr}>
			<FormControl>
				<InputLabel>
					Charging mode
				</InputLabel>
				<div style={{display: 'flex'}}>
					<Select value={mode} onChange={change('mode')} disabled={!selected || loading} style={{'flex': 5}}>
						{options}
					</Select>
					{ 
						explanation ? 
							<Tooltip title={tooltipMode} placement="top">
								<IconButton>
									<HelpIcon />
								</IconButton>
							</Tooltip>
							: null 
					}
				</div>
			</FormControl>
			{ showSlider ? <div className="ev-charger-slider-elements-div">
				<InputLabel>
					{sliderVals.label}
				</InputLabel>
				<Slider 
					disabled={loading || !selected}
					valueLabelDisplay={sliderVals.showValue ? 'on' : 'off'}
					value={sliderVals.value}
					step={sliderVals.step}
					max={sliderVals.max}
					onChange={localSetter}
					onChangeCommitted={handleChange}
					marks={sliderVals.marks || []}
				/>
				{
					controlText ?
						<Tooltip title={controlText} placement="top">
							<IconButton>
								<HelpIcon />
							</IconButton>
						</Tooltip>
						: null 
				}
			</div> : null }
			<FormControl>
				<InputLabel>
					Number of phases
				</InputLabel>
				<Select 
					disabled={loading || !selected}
					label="Number of phases"
					value={numberOfPhases}
					onChange={phaseChange}
				>
					<MenuItem value={1}>1</MenuItem>
					<MenuItem value={3}>3</MenuItem>
				</Select>
			</FormControl>
		</div>
	);
}

function StrategyAdvanced(props){
	return (
		<div>
			Advanced
		</div>
	);
}

function Strategy(props){
	const {className, ...rest} = props;
	// Will need to add the advanced strategy part here
	return (
		<div className={`${className}`}>
			<StrategySimple {...rest}/>
		</div>
	);
}

function ChargerDiagnostics(props){
	const { diagnostic } = props;
	const { status, details } = diagnostic || {};
	const [expanded, setExpanded] = useState(false);

	return (
		<Accordion expanded={ expanded } onClick={ () => setExpanded(!expanded) }>
			<AccordionSummary>
				<div className="ev-charger-diagnostic-accordion">
					<div>Diagnostic</div>
					{ !expanded ? <div className='ev-charger-diagnostic-summary'>{details}</div> : null }
					<div className={status ? "ev-charger-diagnostic-accordion-status-good" : "ev-charger-diagnostic-accordion-status-bad"}>{ status ? <CheckIcon /> : <ClearIcon /> }</div>
				</div>
			</AccordionSummary>
			<AccordionDetails>
				{details || 'No diagnostic information available at this time'}
			</AccordionDetails>
		</Accordion>
	);
}

function Charger(props){
	const { state = {}, children, label, onClick, image, charger, setCharger = () => {}, valid, onChange = () => {} } = props;
	const { strategy: existingStrategy = {}, chargerSettings} = charger || {};

	const components = { Info : {}, Strategy };
	const [ component, setComponent ] = useState('Info');
	const [ strategy, setStrategy ] = useState(existingStrategy);
	const validStrat = Object.entries(strategy).every( ([k, v]) => v !== undefined);
	const childCName = component !== "Info" ? 'ev-component-display-none' : undefined;
	const setChargerInside = (newCharger) => setCharger({...(charger ? charger : {}), ...newCharger});
	const childProps = { ...props, charger, setCharger: setChargerInside };
	const stratProps = { ...props, charger, setCharger: setChargerInside, className: component !== "Strategy" ? 'ev-component-display-none' : undefined, selected: Boolean(charger), strategy, setStrategy, chargerSettings };

	const childrenWithProps = React.Children.map(children, child => {
		// Checking isValidElement is the safe way and avoids a typescript error too.
		if (React.isValidElement(child)) {
			return React.cloneElement(child, { ...childProps });
		}
		return child;
	});

	useEffect( () => {
		setCharger({strategy});
	}, [strategy]);

	useEffect( () => {
		console.log("I WAS CHANGED", charger);
	}, [charger])

	useEffect( () => {
		const validStrat = Object.entries(strategy).every( ([k, v]) => v !== undefined);

		onChange(valid && validStrat);

	}, [valid, strategy]);


	const tabStyle = {};
	const tabsProps = {
		orientation: "vertical",
		className: "ev-charger-tabs",
		value: component,
		onChange: (e, value) => setComponent(value)
	};

	const tabs = Object.entries(components).map(([name, Component]) => {
		const { strategy } = charger || {};
		const IconComponent = name === "Info" ? AppsIcon : SettingsInputComponentIcon;
		const tabInvisible = name === "Info" ? (valid || !Boolean(charger)) : (validStrat || !Boolean(charger));
		const Icon = (<Badge color="secondary" variant="dot" invisible={tabInvisible}><IconComponent /></Badge>);

		return <Tab icon={Icon} key={name} value={name}/>
	});
	const control = (
		<Checkbox onClick={() => onClick(charger)} checked={Boolean(charger)} />
	);
	const labelComp = (
		<>
			{image ? <img src={image} className="ev-charger-accordion-image"/>: null}
			{label}
		</>
	);

	return (
		<Accordion className="ev-charger-details">
			<AccordionSummary aria-label="Expand" expandIcon={<ExpandMoreIcon />}>
				<FormControlLabel
					aria-label="Choose"
					onClick={(event) => event.stopPropagation()}
					onFocus={(event) => event.stopPropagation()}
					control={control}
					label={labelComp}
				/>
			</AccordionSummary>
			<AccordionDetails>
				<Tabs {...tabsProps}>
					{tabs}
				</Tabs>
				{childCName ? 
					<div className={childCName}>{childrenWithProps}</div> : 
					<>{childrenWithProps}</>
				}
				<Strategy {...stratProps} />
			</AccordionDetails>
		</Accordion>
	);
}

function ChargerInfo(props){
	const { className, charger, brand, submitCharger, loading, id } = props;
	const { enabled, model, status, diagnostic, serial } = charger || {};

	const disabled = async () => {
		const r = window.confirm(`Forget ${brand} ${id}? FlexIO will no longer control this device`);

		try{
			if(r){
				delete charger.token;
				await submitCharger(brand, id, null);
			}
			else console.log("NOT DOING IT");
		}
		catch(e){
			console.log("ERROR", e);
		}
	}

	const chargerEnable = async (ev) => {
		await submitCharger(
			brand,
			id,
			merge({}, charger, { enabled: ev.target.checked }),
		);
	}

	const statusTable = {
		'-1': 'Unavailable',
		0: 'Charging',
		1: 'Available',
		2: 'Battery Full',
	};

	const classNameStr = `ev-charger-info ${(className ? className : '')}`;
	const statusText = status in statusTable ? statusTable[status] : 'Unavailable';
	const control = (<Switch checked={enabled} disabled={loading} onChange={chargerEnable} />);
	const alertSeverity = statusText === 'Unavailable' ? 'warning' : 'success';

	return (
		<div className={classNameStr}>
			<FormControlLabel
				labelPlacement="start"
				className='ev-charger-enable'
				control={control}
				label="Controlled"
			/>
			<div className="ev-charger-info-box">
				<Alert severity={alertSeverity}>
					{`Status: ${statusText}`}
				</Alert>
				<ChargerDiagnostics diagnostic={diagnostic} />
				<Button component='div' onClick={disabled} startIcon={<DeleteIcon />}>
					Forget
				</Button>
			</div>
		</div>
	);
}

function ExistingCharger(props){
	const [ component, setComponent ] = useState('Info');
	const { charger, brand, submitCharger, loading, id } = props;
	const { strategy } = charger;
	const tabsProps = {
		className: "ev-charger-tabs",
		value: component,
		onChange: (e, value) => setComponent(value)
	};

	const components = { Info: ChargerInfo, Strategy: StrategySimple };
	const tabs = Object.entries(components).map(([name, Component]) => {
		const IconComponent = name === "Info" ? AppsIcon : SettingsInputComponentIcon;
		const Icon = (<IconComponent />);

		return <Tab icon={Icon} key={name} value={name}/>
	});
	const setStrategy = (newStrat) => {
		console.log("CHANGEING", newStrat, strategy)
	}
	const infoProps = {
		className: component !== "Info" ? 'ev-component-display-none' : undefined,
		brand,
		charger,
		submitCharger,
		loading,
		id,
	};
	const stratProps = {
		className: component !== "Strategy" ? ' ev-component-display-none' : undefined,
		existing: true,
		setStrategy: async (newStrategy) => {
			await submitCharger(
				brand,
				id,
				merge({}, charger, { strategy: newStrategy }),
			);
		},
		setCharger: async (newCharger) => {
			await submitCharger(
				brand,
				id,
				merge({}, charger, newCharger),
			);
		},
		strategy,
		charger,
		submitCharger,
		loading,
		selected: true,
		disableFirst: true,
		brand,
	};

	return (
		<div>
			<Tabs {...tabsProps}>
				{tabs}
			</Tabs>
			<ChargerInfo {...infoProps}/>
			<StrategySimple {...stratProps}/>
		</div>
	);
}

//export default connect((state) => ({ chargers: state.chargers }), { setChargers }, null)(Charger);
export {modes, ExistingCharger};
export default Charger;

