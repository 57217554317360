import React, { useState, useEffect } from 'react';
import { AmplifyProvider, Authenticator, useAuthenticator, SelectField, PhoneNumberField, CheckboxField } from '@aws-amplify/ui-react';
import { Amplify, Auth, API } from 'aws-amplify';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';

import { validateIBAN, isValidIBAN } from 'ibantools';

import '@aws-amplify/ui-react/styles.css';

import './login-styles.scss';

import LifepowrLogo from "@lifepowr/components/src/assets/logos/LPLogo.png";

import { useTerms } from "../terms";

import getClientInfo from "../../clients";

function providerHoC(Component, type = 'consumer') {
	const { Provider } = Authenticator;
	const NewComponent = checkLogin(Component, type);

	return (props) => {
		return (
			<Provider>
				<NewComponent />
			</Provider>
		);
	}
}

/**
 * Higher order component that checks whether the user's login credentials exist (meaning that the user was properly authorized)
 * @param  {React.Component} Component The Component to wrap
 * @return {function}                  The wrapped Component
 */
function checkLogin(Component, type = 'consumer') {
	const formFields = {
		signIn: {
			username: {
				labelHidden: false,
				placeholder: 'Email',
				label: 'Email:'
			},
			password: {
				labelHidden: false,
				placeholder: 'Password',
				label: 'Password:'
			},
		},
		signUp: {
			username: {
				label: 'Email',
				placeholder: 'Email',
				isRequired: true,
				labelHidden: false,
			},
			password: {
				label: 'Password',
				placeholder: 'Password',
				isRequired: true,
				labelHidden: false,
			},
			'custom:firstName': {
				label: 'First Name',
				placeholder: 'First Name',
				isRequired: true,
				labelHidden: false,
			},
			'custom:lastName': {
				label: 'Last Name',
				placeholder: 'Last Name',
				isRequired: true,
				labelHidden: false,
			},
			'custom:iban': {
				label: 'IBAN',
				placeholder: 'eg: BEXXXXXXXXXXXXXX',
				isRequired: false,
				labelHidden: false,
			},
		},
		resetPassword: {
			username: {
				placeholder: 'Enter your email',
			},
		},
	};


	const services = {
		async handleSignUp(formData) {
			const regex = / /gi;
			let { username, password, attributes } = formData;
			// custom username
			username = username.replace(regex, '');
			username = username.toLowerCase();
			return Auth.signUp({
				username,
				password,
				attributes,
			});
		},
		async handleSignIn(formData) {
			const regex = / /gi;
			let { username, password, attributes } = formData;
			// custom username
			username = username.replace(regex, '');
			username = username.toLowerCase();
			return Auth.signIn({
				username,
				password,
				attributes,
			});
		},
		async handleForgotPassword(username) {
			const regex = / /gi;
			let newUsername = username.replace(regex, '');
			newUsername = newUsername.toLowerCase();
			return Auth.forgotPassword(newUsername);
		},
		async handleForgotPasswordSubmit(formData) {
			const regex = / /gi;
			let { username, code, password } = formData;
			username = username.replace(regex, '');
			username = username.toLowerCase();
			return Auth.forgotPasswordSubmit(username, code, password);
		},
		async validateCustomSignUp(formData) {
			if (!formData["custom:tocversion"]) {
				return {
					'custom:terms': 'You must agree to the Terms & Conditions',
				};
			}
			if (formData["custom:iban"] && !isValidIBAN(formData["custom:iban"])) {
				return {
					'custom:iban': 'Invalid IBAN',
				};
			}
		},
	};

	const hideSignUp = true;//type === 'consumer' ? false : true;

	const Header = () => {

		const clientInfo = getClientInfo();

		return (
			<div style={{ width: '100%', marginBottom: '20px', display: 'grid', justifyContent: 'center' }}>
				{clientInfo?.logo ? (
					<clientInfo.logo />
				) : (
					 <img src={LifepowrLogo} alt='lplogo'/> 
				)}
			</div>
		);
	};
	const components = {
		Header,

		SignUp: {
			FormFields: () => {
				const [open, setOpen] = useState(false);
				const { validationErrors } = useAuthenticator();
				const termsObj = useTerms();
				const { terms, version, error } = termsObj || {};
				let strRes = error ? error : 'Loading . . .';

				const Label = () => {
					return (
						<>
							I accept Lifepowr's <div onClick={(ev) => { ev.preventDefault(); setOpen(true); }} className="login-checkbox">terms of use</div>
						</>
					);
				}

				return (
					<>
						{/* Re-use default `Authenticator.SignUp.FormFields` */}
						<Authenticator.SignUp.FormFields />

						{/* Append & require Terms & Conditions field to sign up  */}
						<SelectField
							name="custom:language"
							label="Language"
							isRequired
						>
							<option value="En">English</option>
							<option value="Nl">Nederlands</option>
						</SelectField>

						<CheckboxField
							hasError={!!validationErrors.acknowledgement}
							name="custom:tocversion"
							label={<Label />}
							value={version}
							isRequired
						/>

						<Dialog
							open={open}
							onClose={() => setOpen(false)}
							scroll='paper'
						>
							<DialogTitle>
								Lifepowr Terms and Conditions
							</DialogTitle>
							<DialogContent style={{ paddingLeft: '35px' }} dangerouslySetInnerHTML={{ __html: terms ? terms : strRes }} />
							<DialogActions>
								<Button onClick={() => setOpen(false)}>Close</Button>
							</DialogActions>
						</Dialog>
					</>
				);
			}
		}
	}

	return (props) => {
		const terms = useTerms();
		const auth = useAuthenticator();
		const { user, signOut, authStatus } = auth;
		const [clientInfo, setClientInfo] = useState(getClientInfo());
		const [userNumbers, setUserNumbers] = useState(0);

		useEffect(
			() => {
				const clientInfo = getClientInfo();
				console.log('useffect clientinfo : ', clientInfo);
				if (clientInfo) {
					setClientInfo(clientInfo);
				}
			},
			[]
		);

		useEffect(
			() => {
				if (clientInfo?.idp && authStatus === 'unauthenticated') fedSignIn(clientInfo.idp);
			},
			[clientInfo, authStatus],
		)

		// Still have to copy cookies around unfortunately =/
		window.document.cookie.split(";").forEach((c) => {
			const [k, v] = c.split("=");

			if (k && k.includes("CognitoIdentityServiceProvider")) {
				localStorage.setItem(k.trim(), v.trim());
			}
		});

		const fedSignIn = async (idp) => {
			try {
				await Auth.federatedSignIn({ customProvider: idp });
			} catch (error) {
				console.log("error signing in", error);
			}
		}

 		const loginClass = clientInfo && clientInfo.loginClass ? clientInfo.loginClass : 'loginForm';
		
		return (
			<Authenticator className={loginClass} services={services} formFields={formFields} hideSignUp={hideSignUp} components={components}>
				<Component {...props} />
			</Authenticator>
		);
	}
}

export default providerHoC;
