import { useRef, useEffect, useCallback } from 'react';
import { PubSub, Auth } from 'Amplify';
import { nanoid } from 'nanoid';

function useSocket() {

	const subs = useRef({});

	useEffect(() => {
		const unsubscribeSubtopic = ([topic, sub]) => sub && sub.unsubscribe()
		return () => Object.entries(subs.current).forEach(unsubscribeSubtopic);
	}, []);

	const subscribe = useCallback(
		(topic, cb) => {
			const { current } = subs;
			if (!(topic in current)) {
				subs.current = {
					...current,
					[topic]: PubSub.subscribe(topic).subscribe({
						next: data => cb(data),
						error: error => console.error(error),
						close: () => console.log(`subscribe topic[${topic}] Done`),
					})
				};
			}
		},
		[],
	);

	const unsubscribe = useCallback(
		(topic) => {
			const { current } = subs;
			const { [topic]: sub, ...rest } = current;

			if (sub) sub.unsubscribe();
			subs.current = rest;
		},
		[],
	)

	const publish = useCallback(
		(topic, body, reqReply = false, timeout = 5000) => {
			// const { current : userToken } = token;
			return new Promise( async (resolve, reject) => {
				// const res = await Auth.currentUserCredentials();
				const userToken = (await Auth.currentSession()).getAccessToken().getJwtToken();
				let sub, timer;
				try {
					if (!reqReply) {
						resolve(PubSub.publish(topic, body));
					}
					else {
						const correlationId = nanoid();
						const replyTo = nanoid();
						sub = PubSub.subscribe(`${topic}/response/${replyTo}`).subscribe({
							next: ({ value }) => {
								let processedValue = ((typeof value) === 'string') ? JSON.parse(value) : value;
								const { body: { code, result } = {}, correlationId: respCorrId } = processedValue;

								if (respCorrId === correlationId) {
									switch (code) {
										case 1:
											resolve(result);
											break;
										case -1:
											reject(`Task execution error: ${result}`);
											break;
										default:
											reject(`Unhandled return code '${code}' : ${result}`);
											break;
									};
									sub.unsubscribe();
									if(timer) clearTimeout(timer);
								}
							},
							error: error => console.error(error),
							close: () => console.log(`publish [subscribe] topic[${topic}] Done`),
						});
						if(timeout) timer = setTimeout(() => {
							reject(`Request timed out`);
						}, timeout);
						else sub.unsubscribe();
						PubSub.publish(topic, { replyTo, correlationId, body, userToken });
					}
				}
				catch (e) {
					if (sub) sub.unsubscribe();
					reject(e);
				}
			});
		},
		[],
	);

	return { publish, subscribe, unsubscribe };
}

export default useSocket;