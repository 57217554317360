import React, { useState, useRef, useEffect } from 'react';
import './device-details.styles.scss';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import CircularProgressLoader from '@lifepowr/components/src/components-shared/circular-progress-loader-component/circular-progress-loader.component';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CheckIcon from '@mui/icons-material/Check';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import Tooltip from '@mui/material/Tooltip';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import Stack from '@mui/material/Stack';
import DeviceOnboardingDialog from '../device-config-wizard-component';

// import Form from "@rjsf/core";
// import Form from '@rjsf/material-ui';
//import { MuiForm5 as Form } from '@rjsf/material-ui';

import { useLocation, useHistory } from 'react-router-dom';

import validator from '@rjsf/validator-ajv8';
import Form from '@rjsf/mui';
import useMediaQuery from '@mui/material/useMediaQuery';
import { connect } from 'react-redux';
import { Auth, API } from 'Amplify';

// import Inspector from 'react-inspector'

// import FormInput from '@lifepowr/components/src/components-shared/form-input/form-input.component';
// import House from '@lifepowr/components/src/assets/images/image-device-details-house.png';

import { merge, cloneDeep, mergeWith, omitBy, isNull, get } from "lodash";
import { nanoid } from "nanoid";

import useSocket from '@lifepowr/components/src/components/socket/useSocket';

import schemaFuncs from './uiSchema';
import _formSchema from './formSchema.json';
// TODO: Remove the type check once INFRA-24 is done.
import legacyFormSchema from './legacyFormSchema.json'
import Converters from './converters';

import balancingSchema from './balancingSchema';
import balancingUiSchema from './balancingUiSchema';

import customizer from '../../../../utils/customizer';
import getClientInfo from '../../../../clients';
import { AccountStatus, generatedAccountStatus } from "@lifepowr/components/src/utils/account-status";
import Box from '@mui/material/Box';
import { generateConverterSchema, generateNewFormConfig, updateConvertersData } from './device-details-utils';

/*const formReducer = (state, event ) => {
    return { ...state, [event.name] : event.value }
}*/

const { makeUiSchema: uiSchemaMaker, filterSchemaProperties, 
    DEFAULT_SCHEMA_KEYS,
    USER_NONE_ADVANCED_SCHEMA_KEYS,  
} = schemaFuncs;

function Link(props){
    const { accountStatus, email, userMode, width, thingName } = props;
    const [ open, setOpen ] = useState(false);
    const [ linkEmail, setLinkEmail ] = useState('');
    const [ loading, setLoading ] = useState(false);
    const [ , setError ] = useState('');

    const emailChange = (ev, v) => {
        setLinkEmail(ev.target.value);
    };

    const submit = async (ev, type = 'link') => {
        const regex = / /gi;
        const processedEmail = linkEmail;
        const processedEmailNoWhite = processedEmail.replace(regex, '');
        const processedEmailLowerCase = processedEmailNoWhite.toLowerCase();

        // Need to find way to not send emails
        const body = { email: processedEmailLowerCase, /* noComms: true */ };

        if(type === 'unlink'){
            let r = window.confirm("Are you sure you want to unlink this account?");
            if(!r) return 0;
        }
        setLoading(true);
        try{
            if(type === 'link') await API.post("IO_API", `/devices/${thingName}/link`, { body });
            else if(type === 'unlink') await API.del("IO_API", `/devices/${thingName}/link`, { body });
            setError('');
            setLinkEmail('');
        }
        catch(e){
            const errBody = e?.response?.data;
            console.log("ERROR", e.response.data, errBody, e.response);
            window.alert(`ERROR: ${e.response.data}`);
            setError(errBody ? errBody : `${e}`);
        }
        setOpen(false);
        setLoading(false);
    }

    if(loading) return (<CircularProgressLoader />);

    //{ errorLink ? <div className='requestError'>Error: {errorLink}</div> : null }
    return (
        <>
            <Typography variant="h5"> Linked Account </Typography>
            <div>

                <Box pb={1} display="flex" gap={0.5} alignItems="center">
                    <AccountStatus status={accountStatus} />
                    { email ? `Current linked account email: ${email}` : 'Device not linked to an account' }
                    { !userMode ? <Button onClick={() => {email ? submit({}, 'unlink') : setOpen(true)}}>{email ? 'Unlink account' : 'Link Account'}</Button> : null }
                </Box>

                <Dialog fullWidth maxWidth={width} open={open} onClose={() => setOpen(false)}>
                    <DialogTitle>Link user account</DialogTitle>
                    <DialogContent>
                        <TextField value={linkEmail} onChange={emailChange} label="Account email"/>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={submit}>{loading ? 'Loading . . .' : 'Submit'}</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    );
}

const BALANCING_ACTIONS = {
    CANCEL: {
        action: 'cancel',
        label: 'Cancel balancing',
        message: 'You are about to remove all EAN data & FCR allocation & set an opt out flag on this device, are you sure you want to continue?',
        description: 'Removes all EAN data, FCR allocation & set an opt out flag on this device.',
    },
    REMOVE: {
        action: 'remove',
        label: 'Remove EAN',
        message: 'You are about to remove all EAN info for this device, are you sure you want to continue?',
        description: 'Removes all EAN data from this device.',
    },
    RESET: {
        action: 'reset',
        label: 'Reset',
        message: 'You are about to remove the opt out flag for this device, are you sure you want to continue?',
        description: 'Resets the opt out flag for this device.',
    },
};

function Balancing(props) {
    const { schema, uiSchema, formData, thingName, optOutFcr, balancing, admin } = props;
    const [currentData, setCurrentData] = useState(formData)
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [edit, setEdit] = useState(false);
    const { ean } = formData || {};
    const { fcr, reference } = balancing || {};
    const { bsp } = fcr || {};

    const disabled = loading || (ean && !edit);

    /* useEffect(
        () => {
            if(!ean) setEdit(true);
        },
        [ean]
    ); */

    // Must put here some other info regarding where I am in FCR
    // 1. Insert info for FCR - no ean
    // 2. User in waiting list - ean + elia id
    // 3. Info about current vDP - ean + elia id + vdp id

    const onSubmit = async (ev) => {
      setLoading(true);
      setError(null);
      try {
        const { formData: body } = ev;
        await API.post(
          "IO_API",
          `/devices/${thingName}/balancing`,
          { body }
        );
        setEdit(false);
      } catch (e) {
        setError(e.response.data);
      }
      setLoading(false);
    };

    const onAction = async ({ message, action }) => {
      const confirm = window.confirm(message);

      if (!confirm) return;

      setLoading(true);
      setError(null);

      try {
        await API.del(
          "IO_API",
          `/devices/${thingName}/balancing?action=${action}`
        );
        setCurrentData(undefined);
      } catch (e) {
        setError(e.response.data);
      }

      setLoading(false);
    };

    const onChange = (ev) => {
        setCurrentData(ev.formData);
    }

    const onToggleEdit = () => setEdit((o) => !o);

    const EanActionButton = ({ action, show }) =>
      show ? (
        <Tooltip title={action.description}>
          <Button
            variant="outlined"
            disabled={loading || edit}
            onClick={() => onAction(action)}
          >
            {action.label}
          </Button>
        </Tooltip>
      ) : null;
        
    const AdminContent = ({ showSeparator }) =>
      admin ? (
        <>
          {showSeparator && <Box borderRight="solid 1px gray" />}

          {/* Remove */}
          <EanActionButton action={BALANCING_ACTIONS.REMOVE} show={ean} />

          {/* Cancel */}
          <EanActionButton
            action={BALANCING_ACTIONS.CANCEL}
            show={!optOutFcr}
          />

          {/* Reset */}
          <EanActionButton action={BALANCING_ACTIONS.RESET} show={optOutFcr} />
        </>
      ) : null;


    if (optOutFcr) {
        return (
            <Stack spacing={2}>
                <Typography>You are opted out of balancing.</Typography>
                { error ? <Alert severity="error">{error}</Alert> : null }
                <Stack spacing={2} direction="row">
                    <AdminContent />
                </Stack>
            </Stack>
        );
    }
    
    if (ean && bsp) {
        return (
            <Stack spacing={2}>
                <Typography>Ready to participate</Typography>
                { /* reference ? <div>TSO reference: {reference}</div> : null */ }
                { /* bsp && admin ? <div>Service provider: {bsp}</div> : null */ }
                { error ? <Alert severity="error">{error}</Alert> : null }
                <Stack spacing={2} direction="row">
                    <AdminContent />
                </Stack>
            </Stack>
        );
    }
    
    return (
      <Stack spacing={2}>
        {reference ? <Typography>TSO reference: {reference}</Typography> : null}
        <Form
          schema={schema}
          uiSchema={uiSchema}
          validator={validator}
          onChange={onChange}
          formData={currentData}
          disabled={disabled}
          onSubmit={onSubmit}
          extraErrors={error ? { Error: { __errors: [error] } } : {}}
        >
          <Stack spacing={2} direction="row">

            {/* Submit */}
            {(!ean || edit) && <Button type="submit" disabled={loading} variant="contained">
                {loading ? <CircularProgress size={20} /> : "Submit"}
            </Button>}
            
            {/* Edit */}
            {(ean && !edit) && <Button variant="contained" onClick={onToggleEdit}>Edit</Button>}
            
            {/* Cancel */}
            {(ean && edit) && <Button variant="outlined" onClick={onToggleEdit}>Cancel</Button>}

            {/* Admin button */}
            <AdminContent showSeparator={true} />

          </Stack>
        </Form>
      </Stack>
    );
}

//
function Configuration(props){
    const { connected, settings, thingName, configuration, advanced, userMode } = props;
    const [ clientToken ] = useState(nanoid());
    const [ formData, setFormData ] = useState({settings, configuration: {}});
    const [ newFormConfig, setNewFormConfig ] = useState(formData);
    const [ newConf, setNewConf ] = useState(false);
    const [ deviceSchema, setDeviceSchema] = useState({});
    const [ confSchema, setConfSchema] = useState({});
    const [ schema, setSchema ] = useState({});
    const [ prefix, setPrefix ] = useState({});
    const [ retry, setRetry ] = useState(0);
    const [ error, setError ] = useState('');
    const [ errorFetchingSchemas, setErrorFetchingSchemas ] = useState('');
    const [ errorForm, setErrorForm] = useState({});
    const [ loading, setLoading ] = useState(0);
    const { publish = () => undefined, subscribe = () => undefined } = useSocket();
    const { converter = '' } = configuration || {};
    const { configuration: { converter : { brand } = {} } = {} } = newFormConfig || {};
    const [converterBrand, ...converterModelArr] = converter.split(' ');
    const converterModel = converterModelArr.join(' ');
    const convBrandCompare = newConf ? brand : converterBrand;
    const uiSchema = uiSchemaMaker(schema, newConf ? newFormConfig.settings : settings, newConf ? newFormConfig.configuration : configuration);
    const newUiSchema = {
        configuration: {
            "ui:options": {label: false},
            address: {
                "ui:options": {
                    classNames: 'address-container',
                    title: false,
                }
            },
            converter: {
                "ui:options": {
                    title: "",
                },
                brand: {
                    "ui:widget": "hidden"
                },
                model: {
                    "ui:title": 'Converter Model',
                    "ui:widget": (convBrandCompare === 'DefaultInverter' ? 'hidden' : undefined),
                }
            }
        },
        settings: {
            "ui:title": "",
            ...uiSchema
        }
    };

    const formRef = useRef(null);

    const validateOnChange = (changedKey) => {

        // We use setTimeout to: 
        //  - Access the latest version of state.errors (skip ahead racing conditions).
        //  - Enforce a re-render.

        setTimeout(() => {

            // Run form validation only when an error is found for a given changedKey 
            if (changedKey) {
                
                // Convert from `root_configuration_address` (example)
                const [, ...changedKeyPathArray] = changedKey.split('_');

                // To `configuration.address` (example)
                const changedKeyPath = ['', ...changedKeyPathArray].join('.');

                // Find the error related to changedKey using changedKeyPath
                const shouldRevalidate = formRef.current?.state.errors.find(({ property }) => changedKeyPath === property);

                // Run form validation when an error is found 
                if (shouldRevalidate) {
                    formRef.current?.validateForm();
                }
            }

        }, 250);        
    };

    const formProps = {
        schema,
        uiSchema: newUiSchema,

        formData,
        validator,
        extraErrors: errorForm || {},
        formContext: { clientToken },
        ref: formRef,
        className: `${newConf ? '' : 'configurationFormDisabled'} configurationForm confFormCommon`,
        disabled: !newConf,
        liveValidate: false,
        omitExtraData: true,
        showErrorList: false,
        // Run form validation on every blur event
        onBlur: () => formRef.current?.validateForm(),
        onChange: (ev, changedKey) => {

            validateOnChange(changedKey);

            const { errors = [], formData: newData } = ev;

            //
            setError('');
            setNewFormConfig(newData);

        }
    };

    const submit = async () => {
        // Only submit when the form is valid
        const formIsValid = formRef.current?.validateForm();
        if (!formIsValid) return;

        const { current : { state: { formData } } } = formRef;
        const { settings: formSettings, configuration } = formData || {};
        const { address, converter, reference } = configuration || {};
        const { brand, model } = converter || {};

        // formData.settings.nominalElectricityPrice += (settings.nominalElectricityPrice > 1 ? 0 : 5);

        setLoading(true);
        try{
            const mergedSettings = mergeWith(Object.create(settings), formSettings, customizer);
            const body = {
              formData: {
                settings: mergedSettings,
                configuration: {
                  address,
                  converter: `${brand} ${model}`,
                  reference,
                },
              },
              clientToken,
            };
            console.log("SENDING", thingName, clientToken, body);
            await API.post("IO_API", `/devices/${thingName}/config`, { body });
            setNewConf(false);
        }
        catch(e){
            console.log('ERROR', e);
            const errBody = e?.response?.data?.body || e;
            setErrorForm({
                "Request": {"__errors": [`${errBody}`]}
            });
        }
        setLoading(false);
    }

    const cancel = () => {
        setNewConf(false);

        // We use setTimeout to enforce a re-render
        setTimeout(() => {
            formRef.current?.validateForm();
        }, 250);
    }

    useEffect( () => {
        subscribe(`fleet/devices/${thingName}/delta/error`, ({ value }) => {
            const { clientToken: reqToken, error: reqError, delta } = value;

            if(clientToken === reqToken){
                setErrorForm({
                    "EMS": { "__errors": [`${reqError} - ${JSON.stringify(delta)}`] }
                });
            }
        });
    }, []);

    useEffect( () => {
        const { current } = formRef;

        if(current){
            const { formElement } = current;
            if(error && formElement){
                // Assuming first one, must consider further queries if something else
                const [el] = formElement.querySelectorAll('.MuiSvgIcon-colorError');
                if(el) el.parentElement.parentElement.parentElement.parentElement.parentElement.scrollIntoView();
            }
        }

    }, [error]);

    // Todo: To improve performance, run this check only when `brand` or `model` values change.
    useEffect(
        () => {
            const newFormData = updateConvertersData(newFormConfig, formData);

            // ABSOLUTELY NECESSARY MAGIC - MAYBE RJSF v6 FIXES THIS
            // CONSIDER USING THIS FOR ALL ONEOF, ANYOF AND ALLOF FIELDS
            const base = document.getElementById('root_settings_converters_0_battery__anyof_select')
            const defs = schema?.definitions || {};
            let found = null;
            Object.entries(defs).forEach(([k, v])=>{
                const { title } = v || {};
                const { textContent } = base || {};

                if (title && textContent && title === textContent) {
                    const { properties = {} } = v || {};
                    const conv = newFormData?.settings?.converters;
                    const battery = conv[0]?.battery;
                    let mustUpdate = false;
                    Object.entries(properties).forEach(([k1, v1]) =>{
                        // console.log('PROBLEM?', k1, v1, battery);
                        if (!(k1 in battery) || battery[k1] === undefined) {
                            // console.log('PROBLEM', k1, v1, battery);
                            const { default: df } = v1;
                            battery[k1] = df;
                        }
                    });
                }
            });
            // END OF ABSOLUTELY NECESSARY MAGIC
            setFormData(newFormData);
        },
        [newFormConfig],
    )

    useEffect(() => {
      if (newConf) {
        setErrorForm({});
      } else {
        setNewFormConfig(generateNewFormConfig(configuration, settings));
      }
    }, [settings, configuration, newConf]);

    useEffect( () => {
        async function getSchemas(thingName){
            setLoading(true);
            setErrorFetchingSchemas(false);
            const prefix = `fleet/devices/${thingName}`;
            const result = await publish(`${prefix}/state/interact`, {task:'schemas', args: {type: 'settings'}}, true);
            const uiSchema = uiSchemaMaker(result);
            
            // By default we show limited set of configurations for end users.
            // Otherwise show the default set.
            const keysToKeep = userMode && !advanced ? USER_NONE_ADVANCED_SCHEMA_KEYS : DEFAULT_SCHEMA_KEYS;
            const fProps = filterSchemaProperties(result, keysToKeep);
            const slicedSchema = Object.fromEntries( Object.entries(result).filter(([k,v]) => !['properties', 'minProperties', 'title', 'description', 'required'].includes(k) ))
            const required = [];
            /*const slicedProperties = Object.fromEntries(
                Object.entries(uiSchema).map( ([k, v]) => {
                    const { [k]: sVal } = result.properties || {};

                    if(k && sVal){
                        required.push(k);
                        return [k, sVal];
                    }
                }).filter(v => v)
            );
            slicedProperties.tariffStructure.oneOf = slicedProperties.tariffStructure.anyOf;
            delete slicedProperties.tariffStructure.anyOf;
            fProps.tariffStructure.oneOf = fProps.tariffStructure.anyOf;*/

            setPrefix(slicedSchema)
            setDeviceSchema({
                type: 'object',
                properties: fProps,
                title: "",
                required,
            });
        }
        getSchemas(thingName)
            .catch((e) => {
                console.log("PROBLEM GETING SCHEMAS", e);
                setErrorFetchingSchemas(true);
            })
            .finally( () => {
                setLoading(false);
            });
    }, [connected, retry, thingName, advanced]);

    useEffect(
        () => {
            // TODO: Remove the type check once INFRA-24 is done.
            const formSchema = (typeof configuration.address) === 'string' ? legacyFormSchema : _formSchema;
            formSchema.properties.converter = generateConverterSchema(configuration);
            setConfSchema(formSchema);
        },
        [configuration, Converters]
    );

    useEffect(
        () => {
            // VERY DEPENDENT ON THE SCHEMA AND WHERE THE BATTERY PROPERTY IS //
            // Current reference:
            // https://bitbucket.org/lifepowr/io-energy-management-strategy/src/master/schemas/variables.json
            const { converters = [], tariffStructure } = settings || {};
            const [ { battery: { batteryType = 'None' } = {} } = {} ] = converters;
            const { tariffType = '' } = tariffStructure || {};
            // Replacing any unwanted characters
            const workedType = batteryType.replace(/[.\s]/g, '');
            const workedTariffType = tariffType.toLowerCase();
            const limitMatch = 6;
            const obj = prefix?.definitions?.Converter?.properties?.battery?.anyOf || [];
            const obj2 = deviceSchema?.properties?.retrofitPVMeasurement;
            const obj3 = deviceSchema?.properties?.tariffStructure?.anyOf || [];

            const tests = obj.map( ({"$ref": ref}, idx) => {
                const [, , refName = ''] = (ref || '').split('/');
                const wrokedRef = refName.replace('Battery', '').toLowerCase();
                let typeTemp = workedType.toLowerCase();
                let matchFound = false;
                while(!matchFound && typeTemp.length > 0){
                    if(wrokedRef.includes(typeTemp)) matchFound = true;
                    else typeTemp = typeTemp.slice(0, -1);
                }
                return [idx, typeTemp.length >= limitMatch ? typeTemp.length : 0];
            });
            const tests2 = obj3.map( ({"$ref": ref}, idx) => {
                const [, , refName = ''] = (ref || '').split('/');
                const wrokedRef = refName.toLowerCase();
                let typeTemp = workedTariffType.toLowerCase();
                let matchFound = false;
                while(!matchFound && typeTemp.length > 0){
                    if(wrokedRef.includes(typeTemp)) matchFound = true;
                    else typeTemp = typeTemp.slice(0, -1);
                }
                return [idx, typeTemp.length >= limitMatch ? typeTemp.length : 0];
            });
            const found = tests.reduce( (prev, current, idx) => current[1] > prev[1] ? current : prev, tests[0]);
            const found2 = tests2.reduce( (prev, current, idx) => current[1] > prev[1] ? current : prev, tests2[0]);
            if(found){
                let removed = obj.splice(found[0], 1);
                obj.splice( 0, 0, removed[0] );
            }

            if(found2){
                let removed = obj3.splice(found2[0], 1);
                obj3.splice( 0, 0, removed[0] );
            }

            if (obj2) {
                obj2.enumNames = ["None", "InverterPvRetro", "EastronPvRetro"];
            }
            //                      END OF STUPID THING                       //

            const _schema = {
                ...prefix,
                properties: {
                    configuration: confSchema,
                    settings: deviceSchema,
                },
            };

            if (userMode && !advanced) {
                delete _schema.properties.configuration;
            }

            setSchema(_schema);
        },
        [confSchema, deviceSchema, prefix, settings, userMode, advanced],
    )

    if(loading) return (<CircularProgressLoader />);
    if(errorFetchingSchemas) return (<Alert severity="error" action={<Button onClick={() => setRetry(old => old + 1)}>Retry</Button>}>Device is unavailable</Alert>);

    return (
        <>
            <div className="device-details-form-title">
                <div className="device-details-form-title-buttons">
                    {
                        newConf ?
                            <>
                                <Button disabled={loading} onClick={submit}>Submit</Button>
                                <Button disabled={loading} onClick={cancel}>Cancel</Button>
                            </>
                         : <Button disabled={!connected} onClick={() => setNewConf(true)}>New configuration</Button>
                    }
                </div>
            </div>
            { !connected ?
                <div>
                    <span>Device is unavailable <Button onClick={() => setRetry(old => old + 1)}>Retry</Button> </span>
                </div>:
                <div className="device-details-form-wrapper">
                    {
                        settings ?
                            <Form {...formProps}><div></div></Form> :
                            <div>No requested configuration found</div>
                    }
                </div>
            }
        </>
    );
}

/**
 * @returns A component with a form and a set of inputs for this device.
 */
const DeviceDetails = ({ admin, userMode, shadow, deviceId, attributes, connected, global: g }) => {
    const location = useLocation()
    const history = useHistory()
    const { desired : { configuration, balancing } = {}, reported: { settings, configuration: configurated } = {} } = shadow || {};
    const { reference, address, converter, addressObj } = configurated || {};
    const batType = shadow?.desired?.configuration?.installation?.battery?.batteryType;
    const { account, email } = attributes || {};
    const accountStatus = generatedAccountStatus(account, email);
    const [ errorLink, setErrorLink ] = useState('');
    const thingName = deviceId;
    const { advanced } = g;

	const [activateOnboarding, setActivateOnboarding] = useState(false);
    const [expanded, setExpanded] = useState('config');
    const [userAttrs, setUserAttrs] = useState(null);
    const [retry, setRetry] = useState(0);
    const [balancingSchemaEdited, setBalancingSchemaEdited] = useState(balancingSchema);
    const { ean, optOutFcr, fcr = {} } = balancing || {};
    const { bsp } = fcr || {};
    const formData = {
        ean
    };

    let BalanceIcon = WarningAmberIcon;
    let balanceColor = 'red';
    let tooltip = 'Balancing information is required';
    if (ean && optOutFcr && false) {
    } else if (ean && !bsp) {
        BalanceIcon = TimelapseIcon;
        balanceColor = 'gold';
        tooltip = 'Evaluation';
    } else if (ean && bsp) {
        BalanceIcon = CheckIcon; //CheckIcon;
        balanceColor = 'green';
        tooltip = 'Ready to participate';
    }

    useEffect(
        () => {
            Auth.currentUserInfo()
                .then(user => {
                    const { attributes = {} } = user || {};
                    const newAttrs = Object.fromEntries(
                        Object.entries(attributes).map(([k,v]) => [k.split(':').pop(), v]),
                    );
                    setUserAttrs(newAttrs);
                })
                .catch(err => {
                    console.error('User could not been retrieved ' +  err.log)
                });
        },
        [retry],
    );


    let width = 'xs';
    if(useMediaQuery('(min-width:900px)')) width = 'sm';
    if(useMediaQuery('(min-width:1200px)')) width = 'md';

    const configProps = {
        userMode, advanced, connected, settings, width, thingName, configuration: { reference, address: addressObj || address, converter }
    };

    const balancingProps = {
        schema: balancingSchemaEdited,
        uiSchema: balancingUiSchema,
        formData,
        thingName,
        optOutFcr,
        balancing,
        userMode,
        admin,
    }
    const linkProps = {
        accountStatus, email, width, userMode, thingName
    };

    let clientInfo = getClientInfo();

    const showEanFederated = clientInfo ? clientInfo?.ean : true;
    const showEanShow = true;

    const activateOnQueryParam = (queryParamName = "", callback = () => {}) => {
      const queryParams = new URLSearchParams(location.search);
      if (queryParams.has(queryParamName)) {
        if (callback) {
          callback();
        }
        queryParams.delete(queryParamName);
        history.replace({
          search: queryParams.toString(),
        });
      }
    };
    useEffect(() => {
      activateOnQueryParam("wizard", () => setActivateOnboarding(true));
    }, []); 
 
    return (
        <div className='device-details'>
            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Box>
                    <Link {...linkProps} />
                </Box>
                <Box alignSelf="end">
                    {/* { connected ? <Button onClick={() => setActivateOnboarding(true)} className='replace-button'>Config Wizard</Button> : null } */}
                    <DeviceOnboardingDialog isOpen={activateOnboarding} connected={connected} onClose={() => setActivateOnboarding(false)} />
                </Box>
            </Box>
            <Divider />
            { showEanFederated && showEanShow ? <Accordion expanded={expanded === 'balance'} onChange={() => setExpanded('balance')}>
                <AccordionSummary>
                    <Box display="flex" flexDirection="row" gap="10px" alignItems="center">
                        <Tooltip title={tooltip}>
                            <BalanceIcon sx={{ color: balanceColor }}/>
                        </Tooltip>
                        <Typography variant="h5">
                            Balancing
                        </Typography>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <Balancing {...balancingProps}/>
                </AccordionDetails>
            </Accordion> : null }
            <Accordion  expanded={expanded === 'config'} onChange={() => setExpanded('config')}>
                <AccordionSummary>
                    <Typography variant="h5">
                        Configuration
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Configuration {...configProps}/>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

export default connect(
    (state) => {
        const { global } = state || {};
        const {  shadow, attributes, connected  } = state.system || {};

        return { shadow, attributes, connected, global };
    },
    null,
    null
)(DeviceDetails);
