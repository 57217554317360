// import { omitBy, isNull } from 'lodash';

/**
 * [nestedOmit description]
 *
 * @param   {object} object [description]
 * @returns {object}        [description]
 */
function nestedOmit(object) {
  return Object.fromEntries(
    Object.entries(object).map(
      ([k, v]) => {
        let returned;
        if (
          (typeof v) === 'object'
          && !Array.isArray(v)
          && v !== null
        ) returned = [k, nestedOmit(v)];
        else if (v !== null) returned = [k, v];
        return returned;
      },
    ).filter((e) => e),
  );
}

export { nestedOmit };
