import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import OtpInput from "react-otp-input-rc-17";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CircularProgress from '@mui/material/CircularProgress';

import Inspector from 'react-inspector'

import { connect } from 'react-redux';
import { API, Auth } from 'Amplify';

import './styles.scss';
import Typography from "@mui/material/Typography";
import {Container, FormControl, Radio, RadioGroup} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';

import {groupDiagnostics} from "@lifepowr/components/src/utils/diagnostics";
import otpInputStyles from '../../../../utils/otp-input-styles';

function LogEntry(props){
	const {timestamp, message} = props;
	const timeStr = (new Date(timestamp)).toUTCString();

	let processedMessage = (<></>)

	try{
		processedMessage = (<Inspector data={JSON.parse(message)} />);
	}
	catch(e){
		console.log("BAD PROCESSING", e);
	}

	return (
		<Accordion>
			<AccordionSummary className="log-message">
				<div className="timestamp">{timeStr}</div>
				<div className="message">{message}</div>
			</AccordionSummary>
			<AccordionDetails className="message-details">
				{processedMessage}
			</AccordionDetails>
		</Accordion>
	);
}

// Test comment
function DeviceReplace(props){
	const { replace, setReplace } = props;
	const [claimCode, setClaimCode] = useState('');
	const [warning, setWarning] = useState(true);
	const [success, setSuccess] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const disabled = claimCode.length < 6;
	const { deviceName: thingName } = useParams();

	useEffect(
		() => {
			return () => {
				setClaimCode('');
				setWarning(true);
				setError(null);
				setSuccess(null);
				setLoading(false);
			}
		},
		[replace],
	);

	const replaceDevice = async () => {
		let result;
		setError(null);
		setSuccess(null);
		setLoading(true);
		try{
			result = await API.post(
				"IO_API",
				`/devices/${thingName}/replace`, { body: { code: claimCode } },
			);
			console.log(result);
			setSuccess(result);
		} catch (e) {
			setError(e?.response?.data || `${e}`);
		}
		setLoading(false);

		setTimeout(
			() => {

			},
			2000
		);
	}

	const codeChange = (ev) => {
		const newCode = ev.replaceAll(/[^a-zA-Z0-9]/g,"").toUpperCase();
		setClaimCode(newCode);
	}

	let buttons = (
		<DialogActions>
			<Button onClick={()=>setReplace(false)}>Cancel</Button>
			<Button disabled={disabled} onClick={()=>replaceDevice()}>Replace</Button>
		</DialogActions>
	);
	if (loading) buttons = (
		<CircularProgress />
	);
	else if (success) buttons = (
		<DialogActions>
			<Button onClick={()=>setReplace(false)}>Close</Button>
		</DialogActions>
	);

	return (
		<Dialog open={replace} onClose={()=>setReplace(false)}>
			<DialogTitle>Replace device</DialogTitle>
			<DialogContent>
				{
					error ?
						<Alert severity="error">
							<AlertTitle>Error</AlertTitle>
							An error ocurred: {error}
						</Alert> : null
				}
				{
					success ?
						<Alert severity="success">
							<AlertTitle>Success</AlertTitle>
							Success: {success}
						</Alert> : null
				}
				<DialogContentText>
					Please provide the replacement unit pairing code
				</DialogContentText>
				<OtpInput value={claimCode} numInputs={6} onChange={codeChange} containerStyle={otpInputStyles.container} inputStyle={otpInputStyles.inputStyle} />
				{ warning ?
					<Alert severity="warning"
						action={<Button onClick={() => setWarning(false)}>Understood</Button>}
					>
						<AlertTitle>Warning</AlertTitle>
						This action is irreversible. The replaced device will be rendered useless
					</Alert> : null
				}
			</DialogContent>
			{
				!warning ? buttons : null
			}
		</Dialog>
	);
}

function ForceUpdate({ isOpen, onClose }) {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [selectedFleetName, setSelectedFleetName] = useState(null);
  const { deviceName: thingName } = useParams();

  const initialize = () => {
    setSubmitted(false);
    setError(false);
    setSelectedFleetName(null);
    fetchTemplates();
  };

  const fetchTemplates = async () => {
    setLoading(true);
    try {
      const results = await API.get("IO_API",`/devices/${thingName}/update`);
      setTemplates(results.data);
    } catch (e) {
      setError(e?.response?.data || `${e}`);
    }
    setLoading(false);
  };

  const onSubmit = async () => {
    setError(null);
    setLoading(true);
    try {
      await API.post("IO_API", `/devices/${thingName}/update`, {
        body: { fleetName: selectedFleetName},
      });
      setSubmitted(true);
    } catch (e) {
      setError(e?.response?.data || `${e}`);
    }
    setLoading(false);
  };

  useEffect(() => {
    initialize();
  }, [isOpen]);

  return (
    <Dialog fullWidth maxWidth="sm" open={isOpen}>
      <DialogTitle>Update device</DialogTitle>
      <DialogContent>
        {/* List of templates */}
        {!error && !submitted ? (
          <DialogContentText>
            <Typography paddingBottom={2}>
              Select a template to update the device with:
            </Typography>
            <FormControl>
              <RadioGroup
                name="force-update-templates-radio-buttons-group"
                value={selectedFleetName}
                onChange={(event) => setSelectedFleetName(event.target.value)}
              >
                {templates.map((template) => (
                  <FormControlLabel
                    disabled={loading}
                    key={template}
                    value={template}
                    control={<Radio />}
                    label={template}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </DialogContentText>
        ) : null}

        {/* Success message  */}
        {submitted ? (
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            Your request has been submitted, a job will be create to update the
            device.
          </Alert>
        ) : null}

        {/* Error message */}
        {error && !submitted ? (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            An error ocurred: {error}
          </Alert>
        ) : null}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <LoadingButton
          onClick={onSubmit}
          loading={loading}
          hidden={!!submitted || !!error}
        >
          <span>Submit</span>
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

function DiagnosticDetails(props){
	const { name, details } = props;
	const logName = `diagnostics/${name}`;
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [logs, setLogs] = useState([]);
	const [display, setDisplay] = useState(true);
	const [tokens, setTokens] = useState([]);
	const [params, setParams] = useState({
		limit: 100,
		nextToken: null,
		range: 1000 * 24 * 60 * 60,
		forward: false
	});
	const { deviceName: thingName } = useParams();
	const ranges = [1, 2, 5, 7, 10, 14];
	const rangeButtons = ranges.map( noDays => {
		const timeRange = noDays * 1000 * 24 * 60 * 60;
		const rangeClick = (ev) => {
			setLogs([]);
			setParams(old => ({...old, range: timeRange, nextToken: null}));
		}
		const { range } = params;
		return (
			<button
				key={noDays}
				className={`range-button ${range === timeRange ? 'clicked' : ''}`}
				disabled={range === timeRange}
				onClick={rangeClick}
			>
				{`${noDays}d`}
			</button>
		);
	});

	let status;
	const displayEntries = logs
		.sort(({timestamp: tA},{timestamp: tB}) => Math.sign(tB - tA))
		.map((log, idx, arr) => {
			const old = idx > 0 ? arr[idx - 1] : {}
			const { timestamp: tsOld, message: msgOld = '{}'} = old;
			const { timestamp, message } = log;
			const { statusOld } = JSON.parse(msgOld);
			const { status: statusNew } = JSON.parse(message);
			if(timestamp === tsOld) return null;
			if(display) {
				if(status === undefined || statusNew !== status){
					status = statusNew;
					return log;
				} else return null;
			}
			return log;
		})
		.filter(log => log);

	const logEntries = displayEntries
		.map( ({timestamp, message}) => <LogEntry key={timestamp} timestamp={timestamp} message={message}/>);
		const [backToken, forwardToken] = tokens;
		const { nextToken: token } = params;

	let limitingStr = 'No entries found';
	let detailsStr = details;

	if(loading) limitingStr = 'Loading . . .';
	else if(error) limitingStr = error;

	useEffect( () => {
		setLoading(true);
		setError(null);
		API.get("IO_API", `/devices/${thingName}/logs`, { queryStringParameters: { logName, ...params } })
			.then( (res) => {
				const {backToken, forwardToken, logs = []} = res;
				const { forward } = params
				setLogs(old => {
					const newLogs = logs.filter( ({timestamp: tA}) => old.every( ({timestamp: tB}) => tB !== tA) );
					return [...old, ...newLogs];
				});
				setTokens(([oldB, oldF]) => [forward ? (oldB || backToken) : backToken, forward ? forwardToken : (oldF || forwardToken)]);
				setLoading(false);
			})
			.catch( (err) => {
				setError(`${err}`);
				setLoading(false);
				console.log("ERR", err);
			});
	}, [thingName, logName, params]);

	return (
		<div className="diagnosis-details" onClick={(ev) => ev.stopPropagation()}>
			<div className="diagnosis-message">
				<div>Current status message:</div>
				<div>{details ? detailsStr : 'N/A'}</div>
			</div>
			<FormGroup className="diagnosis-display">
				<FormControlLabel control={<Checkbox checked={display} onClick={() => setDisplay(old => !old)} />} label="Only status changes" />
			</FormGroup>
			<Button component="div" className="diagnosis-direction-forward" disabled={loading} onClick={() => setParams(old => ({...old, nextToken: forwardToken, forward: true}))}>{!loading ? 'Newer entries' : ' Loading ...'}</Button>
			<div className="diagnosis-logs"> {logEntries.length > 0 ? logEntries : (loading ? '' : 'No entries')} </div>
			<Button component="div" className="diagnosis-direction-back" disabled={loading} onClick={() => setParams(old => ({...old, nextToken: backToken, forward: false}))}>{!loading ? 'Older entries' : ' Loading ...'}</Button>
		</div>
	);
}

function DeviceDiagnostics(props){
	const { diagnostics = {}, connected, admin } = props;
	const [active, setActive] = useState(-1);
	const [replace, setReplace] = useState(false);
	const [forceUpdateDialogVisibility, setForceUpdateDialogVisibility] = useState(false);

	const diagnosticGroups = groupDiagnostics(diagnostics);

	const diagnosticGroupComponents = diagnosticGroups.map(([groupName, diagnosticsList]) => {
		const diagnosticComponents = diagnosticsList.map((diagnostic, groupIdx) => {
			const {internalName, humanName, status, details} = diagnostic;
			const idx = `${groupName}-${groupIdx}`;
			let detailsStr = details;

			if((typeof details) === 'object' && details !== null){
				detailsStr = JSON.stringify(details);
			}
			return (
				<Accordion key={internalName} expanded={ active===idx } onClick={ () => setActive((old) => old !== idx ? idx : -1 ) }>
					<AccordionSummary>
						<div className="device-diagnostics-accordion">
							<div>{humanName}</div>
							{ !(active===idx) ? <div className='device-diagnostics-accordion-details-summary'>{detailsStr}</div> : null }
							<div className={status ? "device-diagnostics-accordion-status-good" : "device-diagnostics-accordion-status-bad"}>{ status ? <CheckIcon /> : <ClearIcon /> }</div>
						</div>
					</AccordionSummary>
					<AccordionDetails>
						{ active === idx ? <DiagnosticDetails name={internalName} details={detailsStr} /> : null }
					</AccordionDetails>
				</Accordion>
			);
		})
		return (
			<React.Fragment key={groupName}>
				<Typography variant="h5">
					{groupName}
				</Typography>
				<div className="diagnostics-container">
					{diagnosticComponents}
				</div>
			</React.Fragment>
		);
	});

	return (
		<>
			{ connected && admin ? <Button onClick={() => setForceUpdateDialogVisibility(true)} className='replace-button'>Force update</Button> : null }
			{ !connected ? <Button onClick={() => setReplace(true)} className='replace-button'>Replace device</Button> : null }
			{ !connected ? <DeviceReplace replace={replace} setReplace={setReplace}/> : null }
			{ connected ? <ForceUpdate isOpen={forceUpdateDialogVisibility} onClose={() => setForceUpdateDialogVisibility(false)} /> : null }
			<Container>
				{diagnosticGroupComponents}
			</Container>
		</>
	)
}

const mapStateToProp = (state) => {
	const { diagnostics, connected } = state?.system?.shadow?.reported || {};
	const system = state?.system;
	return { diagnostics, connected };
}

export default connect(mapStateToProp, null, null)(DeviceDiagnostics);
