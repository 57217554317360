import { mergeWith } from 'lodash';

/**
 * [customizer description]
 *
 * @param   {object} oldVal [description]
 * @param   {object} newVal [description]
 * @param   {string} key    [description]
 * @returns {any}           [description]
 */
function customizer(oldVal, newVal, key/* , object, source, stack */) {
  if (
    (typeof newVal) === 'object'
    && !Array.isArray(newVal)
    && newVal !== null
  ) {
    const merged1 = Object.entries(newVal).reduce(
      (acc, [k, v]) => {
        const inner = oldVal[k];
        let innerMerged = v;
        if (
          (typeof inner) === 'object'
                && !Array.isArray(inner)
                && inner !== null
        ) innerMerged = mergeWith(v, inner, customizer);
        acc[k] = innerMerged;
        return acc;
      },
      oldVal,
    );
    const merged = Object.entries(merged1).reduce(
      (acc, [k, v]) => {
        if (k in newVal) acc[k] = v;
        else acc[k] = null;
        return acc;
      },
      {},
    );
    return merged;
  }

  if (newVal === undefined) return null;

  return newVal;
}

export default customizer;
